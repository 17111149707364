import { theme } from 'config/theme';
import { AlleHeader, Button, HaveQuestionsModal, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { AddressType } from 'lib/hooks/useMerchantDetail';
import useStore from 'lib/hooks/useStore';
import { FormatPhoneNumber } from 'lib/utils';
import React from 'react';
import styled from 'styled-components';

type AlleChoosePlanProps = {
  loading: boolean | undefined;
  phone: string | null;
  name: string | null;
  address: AddressType | null;
  gotoCall: () => void;
  getAmount: () => string | undefined;
  navigateToPlan: () => Promise<void>;
};

const AlleChoosePlan = ({
  getAmount,
  gotoCall,
  navigateToPlan,
  loading,
  address,
  name,
  phone,
}: AlleChoosePlanProps) => {
  const { organization } = useStore();

  return (
    <ContentContainer justify="space-between">
      <AlleHeader showBackButton={false} />
      <InnerContainer>
        <PageTitle m={'8px'} color={theme.main.black10} fontSize="18px" fontWeight={600} lineHeight="32px">
          Complete your purchase
        </PageTitle>
        <Paragraph m={'0 8px 24px'}>Your payment options are ready</Paragraph>

        <SuccessContainer>
          <ApprovalContainer>
            <Title
              fontSize="11px"
              fontWeight={400}
              color={theme.main.pinkishBeige}
              m={'10px 0px 0px 0px'}
              textAlign="center"
              fontFamily="Graphik"
            >
              YOUR PURCHASE
            </Title>

            <Title
              fontSize="46px"
              color={theme.main.white}
              fontWeight={400}
              useElegantFontType={true}
              m={'10px 0 0 0'}
              textAlign="center"
            >
              {getAmount()}
            </Title>

            <DetailInfoContainer>
              <Subtitle fontSize="16px" m={'24px 0px 10px 0px'} textAlign="center" fontWeight="700">
                {name || organization?.name}
              </Subtitle>

              {address && (
                <Subtitle fontSize="16px" m={'0 0 0 0'} textAlign="center">
                  {address?.street} {address?.zip}, {address?.city}, {address?.state?.name}
                </Subtitle>
              )}

              {(phone || organization?.phone) && (
                <Subtitle
                  fontSize="16px"
                  m={'0 0 0 0'}
                  textAlign="center"
                  onClick={gotoCall}
                  cursor="pointer"
                  underlined={true}
                >
                  {FormatPhoneNumber(phone || organization?.phone)}
                </Subtitle>
              )}
              <PaymentPlansTextContainer>
                <Subtitle fontSize="16px" m={'0 4px 0 0'} textAlign="center">
                  Payment plans by
                </Subtitle>
                <Icon src="cherry_white_logo" width={60} />
              </PaymentPlansTextContainer>
            </DetailInfoContainer>
            <Gap />
          </ApprovalContainer>
        </SuccessContainer>
        <Button
          data-testid="submit"
          onClick={navigateToPlan}
          loading={loading}
          disabled={loading}
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
          m="24px 0"
        >
          Choose My Plan
        </Button>
      </InnerContainer>
      <InnerContainer>
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_black'} />
          <InfoText>
            If the amount of your purchase is incorrect, please contact {organization?.name} so they can correct the
            error.
          </InfoText>
        </InfoContainer>
      </InnerContainer>
      <HaveQuestionsModal />
    </ContentContainer>
  );
};

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageTitle = styled(Title)`
  font-family: 'Graphik' !important;
`;

const Paragraph = styled(Subtitle)`
  font-family: 'Graphik' !important;
  font-size: 16px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const InfoText = styled.span`
  color: ${(props) => props.theme.main.black20};
  margin-left: 12px;
  font-family: 'Graphik';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.2px;
`;

const SuccessContainer = styled.div`
  background-color: ${(props) => props.theme.main.midnightGray};
  border: 16px solid ${(props) => props.theme.main.black10};
  border-radius: 4px;
`;

const ApprovalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px 8px 0 0;
  text-align: center;

  &::after {
    position: absolute;
    bottom: -20px;
    left: 0px;
    content: '';
    display: block;
    background: radial-gradient(circle, #ffffff00 20px, #ffffff 20px);
    background-size: 35px 40px;
    background-position: -20px -20px;
    width: 100%;
    height: 40px;
    z-index: -1;
  }
`;

const DetailInfoContainer = styled.div`
  margin: 0 16px;

  * {
    font-family: 'Graphik' !important;
    display: block;
    color: ${(props) => props.theme.main.grayishBeige};
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
`;

const Gap = styled.div`
  height: 24px;
`;

const PaymentPlansTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 24px auto 0;
  width: fit-content;

  * {
    text-transform: none;
    display: inline-block;
  }

  span {
  }
`;

export default AlleChoosePlan;
