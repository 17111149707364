import { useEffect } from 'react';
import styled from 'styled-components';

import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { TRACK_VERSION, WEBPREQUAL } from 'lib/constants';
import { useNavigation } from 'lib/hooks/useNavigation';

import { Container, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { useTranslation } from 'react-i18next';

const PosLinkCanceled = () => {
  const { trackPage } = useSegment();
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();

  const { organization } = useStore;

  useEffect(() => {
    trackPage('pocLinkCancelled', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
    });
  }, []);

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'warning_outline'} />
        <Title m={'10px 0px 8px 0px'} data-testid="poc-link-cancelled">
          {translate('posLinkCanceled.title')}
        </Title>
        <Subtitle m={'24px 0px 24px 0px'}>{translate('posLinkCanceled.subTitle')}</Subtitle>

        <MessageTitle>{translate('posLinkCanceled.info')}</MessageTitle>
        <Subtitle m={'0px 0px 24px 0px'}>
          {translate('posLinkCanceled.link', {
            name: organization?.name,
          })}
        </Subtitle>
      </ContentContainer>
    </Container>
  );
};

export const MessageTitle = styled.span`
  font-family: ${theme.fonts.primary}, serif;
  color: ${(props) => props.theme.main.grayColor};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  font-style: normal;
  margin: 24px 0 8px 0;
`;

export default PosLinkCanceled;
