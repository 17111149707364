import { useEffect } from 'react';

import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { FormatAmount } from 'lib/utils';
import { useSentry } from 'lib/hooks/useSentry';
import { useTranslation } from 'react-i18next';

const PosSuccess = () => {
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { t: translate } = useTranslation();
  const { setPageIndex, setIsPosSplitPayment, selectedPlan, setSessionData, sessionData } = useStore();

  useEffect(() => {
    setPageIndex(0);
  }, [setPageIndex]);

  const posPayWithCardHandler = () => {
    try {
      setIsPosSplitPayment(true);
      setSessionData({
        posRemainingAmount: sessionData?.treatmentAmount - selectedPlan?.grossAmount,
      });
      navigate(`pos-payment-review`);
    } catch (err) {
      captureException(err, { session: sessionData });
    }
  };

  return (
    <>
      <Container showBackButton={false}>
        <ContentContainer>
          <Icon src={'party'} />
          <Title m={'10px 0px 8px 0px'}>
            {translate('posSuccess.title', {
              amount: FormatAmount(selectedPlan?.grossAmount),
            })}
          </Title>
          <Subtitle>
            {translate('posSuccess.subTitle', {
              amount: FormatAmount(sessionData.treatmentAmount - selectedPlan?.grossAmount),
            })}
          </Subtitle>
        </ContentContainer>
        <ButtonContainer>
          <Button onClick={posPayWithCardHandler}>{translate('buttons.continue')}</Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default PosSuccess;
