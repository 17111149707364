import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { ButtonUnstyledProps, useButton } from '@mui/base/ButtonUnstyled';

import { theme } from 'config/theme';
import { Icon } from 'lib/components';

enum ButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  BLACK = 'BLACK',
}

const BUTTON_STYLE_MAP = {
  [ButtonType.PRIMARY]: {
    backgroundColor: theme.main.green,
    color: theme.main.white,
    border: `1px solid ${theme.main.green} !important`,
    letterSpacing: 'normal',
  },
  [ButtonType.SECONDARY]: {
    backgroundColor: theme.main.white,
    color: theme.main.midnightBlue,
    border: `1px solid ${theme.main.grayColor} !important`,
    letterSpacing: 'normal',
  },
  [ButtonType.BLACK]: {
    backgroundColor: theme.main.alleBlack,
    color: theme.main.white,
    border: `none !important`,
    letterSpacing: '1px',
  },
};

type ButtonTypes = {
  primary?: boolean;
  secondary?: boolean;
  black?: boolean;
};

const getButtonType = ({ secondary, black }: ButtonTypes) => {
  if (secondary) return ButtonType.SECONDARY;
  else if (black) return ButtonType.BLACK;

  return ButtonType.PRIMARY;
};

const getButtonStylesByType = (buttonType: ButtonType) => {
  return buttonType ? BUTTON_STYLE_MAP[buttonType] : BUTTON_STYLE_MAP[ButtonType.PRIMARY];
};

const CustomCircularProgress = muiStyled(CircularProgress)`
  margin-left: 10px;
  position: absolute;
  right: 50px;
  margin-top: 3px;
`;

const IconContainer = styled.div`
  margin-right: 10px;
  padding-top: 4px;
`;

const EndIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const CustomButtonRoot = styled<ButtonUnstyledProps | any>('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${(props) => getButtonStylesByType(getButtonType(props)).backgroundColor};
  width: ${(props) => (props?.block ? '100%' : '250px')};
  padding: ${(props) => (props.p ? props.p : '15px')};
  border-radius: 4px;
  color: ${(props) => getButtonStylesByType(getButtonType(props)).color};
  font-weight: ${(props) => props.fontWeight};
  font-family: ${(props) => props?.fontFamily}, serif !important;
  font-size: ${(props) => `${props.fontSize}px`};
  transition: all 200ms ease;
  cursor: pointer;
  border: ${(props) => getButtonStylesByType(getButtonType(props)).border};
  margin: ${(props) => (props.m ? props.m : 'unset')};
  margin-bottom: ${(props) => (props.mb ? 0 : '10px')};
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
  letter-spacing: ${(props) => getButtonStylesByType(getButtonType(props)).letterSpacing};

  &.focusVisible {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.disabled {
    background-color: #dadada;
    opacity: 0.8;
    cursor: unset;
    box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
    color: ${(props) => props.theme.main.midnightBlue40};
    border: 1px solid #dedede !important;
  }
`;

export const Button = React.forwardRef(function CustomButton(
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  props: ButtonUnstyledProps | any,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  ref: React.ForwardedRef<any>,
) {
  const {
    children,
    loading,
    color = theme.main.green,
    block = true,
    secondary = false,
    black = false,
    icon = false,
    endIcon = null,
    mb = false,
    m = null,
    p = null,
    uppercase = false,
    fontWeight = 600,
    fontSize = 18,
    fontFamily = theme.fonts.primary,
    dataTestId = 'submit',
  } = props;
  const { active, disabled, focusVisible, getRootProps } = useButton({
    ...props,
    ref,
    component: CustomButtonRoot,
  });

  const classes = {
    active,
    disabled,
    focusVisible,
  };

  return (
    <CustomButtonRoot
      {...getRootProps()}
      className={clsx(classes)}
      type="submit"
      data-testid={dataTestId}
      color={color}
      block={block}
      secondary={secondary}
      black={black}
      mb={mb}
      m={m}
      p={p}
      uppercase={uppercase}
      fontWeight={fontWeight}
      fontSize={fontSize}
      fontFamily={fontFamily}
    >
      {icon ? (
        <IconContainer>
          <Icon width={16} height={16} src={icon} />
        </IconContainer>
      ) : null}
      {children}
      {endIcon && <EndIconContainer>{endIcon}</EndIconContainer>}
      {loading ? <CustomCircularProgress size={20} color={'inherit'} /> : null}
    </CustomButtonRoot>
  );
});
