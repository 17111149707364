import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Title } from 'lib/components';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { WEBPREQUAL, getSurveyLink, getSurveyVersion, isSurveyEnabledIndustry, surveyPages } from 'lib/constants';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { Redirect } from 'lib/utils';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';

export const SurveyTypeForm = ({
  title,
  description,
  page,
  backgroundColor = '#e6f9f2',
  padding = '16px',
  margin = '32px 0',
}) => {
  const { t: translate } = useTranslation();
  const { flags } = useFlags();
  const { organization, sessionApiData } = useStore();
  const { trackSegmentEvent } = useSegment();
  const { featureFlags } = sessionApiData || {};
  const isNewApprovalScreenEnable = featureFlags?.newApprovalScreen || false;

  const isSurveyEnabled = flags?.APPROVALSURVEY?.approvalScreen?.isEnabled() || false;
  const show = isSurveyEnabled && isSurveyEnabledIndustry(organization);
  const version = getSurveyVersion(organization?.industry);
  const { sendActionSegmentEvent, sendLoadSegmentEvent } = useSegmentContext();

  const gotoSurvey = () => {
    const { application } = useStore.getState() || {};
    trackSegmentEvent(WPQSegmentNames.surveyAccepted, {
      application: WEBPREQUAL,
      page: page,
      version: version,
    });
    sendActionSegmentEvent(WPQSegmentNames.allSetSurveyClicked);
    Redirect(
      `https://withcherry.typeform.com/to/${getSurveyLink(organization?.industry)}#application_id=${
        application?.id
      }&screen_source=${surveyPages[page]}&version=${version}`,
    );
  };

  useEffect(() => {
    if (show && isNewApprovalScreenEnable) {
      sendLoadSegmentEvent(WPQSegmentNames.allSetSurveyShown);
    }
    trackSegmentEvent('Survey displayed to user', {
      application: WEBPREQUAL,
      page: page,
      version: version,
    });
  }, [show]);
  return (
    <>
      {show ? (
        <SurveyContainer backgroundColor={backgroundColor} padding={padding} margin={margin}>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Button onClick={gotoSurvey}>{translate('buttons.completeSurvey')}</Button>
        </SurveyContainer>
      ) : null}
    </>
  );
};

const SurveyContainer = styled.div<{ backgroundColor: string; padding: string; margin: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Description = styled.span`
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
