import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { theme as configTheme } from 'config/theme';
import { Button, Icon, IconGap, ModalHeader, Title } from 'lib/components';
import { ButtonContainer } from 'lib/components/Common';
import { US_STATES } from 'lib/constants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

type Address = {
  address: string;
  borrowerKYCOutcomeId: string;
  city: string;
  state: string;
  zip: string;
};

interface MovingModalModal {
  show: boolean;
  hideModal: () => void;
  confirmAddress: () => void;
  clearForm: () => void;
  enteredAddress?: Address;
  modalType: ModalType;
  setModalType: (string) => void;
}

enum ModalType {
  QUESTION = 'QUESTION',
  CONFIRMATION = 'CONFIRMATION',
  PREVIOUS_ADDRESS = 'PREVIOUS_ADDRESS',
}

const MovingModal = ({
  show,
  hideModal,
  enteredAddress,
  setModalType,
  modalType,
  confirmAddress,
  clearForm,
}: MovingModalModal) => {
  const [loading, setLoading] = useState(false);
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const { t: translate } = useTranslation();

  const closeModalOnClickAway = (e) => {
    if (e?.target?.ariaHidden) {
      hideModal();
    }
  };

  const title = () => {
    return modalType === ModalType.QUESTION
      ? translate('personalAddressVerify.movingModal.movedRecentlyTitle')
      : translate('personalAddressVerify.movingModal.confirmAddressTitle');
  };

  const description = () => {
    if (modalType === ModalType.QUESTION) {
      return (
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: translate('personalAddressVerify.movingModal.haveYouMoved'),
            }}
          />
        </>
      );
    } else {
      return (
        <AddressContainer>
          {translate('personalAddressVerify.movingModal.confirmEntredAddress')}
          <AddressSpan>
            <b>{enteredAddress?.address}</b>
            <b>
              {enteredAddress?.city}, {US_STATES.find((x) => x.value === enteredAddress?.state)?.name},
              {enteredAddress?.zip}
            </b>
          </AddressSpan>
        </AddressContainer>
      );
    }
  };

  const closeClick = () => {
    const closeEventName =
      modalType === ModalType.QUESTION
        ? WPQSegmentNames.closeMovedModalClicked
        : WPQSegmentNames.closeConfirmAddressModalClicked;
    sendActionSegmentEvent(closeEventName);
    hideModal();
  };

  const confirmEnteredAddress = () => {
    sendActionSegmentEvent(WPQSegmentNames.confirmYourAddressClicked);
    setLoading(true);
    confirmAddress();
  };

  const confirmMoving = () => {
    clearForm();
    sendActionSegmentEvent(WPQSegmentNames.yesRecentlyMovedClicked);
    setModalType(ModalType.PREVIOUS_ADDRESS);
    sendLoadSegmentEvent(WPQSegmentNames.previousAddressPageLoad);
    hideModal();
  };

  const notMoved = () => {
    sendActionSegmentEvent(WPQSegmentNames.notRecentlyMovedClicked);
    setModalType(ModalType.CONFIRMATION);
    sendLoadSegmentEvent(WPQSegmentNames.confirmAddressModalLoad);
  };

  const editAddress = () => {
    sendActionSegmentEvent(WPQSegmentNames.editYourAddressClicked);
    hideModal();
  };

  const buttonContainer = () => {
    if (modalType === ModalType.QUESTION) {
      return (
        <>
          <Button onClick={confirmMoving} secondary>
            {translate('personalAddressVerify.movingModal.yesIMoved')}
          </Button>
          <Button onClick={notMoved} secondary>
            {translate('personalAddressVerify.movingModal.noIHavent')}
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button onClick={confirmEnteredAddress} secondary loading={loading} disabled={loading}>
            {translate('personalAddressVerify.movingModal.confirmAddress')}
          </Button>
          <Button onClick={editAddress} secondary loading={loading} disabled={loading}>
            {translate('personalAddressVerify.movingModal.editAddress')}
          </Button>
        </>
      );
    }
  };

  return (
    <Backdrop
      sx={{ color: configTheme.main.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      onClick={closeModalOnClickAway}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer isLarge={false}>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>{title()}</Title>
            <Icon hover={true} src={'close'} onClick={closeClick} />
          </ModalHeader>
          <PurchaseContainer>
            <p>{description()}</p>
          </PurchaseContainer>
          <ButtonContainer>{buttonContainer()}</ButtonContainer>
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

export default MovingModal;

const ModalContainer = styled.div<{ isLarge: boolean }>`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0 -36px 67px rgba(0, 0, 0, 0.07), 0 -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0 -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 12px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;

    @media (max-height: ${(props) => (props.isLarge ? '700px' : '530px')}) {
      top: 30px;
      overflow-y: scroll;
    }
  }
`;

const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0px 16px 8px;
  color: ${(props) => props.theme.main.black};
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`;

const AddressSpan = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
