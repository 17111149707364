import { useApolloClient } from '@apollo/client';
import { useState } from 'react';

import { GET_MERCHANT_COMMUNICATION_SETTINGS } from 'lib/graphql/queries';
import { useSentry } from 'lib/hooks/useSentry';

export const useMerchantCommunicationSettings = () => {
  const client = useApolloClient();
  const { captureException } = useSentry();

  const [phone, setPhone] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [website, setWebsite] = useState<string | null>(null);

  const fetchMerchantCommunicationSettings = async (merchantId: string) => {
    try {
      if (merchantId && merchantId !== '0') {
        const { data: getMerchantCommunicationSettingsData = {} } = await client.query({
          query: GET_MERCHANT_COMMUNICATION_SETTINGS,
          variables: {
            input: {
              merchantId,
            },
          },
        });

        const { phone, name, website } = getMerchantCommunicationSettingsData?.getMerchantCommunicationSettings || {};

        setPhone(phone);
        setName(name);
        setWebsite(website);
      }
    } catch (err) {
      captureException(err, { message: 'Choose Plan - Get Merchant Communication Settings Error', merchantId });
    }
  };

  return { phone, name, website, fetchMerchantCommunicationSettings };
};
