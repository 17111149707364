import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import * as Sentry from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';

import App from './App';
import './index.css';
import './i18n';

import { theme } from 'config/theme';
import {
  APP_ENV,
  NEWRELIC_ACCOUNT_ID,
  NEWRELIC_AGENT_ID,
  NEWRELIC_APPLICATION_ID,
  NEWRELIC_LICENSE_KEY,
  SENTRY_DSN,
  SEGMENT_API_URL,
  SEGMENT_KEY,
} from 'config';
import { shouldIgnoreError } from 'lib/utils';
import { FeatureFlagsContextProvider } from 'lib/hooks/FeatureManagement/FlagsContext';
import { analytics } from './lib/hooks/useSegment';

analytics
  .load(
    {
      writeKey: SEGMENT_KEY,
    },
    {
      integrations: {
        'Segment.io': {
          apiHost: SEGMENT_API_URL,
          deliveryStrategy: {
            strategy: 'batching',
            config: {
              size: 10,
              timeout: 2000,
            },
          },
        },
      },
      retryQueue: true,
    },
  )
  .catch((error) => {
    Sentry.captureException(error);
  });

const container = document.getElementById('root');
const root = createRoot(container!);

/** New Relic Browser Agent */
const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: NEWRELIC_LICENSE_KEY,
    applicationID: NEWRELIC_APPLICATION_ID,
    sa: 1,
  },
  loader_config: {
    accountID: NEWRELIC_ACCOUNT_ID,
    trustKey: '3920335',
    agentID: NEWRELIC_AGENT_ID,
    licenseKey: NEWRELIC_LICENSE_KEY,
    applicationID: NEWRELIC_APPLICATION_ID,
  },
};

const newRelicInstance = new BrowserAgent(options);

SentryBrowser.init({
  dsn: SENTRY_DSN,
  integrations: [new SentryBrowser.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: APP_ENV,
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'Unhandled Promise Rejection: Failed to fetch',
    'Unhandled Promise Rejection: Load failed',
    'ApolloError: Load failed',
  ],
  beforeSend: (error, hint) => {
    return shouldIgnoreError(error, hint) ? null : error;
  },
  beforeBreadcrumb(breadcrumb, hint) {
    return breadcrumb.category === 'console' ? null : breadcrumb;
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary>
        <FeatureFlagsContextProvider>
          <App />
        </FeatureFlagsContextProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
);
