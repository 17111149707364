import React from 'react';
import styled from 'styled-components';

import { Button } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useBrowserHistory } from 'lib/hooks/useBrowserHistory';
import { useTranslation } from 'react-i18next';

interface Props {
  icon?: string;
  useElegantFontType?: boolean;
}

export const ChatSupportNiceButton = React.memo(({ icon }: Props) => {
  const { setShowChat, isAlle } = useStore();
  const { t: translate } = useTranslation();
  const { sendActionSegmentEvent } = useSegmentContext();
  const { pageName, merchantSlug } = useBrowserHistory();

  const openChat = () => {
    // eslint-disable-next-line
    // @ts-ignore
    window?.icPatronChat?.show();
    setShowChat(true);
    sendActionSegmentEvent(WPQSegmentNames.helpModalChatClicked, {
      pageName: pageName || '',
      merchantSlug: merchantSlug || '',
    });
  };

  return (
    <>
      {isAlle ? (
        navigator.cookieEnabled ? (
          <ChatRef onClick={openChat}>{translate('header.modal.chatSupport')}</ChatRef>
        ) : null
      ) : (
        <Button
          id={'chat--widget'}
          onClick={openChat}
          secondary={true}
          icon={icon || 'chat'}
          fontWeight={600}
          fontSize={18}
        >
          {translate('header.modal.chatSupport')}
        </Button>
      )}
    </>
  );
});

const ChatRef = styled.div`
  font-family: 'Graphik', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.main.alleBlack};
  text-decoration: underline;
  margin-bottom: 24px;
`;
