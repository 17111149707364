import { Box } from '@mui/material';
import { Button, Subtitle, SurveyTypeForm } from 'lib/components';
import { FaqKycList } from 'pages/Faq/components';
import FaqKycDenialList from 'pages/Faq/components/FaqKycDenialList';
import { MessageTitle } from 'pages/FrozenCredit/FrozenCredit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { theme } from '@frontend/cherry-library';
import KycErrorBase from './KycErrorBase';

interface KycDenialProps {
  isNewDenialUi: boolean;
  goToCustomerPortal: () => void;
}

export default function KycDenial({ isNewDenialUi, goToCustomerPortal }: KycDenialProps) {
  const { t: translate } = useTranslation();

  return (
    <KycErrorBase
      isNewDenialUi={isNewDenialUi}
      title={translate('result.kycDenial.subtitle')}
      faqList={<FaqKycDenialList />}
      oldPage={
        <>
          <SubtitleContainer>
            <Subtitle color={theme.main.midnightBlue}>{translate('result.subTitle2')}</Subtitle>

            <SurveyTypeForm
              page="DENIAL"
              title={translate('result.survey.title', {
                price: '$25',
              })}
              description={translate('result.survey.desc', {
                price: '$25',
              })}
            />

            <StyledMessageTitle>{translate('result.info.title')}</StyledMessageTitle>
            <Subtitle m="16px 0 16px 0" color={theme.main.midnightBlue}>
              {translate('result.info.subTitle1')}
            </Subtitle>
            <Subtitle m="0 0 16px 0" color={theme.main.midnightBlue}>
              {translate('result.info.subTitle2')}
            </Subtitle>
            <Subtitle m="0 0 20px 0" color={theme.main.midnightBlue}>
              {translate('result.info.subTitle3')}
            </Subtitle>

            <Button onClick={goToCustomerPortal}>{translate('buttons.patientPortal')}</Button>
          </SubtitleContainer>

          <SubtitleContainer removePadding={true}>
            <FaqKycList />
          </SubtitleContainer>
        </>
      }
    />
  );
}

const StyledMessageTitle = styled(MessageTitle)`
  color: ${(props) => props.theme.main.textColor};
  margin-top: 40px;
`;

const SubtitleContainer = styled(Box)<{ removePadding?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props?.removePadding ? '20px -24px 0' : 'initial')};
`;
