import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import { theme as CherryTheme } from 'config/theme';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const UnderlinedTextField = styled((props: any) => (
  <MuiTextField
    inputRef={props?.ref}
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    InputProps={{ disableUnderline: true, endAdornment: props?.endAdornment } as Partial<any>}
    {...props}
  />
))(({ value }) => {
  return {
    '& label': {
      color: '#dadada',
      fontSize: 14,
    },
    '& label.Mui-focused': {
      color: `${CherryTheme.main.grayColor}}`,
    },
    '& label.Mui-error': {
      color: '#d32f2f',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
    },
    '& .MuiFormLabel-filled': {
      color: `${CherryTheme.main.grayColor}}`,
    },
    '& .MuiFilledInput-root': {
      borderBottom: `1px solid ${CherryTheme.main.silverGray}`,
      overflow: 'hidden',
      backgroundColor: `${CherryTheme.main.white}}`,
      fontFamily: 'Graphik',
      borderRadius: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'rgba(2, 2, 2, 0.1)',
        borderColor: 'transparent',
      },
      '&.Mui-error': {
        backgroundColor: `${CherryTheme.main.white} !important}`,
        borderColor: '#d32f2f',
      },
      input: {
        fontSize: 14,
        padding: 10,
        color: `${CherryTheme.main.black20}`,
      },
    },
    '& .MuiFilledInput-root:before': {
      border: 'none !important',
    },
    '& .MuiFilledInput-root:after': {
      border: 'none !important',
    },
    '& .MuiFilledInput-input': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
      '&:-webkit-autofill': {
        WebkitBoxShadow: `0 0 0 30px ${CherryTheme.main.white} inset`,
        WebkitTextFillColor: `${CherryTheme.main.grayColor}`,
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: 14,
      visibility: value ? 'hidden' : 'visible',
      transition: 'none',
      transform: 'translate(10px, 50%) scale(1)',
    },
    '& .MuiInputLabel-input': {
      '&:focus': {
        outline: 'none',
      },
    },
    '& input:invalid + fieldset': {
      borderColor: '#d32f2f',
      borderWidth: 2,
    },
    '.MuiSelect-select': {
      padding: 8,
    },
  };
});
