import { useEffect, useState } from 'react';
import styled from 'styled-components';
import CheckboxItem from 'pages/ConnectBankAccount/components/CheckboxItem';
import faqConnectWithPlaid from 'pages/Faq/components/faqConnectWithPlaid.json';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { FaqListModal } from 'lib/components/FaqListModal';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { LANGUAGES, WEBPREQUAL } from 'lib/constants';
import AlleDefaultConnectBankAccount from './AlleDefaultConnectBankAccount';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useTranslation } from 'react-i18next';

const REQUIRED_CONFIRM_COUNT = 3;
const INCOME_VERIFICATION_RETRY_ERROR = 'Retry';
const MX_RELATED_ERROR = 'Unsupported_Error';

interface Props {
  isOptions: boolean;
  goToPlaid: () => void;
  loading: boolean;
  goToOriginalApproval: () => void;
  uploadPaystub: () => void;
  featureFlags: {
    mxEnabled: boolean;
  };
  organization: {
    name: string;
  };
}

const DefaultConnectBankAccount = ({
  isOptions,
  goToPlaid,
  loading,
  uploadPaystub,
  featureFlags,
  organization,
  goToOriginalApproval,
}: Props) => {
  const [isRetry, setIsRetry] = useState(false);
  const { trackSegmentEvent } = useSegment();
  const { t: translate } = useTranslation();
  const { isAlle, defaultLanguage } = useStore();

  const [displayFaqModal, setDisplayFaqModal] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState<boolean[]>([false, false, false]);
  const { application } = useStore.getState() || {};
  const { sendActionSegmentEvent } = useSegmentContext();

  useEffect(() => {
    const { incomeVerification, application } = useStore.getState() || {};

    if (incomeVerification?.errorMessage === INCOME_VERIFICATION_RETRY_ERROR) {
      setIsRetry(true);
    }

    if (
      incomeVerification?.errorMessage === INCOME_VERIFICATION_RETRY_ERROR ||
      application?.ivAllow?.paystub ||
      incomeVerification?.errorMessage === MX_RELATED_ERROR
    ) {
      setCheckboxValues([true, true, true]);
    }
  }, []);

  const trackCheckboxCheckedEvent = (checkboxIndex, checkboxItems) => {
    const { application, borrower } = useStore.getState();
    let eventName: string;
    switch (checkboxIndex) {
      case 0:
        eventName = WPQSegmentNames.ivAtLeast3MonthClicked;
        break;
      case 1:
        eventName = WPQSegmentNames.ivDepositIncomeClicked;
        break;
      case 2:
        eventName = WPQSegmentNames.ivLandingMake1000Clicked;
        break;
    }
    const value = checkboxItems[checkboxIndex];

    // @ts-ignore we should use ts-ignore because of eventName field
    sendActionSegmentEvent(eventName, {
      value,
    });
  };

  const handleCheckboxClick = (checkboxIndex: number) => {
    const checkboxItems = checkboxValues?.map((value, index) => (index === checkboxIndex ? !value : value));
    trackCheckboxCheckedEvent(checkboxIndex, checkboxItems);
    setCheckboxValues(checkboxItems);
  };

  const getConfirmedBoxCount = () => checkboxValues?.filter((checkbox) => checkbox)?.length;

  const isSubmitAllowed = () => getConfirmedBoxCount() === REQUIRED_CONFIRM_COUNT;

  const getSubmitButtonText = () => {
    const confirmedBoxCount = getConfirmedBoxCount();
    const remainingConfirmCount = REQUIRED_CONFIRM_COUNT - confirmedBoxCount;

    return confirmedBoxCount < 3
      ? translate('defaultConnectBankAccount.button.remaining', {
          remainingConfirmCount: remainingConfirmCount,
          tag: remainingConfirmCount > 1 ? 's' : '',
        })
      : translate('defaultConnectBankAccount.button.connectBankAccount');
  };

  const handleHaveaQuestion = () => {
    setDisplayFaqModal(true);
    sendActionSegmentEvent(WPQSegmentNames.ivHaveQuestionClicked);
  };

  const renderButton = () => {
    if (isOptions) {
      return (
        <ButtonText onClick={uploadPaystub}>{translate('defaultConnectBankAccount.button.verifyOther')}</ButtonText>
      );
    }

    return null;
  };

  if (isAlle) {
    return (
      <AlleDefaultConnectBankAccount
        isOptions={isOptions}
        goToPlaid={goToPlaid}
        loading={loading}
        uploadPaystub={uploadPaystub}
        featureFlags={featureFlags}
        organization={organization}
        checkboxValues={checkboxValues}
        handleCheckboxClick={handleCheckboxClick}
        isRetry={isRetry}
        isSubmitAllowed={isSubmitAllowed}
        setDisplayFaqModal={setDisplayFaqModal}
        displayFaqModal={displayFaqModal}
        getSubmitButtonText={getSubmitButtonText}
      />
    );
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <IconContainer>
            <Icon src={'cherry-logo'} width={24} height={24} />
            <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 4px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 12px 0px 4px'} width={4} height={4} src={'dot'} />
            <Icon src={'bank'} />
          </IconContainer>
          <Title m={'10px 0px 8px 0px'}>{translate('defaultConnectBankAccount.title')}</Title>
          <Subtitle m={'0px 0px 24px 0px'}>
            {translate('defaultConnectBankAccount.subTitle', {
              type: featureFlags?.mxEnabled ? 'MX' : 'Plaid',
            })}
          </Subtitle>
          {!isRetry ? (
            <>
              <Subtitle>{translate('defaultConnectBankAccount.retry.title')}</Subtitle>

              <CheckboxItem
                label={translate('defaultConnectBankAccount.checkbox.item1')}
                checked={checkboxValues[0]}
                onChange={() => handleCheckboxClick(0)}
              />
              <CheckboxItem
                label={translate('defaultConnectBankAccount.checkbox.item2')}
                checked={checkboxValues[1]}
                onChange={() => handleCheckboxClick(1)}
              />
              <CheckboxItem
                label={translate('defaultConnectBankAccount.checkbox.item3')}
                checked={checkboxValues[2]}
                onChange={() => handleCheckboxClick(2)}
              />
            </>
          ) : null}
        </InnerContainer>
      </ContentContainer>
      <InfoContainer>
        <Icon width={16} height={20} src={'lock-secure'} />
        <InfoText>
          {translate('defaultConnectBankAccount.info', {
            name: organization?.name,
          })}
        </InfoText>
      </InfoContainer>
      <ButtonContainer>
        <Button disabled={!isSubmitAllowed() || loading} loading={loading} onClick={goToPlaid}>
          {getSubmitButtonText()}
        </Button>
        {application?.mickeyMouse && (
          <Button disabled={loading} secondary={true} onClick={goToOriginalApproval}>
            {translate('buttons.goToOriginalApproval')}
          </Button>
        )}
        {renderButton()}
        <ButtonText onClick={handleHaveaQuestion}>{translate('buttons.haveAQuestion')}</ButtonText>
      </ButtonContainer>
      <FaqListModal
        hideModal={() => setDisplayFaqModal(false)}
        show={displayFaqModal}
        faqList={faqConnectWithPlaid?.[defaultLanguage || LANGUAGES[0]]}
        title={translate('buttons.haveAQuestion')}
        titleMargin={'auto'}
      />
    </Container>
  );
};

export default DefaultConnectBankAccount;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const ButtonText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.main.green};
  margin: 24px 0;
  cursor: pointer;
`;
