import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Rox from 'rox-browser';
import { useTranslation } from 'react-i18next';

import { OK, WEBPREQUAL, WEB_SITE } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import useStore, { LanguageEnum } from 'lib/hooks/useStore';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { useSentry } from 'lib/hooks/useSentry';

import { ButtonContainer, ContentContainer, FooterText } from 'lib/components/Common';
import { Box, Button, Container, DemoMode, Icon, Loading, Subtitle, TextField, Title } from 'lib/components';

import AlleGetStarted from 'pages/GetStarted/AlleGetStarted';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { LetsGoScreenType } from 'lib/hooks/FeatureManagement/Flags';
import { useInitializeAuth } from 'pages/AuthChecker/services/useInitializeAuth';
import { ButtonClickedEventActions, IframeEventTypes, useIframeEvents } from 'lib/hooks/useIframeEvents';

import { LocationOptions } from 'lib/types';
import { CustomizedGetStarted } from './CustomizedGetStarted';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { getExternalLinkLangParam } from 'lib/utils/ExternalLinkLangParam';
import { APP_ENV } from 'config';
import { useServices } from 'pages/AuthChecker/services';

let segmentEventSentCount = 0;

interface Merchant {
  id: string;
  name: string;
  slug: string;
}
interface Organization {
  id: string;
  name: string;
  slug: string;
}

interface LocProps {
  id: string;
}

const GetStarted = () => {
  const { t: translate } = useTranslation();
  const { trackIdentify, trackSegmentEvent } = useSegment();
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { setTag } = useSentry();
  const { flags, hasRoxInitialized } = useFlags();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const { getAuthInfo } = useServices();
  const [searchParams] = useSearchParams();
  const {
    organization,
    locations,
    setSelectedLocation,
    setPageIndex,
    isDemo,
    flowType,
    setUtmSource,
    setUtmMedium,
    organizationGroup,
    isAlle,
    setBorrower,
    defaultLanguage,
    setDefaultLanguage,
  } = useStore();

  const { initializeAuth } = useInitializeAuth();
  const { pushEvent } = useIframeEvents();

  const [merchantPreference, setMerchantPreference] = useState<Merchant>({} as Merchant);
  const [organizationPreference, setOrganizationPreference] = useState<Merchant>({} as Merchant);
  const [loading, setLoading] = useState(false);

  const useSortedLocations = (locations: LocationOptions) => {
    return useMemo(() => {
      return locations ? [...locations]?.sort((a, b) => a?.name?.localeCompare(b?.name)) : null;
    }, [locations]);
  };

  const sortedLocations = useSortedLocations(locations);
  const merchantId = searchParams?.get('m');
  const utmMedium: string | null = searchParams?.get('utm_medium');
  const utmSource: string | null = searchParams?.get('utm_source');
  const letsGoScreenFlag = flags.SCREEN.letsGoScreenType.getValue() as LetsGoScreenType;

  useEffect(() => {
    if (organization?.id && organization?.name !== '' && hasRoxInitialized) {
      Rox.setCustomStringProperty('organization_id_str', organization?.id?.toString());
      Rox.setCustomStringProperty('industry', organization?.industry?.toString());
    }
  }, [organization, hasRoxInitialized]);

  const sendSegmentEvent = (isMultiLocation = false) => {
    if (organization?.id && organization?.name !== '' && segmentEventSentCount < 1) {
      trackIdentify();
      isMultiLocation
        ? sendLoadSegmentEvent(WPQSegmentNames.multiLocationWelcomeLoad)
        : sendLoadSegmentEvent(WPQSegmentNames.singleLocationWelcomeLoad);
      segmentEventSentCount++;
    }
    setBorrower({});
  };

  useEffect(() => {
    if (locations?.length === 1) {
      setSelectedLocation(locations?.[0]);
      sendSegmentEvent();
      return;
    }

    if (locations?.length && merchantId) {
      const findLocation = locations?.find((loc: LocProps) => loc?.id === merchantId);
      setMerchantPreference(findLocation);
      setSelectedLocation(findLocation);
    }
    sendSegmentEvent(true);
  }, [locations]);

  useEffect(() => {
    setPageIndex(0);
  }, [setPageIndex]);

  useEffect(() => {
    findPracticeDrivenUser();
  }, []);

  const merchantPreferenceHandler = async (e: React.ChangeEvent<{ value: Merchant }>) => {
    trackSegmentEvent(AnalyticEventNames.LOCATION_SELECT_ONCHANGE, { application: WEBPREQUAL });
    setMerchantPreference(e?.target?.value);
    setSelectedLocation(e?.target?.value);
  };

  const organizationPreferenceHandler = async (e: React.ChangeEvent<{ value: Merchant }>) => {
    setOrganizationPreference(e?.target?.value);
    const data = await initializeAuth(e?.target?.value.slug);
    if (data?.code === OK) {
      await getAuthInfo();
    } else {
      navigate(`not-found`);
    }
  };

  const findPracticeDrivenUser = () => {
    setUtmSource(utmSource);
    setUtmMedium(utmMedium);

    if (utmSource) {
      setTag('utmSource', utmSource);
    }

    if (utmMedium) {
      setTag('utmSource', utmMedium);
    }
  };

  const listItem = [
    {
      id: 'list-item-1',
      icon: 'payment',
      text: translate('getStarted.chooseYourPlan'),
    },
    {
      id: 'list-item-2',
      icon: 'calendar',
      text: translate('getStarted.manageYourPayments'),
    },
    {
      id: 'list-item-3',
      icon: 'heart',
      text: translate('getStarted.getTheCareYouDeserve'),
    },
  ];

  const changeLanguageHandler = () => {
    const language = defaultLanguage === LanguageEnum.EN ? LanguageEnum.ES : LanguageEnum.EN;
    setDefaultLanguage(language);
    if (locations?.length > 1) {
      trackSegmentEvent(WPQSegmentNames.multiLocationWelcomeScreenChangeLanguageClicked, {
        newLanguage: language,
      });
    } else {
      trackSegmentEvent(WPQSegmentNames.singleLocationWelcomeScreenChangeLanguageClicked, {
        newLanguage: language,
      });
    }
  };

  const handleLetsGoButton = async () => {
    const { selectedLocation, flowType } = useStore.getState() || {};

    if (locations?.length === 1) {
      sendActionSegmentEvent(WPQSegmentNames.singleLocationButtonClicked);
    } else {
      sendActionSegmentEvent(WPQSegmentNames.multiLocationWelcomeScreen);
    }
    trackEvent(AnalyticEventNames.WELCOME_APPLY);
    trackEvent(AnalyticEventNames.WELCOME_CONTINUE);
    trackSegmentEvent(AnalyticEventNames.WELCOME_CONTINUE, { application: WEBPREQUAL, isDemo });

    if (!selectedLocation && flowType === 'PointOfSalesFlow') {
      setSelectedLocation(locations?.[0]);
    }

    if (!isControlGroup()) {
      switch (letsGoScreenFlag) {
        case LetsGoScreenType.FAST_APPLY:
          trackSegmentEvent('Start Application', { application: WEBPREQUAL, test: 'fast_apply_page_enabled' });
          break;

        case LetsGoScreenType.NO_IMPACT_TO_CREDIT:
          trackSegmentEvent('Start Application', { application: WEBPREQUAL, test: 'no_credit_impact_enabled' });
          break;

        case LetsGoScreenType.PRACTICE_ENDORSEMENT:
          trackSegmentEvent('Start Application', { application: WEBPREQUAL, test: 'practice_endorsed_page_enabled' });
          break;

        case LetsGoScreenType.SOCIAL_PROOF:
          trackSegmentEvent('Start Application', { application: WEBPREQUAL, test: 'socialproof_page_enabled' });
          break;

        case LetsGoScreenType.ZERO_APR:
          trackSegmentEvent('Start Application', { application: WEBPREQUAL, test: 'zero_apr_page_enabled' });
          break;

        default:
          break;
      }
    } else {
      trackSegmentEvent('Start Application', { application: WEBPREQUAL });
    }

    if (isAlle) {
      pushEvent(IframeEventTypes.BUTTON_CLICKED, ButtonClickedEventActions.GET_STARTED_CONTINUE_BUTTON_CLICKED);
    }
    navigate(isAlle ? `info` : `terms`);
  };

  const RenderTitle = () => {
    return (
      <Helmet>
        <title>
          {translate('getStarted.helmetTitle', {
            organizationName: organization?.name,
          })}
        </title>
      </Helmet>
    );
  };

  const isControlGroup = () => {
    return letsGoScreenFlag === LetsGoScreenType.CONTROL;
  };

  const organizationGroupMerchants: [] = useMemo(
    () =>
      organizationGroup?.organizations
        ?.reduce((prevResult, curr) => {
          prevResult.push({ ...curr, slug: curr?.slug });
          return prevResult;
        }, [])
        .sort((a, b) => a?.name?.localeCompare(b?.name)),
    [organizationGroup],
  );
  const organizationList: Organization[] = organizationGroupMerchants;
  const locationList: LocationOptions | null = sortedLocations;

  const quoteText = translate('getStarted.quote.text', {
    quoteColorText1: `<b>${translate('getStarted.quote.easyToUse')}</b>`,
    quoteColorText2: `<b>${translate('getStarted.quote.superFast')}</b>`,
  });

  const footerText = translate('getStarted.footer.text', {
    lendingPartners: `<a target="_blank" href=${WEB_SITE}/lending-partners/${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('getStarted.footer.lendingPartners')}</a>`,
    spanishDisclosure: `<a target="_blank" href=${WEB_SITE}/spanish>${translate(
      'getStarted.footer.spanishDisclosure',
    )}</a>`,
  });

  if (isAlle) {
    return <AlleGetStarted onContinue={handleLetsGoButton} />;
  }

  return (
    <>
      {(isDemo || APP_ENV === 'sandbox') && <DemoMode />}
      <Container showBackButton={false} hideHeader={!isControlGroup()}>
        {!isControlGroup() && <CustomizedGetStarted onClickLetsGo={handleLetsGoButton} type={letsGoScreenFlag} />}
        {isControlGroup() && (
          <>
            <ContentContainer justify="space-between">
              <InnerContainer>
                <RenderTitle />
                <Icon src={'cherry-logo'} />
                <Title m={'10px 0px 0px 0px'} data-testid="title">
                  {translate('getStarted.startTreatmentToday')}
                </Title>
                <Subtitle m={'10px 0px 24px 0px'}>
                  {translate('getStarted.APRPlansAvailableAndNoImpactOnYourCreditToApply', {
                    APRPercent: 0,
                  })}
                </Subtitle>
                {(!locations || locations?.length < 2) && !organizationGroup ? (
                  <>
                    <Button onClick={handleLetsGoButton} data-testid="submit" loading={loading}>
                      {translate('buttons.letsGo')}
                    </Button>
                    <Button secondary onClick={changeLanguageHandler}>
                      {translate('buttons.viewInEnglish')}
                    </Button>
                    <ControlContainer>
                      <Subtitle m={'10px 0px 24px 0px'}>{translate('getStarted.applyingTakesSeconds')}</Subtitle>
                      {listItem?.map((item) => {
                        const { id, icon, text } = item || {};
                        return (
                          <ListItem key={id}>
                            <Icon src={icon} width={40} height={40} />
                            <ItemTitle>{text}</ItemTitle>
                          </ListItem>
                        );
                      })}
                    </ControlContainer>
                  </>
                ) : null}

                {organizationList && organizationList?.length > 1 ? (
                  <ControlContainer>
                    <Subtitle m={'0px 0px 8px 0px'}>
                      {organizationGroup
                        ? translate('getStarted.chooseAPractice')
                        : translate('getStarted.organizationHasMultipleLocationsWhereWouldYouPreferToGetTreatment', {
                            organizationName: organization?.name,
                          })}
                    </Subtitle>
                    <Box>
                      <TextField
                        id="organization"
                        variant="filled"
                        data-testid="organization"
                        data-name="organization"
                        label={translate('getStarted.practice')}
                        select={true}
                        value={organizationPreference}
                        onChange={organizationPreferenceHandler}
                        SelectProps={{
                          renderValue: (option) => option?.name,
                        }}
                      >
                        {organizationList?.map((option) => (
                          //@ts-ignore menu Item value type
                          <MenuItem data-testid="stateItem" key={option?.id} value={option}>
                            <MenuItemValue>
                              <Label primary={true}>{option?.name}</Label>
                            </MenuItemValue>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </ControlContainer>
                ) : null}
                {locationList && locationList?.length > 1 ? (
                  <ControlContainer>
                    <Subtitle m={'0px 0px 8px 0px'}>
                      {organizationGroup
                        ? translate('getStarted.chooseAPractice')
                        : translate('getStarted.organizationHasMultipleLocationsWhereWouldYouPreferToGetTreatment', {
                            organizationName: organization?.name,
                          })}
                    </Subtitle>
                    <Box>
                      <TextField
                        id="location"
                        variant="filled"
                        data-testid="location"
                        data-name="location"
                        label={
                          organizationGroup
                            ? translate('getStarted.practice')
                            : translate('getStarted.selectLocationOptional')
                        }
                        select={true}
                        value={merchantPreference}
                        onChange={merchantPreferenceHandler}
                        SelectProps={{
                          renderValue: (option) => option?.name,
                        }}
                      >
                        {locationList?.map((option) => (
                          //@ts-ignore menu Item value type
                          <MenuItem data-testid="stateItem" key={option?.id} value={option}>
                            <MenuItemValue>
                              <Label primary={true}>{option?.name}</Label>
                              <Label>
                                {option?.address?.street} - {option?.address?.city?.name}
                              </Label>
                            </MenuItemValue>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                    <Button onClick={handleLetsGoButton} data-testid="submit" loading={loading}>
                      {translate('buttons.letsGo')}
                    </Button>
                    <Button secondary onClick={changeLanguageHandler}>
                      {translate('buttons.viewInEnglish')}
                    </Button>
                  </ControlContainer>
                ) : null}
              </InnerContainer>
              {!locations || locations?.length < 2 ? (
                <InnerContainer>
                  <QuoteContainer>
                    <Icon width={16} height={16} src={'quote'} />
                    <QuoteText dangerouslySetInnerHTML={{ __html: quoteText }} />
                  </QuoteContainer>
                  <QuoteTriangle>▼</QuoteTriangle>
                  <QuoteOwner>{translate('getStarted.quote.quoteOwner')}</QuoteOwner>
                </InnerContainer>
              ) : null}
            </ContentContainer>
            <StyledButtonContainer>
              <FooterText dangerouslySetInnerHTML={{ __html: footerText }} />
            </StyledButtonContainer>
          </>
        )}
      </Container>
      {(flowType === 'PartnerLinkFlow' || flowType === 'OauthFinderFlow') && (
        <CenterLoading>
          <Loading size={40} />
        </CenterLoading>
      )}
    </>
  );
};

export default GetStarted;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
`;

const ItemTitle = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: ${(props) => props.theme.main.midnightBlue};
  margin-left: 20px;
`;

const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0 16.9907px 31.6215px rgba(0, 0, 0, 0.07), 0 6.20187px 11.5424px rgba(0, 0, 0, 0.0482987),
    0 3.01089px 5.60361px rgba(0, 0, 0, 0.0389404), 0 1.476px 2.74699px rgba(0, 0, 0, 0.0310596),
    0 0.583611px 1.08616px rgba(0, 0, 0, 0.0217013);
  border-radius: 12px;
  text-align: center;
  padding: 16px;
`;

const QuoteText = styled.span`
  margin-top: 12px;
  font-weight: 300;
  font-size: 12px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const QuoteOwner = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const QuoteColorText = styled.span`
  color: ${(props) => props.theme.main.black};
  font-weight: 700;
`;

const QuoteTriangle = styled.div`
  margin-top: -10px;
  color: ${(props) => props.theme.main.white};
  text-shadow: 0 16.9907px 31.6215px rgba(0, 0, 0, 0.07), 0 6.20187px 11.5424px rgba(0, 0, 0, 0.0482987),
    0 3.01089px 5.60361px rgba(0, 0, 0, 0.0389404), 0 1.476px 2.74699px rgba(0, 0, 0, 0.0310596),
    0 0.583611px 1.08616px rgba(0, 0, 0, 0.0217013);
  text-align: center;
`;

const MenuItemValue = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const Label = styled.span<{ primary?: boolean }>`
  font-weight: 400;
  font-size: ${(props) => (props.primary ? '18px' : '12px')};
  line-height: 19px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.main.textColor};
`;

const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CenterLoading = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.main.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000000000;
`;

const StyledButtonContainer = styled(ButtonContainer)`
  padding: 0 24px 12px 24px;

  strong {
    color: ${(props) => props.theme.main.green};
    text-decoration: underline;
  }
`;
