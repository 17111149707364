import { useEffect, useRef, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import useStore from 'lib/hooks/useStore';
import { useViewport } from 'lib/hooks/useViewport';
import { Container, Icon, Subtitle, Button, CheckoutProgressBar } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { theme } from 'config/theme';
import { PlanBox } from './components/PlanBox';
import { SelectedProductCard } from './components/SelectedPlanCard';
import { FormatAmount } from 'lib/utils';
import CustomDownPayment from './components/CustomDownPayment';
import { IPlan } from 'pages/Plans/types';
import { useSegment } from 'lib/hooks/useSegment';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSentry } from 'lib/hooks/useSentry';

interface PlanListProps {
  planList: IPlan[];
}

const PlanSelection = ({ planList }: PlanListProps) => {
  const { isMobile } = useViewport();
  const { sendActionSegmentEvent } = useSegmentContext();
  const { t: translate } = useTranslation();
  const { trackOptionSelected } = useSegment();
  const { navigate } = useNavigation();
  const { captureException } = useSentry();

  const { loan, setSelectedPlan, selectedPlan, organization } = useStore();
  const [customizeList, setCustomizeList] = useState<IPlan[]>();
  const [activePlan, setActivePlan] = useState<IPlan>(planList[0]);
  const [customDownPaymentVisibility, setCustomDownPaymentVisibility] = useState(false);
  const loadSegmentController = useRef(true);

  const amount = loan?.purchaseAmount;

  useEffect(() => {
    setActivePlan(selectedPlan ?? planList[0]);
  }, [selectedPlan]);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendActionSegmentEvent(`WPQ.OUTCOME.PLAN_SELECTION_${activePlan?.product?.term}_MONTH_PLAN_CLICKED`);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const navigateToPlanReview = () => {
    try {
      const { application } = useStore.getState() || {};
      trackOptionSelected(organization, application, loan, activePlan, amount);
      sendActionSegmentEvent(`WPQ.OUTCOME.PLAN_SELECTION_${activePlan?.product?.term}_MONTH_PLAN_SELECTED`);
      setSelectedPlan(activePlan);
      navigate(`plan-review`);
    } catch (err) {
      captureException('New Plans Listing Error When Try To Navigate Review', {
        error: err,
        page: 'Plans',
        organizationId: organization?.id,
        loanId: loan?.id,
        activePlan: activePlan,
      });
    }
  };

  useEffect(() => {
    customDownPaymentsPrioritizedList();
  }, [planList]);

  const customDownPaymentsPrioritizedList = () => {
    const finalArray = planList.filter((plan) => plan.product?.parentId);
    planList.map((plan) => {
      const isCustomPlanExist = finalArray.find((x) => x.product?.term === plan?.product?.term);
      if (!isCustomPlanExist) {
        finalArray.push(plan);
      }
    });
    finalArray.sort((a, b) => a?.product.term - b?.product?.term);
    const newActivePlan = finalArray?.find((x) => x?.product?.term === activePlan?.product?.term) || activePlan;
    setActivePlan(newActivePlan);
    setCustomizeList(finalArray);
  };

  const renderButton = () => {
    return (
      <Button data-testid={'choose-month-plan'} onClick={navigateToPlanReview}>
        {translate('plans.newChoosePlan', {
          selectedMonth: activePlan?.product?.term,
        })}
      </Button>
    );
  };

  return (
    <>
      <Container showBackButton={true} backUrl={`choose-plan`}>
        <CustomContentContainer justify="space-between" noPadding={true} IsMobile={isMobile}>
          <InnerContainer>
            <CheckoutProgressBar />

            <InsideContainer>
              <Subtitle
                m={'11px 0px 12px 0px'}
                color={theme.main.midnightBlue}
                dangerouslySetInnerHTML={{
                  __html: translate('plans.newSubTitle', {
                    amount: FormatAmount(amount),
                  }),
                }}
              ></Subtitle>
            </InsideContainer>
            <ProductContainer>
              {customizeList && (
                <ProductList totalPlanLength={planList?.length} isMobile={isMobile}>
                  {customizeList?.map((data, index) => (
                    <>
                      <PlanBox
                        key={`plan-box-${index}`}
                        isActive={data?.product?.term === activePlan?.product?.term}
                        setActivePlan={setActivePlan}
                        plan={data}
                        totalLength={planList?.length}
                      />
                    </>
                  ))}
                </ProductList>
              )}
              <SelectedProductCard selectedPlan={activePlan} openCustomDownPayment={setCustomDownPaymentVisibility} />
            </ProductContainer>
          </InnerContainer>
          {activePlan?.promo && activePlan?.product?.promoApr !== null && activePlan?.product?.fallbackApr !== null && (
            <InnerContainer>
              <InfoContainer>
                <Icon width={20} height={20} src={'info_circle_outline'} />
                <InfoText
                  dangerouslySetInnerHTML={{
                    __html: translate('plans.apr', {
                      apr: activePlan?.product?.fallbackApr,
                    }),
                  }}
                />
              </InfoContainer>
            </InnerContainer>
          )}
        </CustomContentContainer>
        {!isMobile && <ButtonContainer>{renderButton()}</ButtonContainer>}
        {activePlan && (
          <CustomDownPayment
            show={customDownPaymentVisibility}
            hideModal={setCustomDownPaymentVisibility}
            activePlan={activePlan}
          />
        )}
      </Container>
      {isMobile && !customDownPaymentVisibility && <StickyButton>{renderButton()}</StickyButton>}
    </>
  );
};

export default PlanSelection;

const CustomContentContainer = styled(ContentContainer)<{ IsMobile: boolean }>`
  padding-bottom: ${(props) => (props.IsMobile ? '140px' : '0px')};
`;

const StickyButton = styled.div`
  display: flex;
  bottom: 0px;
  position: absolute;
  width: -webkit-fill-available;
  background-color: white;
  padding: 24px 24px 24px 24px;
  box-shadow: 0 -36px 67px rgba(0, 0, 0, 0.07), 0 -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0 -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
`;

const InsideContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const ProductContainer = styled.div`
  background-color: ${theme.main.lightGreen};
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  margin-top: 4px;
  margin-bottom: 16px;
`;

const ProductList = styled.div<{ totalPlanLength: number; isMobile: boolean }>`
  padding: 16px 16px 0px;
  display: flex;
  flex-wrap: ${(props) => (props?.totalPlanLength > 5 && props?.isMobile ? 'wrap' : 'no-wrap')};
  justify-content: ${(props) => (props?.totalPlanLength === 5 ? 'space-between' : 'flex-start')};
`;
