import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';

import { Button, Container, DemoMode, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { FormatAmount } from 'lib/utils';
import { APP_ENV } from 'config';

const ApplyCheckoutLanding = () => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const { organization, setPageIndex, isDemo, sessionData } = useStore();

  useEffect(() => {
    setPageIndex(0);
  }, [setPageIndex]);

  const RenderTitle = () => {
    return (
      <Helmet>
        <title>
          {translate('applyCheckoutLanding.helmetTitle', {
            organizationName: organization?.name,
          })}
        </title>
      </Helmet>
    );
  };

  const translateListItem = (itemName: string) => translate(`applyCheckoutLanding.listItem.${itemName}`);
  const listItem = ['item1', 'item2'].map(translateListItem);

  const navigateToTerms = () => {
    navigate(`terms`);
  };

  return (
    <>
      {(isDemo || APP_ENV === 'sandbox') && <DemoMode />}
      <Container showBackButton={false}>
        <ContentContainer justify="space-between">
          <InnerContainer>
            <RenderTitle />
            <Icon src={'cherry-logo'} />
            <Title m={'10px 0px 0px 0px'} data-testid="title">
              {translate('applyCheckoutLanding.title', {
                treatmentAmount: FormatAmount(sessionData?.treatmentAmount),
              })}
            </Title>
            <Subtitle>
              <ItemContainer>
                {listItem?.map((item, index) => (
                  <Item key={index}>{item}</Item>
                ))}
              </ItemContainer>
            </Subtitle>
          </InnerContainer>
        </ContentContainer>
        <ButtonContainer>
          <Button onClick={navigateToTerms}>{translate('buttons.continue')}</Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default ApplyCheckoutLanding;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Flex = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  margin: ${(props) => props?.margin || 0};
`;

const Span = styled.span`
  color: ${(props) => props.theme.main.primary};
  margin-left: ${(props) => props.theme.constants.s};
`;

const ItemContainer = styled.ul``;
const Item = styled.li`
  margin-bottom: 5px;
`;
