import React from 'react';
import styled from 'styled-components';

import useStore from 'lib/hooks/useStore';
import { redirectToCustomerPortal } from 'lib/utils';

import { AlleHeader, Button, HaveQuestionsModal, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { theme } from 'config/theme';
import Confetti from 'react-confetti';

const AlleSuccess = ({ confettiActive, containerRef }) => {
  const { organization } = useStore();

  const confettiColors: string[] = ['#9A6B5E', '#090909', '#CFCBCA', '#EFE1DD', '#C08676'];

  return (
    <ContentContainer>
      <AlleHeader showBackButton={false} />
      <PageTitle m={'10px 0px 8px 0px'} color={theme.main.black10} fontSize="18px" fontWeight={600} lineHeight="32px">
        Thanks for confirming your purchase!
      </PageTitle>

      <Paragraph m={'10px 0px 24px 0px'}>
        Your payment plan with {organization?.name} is confirmed! Please take a moment to explore your Cherry Patient
        Portal. You can:
      </Paragraph>
      <InfoContainer>
        <InfoItem>
          <IconContainer>
            <Icon src={'check'} width={21} />
          </IconContainer>
          <InfoText>Track payoff progress and view previous and future payments</InfoText>
        </InfoItem>
        <InfoItem>
          <IconContainer>
            <Icon src={'check'} width={21} />
          </IconContainer>
          <InfoText>Edit payment methods and manage Autopay</InfoText>
        </InfoItem>
        <InfoItem>
          <IconContainer>
            <Icon src={'check'} width={21} />
          </IconContainer>
          <InfoText>View terms of your plan and download documents and statements</InfoText>
        </InfoItem>
      </InfoContainer>
      {confettiActive && (
        <ConfettiContainer>
          <Confetti
            width={containerRef.current?.clientWidth}
            height={containerRef.current?.clientHeight}
            numberOfPieces={300}
            colors={confettiColors}
            recycle={false}
          />
        </ConfettiContainer>
      )}
      <Button
        onClick={redirectToCustomerPortal}
        black={true}
        uppercase={true}
        thinText={true}
        fontWeight={400}
        fontSize={11}
      >
        View in Cherry patient portal
      </Button>
      <FooterInfoText>
        <span>Taking you to </span> <Icon src="cherry_black_logo" width={60} />
      </FooterInfoText>
      <FootNote>
        <Icon width={20} height={20} src={'info_circle_black'} />
        <InfoText>This payment plan should show in your Cherry Patient Portal within one business day.</InfoText>
      </FootNote>
    </ContentContainer>
  );
};

const PageTitle = styled(Title)`
  font-family: 'Graphik' !important;
`;

const Paragraph = styled(Subtitle)`
  font-size: 16px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const InfoText = styled.div`
  color: ${(props) => props.theme.main.midnightBlue};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-left: 12px;
  width: calc(100% - 45px);
  font-family: 'Graphik' !important;
`;

const IconContainer = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.main.beige};
  border-radius: 50%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);

  * {
    font-family: 'Graphik' !important;
  }
`;

const FooterInfoText = styled.div`
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.2px;
  display: flex;
  align-items: center;
  margin: 0 auto;

  span {
    margin-right: 4px;
  }
`;

const FootNote = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0 32px;
  font-size: 12px;

  div {
    width: 100%;
  }
`;

const ConfettiContainer = styled.div`
  position: absolute;
  margin-left: -20px;
`;

export default AlleSuccess;
