import { gql } from '@apollo/client/core';

export const FETCH_AUTH_INFO = gql`
  query getUserAuthInfo {
    getUserAuthInfo {
      code
      features
      merchant {
        id
        name
      }
      message
      organization {
        id
        name
        slug
        isDemo
        website
        phone
        industry
        menuCode
      }
      success
      tokenType
      user {
        id
        firstName
        lastName
      }
      locations {
        id
        name
        address {
          id
          createdAt
          street
          zip
          city
        }
      }
    }
  }
`;
