import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import { GQL_URL } from 'config';
import jwtDecode from 'jwt-decode';

import StorageService from 'lib/services/Storage';
import NavigateService from 'lib/services/Navigate';
import useStore from 'lib/hooks/useStore';

const isExpiredToken = (token = '') => {
  try {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const decodedToken = jwtDecode(token) as any;

    const tokenExpiration = decodedToken.exp * 1000 - 10000;
    const currentTime = Date.now();

    return tokenExpiration < currentTime;
  } catch {
    return false;
  }
};

const httpLink = new HttpLink({
  uri: GQL_URL,
});

const authMiddleware = setContext(async (_, { headers, ...rest }) => {
  const { authData } = useStore.getState() || {};
  const { token, loanToken, slug } = authData || {};
  try {
    const userToken = headers?.extra ? token : loanToken;

    if (userToken !== 'undefined' && Boolean(userToken) && !isExpiredToken(userToken)) {
      return {
        headers: {
          ...headers,
          Authorization: userToken ? `Bearer ${userToken}` : '',
          'x-client-name': 'WPQ',
        },
        ...rest,
      };
    } else {
      const path = window.location.pathname;
      const splittedPathName = path.split('/');
      if (Boolean(userToken)) {
        NavigateService.navigate(splittedPathName[1]);
        StorageService.clearUserData();
      } else if (!userToken && ['getAuthInit', 'getExchangeToken', 'fetchBanner'].includes(_.operationName as string)) {
        return {
          headers: {
            ...headers,
            'x-client-name': 'WPQ',
          },
          ...rest,
        };
      } else if (performance.navigation.type == performance.navigation.TYPE_RELOAD && !Boolean(userToken)) {
        NavigateService.navigate(splittedPathName[1]);
      }

      return { headers: { ...headers } };
    }
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  } catch (error: any) {
    Sentry.captureException(error, {
      level: 'error',
      extra: {
        errorMessage: error?.message,
      },
    });

    client.stop();
    NavigateService.navigate(`/${slug}`);
    StorageService.clearUserData();
  }
});

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const checkTokenValidLink = new ApolloLink((operation: any, forward: any) => {
  const { authData } = useStore.getState() || {};
  const { slug } = authData || {};
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return forward(operation)?.map((response: any) => {
    try {
      if (response?.data?.[operation?.operationName]?.code === 'auth.token.invalid') {
        NavigateService.navigate(`/${slug}`);
        StorageService.clearUserData();
        return response;
      } else {
        return response;
      }
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (error: any) {
      Sentry.captureException(error, {
        level: 'error',
        extra: {
          errorMessage: error?.error?.message,
        },
      });
    }
  });
});

const link = checkTokenValidLink.concat(httpLink);

const client = new ApolloClient({
  name: 'webprequal',
  cache: new InMemoryCache(),
  link: from([authMiddleware, link]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});

export default client;
