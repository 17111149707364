import { theme } from 'config/theme';
import styled from 'styled-components';

export const Title = styled.span<{
  m?: string;
  p?: string;
  color?: string;
  fontSize?: string;
  fontFamily?: string;
  textAlign?: string;
  lineHeight?: string;
  fontWeight?: number;
  useElegantFontType?: boolean;
}>`
  font-family: ${(props) =>
      props.fontFamily ? props.fontFamily : props.useElegantFontType ? 'Petersburg' : theme.fonts.primary},
    serif;
  margin: ${(props) => props.m};
  padding: ${(props) => props.p};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 700)};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize} !important` : '20px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '27px')};
  text-align: ${(props) => (props?.textAlign ? props?.textAlign : 'left')};
  color: ${(props) => (props.color ? props.color : props.theme.main.midnightBlue)};

  i {
    color: ${(props) => props.theme.main.green};
    font-style: normal;
  }
`;
