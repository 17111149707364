import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import axios, { AxiosResponse } from 'axios';
import i18n from 'i18next';

import { GET_AUTH_INIT, GET_EXCHANGE_TOKEN, START_OTP_FLOW } from 'lib/graphql/mutations';
import { API_URL_STATIC, APP_ENV, SANDBOX_URL } from 'config';

import { AlleFullScreenLoading, Icon, Loading } from 'lib/components';
import { LANGUAGES, OK, SCOPE, WEB_SITE } from 'lib/constants';
import { useAnalytics } from 'lib/hooks/useAnalytics';
import useStore from 'lib/hooks/useStore';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import { useGetAgreementData } from 'lib/hooks/useGetAgreementData';
import NavigateService from 'lib/services/Navigate';
import StorageService from 'lib/services/Storage';
import { exchangeTokenPattern, initChatNiceWidget, Redirect, redirectToPaymentPlans, uuidPattern } from 'lib/utils';
import { usePlans } from 'pages/Plans/services';

import { useIdleTimeout } from 'lib/hooks/useIdleTimeout';
import { useServices } from './services';
import { DefaultFlow, IncomeVerificationFlow, LoanStatuses, OauthWpqFlow, PartnerLinkFlow } from './type';
import { loanStatusService } from './utilityFunctions';
import usePartnerApiEventSource from 'lib/hooks/usePartnerApiEventSource';
import { IframeEventActions, IframeEventTypes, useIframeEvents } from 'lib/hooks/useIframeEvents';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useSentry } from 'lib/hooks/useSentry';
import { useSegment } from 'lib/hooks/useSegment';
import Rox from 'rox-browser';
import client from 'lib/graphql/client';
import { GET_APPLY_WITH_PATIENT_CONSENT } from 'lib/graphql/queries';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { useYellowAIChatbot } from 'lib/hooks/useYellowAIChatbot';

const staticRequest = axios.create({
  baseURL: API_URL_STATIC,
});

export const AuthChecker = () => {
  const { addBreadcrumb, setTag } = useSentry();
  const { search } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { applicationStatusUpdated } = useAnalytics();
  const [searchParams] = useSearchParams();
  const { getPaymentMethods } = usePlans();
  const { flags, hasRoxInitialized } = useFlags();
  const { getAuthInfo, getApplication, getLoan, getBorrower, createApplication, getBorrowerPaymentMethods } =
    useServices();
  const { getProductCalculation } = useProductCalculation();
  const { startCheckApplicationStatus } = useSSEContext();
  const { listenPartnerApiEvents } = usePartnerApiEventSource();
  const { pushEvent } = useIframeEvents();
  const { trackAlleIframeLoaded } = useSegment();
  const { getAgreementData } = useGetAgreementData();

  const { initChatbotYellowAI, updateChatbotYellowAI } = useYellowAIChatbot();

  const ChatInit = useRef(false);
  const isUuidParam = uuidPattern.test(id ?? '');

  const {
    setOrganization,
    setType,
    setPartner,
    setIsDemo,
    setAppLoading,
    appLoading,
    setFlowType,
    setApplication,
    setIncomeVerification,
    showChat,
    setShowChat,
    setOrganizationGroup,
    setSessionData,
    setSelectedLocation,
    setLoan,
    setBorrower,
    setIsAlle,
    setPartnerApplicationId,
    setSelectedPlan,
    setDownPaymentMethod,
    setInstallmentMethod,
    setApplyWithPatientReviewData,
    setAuthData,
    setSessionApiData,
    organization,
  } = useStore();

  useIdleTimeout({ idleTime: 45 });
  NavigateService.initNavigate(navigate);

  const [getAuthInit] = useMutation(GET_AUTH_INIT);
  const [getExchangeToken] = useMutation(GET_EXCHANGE_TOKEN);
  const [startOtpFlow] = useMutation(START_OTP_FLOW);

  const isRequestSentRef = useRef(false);
  const getLocalizationFiles = useCallback(async () => {
    try {
      setAppLoading(true);
      const languagePromises: Array<Promise<AxiosResponse>> = [];
      const sessionTime = new Date().getTime();
      LANGUAGES.forEach((language: string) => {
        languagePromises.push(staticRequest.get(`/locales/${language}.json?cb=${sessionTime}`));
      });
      const resp = await Promise.all(languagePromises);

      LANGUAGES.forEach((language, index) => {
        i18n?.addResourceBundle(language, 'translation', resp?.[index]?.data);
      });
      setAppLoading(false);
    } catch (err) {
      setAppLoading(false);
    }
  }, []);

  const initializeAuthFlow = async (slug) => {
    const { authData } = useStore.getState();
    const initializeUserResponse = await initializeAuth(slug);
    let authInitResponse;
    setAuthData({
      slug,
      loanToken: authData?.loanToken ?? '',
      token: initializeUserResponse?.token,
      refreshToken: initializeUserResponse?.refreshToken,
    });
    if (!initializeUserResponse.organizationGroup) {
      authInitResponse = await getAuthInfo();
    }

    addBreadcrumb('function.initializeAuthFlow', `Initialize auth flow started: ${slug}`);
    addBreadcrumb('function.initializeAuthFlow', `User info: ${initializeUserResponse?.user?.id}`);
    addBreadcrumb('function.initializeAuthFlow', `Organization info: ${initializeUserResponse?.organization?.id}`);

    return {
      ...initializeUserResponse,
      ...(!initializeUserResponse.organizationGroup && { authInit: authInitResponse }),
    };
  };

  const initializeAuth = async (slug: string) => {
    const { data } = await getAuthInit({ variables: { input: { slug, scope: SCOPE } } });
    if (data?.getUserAuthInit?.code === OK) {
      const { organization } = data?.getUserAuthInit || {};
      setOrganization(organization);
      setOrganizationGroup(data?.getUserAuthInit?.organizationGroup);
      setIsDemo(data?.getUserAuthInit?.organization?.isDemo);

      addBreadcrumb('function.initializeAuth', `Initialize auth started: ${slug}`);
      addBreadcrumb('function.initializeAuth', `organization info: ${data?.getAuthInit?.organization?.id}`);
      addBreadcrumb('function.initializeAuth', `industry info: ${data?.getAuthInit?.organization?.industry}`);
      addBreadcrumb('function.initializeAuth', `demo info: ${data?.getAuthInit?.organization?.isDemo}`);

      const isSandboxNavigationEnabled = flags?.DEMO?.sandboxNavigationEnabled?.isEnabled();
      if (data?.getUserAuthInit?.organization?.isDemo && APP_ENV !== 'sandbox' && isSandboxNavigationEnabled) {
        window.location.href = `${SANDBOX_URL}/${slug}`;
      }

      return data?.getUserAuthInit;
    } else {
      navigate(`/${slug}/not-found`);
    }
  };

  const applyCheckoutFlow = async ({ data }: { data: IncomeVerificationFlow }) => {
    setFlowType('ApplyCheckoutFlow');
    const { borrowerId, slug, phone, treatmentAmount, merchantId, locations } = data || {};
    await getBorrower(borrowerId);
    const params = {
      phone,
      treatmentAmount,
      merchantId,
    };
    const findLocation = locations!.find((loc) => loc?.id === merchantId);
    setSelectedLocation(findLocation);
    setSessionData(params);
    setAppLoading(false);

    addBreadcrumb('function.applyCheckoutFlow', `Apply checkout flow started: ${slug}`);
    addBreadcrumb('function.applyCheckoutFlow', `Borrower info: ${borrowerId}`);
    addBreadcrumb('function.applyCheckoutFlow', `Merchant info: ${merchantId}`);
    addBreadcrumb('function.applyCheckoutFlow', `Location info: ${findLocation}`);

    navigate(`/${slug}/apply-checkout-landing`);
  };

  const pointOfSalesFlow = async ({ data }: { data: IncomeVerificationFlow }) => {
    setFlowType('PointOfSalesFlow');
    const { borrowerId, slug, phone, firstName, lastName, treatmentAmount, pointOfSaleId } = data || {};
    await getBorrower(borrowerId);

    const params = {
      firstName,
      lastName,
      phone,
      treatmentAmount,
      pointOfSaleId,
      posRemainingAmount: treatmentAmount,
    };
    setSessionData(params);
    setAppLoading(false);

    addBreadcrumb('function.pointOfSalesFlow', `Point of sales flow started: ${slug}`);
    addBreadcrumb('function.pointOfSalesFlow', `Borrower info: ${borrowerId}`);
    addBreadcrumb('function.pointOfSalesFlow', `Sale info: ${pointOfSaleId}`);
    setTag('flow', 'pointOfSalesFlow');

    navigate(`/${slug}/pos-landing`);
  };

  const incomeVerificationFlow = async ({ data }: { data: IncomeVerificationFlow }) => {
    setFlowType('IncomeVerificationFlow');
    const { borrowerId, applicationId, slug } = data || {};
    await getBorrower(borrowerId);

    setApplication({ id: applicationId });
    setAppLoading(false);

    addBreadcrumb('function.incomeVerificationFlow', `Income verification flow started: ${slug}`);
    addBreadcrumb('function.incomeVerificationFlow', `Borrower info: ${borrowerId}`);
    addBreadcrumb('function.incomeVerificationFlow', `Application info: ${applicationId}`);
    setTag('flow', 'incomeVerificationFlow');

    navigate(`/${slug}/income-verification`);
  };

  const oauthWpqFlow = async ({ data }: { data: OauthWpqFlow }) => {
    setFlowType('OauthWpqFlow');
    const { borrowerId, applicationId, slug, oauthStateId } = data || {};
    await getBorrower(borrowerId);

    setApplication({ id: applicationId });

    try {
      const applicationResponse = await getApplication(applicationId);
      if (applicationResponse?.partner?.toLowerCase() === 'alle') {
        setIsAlle(true);
      }
    } catch (e) {
      setIsAlle(false);
    }

    setAppLoading(false);

    addBreadcrumb('function.oauthWpqFlow', `Oauth flow started: ${slug}`);
    addBreadcrumb('function.oauthWpqFlow', `Application info: ${applicationId}`);
    addBreadcrumb('function.oauthWpqFlow', `Borrower info: ${borrowerId}`);
    setTag('flow', 'oauthWpqFlow');

    navigate(`/${slug}/bank-connect-callback/${oauthStateId}`);
  };

  const partnerLinkFlow = async ({ data }: { data: PartnerLinkFlow }) => {
    setFlowType('PartnerLinkFlow');
    const { borrowerId, partner, applicationId } = data || {};
    await getBorrower(borrowerId);
    setApplication({ id: applicationId });
    setPartner(partner);
    if (partner === 'Alle') {
      await createApplication();
      const { application, partnerApplicationId } = useStore.getState();
      if (partnerApplicationId === null) {
        setPartnerApplicationId(application?.id);
        listenPartnerApiEvents(application?.id);
      }
      await startCheckApplicationStatus({});
    } else {
      addBreadcrumb('function.partnerLinkFlow', `Partner link flow started: ${partner}`);
      addBreadcrumb('function.partnerLinkFlow', `Borrower info: ${borrowerId}`);
      addBreadcrumb('function.partnerLinkFlow', `Application info: ${applicationId}`);
      setTag('flow', 'partnerLinkFlow');

      await createApplication();
      await startCheckApplicationStatus({});
    }
    setTimeout(() => {
      setAppLoading(false);
      pushEvent(IframeEventTypes.IFRAME, IframeEventActions.LOADED);
      if (isAlle) {
        trackAlleIframeLoaded(true);
      }
    }, 400);
  };

  const applicationLinkFlow = async ({ data }: { data: PartnerLinkFlow }) => {
    setFlowType('ApplicationLinkFlow');
    const { borrowerId, applicationId } = data || {};

    await getBorrower(borrowerId);
    setApplication({ id: applicationId });

    addBreadcrumb('function.applicationLinkFlow', `application link flow started`);
    addBreadcrumb('function.applicationLinkFlow', `Borrower info: ${borrowerId}`);
    addBreadcrumb('function.applicationLinkFlow', `Application info: ${applicationId}`);
    setTag('flow', 'applicationLinkFlow');

    await startCheckApplicationStatus({});
    setAppLoading(false);
  };

  const defaultFlow = async ({ data }: { data: DefaultFlow }) => {
    setFlowType('CheckoutFlow');
    const { idLoan, borrowerId, slug } = data || {};

    addBreadcrumb('function.defaultFlow', `Default flow started: ${slug}`);
    addBreadcrumb('function.defaultFlow', `Borrower info: ${borrowerId}`);
    addBreadcrumb('function.defaultFlow', `Loan info: ${idLoan}`);
    setTag('flow', 'CheckoutFlow');

    const loanData = await getLoan(idLoan);
    Rox.setCustomStringProperty('application_id_str', String(loanData?.applicationId));
    await getBorrower(borrowerId);
    const applicationResponse = await getApplication(loanData?.applicationId);

    if (applicationResponse?.partner?.toLowerCase() === 'alle') {
      setIsAlle(true);
    }

    setTag('flow', 'defaultFlow');
    if (loanData?.status === LoanStatuses.AWAITING) {
      addBreadcrumb('function.defaultFlow', `Redirect to slug: ${slug}`);
      navigate(`/${slug}`);
      setAppLoading(false);
    } else if (loanData?.status === LoanStatuses.CANCELLED) {
      addBreadcrumb('function.defaultFlow', `Expired: ${slug}`);
      addBreadcrumb('function.defaultFlow', `Loan info: ${loanData}`);

      navigate(`/${slug}/expired`);
    } else {
      if (loanData?.selfCheckout) {
        addBreadcrumb('function.defaultFlow', `Self checkout: true`);
        applicationStatusUpdated(applicationResponse);
        loanStatusService(loanData, slug);
        setAppLoading(false);
      } else {
        addBreadcrumb('function.defaultFlow', `Polling: started`);
        await startCheckApplicationStatus({ applicationId: applicationResponse?.id });
        setAppLoading(false);
      }
    }
  };

  const selfCheckoutWithEmail = async ({ data }: { data: DefaultFlow }) => {
    setFlowType('CheckoutWithEmailFlow');
    const { idLoan, borrowerId, slug, phone } = data || {};

    setLoan({ id: idLoan });
    setBorrower({ borrowerId, phone });

    setTag('flow', 'selfCheckoutWithEmail');
    addBreadcrumb('function.selfCheckoutWithEmail', `Self checkout with email started with: ${slug}`);
    addBreadcrumb('function.selfCheckoutWithEmail', `Borrower info: ${borrowerId}`);
    addBreadcrumb('function.selfCheckoutWithEmail', `Loan info: ${idLoan}`);

    navigate(`/${slug}/phone`);
    setAppLoading(false);
  };

  const selfCheckoutConfirm = async ({ data }: { data: DefaultFlow }) => {
    setFlowType('CheckoutWithConfirmFlow');
    const { idLoan, borrowerId, slug, phone } = data || {};
    const { sessionApiData } = useStore.getState();
    const { featureFlags } = sessionApiData || {};

    const loan = await getLoan(idLoan);
    await getBorrower(borrowerId);
    await getBorrowerPaymentMethods();
    const productCalculations = await getProductCalculation(loan?.applicationId, loan?.grossAmount);
    const isDownPaymentEnabled = !isAlle;

    let filteredProductCalculations = productCalculations;
    if (!isDownPaymentEnabled) {
      filteredProductCalculations = productCalculations.filter(
        (calculation) => calculation?.product?.parentId === null,
      );
    }

    const selectedPlan = filteredProductCalculations?.find(
      (productCalculation) => productCalculation?.product?.id === String(loan?.productId),
    );

    const paymentMethodsResponse = await getPaymentMethods();
    const downPaymentMethod = paymentMethodsResponse?.contents?.find(
      (method) => Number(method.id) === loan?.downPaymentPaymentMethodId,
    );
    const installmentPaymentMethod = paymentMethodsResponse?.contents?.find(
      (method) => Number(method.id) === loan?.installmentPaymentMethodId,
    );

    await getAgreementData();

    setSelectedPlan(selectedPlan);
    setDownPaymentMethod(downPaymentMethod);
    setInstallmentMethod(installmentPaymentMethod);

    setLoan({ id: idLoan });
    setBorrower({ borrowerId, phone });

    setTag('flow', 'selfCheckoutConfirm');
    addBreadcrumb('function.selfCheckoutConfirm', `Self checkout confirm started with: ${slug}`);
    addBreadcrumb('function.selfCheckoutConfirm', `Borrower info: ${borrowerId}`);
    addBreadcrumb('function.selfCheckoutConfirm', `Loan info: ${idLoan}`);

    navigate(`/${slug}/confirm`);
    setAppLoading(false);
  };

  const checkoutOnDevice = async ({ data }: { data: DefaultFlow }) => {
    setFlowType('CheckoutOnDeviceFlow');
    const { idLoan, borrowerId, slug, phone } = data || {};

    await startOtpFlow({
      variables: {
        input: {
          phone,
        },
      },
      context: {
        headers: {
          extra: 'borrowerInit',
        },
      },
    });

    setSessionData({
      phone,
    });

    setLoan({ id: idLoan });
    setBorrower({ borrowerId, phone });

    setTag('flow', 'checkoutOnDevice');
    addBreadcrumb('function.checkoutOnDevice', `Checkout on device started with: ${slug}`);
    addBreadcrumb('function.checkoutOnDevice', `Borrower info: ${borrowerId}`);
    addBreadcrumb('function.checkoutOnDevice', `Loan info: ${idLoan}`);

    navigate(`/${slug}/verify`);
    setAppLoading(false);
  };

  const applyWithPatientFlow = async ({ data }) => {
    const { slug, consentId } = data;
    try {
      const { data } = await client.query({
        query: GET_APPLY_WITH_PATIENT_CONSENT,
        variables: { input: { id: consentId } },
      });
      if (data?.getApplyWithPatientConsent?.status) {
        setApplyWithPatientReviewData(data?.getApplyWithPatientConsent);
        navigate(`/${slug}/apply-with-patient`);
      } else {
        navigate(`/${slug}/expired`);
      }
    } catch (err) {
      navigate(`/${slug}/expired`);
      console.error(err);
    }
  };

  const applicationApprovalAmountIncreaseFlow = async ({ data }) => {
    setFlowType('ApplicationApprovalAmountIncreaseFlow');
    const { borrowerId, applicationId } = data || {};

    await getBorrower(borrowerId);
    setApplication({ id: applicationId });

    setTag('flow', 'applicationApprovalAmountIncreaseFlow');

    await startCheckApplicationStatus({});
    setAppLoading(false);
  };

  const getInitialRequests = async () => {
    try {
      if (id) {
        if (exchangeTokenPattern.test(id)) {
          const { data } = await getExchangeToken({ variables: { input: { token: id } } });
          const {
            idLoan,
            applicationId,
            borrowerId,
            firstName,
            lastName,
            phone,
            pointOfSaleId,
            treatmentAmount,
            slug,
            incomeVerificationId,
            partner,
            merchantId,
            consentId,
          } = data?.getExchangeToken?.data || {};

          const oauthStateId = searchParams.get('oauth_state_id');

          const exchangeTokenType = data?.getExchangeToken?.type;
          setType(exchangeTokenType);
          setIncomeVerification({ id: incomeVerificationId });

          const { authData } = useStore.getState();
          setAuthData({
            slug,
            loanToken: data?.getExchangeToken?.token,
            token: authData?.token ?? '',
            refreshToken: '',
          });

          if (partner === 'Alle') {
            setIsAlle(true);
          }

          if (data?.getExchangeToken?.status === 'EXPIRED') {
            await initializeAuthFlow(slug);
            navigate(`/${slug}/expired`);
            setAppLoading(false);
            setFlowType('ExpiredFlow');
          } else {
            const authFlowData = await initializeAuthFlow(slug);
            switch (exchangeTokenType) {
              case 'APPLY_WITH_BORROWER_CONSENT':
                applyWithPatientFlow({
                  data: {
                    slug,
                    consentId,
                  },
                });
                break;
              case 'APPLY_CHECKOUT':
                await applyCheckoutFlow({
                  data: {
                    borrowerId,
                    applicationId,
                    slug,
                    phone,
                    merchantId,
                    treatmentAmount,
                    locations: authFlowData.authInit.locations,
                  },
                });
                break;
              case 'POINT_OF_SALE':
                await pointOfSalesFlow({
                  data: { borrowerId, applicationId, slug, firstName, lastName, phone, pointOfSaleId, treatmentAmount },
                });
                break;
              case 'INCOME_VERIFICATION':
                await incomeVerificationFlow({ data: { borrowerId, applicationId, slug } });
                break;
              case 'OAUTH_WPQ':
                await oauthWpqFlow({ data: { borrowerId, applicationId, slug, oauthStateId } });
                break;
              case 'PARTNER_LINK':
                await partnerLinkFlow({
                  data: { borrowerId, partner, applicationId, organizationData: authFlowData.organization },
                });
                break;
              case 'APPLICATION_APPROVAL_AMOUNT_INCREASE':
                await applicationApprovalAmountIncreaseFlow({
                  data: { borrowerId, partner, applicationId, organizationData: authFlowData.organization },
                });
                break;
              case 'APPLICATION':
                await applicationLinkFlow({
                  data: { borrowerId, partner, applicationId, organizationData: authFlowData.organization },
                });
                break;
              case 'SELF_CHECKOUT_EMAIL':
                await selfCheckoutWithEmail({
                  data: { borrowerId, idLoan, slug, phone },
                });
                break;
              case 'CHECKOUT_ON_DEVICE':
                await checkoutOnDevice({
                  data: { borrowerId, idLoan, slug, phone },
                });
                break;
              case 'CHECKOUT_WITH_PATIENT_CONFIRMATION':
                await selfCheckoutConfirm({
                  data: { borrowerId, idLoan, slug, phone },
                });
                break;
              default:
                await defaultFlow({ data: { borrowerId, idLoan, slug } });
                break;
            }
          }
        } else {
          setFlowType('InitialFlow');
          await initializeAuthFlow(id);
          setAppLoading(false);
        }
      } else {
        redirectToPaymentPlans();
      }
    } catch (err) {
      Redirect(WEB_SITE, '_self');
    }
  };

  /**
   * Initialize Chat Support - Nice
   */
  useEffect(() => {
    StorageService.clearAuthData();
    if (!ChatInit.current) {
      getInitialRequests?.();
      ChatInit.current = true;
      initChatNiceWidget();
    }
  }, []);

  /**
   * Initialize Chatbot - YellowAI
   * Update once borrower information is entered
   */
  const { borrower } = useStore();
  const isNameEntered = !!borrower?.firstName;

  const updateChatbot = () => {
    updateChatbotYellowAI(borrower);
  };

  useEffect(() => {
    initChatbotYellowAI();
  }, []);

  useEffect(() => {
    if (isNameEntered) {
      updateChatbot();
    }
  }, [isNameEntered]);

  useEffect(() => {
    if (hasRoxInitialized && organization?.id) {
      Rox.setCustomStringProperty('organization_id_str', String(organization?.id));
      const isNewDenialUi = flags.SCREEN.newDenialUi.isEnabled() || false;
      const isFakeRequestIncreaseExprimentEnabled = flags.SCREEN.fakeRequestIncreaseExpriment.isEnabled() || false;
      const isDefaultLoanTermEnabled = flags.CHECKOUT.defaultLoanTerm.isEnabled() || false;
      const isNewAllePaymentEstimator = flags.SCREEN.newAllePaymentEstimator.isEnabled() || false;
      setSessionApiData({
        featureFlags: {
          isNewDenialUi,
          isFakeRequestIncreaseExprimentEnabled,
          isDefaultLoanTermEnabled,
          isNewAllePaymentEstimator,
        },
      });
    }
  }, [hasRoxInitialized, organization]);

  useEffect(() => {
    if (!isRequestSentRef.current) {
      getLocalizationFiles();
      isRequestSentRef.current = true;
    }
  }, [getLocalizationFiles]);

  const closeChat = () => {
    setShowChat(false);
  };

  const { isAlle } = useStore.getState();

  return (
    <>
      <div>
        <Outlet />
      </div>
      {/* Wait until the app routes to different url than a uuid parameter */}
      {appLoading || isUuidParam ? (
        isAlle ? (
          <AlleFullScreenLoading isTextPresent={false} />
        ) : (
          <CenterLoading>
            <Loading size={40} />
          </CenterLoading>
        )
      ) : null}
      {showChat ? (
        <CloseChatButton onClick={closeChat}>
          <Icon src={'close-white'} hover width={24} height={24} />
        </CloseChatButton>
      ) : null}
      <GlobalStyle showChat={showChat} />
    </>
  );
};

const GlobalStyle = createGlobalStyle<{ showChat: boolean }>`
  body {
    .chat-iframe-wrap {
      width: ${(props) => (props.showChat ? '380px' : '0')} !important;
      height: 690px !important;
      position: absolute;
      left: calc(100vw - 380px) !important;
      top: calc(100vh - 690px) !important;
      box-shadow: unset !important;

      iframe {
        width: ${(props) => (props.showChat ? '380px' : '0')} !important;
      }
    }

    @media (max-width: 680px) {
      .chat-iframe-wrap {
        width: ${(props) => (props.showChat ? '100%' : '0')} !important;
        height: 100% !important;
        position: fixed;
        left: 0px !important;
        top: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
        box-shadow: unset !important;

        iframe {
          width: ${(props) => (props.showChat ? '100%' : '0')} !important;
        }
      }
    }
  }
`;

const CenterLoading = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.main.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000000000;
`;

const CloseChatButton = styled.div`
  position: absolute;
  z-index: 99999999999;
  left: calc(100vw - 30px) !important;
  top: calc(100vh - 683px) !important;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    left: unset;
    right: 20px !important;
    top: 5px !important;
  }
`;
