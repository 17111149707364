import React from 'react';
import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';

import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { Icon, Title } from 'lib/components';
import FaqListComponent from 'pages/Faq/components/FaqListComponent';

interface FaqItem {
  title: string;
  content: string;
}

interface Props {
  show: boolean;
  hideModal: () => void;
  faqList: FaqItem[];
  title: string;
  titleMargin: string;
  modalHeight?: string;
  modalWidth?: string;
  modalTop?: string;
  scrollEnabled?: boolean;
}

export const FaqListModal = React.memo(
  ({ show, hideModal, faqList, title, titleMargin, modalHeight, modalWidth, modalTop, scrollEnabled }: Props) => {
    const { isAlle } = useStore();

    const closeModalOnClickAway = (e) => {
      const isCaret = e.target?.getAttribute('data-testid') === 'ExpandMoreIcon';
      if (e?.target?.ariaHidden && !isCaret) {
        hideModal();
      }
    };

    return (
      <Backdrop
        sx={{ color: theme.main.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={show}
        onClick={closeModalOnClickAway}
      >
        <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
          <FaqModalContainer
            top={modalTop || '170px'}
            width={modalWidth || '400px'}
            height={modalHeight || '360px'}
            scrollEnabled={scrollEnabled || false}
            useElegantTheme={isAlle}
          >
            <InnerContainer>
              <FaqModalHeader>
                <IconGap />
                <Title
                  m={'24px 0px'}
                  color={isAlle && theme.main.black10}
                  fontSize={isAlle && '16px'}
                  fontWeight={isAlle && 500}
                  lineHeight={isAlle && '24px'}
                >
                  {title}
                </Title>
                <Icon src={'close'} onClick={hideModal} hover={true} height={isAlle && 24} />
              </FaqModalHeader>
              <FaqListContainer>
                <FaqListComponent faqList={faqList} titleMargin={titleMargin} useElegantTheme={isAlle} />
              </FaqListContainer>
            </InnerContainer>
          </FaqModalContainer>
        </Slide>
      </Backdrop>
    );
  },
);

const FaqModalContainer = styled.div<{
  height: string;
  width: string;
  top: string;
  scrollEnabled: boolean;
  useElegantTheme?: boolean;
}>`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background-color: ${(props) => props.theme.main.white};
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 30px;
  height: ${(props) => props.height};

  overflow: ${(props) => (props.scrollEnabled ? 'scroll' : 'visible')};

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: ${(props) => props.top};
    width: ${(props) => props.width};
    border-radius: 6px;
  }

  * {
    font-family: ${(props) => props.useElegantTheme && 'Graphik'};
  }
`;

const FaqModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FaqListContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: -35px;
`;
