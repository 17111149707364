import { useApolloClient } from '@apollo/client';

import { GET_PRODUCT_CALCULATIONS } from 'lib/graphql/queries';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { SortWithProductTerm } from 'lib/utils';

export const useProductCalculation = () => {
  const client = useApolloClient();
  const { setProductCalculations } = useStore();
  const { captureException } = useSentry();

  const getProductCalculation = async (applicationId: string, amount: number) => {
    if (applicationId && amount) {
      const { data } = await client.query({
        query: GET_PRODUCT_CALCULATIONS,
        variables: {
          input: {
            applicationId,
            amount,
          },
        },
      });

      if (data?.getProductCalculationsV2?.data?.length && Array.isArray(data?.getProductCalculationsV2?.data)) {
        try {
          const cloneData = [...data?.getProductCalculationsV2?.data];
          const sortedData = SortWithProductTerm(cloneData);

          setProductCalculations(sortedData);
          return data?.getProductCalculationsV2?.data;
        } catch (err) {
          captureException('Product Calculation Sorting Error');
          setProductCalculations(data?.getProductCalculationsV2?.data);
          return data?.getProductCalculationsV2?.data;
        }
      }
      return [];
    } else {
      return [];
    }
  };

  return { getProductCalculation };
};
