import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer, FooterText } from 'lib/components/Common';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { WEB_SITE } from 'lib/constants';
import { getExternalLinkLangParam } from 'lib/utils/ExternalLinkLangParam';

export const Acknowledgement = () => {
  const { t: translate } = useTranslation();
  const { navigate } = useNavigation();
  const { organization, defaultLanguage } = useStore();

  const navigateToPlanSelectionScreen = () => {
    navigate(`plans`);
  };

  const footerText = translate('phone.footer.text', {
    eSignDisclosuresAndConsent: `<a target="_blank" href=${WEB_SITE}/e-sign-disclosure${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('phone.footer.eSignDisclosuresAndConsent')}</a>`,
    termsOfService: `<a target="_blank" href=${WEB_SITE}/terms${getExternalLinkLangParam(defaultLanguage)}>${translate(
      'phone.footer.termsOfService',
    )}</a>`,
    privacyPolicy: `<a target="_blank" href=${WEB_SITE}/privacy${getExternalLinkLangParam(defaultLanguage)}>${translate(
      'phone.footer.privacyPolicy',
    )}</a>`,
    usaPatriotAct: `<a target="_blank" href=${WEB_SITE}/terms/#patriot-act${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('phone.footer.usaPatriotAct')}</a>`,
  });

  const getStartedFooterText = translate('getStarted.footer.text', {
    lendingPartners: `<a target="_blank" href=${WEB_SITE}/lending-partners/${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('getStarted.footer.lendingPartners')}</a>`,
    spanishDisclosure: `<a target="_blank" href=${WEB_SITE}/spanish>${translate(
      'getStarted.footer.spanishDisclosure',
    )}</a>`,
  });

  return (
    <Container showBackButton={false} isRelative={true}>
      <StyledContentContainer>
        <Icon src={'glasses-review'} />
        <Title m={'10px 0px 8px 0px'} textAlign="left">
          {translate('acknowledgement.title')}
        </Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('acknowledgement.subtitle')}</Subtitle>
        <AcknowledgmentContainer>
          <Title> {translate('acknowledgement.termsAndConditions')}</Title>
          <ul>
            <li>
              <FooterText
                dangerouslySetInnerHTML={{
                  __html: getStartedFooterText,
                }}
              />
            </li>
            <li>
              <FooterText dangerouslySetInnerHTML={{ __html: footerText }} />
            </li>
            <li>
              <FooterText>
                {translate('acknowledgement.ssnCollectTerm', {
                  name: organization?.name,
                })}
              </FooterText>
            </li>
          </ul>
        </AcknowledgmentContainer>
      </StyledContentContainer>
      <ButtonContainer>
        <Button data-testid="submit" onClick={navigateToPlanSelectionScreen}>
          {translate('acknowledgement.continueBtn')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const StyledContentContainer = styled(ContentContainer)`
  max-height: calc(100vh - 220px) !important;
`;

const AcknowledgmentContainer = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: #f2f4f5;
  overflow-y: scroll;
`;
