import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';

import { Button, Container, DemoMode, Icon, RadioButtonGroup, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useInitializeAuth } from 'pages/AuthChecker/services/useInitializeAuth';
import { FormatAmount } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { APP_ENV } from '../../config';

enum RadioButtonEnums {
  YES = 'YES',
  NO = 'NO',
}

const PosQualify = () => {
  const { navigate } = useNavigation();
  const { initializeAuth } = useInitializeAuth();
  const { t: translate } = useTranslation();
  const { setPageIndex, isDemo, sessionData } = useStore();

  const [radioValue, setRadioValue] = useState<RadioButtonEnums | ''>(RadioButtonEnums.YES);

  useEffect(() => {
    setPageIndex(0);
  }, [setPageIndex]);

  const listItem = [
    translate('posQualify.item1', {
      amount: FormatAmount(sessionData?.treatmentAmount / 24),
    }),
    translate('posQualify.item2'),
    translate('posQualify.item3'),
  ];

  const onChangeHandler = (e) => {
    setRadioValue(e?.target?.value);
  };

  const posQualifyHandler = () => {
    if (radioValue === RadioButtonEnums.YES) {
      navigate(``);
    } else {
      navigate(`pos-payment-review`);
    }
  };

  return (
    <>
      {(isDemo || APP_ENV === 'sandbox') && <DemoMode />}
      <Container showBackButton={false}>
        <ContentContainer justify="space-between">
          <InnerContainer>
            <Icon src={'payment'} />
            <Title m={'10px 0px 0px 0px'} data-testid="title">
              {translate('posQualify.title')}
            </Title>
            <ItemContainer>
              {listItem?.map((item, index) => (
                <Item key={index}>{item}</Item>
              ))}
            </ItemContainer>

            <RadioButtonGroup
              onChange={onChangeHandler}
              radioButtonOptions={[
                {
                  textValue: RadioButtonEnums.YES,
                  radioLabel: translate('posQualify.label.yes'),
                },
                {
                  textValue: RadioButtonEnums.NO,
                  radioLabel: translate('posQualify.label.no'),
                },
              ]}
              value={radioValue}
              size={'large'}
            />
          </InnerContainer>
        </ContentContainer>
        <ButtonContainer>
          <Button onClick={posQualifyHandler}>{translate('buttons.continue')}</Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default PosQualify;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemContainer = styled.ul``;
const Item = styled.li`
  margin-bottom: 5px;
`;
