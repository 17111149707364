import React, { useState } from 'react';

import { Accordion, Icon, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import styled from 'styled-components';

interface FaqListProps {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  faqList: any[];
  title?: string;
  titleMargin?: string;
  iconSrc?: string;
  useElegantTheme?: boolean;
}

const FaqListComponent = ({ faqList, title, titleMargin, iconSrc, useElegantTheme }: FaqListProps) => {
  const [expanded, setExpanded] = useState<string | false>('panel0');

  const handleChange = (panel: string) => {
    setExpanded(panel);
  };

  return (
    <ContentWrapper noPadding={true}>
      {iconSrc && <Icon src={iconSrc} />}
      {title && <Title m={titleMargin}>{title}</Title>}
      <Accordion data={faqList} onChange={handleChange} expanded={expanded} isAlle={useElegantTheme} />
    </ContentWrapper>
  );
};

const ContentWrapper = styled(ContentContainer)<{ useElegantTheme?: boolean }>`
  * {
    font-family: ${(props) => props.useElegantTheme && 'Graphik'};
  }
`;

export default FaqListComponent;
