import { useState } from 'react';
import styled from 'styled-components';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { Button, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { useMutation } from '@apollo/client';
import { CONFIRM_POINT_OF_SALE } from 'lib/graphql/mutations';
import { FormatAmount } from 'lib/utils';
import { useTranslation } from 'react-i18next';

type CardElementChangeType = {
  brand: string;
  complete: boolean;
  elementType: string;
  empty: boolean;
  error;
  value: {
    postalCode: string;
  };
};

interface Props {
  clientSecret: string;
}

const CheckoutForm = ({ clientSecret }: Props) => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const { sessionData } = useStore();

  const [confirmPointOfSale] = useMutation(CONFIRM_POINT_OF_SALE);

  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  if (!stripe || !elements) {
    return null;
  }
  const handleSubmit = async (event) => {
    const { organization, isPosSplitPayment, borrower } = useStore.getState();
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements || !clientSecret) {
      return;
    }

    const stripeCardElement = elements.getElement(CardElement);

    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: stripeCardElement!,
          billing_details: {
            email: borrower?.email || '',
            name: borrower?.firstName || '',
            phone: borrower?.phone || '',
          },
        },
      })
      .then(async function (result) {
        setLoading(false);

        if (result.error) {
          // error
        } else {
          await confirmPointOfSale({
            variables: {
              input: {
                organizationId: organization?.id,
                amount: sessionData.posRemainingAmount,
                provider: 'STRIPE',
                providerPaymentId: result?.paymentIntent?.id,
                pointOfSaleId: sessionData.pointOfSaleId,
              },
            },
          });

          const redirectUrl = isPosSplitPayment ? 'success' : 'pos-congrats';
          navigate(redirectUrl);
        }
      });
  };

  const onChangeHandler = (event: CardElementChangeType) => {
    if (event?.complete) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'credit_card'} />
          <Title m={'10px 0px 8px 0px'}>{translate('posLinkCanceled.checkout.title')}</Title>
          <Subtitle
            m={'0 0 24px 0'}
            dangerouslySetInnerHTML={{
              __html: translate('posLinkCanceled.checkout.amount', {
                posRemainingAmount: FormatAmount(sessionData?.posRemainingAmount),
              }),
            }}
          />
          <CardElement
            className="card"
            onChange={onChangeHandler}
            options={{
              style: {
                base: {
                  iconColor: '#000',
                  color: '#000',
                  fontWeight: '500',
                  fontFamily: 'Open Sans, Segoe UI, sans-serif',
                  fontSize: '16px',
                  fontSmoothing: 'antialiased',
                  ':-webkit-autofill': {
                    color: '#a2a2a2',
                  },
                  '::placeholder': {
                    color: '#a2a2a2',
                  },
                },
                invalid: {
                  iconColor: '#FFC7EE',
                  color: '#FFC7EE',
                },
              },
            }}
          />
        </InnerContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={loading || !formValid}
          loading={loading}
          icon={loading || !formValid ? 'lock' : 'lock-active'}
        >
          {`Pay ${FormatAmount(sessionData?.posRemainingAmount)}`}
        </Button>
        <InfoContainer>
          <InfoText>{translate('posLinkCanceled.checkout.info')}</InfoText>
        </InfoContainer>
      </ButtonContainer>
    </StyledForm>
  );
};

export default CheckoutForm;

const StyledForm = styled.form`
  width: 96%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    color: ${(props) => props.theme.main.green};
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-top: 12px;
  color: ${(props) => props.theme.main.grayColor};
`;
