import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { WEB_SITE } from 'lib/constants';

import { Box, Button, SsnInput as Input, Subtitle, Title, UnderlinedTextField as TextField } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { SmallText } from '../Terms/Terms';
import useStore from 'lib/hooks/useStore';
import { theme } from 'config/theme';
import { IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';
import { useTranslation } from 'react-i18next';

const AlleSsnInput = ({ isValid, handleClick, control, checkKeyDown, loading, onBlurHandler }) => {
  const { organizationRegulations } = useStore();
  const { t: translate } = useTranslation();
  const { pushEvent } = useIframeEvents();

  const originator = organizationRegulations?.originatingEntity || 'Cherry Technologies Inc.';
  const loanType =
    organizationRegulations?.originationType === 'RISC'
      ? translate('ssnCollect.footer.riscContract')
      : translate('ssnCollect.footer.anInstallment');

  useEffect(() => {
    pushEvent(IframeEventTypes.PAGE, PageEventActions.SSN_CONFLICT);
  }, []);

  return (
    <Container>
      <ContentContainer>
        <Title color={theme.main.black10} fontSize="24px" fontWeight={400} lineHeight="32px" useElegantFontType={true}>
          Re-enter your Social Security Number
        </Title>
        <Subtitle m="16px 0" textAlign="center" color={theme.main.black20} lineHeight="24px" fontSize="16px">
          We couldn’t find a match for the social security number you entered. Please double check the number and
          carefully re-enter below.
        </Subtitle>
        <Box>
          <Controller
            name="ssn"
            control={control}
            defaultValue=""
            rules={{ required: true, minLength: 11, maxLength: 11 }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="ssn"
                variant="filled"
                data-testid="ssn"
                data-neuro-label="ssn"
                type="tel"
                label="Social Security Number"
                InputProps={{
                  inputComponent: Input,
                  disableUnderline: true,
                }}
                value={value}
                onKeyDown={checkKeyDown}
                onChange={onChange}
                onBlur={onBlurHandler}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Box>

        <SmallText>
          {`See `}
          <InfoColorText href={`${WEB_SITE}/lending-partners/`} target="_blank">
            Cherry’s Lending Partners.
          </InfoColorText>
          {`. See `}
          <InfoColorText href={`${WEB_SITE}/terms/`} target="_blank">
            Terms of Service
          </InfoColorText>
          {`.`}
          <TextDivider />
          {`
          You understand that by clicking on the "Check Eligibility" button, you are providing 'written instructions'
          under the Fair Credit Reporting Act authorizing ${originator} to obtain credit bureau reports from your
          personal credit profile and any other information about you solely to qualify for ${loanType}. AbbVie is
          not a broker or a lender and will have no role in the decision concerning your application or the origination,
          funding, servicing, or other aspects of your Cherry loan.
          `}
        </SmallText>

        <Button
          disabled={!isValid || loading}
          loading={loading}
          onClick={handleClick}
          icon={!isValid || loading ? 'lock' : 'lock-active'}
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
          m="32px 0 0"
        >
          Check Eligibility
        </Button>
      </ContentContainer>
    </Container>
  );
};

export default AlleSsnInput;

const InfoColorText = styled.a`
  color: #9a6b5e;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`;

const TextDivider = styled.div`
  margin: 12px 0;
`;

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: 'Graphik';
`;
