import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';
import { InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { theme as configTheme, theme } from 'config/theme';
import { Button, Icon, IconGap, ModalHeader, NumberFormatInput, TextField, Title } from 'lib/components';
import { ButtonContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { IPlan } from 'pages/Plans/types';
import { FormatAmount } from 'lib/utils';
import { SAVE_DOWN_PAYMENT } from 'lib/graphql/mutations';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import { useSentry } from 'lib/hooks/useSentry';

interface CustomDownPayment {
  show: boolean;
  hideModal: (status: boolean) => void;
  activePlan: IPlan;
}

const CustomDownPayment = ({ show, hideModal, activePlan }: CustomDownPayment) => {
  const { t: translate } = useTranslation();
  const alert = useAlert();
  const { captureException } = useSentry();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const [saveDownPayment] = useMutation(SAVE_DOWN_PAYMENT);
  const { getProductCalculation } = useProductCalculation();
  const { application } = useStore.getState() || {};
  const { loan } = useStore();

  const [formattedAmount, setFormattedAmount] = useState<number | null>(activePlan?.downPaymentAmount);
  const [downPaymentAmount, setDownPaymentAmount] = useState<number>();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line  @typescript-eslint/no-non-null-asserted-optional-chain
  const minEligible = activePlan?.variableDownPayment?.minEligible!;
  // eslint-disable-next-line  @typescript-eslint/no-non-null-asserted-optional-chain
  const maxEligible = activePlan?.variableDownPayment?.maxEligible!;
  const formattedMaxAmount = FormatAmount(maxEligible);
  const formattedMinAmount = FormatAmount(minEligible);
  const DownPaymentError = {
    Maximum: translate('newCustomizePlan.error.maximum', { amount: formattedMaxAmount }),
    Minimum: translate('newCustomizePlan.error.minimum', { amount: formattedMinAmount }),
    Default: translate('newCustomizePlan.error.default'),
  };

  const handleSaveDownPayment = async () => {
    if (!loading) {
      if (!!formattedAmount) {
        sendActionSegmentEvent(WPQSegmentNames.planSelectionCustomDownPaymentContinueClicked, {
          change_status: 'Saved down payment',
          custom_amount: formattedAmount,
        });
        if (formattedAmount > maxEligible) {
          setErrorMessage(`${DownPaymentError.Maximum}`);
        } else if (formattedAmount < minEligible) {
          setErrorMessage(`${DownPaymentError.Minimum}`);
        } else {
          setErrorMessage('');
          try {
            setLoading(true);

            const { data } = await saveDownPayment({
              variables: {
                input: {
                  applicationId: activePlan?.product?.applicationId,
                  loanId: loan?.id,
                  parentId: activePlan?.product?.parentId ? activePlan?.product?.parentId : activePlan?.product?.id,
                  amount: activePlan?.grossAmount,
                  chosenDownPaymentAmount: formattedAmount,
                },
              },
            });
            if (data?.saveDownPayment?.applicationId) {
              try {
                const { application } = useStore.getState() || {};
                const purchaseAmount = loan?.purchaseAmount;
                const applicationId = application?.id;
                const response = await getProductCalculation(applicationId, purchaseAmount);
                if (response && response.length > 0) {
                  closeModal();
                } else {
                  throw new Error('New Customized choose plan - no response');
                }
              } catch (err) {
                captureException('New Product Calculation Error', {
                  error: err,
                  page: 'Customize Plan',
                  message: 'Product Calculation Error',
                  loanId: loan?.id || 'No Loan',
                  applicationId: data?.saveDownPayment?.applicationId || 'No Application',
                });
                closeModal();
              }
            } else {
              alert.info(translate('customizePlan.error.saveDownPayment'));
              closeModal();
            }
          } catch (err) {
            alert.info(translate('customizePlan.error.saveDownPayment'));
            captureException('New Downpayment Error', {
              error: err,
              page: 'Customize Plan',
              message: 'Down payment error.',
              session: activePlan,
            });
            closeModal();
          }
        }
      } else {
        setErrorMessage(`${DownPaymentError.Minimum} ${formattedMinAmount}`);
      }
    }
  };

  useEffect(() => {
    setFormattedAmount(activePlan?.downPaymentAmount);
  }, [activePlan]);

  const closeModalOnClickAway = (e) => {
    if (e?.target?.ariaHidden) {
      closeModal();
      sendLoadSegmentEvent(WPQSegmentNames.planSelectionCustomDownPaymentModalClosed);
    }
  };

  const closeModal = () => {
    setLoading(false);
    hideModal(false);
  };

  const renderAdornment = () => {
    return (
      <InputAdornment
        sx={{
          padding: '1px 0 0 0',
          '& .MuiTypography-root': { color: '#000' },
        }}
        position="start"
      >
        $
      </InputAdornment>
    );
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (downPaymentAmount) {
        saveDownPayment();
      }
    }
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const regex = /^[0-9.\b]+$/;
    if (value && regex.test(value)) {
      setDownPaymentAmount(Number(value));
      setFormattedAmount(Number(value));
    }
  };

  return (
    <Backdrop
      sx={{ color: configTheme.main.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      onClick={closeModalOnClickAway}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer isLarge={false}>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>{translate('plans.customDownPayment.title')}</Title>
            <Icon hover={true} src={'close'} onClick={closeModal} />
          </ModalHeader>
          <PurchaseContainer>{translate('plans.customDownPayment.subTitle')}</PurchaseContainer>
          <CustomTextField
            id="amount"
            variant="filled"
            data-testid="amount"
            data-neuro-label="amount"
            label={translate('plans.customDownPayment.label')}
            type="tel"
            value={formattedAmount}
            max={application?.menu?.maxPurchase}
            onChange={handlePriceChange}
            onKeyDown={checkKeyDown}
            error={errorMessage}
            helperText={errorMessage}
            onFocus={() => {
              sendActionSegmentEvent(WPQSegmentNames.planSelectionCustomDownPaymentAmountClicked);
            }}
            InputProps={{
              inputComponent: NumberFormatInput,
              pattern: '[^0-9.]',
              startAdornment: renderAdornment(),
            }}
          />
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_outline'} />
            <MinimumAmountLabel>{translate('plans.customDownPayment.downPayment')}</MinimumAmountLabel>
            <MinimumAmount> {FormatAmount(activePlan?.variableDownPayment?.minEligible)}</MinimumAmount>
          </InfoContainer>
          <CustomButtonContainer>
            <Button onClick={handleSaveDownPayment} loading={loading}>
              {translate('plans.customDownPayment.saveDownPayment')}
            </Button>
            <Button secondary onClick={closeModal}>
              {translate('buttons.cancel')}
            </Button>
          </CustomButtonContainer>
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

export default CustomDownPayment;

const MinimumAmountLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
`;
const MinimumAmount = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-align: left;
`;
const CustomButtonContainer = styled(ButtonContainer)`
  box-shadow: none !important;
  padding: 24px 0px !important;
`;

const CustomTextField = styled(TextField)`
  width: 100% !important;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  color: ${theme.main.midnightBlue};
  gap: 8px;
`;

const ModalContainer = styled.div<{ isLarge: boolean }>`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0 -36px 67px rgba(0, 0, 0, 0.07), 0 -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0 -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 12px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;

    @media (max-height: ${(props) => (props.isLarge ? '700px' : '530px')}) {
      top: 30px;
      overflow-y: scroll;
    }
  }
`;

const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0px 0px 24px 0px;
  color: ${(props) => props.theme.main.black};
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
`;
