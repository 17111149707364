import useStore from 'lib/hooks/useStore';

export const getIncomeVerificationRouteName = (IVType: 'ASSET_REPORT' | 'PAYSTUB' | 'PAYROLL') => {
  const { sessionApiData } = useStore.getState() || {};
  const { featureFlags } = sessionApiData || {};

  const incomeVerificationRouteName = {
    ASSET_REPORT: featureFlags?.mxEnabled ? 'mx-auth' : 'plaid-auth',
    PAYSTUB: 'paystubs-file-upload',
    PAYROLL: 'payrolls-file-upload',
  };

  return incomeVerificationRouteName[IVType];
};
