export interface Payments {
  date: string;
  amount: number;
  fallback: number;
}

export interface Product {
  applicationId: number;
  apr: number;
  chosenDownPaymentAmount: number;
  createdAt: string;
  creditLineMaxEligible: number;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  downPayment: any;
  downPaymentType: string;
  fallbackApr: number;
  id: number;
  maxEligible: number;
  mdf: number;
  menuId: string;
  minEligible: number;
  moduleString: string;
  parentId?: number;
  periodLength: string;
  promoApr: number;
  promoMdf: number;
  promoTerm: number;
  status: string;
  term: number;
  tierId: number;
}

export interface VariableDownPayment {
  hasOptionToModify: boolean;
  minEligible: number;
  maxEligible: number;
  changeRange: number;
  operandRange: number;
}

export interface CalculationData {
  downPaymentAmount: number;
  financeCharge: number;
  grossAmount: number;
  installmentAmount: number;
  loanAmount: number;
  merchantFund: number;
  merchantRevenue: number;
  promo: boolean;
  totalAmount: number;
  paymentDates: string[];
  payments: Payments[];
  product: Product;
  variableDownPayment: VariableDownPayment;
}

export const SortWithProductTerm = (data: CalculationData[] | null) => {
  return data?.slice()?.sort((a, b) => {
    return a?.product?.term - b?.product?.term;
  });
};
