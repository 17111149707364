import styled from 'styled-components';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { Icon } from 'lib/components';

interface Props {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  paymentMethod: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  selectedPaymentMethod: any;
  selectPaymentMethod: (id: number) => void;
  isPreferred?: boolean;
}

enum TypeEnum {
  ACH = 'ACH',
  CARD = 'CARD',
}

const AllePaymentMethodCard = ({
  paymentMethod,
  selectedPaymentMethod,
  selectPaymentMethod,
  isPreferred = false,
}: Props) => {
  const { id, type, achAccount, storedCard } = paymentMethod || {};

  const cardNumber =
    type === TypeEnum.CARD
      ? storedCard?.last4
      : achAccount?.accountNumber?.substring(achAccount?.accountNumber?.length - 4);

  const cardTitle =
    type === TypeEnum.CARD
      ? `${storedCard?.network?.toLowerCase()} - ${storedCard?.type?.toLowerCase()}`
      : `${achAccount?.bankName}`;

  const radioSxObject = {
    '&.Mui-checked': {
      color: '#9A6B5E',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 24,
    },
  };

  const selectPaymentMethodHandler = () => {
    selectPaymentMethod(paymentMethod);
  };

  return (
    <Container
      data-testid="payment-method-card"
      onClick={selectPaymentMethodHandler}
      isActive={Number(selectedPaymentMethod?.id) === Number(id)}
    >
      <RadioContainer>
        <RadioGroup>
          <Radio
            name="radio-button-true"
            inputProps={{ 'aria-label': 'A' }}
            checked={Number(selectedPaymentMethod?.id) === Number(id)}
            sx={radioSxObject}
            checkedIcon={<Icon width={16} height={16} src={'checkedRadioAlle'} hover={true} />}
            icon={<Icon width={16} height={16} src={'radioAlle'} hover={true} />}
          />
        </RadioGroup>

        <ContentContainer>
          <Title
            isActive={Number(selectedPaymentMethod?.id) === Number(id)}
            capitalize={type === TypeEnum.CARD || type === TypeEnum.ACH}
          >
            {cardTitle}
          </Title>
          <Subtitle isActive={Number(selectedPaymentMethod?.id) === Number(id)}>************ {cardNumber}</Subtitle>
        </ContentContainer>
      </RadioContainer>
      {isPreferred && <PreferredContainer>Preferred</PreferredContainer>}
    </Container>
  );
};

export default AllePaymentMethodCard;

const PreferredContainer = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.main.mutedRedBrown};
  padding: 2px 8px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.main.mutedRedBrown};
`;

const Container = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #f8f1ef;
  border: ${(props) => (props.isActive ? '2px solid #9A6B5E' : '2px solid #E5E0DC')};
  border-radius: 4px;
  padding: 12px 18px;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

const RadioContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 18px;
`;

const Title = styled.span<{ isActive: boolean; capitalize: boolean }>`
  font-weight: 400;
  font-family: 'Graphik';
  font-size: 16px;
  line-height: 24px;
  color: #111;
  text-transform: ${(props) => (props.capitalize ? 'capitalize' : 'uppercase')};
`;

const Subtitle = styled.span<{ isActive: boolean }>`
  font-weight: 400;
  font-family: 'Graphik';
  font-size: 16px;
  line-height: 20px;
  color: #626060;
`;
