import { useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { UPDATE_BORROWER } from 'lib/graphql/mutations';

import { ALLE_AUTO_APPLICATION, WEB_SITE, WEBPREQUAL } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { useServices } from 'pages/AuthChecker/services';

import { Box, Button, Container, Icon, LoadingWithSteps, SsnInput, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer, FooterText } from 'lib/components/Common';

import { ITINNumberRegex } from 'lib/utils';
import { ButtonClickedEventActions, IframeEventTypes, useIframeEvents } from 'lib/hooks/useIframeEvents';

import { ApplicationFlowStatuses, StatedInfo } from 'pages/AuthChecker/type';
import AlleSsnCollect from './AlleSSNCollect';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { getExternalLinkLangParam } from 'lib/utils/ExternalLinkLangParam';

interface FormDataType {
  ssn: string;
}

const SsnCollect = () => {
  const alert = useAlert();
  const { flags } = useFlags();
  const { initPage } = useNeuroId();
  const { trackEvent } = useAnalytics();
  const { captureException } = useSentry();
  const { trackSegmentEvent } = useSegment();
  const { t: translate } = useTranslation();
  const {
    setSessionData,
    sessionData,
    setPageIndex,
    organization,
    setBorrower,
    features,
    applicationFlow,
    isAlle,
    defaultLanguage,
    organizationRegulations,
  } = useStore();
  const hasAlleAutoApplication = features?.includes(ALLE_AUTO_APPLICATION);
  const isLast4ssnEnabled = !isAlle;
  const { createApplicationFlow } = useServices();
  const { pushEvent } = useIframeEvents();

  const [loading, setLoading] = useState(false);
  const [hasITINError, setHasITINError] = useState('');
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const hasRICFeatureFlag = features?.includes('RETAIL_INSTALLMENT_CONTRACT');
  const [updateBorrowerSSN] = useMutation(UPDATE_BORROWER);
  const [last4SSN, setLast4SSN] = useState({
    isLast4SSN: isLast4ssnEnabled,
    last4ssnNotMatch: false,
  });

  const { startCheckApplicationStatus } = useSSEContext();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  useEffect(() => {
    initPage('ssn-collect');
    return () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (loadSegmentController.current) {
      const segmentString = last4SSN.isLast4SSN ? WPQSegmentNames.last4ssnLoad : WPQSegmentNames.ssnLoad;
      sendLoadSegmentEvent(segmentString);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(7);
  }, [setPageIndex]);

  useEffect(() => {
    if (applicationFlow?.status === ApplicationFlowStatuses.ITIN_NOT_SUPPORTED) {
      setHasITINError(translate('ssnCollect.errors.ssn'));
      setFullScreenLoading(false);
      setLoading(false);
      alert.info(translate('ssnCollect.errors.itin'));
    } else if (applicationFlow?.status === ApplicationFlowStatuses.SSN_REQUIRED_1) {
      setFullScreenLoading(false);
      setLoading(false);
      reset({ ssn: '' });
      setLast4SSN({
        isLast4SSN: false,
        last4ssnNotMatch: true,
      });
    }
  }, [applicationFlow]);

  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
    reset,
  } = useForm<FormDataType>({
    mode: 'onChange',
  });

  const onSubmit = async (formData: FormDataType) => {
    if (isAlle) {
      pushEvent(IframeEventTypes.BUTTON_CLICKED, ButtonClickedEventActions.CHECK_ELIGIBILITY_BUTTON_CLICKED);
    }
    const segmentString = last4SSN.isLast4SSN
      ? WPQSegmentNames.last4ssnContinueClicked
      : WPQSegmentNames.ssnContinueClicked;
    sendActionSegmentEvent(segmentString);

    const { borrower, application } = useStore.getState() || {};
    const { requestedAmount } = sessionData;
    try {
      const isITIN = ITINNumberRegex?.test(formData.ssn);
      if (formData.ssn.charAt(0) === '9' && !isITIN && !last4SSN.isLast4SSN) {
        setHasITINError(translate('ssnCollect.errors.notItinRecognize'));
        return;
      }
      setFullScreenLoading(true);
      sendLoadSegmentEvent(WPQSegmentNames.ssnSurveyLoad);
      trackEvent(AnalyticEventNames.SSN_CONTINUE);
      setSessionData(formData);
      setLoading(true);

      trackSegmentEvent('Eligibility Submitted', {
        organizationSlug: organization?.slug,
        organizationName: organization?.name,
        organizationId: organization?.id,
        application: WEBPREQUAL,
        applicationId: application?.id,
        value: 30,
        isRICOpen: hasRICFeatureFlag,
        taxNumberType: isITIN ? 'ITIN' : 'SSN',
      });

      const { data } = await updateBorrowerSSN({
        variables: {
          input: {
            id: borrower?.id,
            source: 'GREET',
            ...(last4SSN.isLast4SSN ? { last4: formData?.ssn } : { ssn: formData?.ssn }),
          },
        },
      });

      if (data?.updateBorrower?.success) {
        setBorrower(data?.updateBorrower?.data);
        const statedInfos: StatedInfo[] = [];
        if (application?.flowType === 'HIGH_LINE' || applicationFlow?.hasTreatmentQuestions) {
          requestedAmount && statedInfos.push({ type: 'REQUESTED_AMOUNT', value: String(requestedAmount) });
        }
        const res = await createApplicationFlow(statedInfos, sessionData.treatmentType);
        if (res?.success) {
          startCheckApplicationStatus({ applicationId: application?.id, isExisting: true });
        }
      } else {
        setLoading(false);
        if (['kyc.status.invalid', 'borrower.kyc_status.invalid'].includes(data?.updateBorrower?.message)) {
          alert.info(translate('ssnCollect.errors.kycStatusInvalid'));
          startCheckApplicationStatus({ applicationId: application?.id, subscribeFlow: true, isExisting: true });
        } else {
          if (isAlle) {
            alert.info(translate('ssnCollect.errors.ssnIncorrect'));
          } else if (data?.updateBorrower?.message === 'argument.invalid') {
            alert.info(translate('ssnCollect.errors.ssnOrItinIncorrect'));
          } else {
            alert.info(translate(data?.updateBorrower?.message || 'ssnCollect.errors.message'));
          }
        }
      }
    } catch (err) {
      setFullScreenLoading(false);
      setLoading(false);
      captureException(err, {
        page: 'SSN Collect',
        message: 'SSN Patch Error',
        borrower: borrower,
      });
    }
  };

  const onBlurHandler = () => {
    trackEvent(AnalyticEventNames.SSN_INP_BLUR);
    const segmentString = last4SSN.isLast4SSN ? WPQSegmentNames.last4ssnFilled : WPQSegmentNames.ssnFilled;
    sendActionSegmentEvent(segmentString);
  };

  const focus = () => {
    setHasITINError('');
    const segmentString = last4SSN.isLast4SSN ? WPQSegmentNames.last4ssnClicked : WPQSegmentNames.ssnClicked;
    sendActionSegmentEvent(segmentString);
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const handleClick = () => {
    handleSubmit(onSubmit)();
  };

  if (isAlle) {
    return (
      <AlleSsnCollect
        isValid={isValid}
        checkKeyDown={checkKeyDown}
        handleClick={handleClick}
        loading={loading}
        control={control}
      />
    );
  }

  const footerTextKey = hasAlleAutoApplication ? 'ssnCollect.footer.textAlleApplication' : 'ssnCollect.footer.newText';
  const footerText = translate(footerTextKey, {
    lendingPartners: `<a target="_blank" href=${WEB_SITE}/lending-partners/${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('ssnCollect.footer.lendingPartners')}</a>`,

    termsOfService: `<a target="_blank" href=${WEB_SITE}/terms/${getExternalLinkLangParam(
      defaultLanguage,
    )}> ${translate('ssnCollect.footer.termsOfService')}</a>`,
    alleLoanTypes:
      organizationRegulations?.originationType === 'RISC'
        ? translate('ssnCollect.footer.riscInstallment')
        : translate('ssnCollect.footer.twoInstallment'),
    originator: organizationRegulations?.originatingEntity || 'Cherry Technologies Inc.',
    entity: organizationRegulations?.originationType === 'LEAD_BANK' ? 'Lead Bank' : organization?.name,
    loanType:
      organizationRegulations?.originationType === 'RISC'
        ? translate('ssnCollect.footer.riscContract')
        : translate('ssnCollect.footer.anInstallment'),
    name: organization?.name,
  });
  return (
    <Container isRelative={true} showBackButton={false}>
      <ContentContainer>
        <Icon src={'fingerprint'} />
        {last4SSN.last4ssnNotMatch ? (
          <>
            <Title m={'10px 0px 8px 0px'}>{translate('ssnCollect.provideSsnTitle')}</Title>
            <Subtitle
              m={'0px 0px 24px 0px'}
              dangerouslySetInnerHTML={{
                __html: translate('ssnCollect.provideSsn'),
              }}
            ></Subtitle>
          </>
        ) : (
          <>
            <Title data-testid="check-eligibility-title" m={'10px 0px 8px 0px'}>
              {translate('ssnCollect.checkEligibility')}
            </Title>
            <Subtitle
              m={'0px 0px 24px 0px'}
              dangerouslySetInnerHTML={{
                __html: translate('ssnCollect.description'),
              }}
            ></Subtitle>
            {last4SSN.isLast4SSN && (
              <Subtitle
                m={'0px 0px 24px 0px'}
                dangerouslySetInnerHTML={{
                  __html: translate('ssnCollect.last4ssn.description'),
                }}
              ></Subtitle>
            )}
          </>
        )}

        <Box>
          <Controller
            name="ssn"
            control={control}
            rules={{
              required: true,
              minLength: last4SSN.isLast4SSN ? 4 : 11,
              maxLength: 11,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="ssn"
                  variant="filled"
                  data-testid="ssn"
                  name={last4SSN.isLast4SSN ? 'last4ssn' : 'ssn'}
                  data-neuro-label="ssn"
                  type="tel"
                  label={
                    last4SSN.isLast4SSN ? translate('ssnCollect.last4ssn.label') : translate('ssnCollect.itinLabel')
                  }
                  onKeyDown={checkKeyDown}
                  InputProps={{
                    inputComponent: SsnInput,
                    disableUnderline: true,
                  }}
                  value={value}
                  onChange={onChange}
                  onFocus={focus}
                  onBlur={onBlurHandler}
                  error={!!error || hasITINError}
                  helperText={error ? error?.message : hasITINError ? hasITINError : null}
                />
              );
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={!isValid || loading}
          loading={loading}
          onClick={handleClick}
          icon={!isValid || loading ? 'lock' : 'lock-active'}
        >
          {translate('buttons.checkEligibility')}
        </Button>
        <FooterText dangerouslySetInnerHTML={{ __html: footerText }} />
      </ButtonContainer>
      {fullScreenLoading && <LoadingWithSteps />}
    </Container>
  );
};

export default SsnCollect;

const InfoColorText = styled.a`
  color: ${(props) => props.theme.main.green};
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`;

const TextDivider = styled.div`
  margin: 12px 0;
`;
