import { Icon, theme } from '@frontend/cherry-library';
import { Box, Stack, Typography } from '@mui/material';
import SurveyNavigatorBox from 'lib/components/SurveyNavigatorBox/SurveyNavigatorBox';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useAdverseActionLetter } from 'lib/hooks/useAdverseActionLetter';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as LocationExportOpenIcon } from 'assets/images/location-export-open.svg';
import { ReactComponent as LoadingIcon } from 'assets/images/loading.svg';

interface KycErrorBaseProps {
  isNewDenialUi: boolean;
  faqList: React.ReactNode;
  oldPage: React.ReactElement;
  title: React.ReactNode;
}

export default function KycErrorBase({ isNewDenialUi, faqList, oldPage, title }: KycErrorBaseProps) {
  const { t: translate } = useTranslation();
  const { isOpenAdverseActionLetterLoading, adverseActionLetterUrl } = useAdverseActionLetter();
  const { sendLoadSegmentEvent } = useSegmentContext();
  const isAdverseActionLetterPageLoadSegmentEventLockedRef = useRef(false);
  const { sendActionSegmentEvent } = useSegmentContext();

  const onAdverseActionLetterClick = () => {
    sendActionSegmentEvent(WPQSegmentNames.denialClickedAdverseActionLetter);
  };

  useEffect(() => {
    if (isNewDenialUi && !isAdverseActionLetterPageLoadSegmentEventLockedRef.current) {
      sendLoadSegmentEvent(WPQSegmentNames.adverseActionLetterPageLoad);
      isAdverseActionLetterPageLoadSegmentEventLockedRef.current = true;
    }
  }, [isNewDenialUi]);

  if (isNewDenialUi) {
    return (
      <>
        <SubtitleContainer mb={3}>
          <PageSubtitle fontSize={14}>{title}</PageSubtitle>
        </SubtitleContainer>
        <WhyWasIDeniedBox>
          <WhyWasIDeniedTitle variant="h3" fontSize={16} fontWeight="700">
            {translate('whyWasIDenied.title')}
          </WhyWasIDeniedTitle>
          <WhyWasIDeniedSubtitle variant="body2">{translate('whyWasIDenied.subtitle')}</WhyWasIDeniedSubtitle>
          <AdverseActionLetterLinkButton
            loading={isOpenAdverseActionLetterLoading}
            href={adverseActionLetterUrl}
            target="_blank"
            onClick={onAdverseActionLetterClick}
          >
            <Stack flexDirection="row" justifyContent="center" gap={1} alignItems="center">
              <Typography fontWeight="600">{translate('whyWasIDenied.buttonText')}</Typography>
              <Icon
                width={20}
                height={20}
                src={isOpenAdverseActionLetterLoading ? SpinningLoadingIcon : LocationExportOpenIcon}
              />
            </Stack>
          </AdverseActionLetterLinkButton>
        </WhyWasIDeniedBox>
        <SurveyNavigatorBox
          page="DENIAL"
          title={translate('result.survey.title', {
            price: '$25',
          })}
        />
        <FaqListContainer>{faqList}</FaqListContainer>
      </>
    );
  } else {
    return oldPage;
  }
}

const WhyWasIDeniedBox = styled(Box)`
  margin-bottom: 36px;
`;

const WhyWasIDeniedTitle = styled(Typography)`
  margin-bottom: 4px !important;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const WhyWasIDeniedSubtitle = styled(Typography)`
  color: ${(props) => props.theme.main.midnightBlue};
  margin-bottom: 8px !important;
`;

const PageSubtitle = styled(Typography)`
  color: ${(props) => props.theme.main.midnightBlue};
`;

const FaqListContainer = styled(Box)``;

const SubtitleContainer = styled(Box)<{ removePadding?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props?.removePadding ? '20px -24px 0' : 'initial')};
`;

const AdverseActionLetterLinkButton = styled.a<{ loading: boolean }>`
  display: block;
  color: ${(props) => (props.loading ? theme.main.midnightBlue40 : theme.main.white)};
  border-radius: 4px;
  padding: 13px 16px;
  text-decoration: none;
  pointer-events: ${(props) => (props.loading ? 'none' : 'initial')};
  background-color: ${(props) => (props.loading ? theme.main.neutralGray : theme.main.kellyGreen)};
`;

const spinAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const SpinningLoadingIcon = styled(LoadingIcon)`
  animation: ${spinAnimation} 1s linear infinite;
`;
