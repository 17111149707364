import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useNavigation } from 'lib/hooks/useNavigation';
import React from 'react';
import styled from 'styled-components';

import { Button } from 'lib/components';
import { redirectToCustomerPortal } from 'lib/utils';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

interface IFAQ {
  title: string;
  content: string;
  hasButton?: boolean;
  hasPreviewPaymentButton?: boolean;
}

interface Props {
  data: IFAQ[];
  onChange: (value: string) => void;
  expanded: string | boolean;
  isAlle?: boolean;
  page?: string;
  bgColor?: string;
}

export const Accordion = React.memo(({ data, onChange, expanded, isAlle = false, page, bgColor }: Props) => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const { sendActionSegmentEvent } = useSegmentContext();

  const onAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    onChange(isExpanded ? panel : '');
  };

  const openPreviewPaymentPage = () => {
    navigate(`preview-my-payments`);
  };

  const goToCustomerPortal = () => {
    switch (page) {
      case 'denial':
        sendActionSegmentEvent(WPQSegmentNames.denialViewInPatientClick);
        break;
      case 'KycDenied':
        sendActionSegmentEvent(WPQSegmentNames.kycDenialPatientPortalClicked);
        break;

      default:
        break;
    }
    redirectToCustomerPortal();
  };

  return (
    <AccordionContainer bgColor={bgColor}>
      {data?.map((faq, index) => {
        return (
          <MuiAccordion
            key={`faq_${index}`}
            expanded={expanded === `panel${index}`}
            onChange={onAccordionChange(`panel${index}`)}
            data-testid="faqContainer"
          >
            <AccordionSummary
              expandIcon={
                !isAlle && (
                  <IconContainer>
                    <ExpandMoreIcon />
                  </IconContainer>
                )
              }
            >
              {isAlle ? (
                <FaqTitleAlle data-testid="faqTitle">{faq?.title}</FaqTitleAlle>
              ) : (
                <FaqTitle data-testid="faqTitle">{faq?.title}</FaqTitle>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <FaqDesc isAlle={isAlle} dangerouslySetInnerHTML={{ __html: faq?.content }} />
              {faq?.hasPreviewPaymentButton && (
                <Button m={'10px 0px'} onClick={openPreviewPaymentPage}>
                  {translate('buttons.previewMyPayments')}
                </Button>
              )}
              {faq?.hasButton && <Button onClick={goToCustomerPortal}>{translate('buttons.patientPortal')}</Button>}
            </AccordionDetails>
          </MuiAccordion>
        );
      })}
    </AccordionContainer>
  );
});

const IconContainer = styled.div`
  color: ${(props) => props.theme.main.green};

  svg {
    transform: scale(1.3);
  }
`;

const AccordionContainer = styled.div<{ bgColor?: string }>`
  margin-top: 10px;
  margin-bottom: 24px;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => (props?.bgColor ? props?.bgColor : props.theme.main.white)};
  box-shadow: none;

  & > ::before {
    display: none;
    border-radius: 0;
  }

  & > div {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: ${(props) => (props?.bgColor ? props?.bgColor : props.theme.main.white)};
    border-radius: 0;
  }

  & > .MuiPaper-root {
    box-shadow: unset;

    a {
      color: ${(props) => props.theme.main.green};
      font-weight: bold;
    }

    & > .MuiButtonBase-root {
      padding: 0;

      & > .MuiAccordionSummary-content {
        margin: 16px 0 !important;
      }
    }

    .MuiAccordion-region {
      & > .MuiAccordionDetails-root {
        padding-top: 0 !important;
        padding-left: 0;
        padding-right: 15px !important;
        text-align: left;

        @media (max-width: ${(props) => props.theme.size.mobileXl}) {
          padding-right: 15px !important;
        }
      }
    }

    &.Mui-expanded {
      margin: 0 !important;
    }
  }
`;

const FaqTitle = styled.span`
  padding: 3px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: ${(props) => props.theme.main.primary};
`;

const FaqTitleAlle = styled.span`
  font-family: 'Petersburg' !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;
`;

const FaqDesc = styled.span<{ isAlle?: boolean }>`
  color: ${(props) => props.theme.main.primary};
  margin-top: -10px;
  font-size: ${(props) => (props.isAlle ? '16px' : '14px')};
  line-height: ${(props) => (props.isAlle ? '24px' : '19px')};
  font-weight: normal;
`;
