import { UseFormSetValue } from 'react-hook-form';

interface ISelectedAddress {
  street?: string;
  addressLine1?: string;
  city: string;
  state: string;
  addressLine2?: string;
  zip: string | number;
}

export const setAddressValues = (
  selectedAddress: ISelectedAddress,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>,
) => {
  if (selectedAddress) {
    setValue('street', selectedAddress.street ?? selectedAddress?.addressLine1, { shouldValidate: true });
    setValue('city', selectedAddress.city, { shouldValidate: true });
    setValue('state', selectedAddress.state, { shouldValidate: true });
    setValue('addressLine2', selectedAddress.addressLine2, { shouldValidate: true });
    setValue('zip', selectedAddress.zip, { shouldValidate: true });
  }
};
