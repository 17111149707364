import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import Confetti from 'react-confetti';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { POST_EMAIL_CONFIRMATION, UPDATE_BORROWER, UPDATE_POINT_OF_SALE_STATUS } from 'lib/graphql/mutations';

import { confettiColors, theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { useSentry } from 'lib/hooks/useSentry';
import { redirectToCustomerPortal } from 'lib/utils';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';

import {
  Button,
  CheckoutProgressBar,
  Container,
  Icon,
  Subtitle,
  SurveyTypeForm,
  TextField,
  Title,
} from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import AlleSuccess from 'pages/Success/AlleSuccess';
import { APP_ENV } from 'config';

interface ContainerRef {
  clientWidth: number;
  clientHeight: number;
}

interface State {
  email: string;
}

const PlanSummary = () => {
  const alert = useAlert();
  const { captureException } = useSentry();
  const { sendActionSegmentEvent } = useSegmentContext();
  const { t: translate } = useTranslation();
  const { organization, isDemo, setFlowType, flowType, sessionData, isAlle, borrower, setBorrower, loan } = useStore();

  const [updatePointOfSaleStatus] = useMutation(UPDATE_POINT_OF_SALE_STATUS);
  const [updateBorrowerEmail, { loading }] = useMutation(UPDATE_BORROWER);
  const [postEmailConfirmation] = useMutation(POST_EMAIL_CONFIRMATION);

  const containerRef = useRef<ContainerRef | null>(null);
  const [confettiActive, setConfettiActive] = useState(true);
  const loadSegmentController = useRef(true);
  const { sendLoadSegmentEvent } = useSegmentContext();
  const [isEmailSendSuccess, setIsEmailSendSuccess] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm<State>({ mode: 'onChange' });

  const onSubmit = async (formData: State): Promise<void> => {
    try {
      sendActionSegmentEvent(WPQSegmentNames.successSubmitEmailClicked);

      if (formData?.email && borrower?.id) {
        const { data } = await updateBorrowerEmail({
          variables: { input: { email: formData?.email, id: borrower?.id, source: 'GREET' } },
        });

        if (data?.updateBorrower?.success) {
          const res = await postEmailConfirmation({
            variables: { input: { loanId: loan?.id } },
          });
          if (res?.data?.postEmailConfirmation?.success) {
            setBorrower({ ...borrower, email: formData?.email });
            setIsEmailSendSuccess(true);
          } else {
            alert.info(`${translate('planSummary.error.borrowerInfo')}`);
          }
        } else {
          setIsEmailSendSuccess(false);
        }
      } else {
        alert.info(`${translate('planSummary.error.borrowerInfo')}`);
      }
    } catch (err) {
      alert.info(`${translate('planSummary.error.info')}`);
      setIsEmailSendSuccess(false);
      captureException(err, { message: 'Success - Update Borrower Info', email: formData?.email });
    }
  };

  const redirectPortal = () => {
    sendActionSegmentEvent(WPQSegmentNames.succesPatientPortalClicked);
    redirectToCustomerPortal();
  };

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.postCheckoutPostFundingLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    if (flowType === 'PointOfSalesFlow') {
      updatePointOfSaleStatus({
        variables: {
          input: {
            organizationId: organization?.id,
            pointOfSaleId: sessionData?.pointOfSaleId,
            applicationId: null,
            status: 'COMPLETED',
          },
        },
      });
    }

    setConfettiActive(true);

    setTimeout(() => {
      setConfettiActive(false);
    }, 5000);

    setFlowType('InitialFlow');
  }, []);

  const handleEmailBlur = () => {
    sendActionSegmentEvent(WPQSegmentNames.successEmailFilled);
  };

  const handleEmailFocus = () => {
    sendActionSegmentEvent(WPQSegmentNames.successEmailClicked);
  };

  if (isAlle) {
    return <AlleSuccess confettiActive={confettiActive} containerRef={containerRef} />;
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer noPadding={true} noMargin>
        <InnerContainer>
          <CheckoutProgressBar />
          <TitleContainer>
            <div>
              <Title lineHeight="normal" m={'10px 0px 8px 0px'}>
                {translate('planSummary.title')}
              </Title>
              <Subtitle lineHeight="normal" fontSize="16px" m={'8px 0px 8px 0px'}>
                {translate('planSummary.subTitle')}
              </Subtitle>
            </div>
            <div>
              <img src="/cherry-dancing.gif" width={128} height={128} alt="cherry-character-dancing" />
            </div>
          </TitleContainer>

          {isDemo || APP_ENV === 'sandbox' ? (
            <DemoModeWarning>
              <Icon src={'warning_outline_red'} />
              <Subtitle
                p={'0px 0px 0 10px'}
                color={`${theme.main.redColor}`}
                dangerouslySetInnerHTML={{
                  __html: translate('planSummary.demo.title'),
                }}
              />
            </DemoModeWarning>
          ) : null}

          {isEmailSendSuccess && borrower?.email ? (
            <EmailContainer>
              <InfoIconContainer center={true}>
                <Subtitle m="0 8px 0 0" fontSize="16px" color={theme.main.green}>
                  {translate('planSummary.info.success')}
                </Subtitle>
                <Icon width={24} height={24} src={'circle_check'} />
              </InfoIconContainer>
            </EmailContainer>
          ) : null}

          {!borrower?.email ? (
            <EmailContainer>
              <Subtitle m="0 0 8px 0" color={theme.main.textColor} fontSize="14px">
                {translate('planSummary.email.title')}
              </Subtitle>

              <Form onSubmit={handleSubmit(onSubmit)} hasError={!!errors?.email}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: '',
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      sx={{
                        '& .MuiFilledInput-root': {
                          border: '0px !important',
                          borderTopRightRadius: '0 !important',
                          borderBottomRightRadius: '0 !important',
                        },
                        '& .MuiFormControl-root': { flex: 1 },
                      }}
                      id="email"
                      variant="filled"
                      data-testid="email"
                      data-neuro-label="email"
                      type="email"
                      label={translate('planSummary.email.label')}
                      autoComplete="off"
                      value={value}
                      onChange={onChange}
                      onBlur={handleEmailBlur}
                      onFocus={handleEmailFocus}
                      error={!!error}
                    />
                  )}
                />
                <ButtonContainer>
                  <StyledButton
                    disabled={!isValid || !!errors?.email || loading}
                    onClick={handleSubmit(onSubmit)}
                    data-testid="submit"
                    loading={loading}
                  >
                    {translate('buttons.submit')}
                  </StyledButton>
                </ButtonContainer>
              </Form>

              <ErrorMessage>
                {Boolean(watch('email')) && !isValid ? <span>{translate('planSummary.email.error')}</span> : null}
              </ErrorMessage>

              <InfoIconContainer>
                <Icon width={20} height={20} src={'info_circle_outline_gray'} />
                <Subtitle m="0 0 0 8px" fontSize="14px">
                  {translate('planSummary.info.text')}
                </Subtitle>
              </InfoIconContainer>
            </EmailContainer>
          ) : null}

          <SurveyTypeForm
            padding="16px 24px"
            margin="0 0 0"
            backgroundColor="#ffffff"
            page="FUND SUCCESS"
            title={translate('planSummary.survey.title', {
              price: '$25',
            })}
            description={translate('planSummary.survey.description', {
              price: '$25',
            })}
          />

          <PortalContainer>
            <Title m={'0px 0px 4px 0px'}>{translate('planSummary.portal.title')}</Title>
            <Subtitle m={'0px 0px 8px 0px'}>{translate('planSummary.portal.subTitle')}</Subtitle>
            <Button onClick={redirectPortal}>{translate('buttons.viewPatientPortal')}</Button>

            <InfoContainer>
              <Icon width={24} height={24} src={'info_circle_outline_gray'} />
              <InfoText>{translate('planSummary.portal.content')}</InfoText>
            </InfoContainer>
          </PortalContainer>
          {confettiActive ? (
            <ConfettiContainer>
              <Confetti
                width={containerRef.current?.clientWidth}
                height={containerRef.current?.clientHeight}
                numberOfPieces={400}
                colors={confettiColors}
                recycle={false}
              />
            </ConfettiContainer>
          ) : null}
        </InnerContainer>
      </ContentContainer>
    </Container>
  );
};

export default PlanSummary;

const DemoModeWarning = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  margin-bottom: 24px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  text-align: left;
  color: #879097;
`;

const InfoIconContainer = styled.div<{ center?: boolean }>`
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  align-items: center;
  margin-top: 8px;
`;

const ConfettiContainer = styled.div`
  position: absolute;
  margin-left: -20px;
  margin-top: -20px;
`;

const InnerContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 24px 0px 24px;
  margin-bottom: 24px;
`;

const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f2fffb;
  padding: 16px 24px;
  margin-bottom: 24px;
`;

const Form = styled.form<{ hasError: boolean }>`
  flex: 1;
  display: flex;
  border: 1px solid ${(props) => (props?.hasError ? '#EC3360' : '#879097')};
  border-radius: 4px;

  label {
    font-family: ${theme.fonts.primary}, serif !important;
  }

  > input[type='email'] {
    width: 100%;
    flex: 1;
    font-size: 16px;
    font-family: ${theme.fonts.primary}, serif !important;
  }
`;

const StyledButton = styled(Button)`
  border: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.7px;
  padding: 15px 16px;
  max-height: 58px;
  color: #ffffff;
  background: #00c37d;
  cursor: pointer;
  font-family: ${theme.fonts.primary}, serif !important;

  :disabled {
    color: #b7bcc1;
    background: #e7e9ea;
  }
`;

const ButtonContainer = styled.div<{ center?: boolean }>`
  max-width: 100px;

  > button {
    margin-bottom: 0;
    border-radius: 0 4px 4px 0;
  }
`;

const ErrorMessage = styled.div`
  color: #ec3360;
  margin-top: 4px;
  font-size: 14px;
  font-family: ${theme.fonts.primary}, serif !important;
`;

const PortalContainer = styled.div`
  padding: 0 24px;
  margin: 0 0 24px;
`;
