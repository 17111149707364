import React, { useState } from 'react';
import styled from 'styled-components';

import { FormatAmount } from 'lib/utils';
import { AlleHeader, Button, HaveQuestionsModal, Icon, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import SummaryCard from './components/AlleSummaryCard';
import { theme } from 'config/theme';
import AgreementModal from './components/AgreementModal';
import useStore from 'lib/hooks/useStore';

type AlleConfirmProps = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  selectedPlan: any;
  loading: boolean | undefined;
  navigateToSuccess: () => Promise<void>;
};

const AlleConfirm = ({ selectedPlan, loading, navigateToSuccess }: AlleConfirmProps) => {
  const [show, setShow] = useState(false);
  const { agreement, applicationRegulations } = useStore();
  const hideModal = () => setShow(false);

  const agreementType =
    applicationRegulations?.originationType === 'RISC' ? 'Retail Installment Contract' : 'Loan Agreement';
  const originator = applicationRegulations?.originatingEntity || 'Cherry Technologies Inc.';

  return (
    <ContentContainer>
      <AlleHeader />
      <PageTitle m={'10px 0px 24px 0px'} color={theme.main.black10} fontSize="18px" fontWeight={600} lineHeight="32px">
        Review your plan summary for your {FormatAmount(selectedPlan?.grossAmount)} purchase
      </PageTitle>
      {!show && <SummaryCard useElegantTheme={true} />}
      <FootNote>
        By Clicking "Agree and Confirm" I acknowledge that I have reviewed and agree to the Truth in Lending Disclosure,{' '}
        {agreementType} and Electronic Payment Authorization. Additionally, I authorize {originator} to debit my
        selected payment methods for today's down payment and as per the agreed schedule according to the{' '}
        {agreementType}.
      </FootNote>
      <Button
        onClick={navigateToSuccess}
        loading={loading}
        disabled={loading}
        black={true}
        uppercase={true}
        thinText={true}
        fontWeight={400}
        fontSize={11}
        m="24px 0"
      >
        Agree and Confirm
      </Button>
      <HaveQuestionsModal />
      <AgreementModal useElegantTheme={true} show={show} hideModal={hideModal} agreement={agreement} />
    </ContentContainer>
  );
};

const PageTitle = styled(Title)`
  font-family: 'Graphik' !important;
`;

const FootNote = styled.div`
  color: ${(props) => props.theme.main.alleBlack};
  font-family: 'Graphik';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.2px;
  margin-top: 24px;
`;

export default AlleConfirm;
