import React from 'react';
import { theme } from 'config/theme';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { IframeEventActions, IframeEventTypes, useIframeEvents } from 'lib/hooks/useIframeEvents';
import { useSegment } from 'lib/hooks/useSegment';

export const CookieModal = React.memo(() => {
  const { t: translate } = useTranslation();
  const reloadPage = () => {
    window.location.reload();
  };

  const { isAlle } = useStore();
  const { pushEvent } = useIframeEvents();
  const { trackAlleIframeLoaded } = useSegment();

  function getBrowserSpecificModal() {
    pushEvent(IframeEventTypes.IFRAME, IframeEventActions.LOADED);
    trackAlleIframeLoaded(false);

    if (/CriOS/i.test(navigator.userAgent)) {
      return (
        <>
          <Title>{translate('cookieModal.title1')}</Title>
          <p>{translate('cookieModal.subTitle1')}</p>
          <p>{translate('cookieModal.subTitle2')}</p>
          <CookieImage
            src={'https://files.withcherry.com/webprequal/cross-site-tracking-chrome-1129.png'}
            alt={translate('cookieModal.alt')}
          />
        </>
      );
    } else if (/AppleWebKit/i.test(navigator.userAgent)) {
      return (
        <>
          <Title>{translate('cookieModal.title2')}</Title>
          <p>{translate('cookieModal.subTitle3')}</p>
          <p>{translate('cookieModal.subTitle4')}</p>
          <CookieImage
            src={'https://files.withcherry.com/webprequal/cross-site-tracking.png'}
            alt={translate('cookieModal.alt')}
          />
        </>
      );
    } else {
      return (
        <>
          <Title>{translate('cookieModal.title3')}</Title>
          <p>{translate('cookieModal.subTitle5')}</p>
          <p>{translate('cookieModal.subTitle6')}</p>
        </>
      );
    }
  }

  return (
    <CookieContainer>
      <ModalContainer>
        {getBrowserSpecificModal()}
        {isAlle ? (
          <Button
            onClick={reloadPage}
            secondary={true}
            uppercase={true}
            thinText={true}
            fontWeight={400}
            fontSize={11}
            m="24px 0"
          >
            {translate('buttons.okay')}
          </Button>
        ) : (
          <OkayButton onClick={reloadPage}>{translate('buttons.okay')}</OkayButton>
        )}
      </ModalContainer>
    </CookieContainer>
  );
});

const CookieContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  font-size: 16px;
  background: ${theme.main.white};
  z-index: ${theme.constants.zIndex} * 10;
  font-family: ${theme.fonts.primary}, serif;
  font-style: normal;
  font-weight: 400;
`;

const ModalContainer = styled.div`
  width: 80%;
  max-width: 342px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 24px 40px;
  border-radius: 24px;
  font-size: 14px;
  line-height: 19px;
  color: ${theme.main.grayColor};
  background: ${theme.main.white};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 12px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(0, 0, 0, 0.02);
`;

const OkayButton = styled.button`
  padding: 15px 16px;
  gap: 8px;
  text-decoration: none;
  cursor: pointer;
  margin-top: 24px;
  font-size: 20px;
  color: ${theme.main.green};
  border-radius: 4px;
  width: 100%;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.4px;
  background: ${theme.main.white};
  border: 2px solid ${theme.main.green};
`;

const Title = styled.h3`
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: ${theme.main.midnightBlue};
  margin: 0 0 24px;
`;

const CookieImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 8px;
`;
