import { GET_FILE_LINK, GET_FILE_LIST } from 'lib/graphql/queries';
import { FileListRequest, FileListResponse, GetFileLinkRequest, GetFileLinkResponse } from 'pages/Result/types';
import { useEffect, useState } from 'react';
import useStore from './useStore';
import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';

export function useAdverseActionLetter() {
  const apolloClient = useApolloClient();
  const [isOpenAdverseActionLetterLoading, setIsOpenAdverseActionLetterLoading] = useState(false);
  const [adverseActionLetterUrl, setAdverseActionLetterUrl] = useState<string>();

  const openAdverseActionLetter = async () => {
    const applicationId = useStore.getState()?.application?.id;
    setIsOpenAdverseActionLetterLoading(true);
    const { data: fileListResponse } = await apolloClient.query<FileListResponse, FileListRequest>({
      query: GET_FILE_LIST,
      variables: {
        input: {
          search: [
            {
              key: 'relationId',
              value: applicationId,
            },
            {
              key: 'relationType',
              value: 'APPLICATION',
            },
            {
              key: 'type',
              value: 'ADVERSE_ACTION_LETTER',
            },
          ],
        },
      },
    });
    const { data: getFileLinkResponse } = await apolloClient.query<GetFileLinkResponse, GetFileLinkRequest>({
      query: GET_FILE_LINK,
      variables: {
        input: {
          fileId: fileListResponse.getFileList?.contents?.[0]?.id,
          queryParams: {
            expirationDateTimestamp: dayjs().add(5, 'minutes').unix(),
          },
        },
      },
    });

    const isFileTokenExpired = fileListResponse.getFileList === null;
    const isFileListEmpty = fileListResponse.getFileList?.total === 0;

    if (isFileTokenExpired && isFileListEmpty) {
      setIsOpenAdverseActionLetterLoading(false);
      return;
    }

    if (getFileLinkResponse.getFileLink?.success) {
      const { path } = getFileLinkResponse.getFileLink.data;
      setAdverseActionLetterUrl(path);
    }
    setIsOpenAdverseActionLetterLoading(false);
  };

  useEffect(() => {
    openAdverseActionLetter();
  }, []);

  return { openAdverseActionLetter, isOpenAdverseActionLetterLoading, adverseActionLetterUrl };
}
