import { InputAdornment } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { Box, Button, Container, Icon, NumberFormatInput, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import InfoModal from '../modals/InfoModal';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { isMissingInfoNextButtonDisabled } from 'lib/utils/IsMissingInfoNextButtonDisabled';
import { DefaultHighlineActiveSteps, DefaultHighlineComponentProps } from '../type';

export const HouseRent = ({ loading, setMissingInfo }: DefaultHighlineComponentProps) => {
  const { t: translate } = useTranslation();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  const [showModal, setShowModal] = useState(false);
  const [houseRent, setHouseRent] = useState<number | null>(null);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.defaultHighLineHousingCostLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (houseRent !== null) {
        setShowModal(true);
      }
    }
  };

  const onAmountApproved = () => {
    setShowModal(false);
    setMissingInfo(String(houseRent), DefaultHighlineActiveSteps.HOUSING_PAYMENT);
  };

  const hideModal = () => setShowModal(false);

  const next = () => {
    sendActionSegmentEvent(WPQSegmentNames.defaultHighLineHousingCostContinue, {
      housingCost: houseRent,
    });
    if (houseRent !== null && (houseRent < 100 || houseRent > 10000)) {
      setShowModal(true);
    } else {
      setMissingInfo(String(houseRent), DefaultHighlineActiveSteps.HOUSING_PAYMENT);
    }
  };

  const onChange = (e) => {
    const value = e?.target?.value;
    const regex = /^[0-9\b]+$/;
    if (value && regex.test(value)) {
      setHouseRent(Number(value));
    } else {
      setHouseRent(null);
    }
  };

  const renderAdornment = () => {
    if (houseRent !== null) {
      return (
        <InputAdornment
          sx={{
            padding: '1px 0 0 0',
            '& .MuiTypography-root': { color: '#000' },
          }}
          position="start"
        >
          $
        </InputAdornment>
      );
    }
    return null;
  };

  const isNextButtonDisabled = isMissingInfoNextButtonDisabled(houseRent, loading);

  return (
    <Container>
      <ContentContainer>
        <Icon src={'home'} />
        <Title m={'10px 0px 8px 0px'}>{translate('defaultHighLine.view.houseRent.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('defaultHighLine.view.houseRent.subTitle')}</Subtitle>
        <Box>
          <TextField
            id="housing-cost"
            data-testid="housing-cost"
            data-neuro-label="housing-cost"
            variant="filled"
            label={translate('defaultHighLine.view.houseRent.label')}
            type="tel"
            value={houseRent}
            onChange={onChange}
            onFocus={() => {
              sendActionSegmentEvent(WPQSegmentNames.defaultHighLineHousingCostClicked);
            }}
            onBlur={(e) => {
              sendActionSegmentEvent(WPQSegmentNames.defaultHighLineHousingCostFilled, {
                metaData: e.target.defaultValue,
              });
            }}
            onKeyDown={checkKeyDown}
            InputProps={{
              disabled: loading,
              inputComponent: NumberFormatInput,
              pattern: '[^0-9.]',
              startAdornment: renderAdornment(),
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={next} disabled={isNextButtonDisabled} loading={loading}>
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
      {showModal && (
        <InfoModal
          show={showModal}
          hideModal={hideModal}
          onApproved={onAmountApproved}
          type={'HOUSING_RENTAL'}
          amount={houseRent}
        />
      )}
    </Container>
  );
};
