import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';

import { Icon, Title } from 'lib/components';
import { useTranslation } from 'react-i18next';

interface AgreementModalProps {
  show: boolean;
  agreement: string;
  hideModal: () => void;
  triggerScroll?: string;
  popup?: boolean;
  useElegantTheme?: boolean;
}

const AgreementModal = ({ show, agreement, hideModal, useElegantTheme = false }: AgreementModalProps) => {
  const { t: translate } = useTranslation();
  return (
    <Backdrop
      sx={{ position: 'fixed', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      onClick={hideModal}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer show={show}>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px 0px'}>{translate('confirm.agreementModal.title')}</Title>
            <IconContainer>
              <Icon src={'close'} onClick={hideModal} hover={true} />
            </IconContainer>
          </ModalHeader>
          <IconGap />
          <IframeContainer id="scrollable-area">
            <StyledIframe useElegantTheme={useElegantTheme} dangerouslySetInnerHTML={{ __html: agreement }} />
          </IframeContainer>
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

export default AgreementModal;

const IconContainer = styled.div`
  margin-top: 25px;
`;

export const ModalContainer = styled.div<{ show: boolean }>`
  width: 90%;
  position: fixed;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 48px;
  height: 96vh;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 600px;
    height: 70vh;
    border-radius: 6px;
  }

  @media (orientation: landscape) and (max-width: 680px) {
    height: 90% !important;
    width: 92% !important;
    top: 2.5% !important;
    bottom: auto !important;
    overflow: hidden !important;
    touch-action: none !important;
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @media {
      top: 0;
      bottom: auto;
      height: 90vh;
      border-radius: 0;
    }
  }

  @media (max-height: 1000px) and (min-width: 680px) {
    height: 70vh;
    top: 25px;
    bottom: auto;
    overflow: hidden;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const IframeContainer = styled.div`
  background: ${(props) => props.theme.main.darkGrayColor};
  padding: 16px;
  margin-bottom: 30px;
  height: 700px;
  overflow-y: scroll;

  @media (max-height: 1140px) and (min-width: 680px) {
    height: 675px;
  }

  @media (max-height: 1105px) and (min-width: 680px) {
    height: 600px;
  }

  @media (max-height: 1040px) and (min-width: 680px) {
    height: 600px;
  }

  @media (max-height: 1000px) and (min-width: 680px) {
    height: 480px;
  }

  @media (max-height: 975px) and (min-width: 680px) {
    height: 400px;
  }

  @media (max-height: 925px) and (min-width: 680px) {
    height: 400px;
  }

  @media (max-height: 865px) and (min-width: 680px) {
    height: 375px;
  }

  @media (max-height: 815px) and (min-width: 680px) {
    height: 350px;
  }

  @media (max-height: 785px) and (min-width: 680px) {
    height: 325px;
  }

  @media (max-height: 750px) and (min-width: 680px) {
    height: 300px;
  }
`;

const StyledIframe = styled.div<{ useElegantTheme: boolean }>`
  color: black;
  background-color: ${(props) => props.theme.main.white};
  padding: ${(props) => props.useElegantTheme && '12px'};

  td {
    padding: 5px 0;
    font-size: 12px;
    word-break: initial !important;
  }

  th {
    font-size: 14px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    padding-bottom: 180px;
    td {
      word-break: initial;
    }
  }
`;
