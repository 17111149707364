import { useEffect } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { API_URL } from 'config';
import { useSentry } from 'lib/hooks/useSentry';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { WEBPREQUAL } from 'lib/constants';

import { usePlans } from 'pages/PlanReview/services';

const AddPlaidBank = () => {
  const alert = useAlert();
  const { t: translate } = useTranslation();
  const { navigate, navigateBack } = useNavigation();
  const { trackSegmentEvent } = useSegment();
  const { getPaymentMethods } = usePlans();
  const { setInstallmentMethod, setAppLoading } = useStore();
  const { captureException } = useSentry();

  useEffect(() => {
    setAppLoading(true);
    window?.addEventListener('message', receiveMessage, false);
    return () => {
      setAppLoading(false);
      window?.removeEventListener('message', receiveMessage);
    };
  }, []);

  const navigateToPaymentList = async (IFrameEvent) => {
    try {
      await getPaymentMethods();
      setInstallmentMethod(IFrameEvent?.paymentMethod);
      setAppLoading(true);
      navigate(`method-list`);
    } catch (err) {
      captureException('Add Plaid Bank Navigate Error', {
        error: err,
        page: 'AddPlaidBank',
        message: 'Navigate to payment list error',
        event: IFrameEvent || 'No Event',
      });

      setAppLoading(false);
      navigate(`loan-cancelled`);
    }
  };

  const getUrl = () => {
    const { borrower, defaultLanguage } = useStore.getState() || {};

    return `${API_URL}/v1/bank-connections/plaid-iframe/${borrower?.id}?language=${defaultLanguage}`;
  };

  const receiveMessage = ({ origin, data }) => {
    if (origin === API_URL) {
      const iFrameEvent = JSON.parse(data);

      trackSegmentEvent('iv_bank_connection', {
        application: WEBPREQUAL,
        events: iFrameEvent,
      });

      switch (iFrameEvent?.event) {
        case 'expired':
          break;
        case 'close':
          navigateBack();
          break;
        case 'complete':
          navigateToPaymentList?.(iFrameEvent);
          setAppLoading(false);
          break;
        case 'loading':
          break;
        case 'loaded':
          setAppLoading(false);
          break;
        case 'plaid-event':
          break;
        case 'plaid-error':
          setAppLoading(false);
          alert.info(translate('addPlaidBank.plaidError.message'));
          navigateBack();
          break;
      }
    }
  };

  return (
    <Container>
      <iframe
        id="add-payment-method"
        data-testid="the-frame"
        title={translate('addCard.iframeTitle')}
        width="100%"
        height="100%"
        frameBorder="0"
        src={getUrl()}
        data-hj-allow-iframe=""
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    height: calc(100vh - 90px);
  }

  iframe {
    height: 100%;
    width: 100%;
  }
`;

export default AddPlaidBank;
