import React, { useEffect } from 'react';
import styled from 'styled-components';

import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { useCherryAccess } from 'lib/hooks/useCherryAccess';

import { Container, Loading, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { useTranslation } from 'react-i18next';

const CherryAccess = () => {
  const { pollLatestCherryAccess } = useCherryAccess();
  const { t: translate } = useTranslation();
  const { setAppLoading } = useStore();

  useEffect(() => {
    setAppLoading(true);
    pollLatestCherryAccess?.();
  }, []);

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <CenterLoading>
          <Title m={'8px 0 24px 0'} fontSize="16px" lineHeight="20px" color={theme.main.white}>
            {translate('cherryAccess.title')}
          </Title>
          <Loading />
        </CenterLoading>
      </ContentContainer>
    </Container>
  );
};

const CenterLoading = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default CherryAccess;
