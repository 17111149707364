import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from '@frontend/cherry-library';

import { Button, Icon } from 'lib/components';
import { BillingAddressModal } from './BillingAddressModal';
import { STORED_CARD_TYPES } from 'lib/constants';
import { PaymentMethod } from 'lib/utils';

interface Props {
  paymentMethod: PaymentMethod;
  selectedPaymentMethod: PaymentMethod | null;
  selectPaymentMethod: (paymentMethod: PaymentMethod) => void;
  isPreferred?: boolean;
}

interface IconName {
  type: string;
  name: string;
}

const Icons: IconName[] = [
  { type: 'CARD', name: 'credit_card' },
  { type: 'ACH', name: 'bank' },
  { type: 'RCC', name: 'remote-check' },
];

enum TypeEnum {
  ACH = 'ACH',
  CARD = 'CARD',
}

export const PaymentMethodCard = ({
  paymentMethod,
  selectedPaymentMethod,
  selectPaymentMethod,
  isPreferred = false,
}: Props) => {
  const { t: translate } = useTranslation();

  const [open, setOpen] = useState(false);

  const { id, type, achAccount, rccAccount, storedCard, addressLine1, addressLine2, city, state, zip } =
    paymentMethod || {};
  const { last4, network, type: cardType, expireMonth, expireYear } = storedCard || {};

  const accountKey = type === TypeEnum.ACH ? achAccount : rccAccount;

  const cardNumber =
    type === TypeEnum.CARD ? last4 : accountKey?.accountNumber?.substring(accountKey?.accountNumber?.length - 4);
  const cardTitle = type === TypeEnum.CARD ? `${network} - ${cardType}` : `${accountKey?.bankName}`;

  const showExpirationDate = type === TypeEnum.CARD && expireMonth && expireYear;

  const isActive = Number(selectedPaymentMethod?.id) === Number(id);
  const isPrepaid = cardType === STORED_CARD_TYPES.PREPAID;
  const showAddress = addressLine1 && isActive;

  const IconNames = () => {
    const findIcon: IconName | undefined = Icons?.find((ic) => ic?.type === type);

    if (isActive) {
      return `active_${findIcon?.name}`;
    } else {
      return findIcon?.name || '';
    }
  };

  const selectPaymentMethodHandler = () => {
    selectPaymentMethod(paymentMethod);
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const LabelSelector = useCallback(() => {
    if (isPrepaid) {
      return <LabelContainer isPrepaid>{translate('downPayment.cardType.prepaid')}</LabelContainer>;
    }
    if (isPreferred) {
      return <LabelContainer>{translate('downPayment.modal.preferred')}</LabelContainer>;
    }
    return null;
  }, [isPrepaid, isPreferred, translate]);

  function formatAddress(
    addressLine1?: string,
    addressLine2?: string,
    city?: string,
    state?: string,
    zip?: string,
  ): string {
    const addressParts: string[] = [];

    if (addressLine1) {
      addressParts.push(addressLine1 + ',');
    }
    if (addressLine2) {
      addressParts.push(addressLine2 + ',');
    }
    if (city) {
      addressParts.push(city);
    }
    if (state) {
      addressParts.push(state);
    }
    if (zip) {
      addressParts.push(zip);
    }
    return addressParts.join(' ');
  }

  return (
    <>
      <Container data-testid="payment-method-card" onClick={selectPaymentMethodHandler} isActive={isActive}>
        <ItemContainer>
          <StyledIcon src={IconNames()} width={20} height={20} />
          <ContentContainer>
            <Title isActive={isActive}>{cardTitle}</Title>
            <SubtitleWrapper>
              <Subtitle isActive={false}>************ {cardNumber}</Subtitle>
              {showExpirationDate && (
                <ExpDate>
                  {translate('downPayment.expires').substring(0, 3)} {expireMonth}/{expireYear}
                </ExpDate>
              )}
            </SubtitleWrapper>
            {showAddress ? (
              <Subtitle isActive={false} marginTop>
                {formatAddress(addressLine1, addressLine2, city, state, zip)}
              </Subtitle>
            ) : null}
          </ContentContainer>
          {LabelSelector()}
        </ItemContainer>
        {showAddress && !isPrepaid ? (
          <Button m={'8px 0px 0px'} mb={'0'} p={'9px 15px'} fontSize={'16'} onClick={openModal}>
            {translate('buttons.editBillingAddress')}
          </Button>
        ) : null}
      </Container>

      {isActive && isPrepaid ? (
        <ErrorMessage>
          <Icon width={24} height={24} src={'error-red'} />
          <ErrorText>{translate('downPayment.cardType.prepaidError')}</ErrorText>
        </ErrorMessage>
      ) : null}

      {open ? (
        <BillingAddressModal
          selectedPaymentMethod={selectedPaymentMethod}
          show={open}
          hideModal={closeModal}
          title={translate('editBillingAddress')}
        />
      ) : null}
    </>
  );
};

const LabelContainer = styled.div<{ isPrepaid?: boolean }>`
  font-size: 10px;
  line-height: 11px;
  color: ${(props) => (props.isPrepaid ? theme.main.cherryRed : theme.main.kellyGreen)};
  background: ${(props) => (props.isPrepaid ? theme.main.cherryRed10 : theme.main.kellyGreen10)};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 5px 5px 5px 6px;
  gap: 4px;
  border-radius: 16px;
  align-content: flex-start;
  align-self: flex-start;
  margin-top: 6px;
`;

const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${theme.main.midnightBlue70};
  border-radius: 4px;
  padding: 12px 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
  flex-direction: column;

  ${({ isActive }) =>
    isActive &&
    `
    border: 2px solid ${theme.main.kellyGreen};
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  flex: 1;
`;

const Title = styled.span<{ isActive: boolean }>`
  font-weight: ${(props) => (props.isActive ? '700' : '400')};
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => (props.isActive ? theme.main.midnightBlue : theme.main.midnightBlue70)};
`;

const Subtitle = styled.span<{ isActive: boolean; marginTop?: boolean }>`
  font-weight: ${(props) => (props.isActive ? '700' : '400')};
  font-size: 10px;
  line-height: 13px;
  color: ${(props) => (props.isActive ? theme.main.midnightBlue : theme.main.midnightBlue70)};
  margin-top: ${(props) => (props.marginTop ? '2px' : '0')};
`;

const StyledIcon = styled(Icon)`
  color: ${theme.main.kellyGreen};
`;

const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ExpDate = styled.span`
  font-size: 10px;
  color: ${theme.main.midnightBlue50};
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

const ErrorText = styled.span`
  color: ${theme.main.cherryRed};

  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
