import dayjs from 'dayjs';
import styled from 'styled-components';

import { DATE_FORMAT } from 'lib/constants';
import { FormatAmount } from 'lib/utils';
import useStore from 'lib/hooks/useStore';
import { useTranslation } from 'react-i18next';

interface DataProps {
  installmentAmount: number;
  downPaymentAmount: number;
  financeCharge: number;
  paymentDates: string[];
  product: {
    term: number;
    apr: number;
    promoApr: number;
    parentId: number;
  };
  promoApr: number;
  promo: boolean;
  id: number;
}

interface PlanCardProps {
  data: DataProps;
  active: boolean;
  fullWidth?: boolean;
  useElegantTheme?: boolean;
}

export const PlanCard = ({ data, active, fullWidth, useElegantTheme }: PlanCardProps) => {
  const { t: translate } = useTranslation();
  const customHeaderText = data?.product?.parentId ? translate('planReview.customTitle') : '';
  const { isAlle } = useStore();

  return (
    <Container isActive={active} fullWidth={fullWidth} useElegantTheme={useElegantTheme}>
      <Header isActive={active} useElegantTheme={useElegantTheme}>
        {translate('planReview.cardTitle', {
          text: customHeaderText,
          term: data?.product?.term,
        })}
      </Header>
      <ContentContainer useElegantTheme={useElegantTheme}>
        <ContentItemContainer>
          <ContentTitle useElegantTheme={useElegantTheme}>{translate('planReview.dueToday')}</ContentTitle>
          <ContentText useElegantTheme={useElegantTheme}>{FormatAmount(data?.downPaymentAmount)}</ContentText>
        </ContentItemContainer>
        <ContentItemContainer>
          <ContentBorder isAlle={isAlle} />
        </ContentItemContainer>
        <ContentItemContainer>
          <ContentTitle useElegantTheme={useElegantTheme}>{translate('planReview.dueLater')}</ContentTitle>
          <ContentText useElegantTheme={useElegantTheme}>
            {translate('planReview.installmentAmount', {
              installmentAmount: FormatAmount(data?.installmentAmount),
            })}
          </ContentText>
          <ContentSubText useElegantTheme={useElegantTheme}>
            <span
              dangerouslySetInnerHTML={{
                __html: translate('planReview.text1', {
                  term: data?.product?.term,
                  date: dayjs(data?.paymentDates?.[0]).format(DATE_FORMAT),
                }),
              }}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: translate('planReview.text2', {
                  financeCharge: FormatAmount(data?.financeCharge),
                }),
              }}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: translate('planReview.text3', {
                  apr: data?.promo ? data?.product?.promoApr : data?.product?.apr,
                }),
              }}
            />
          </ContentSubText>
        </ContentItemContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div<{ isActive: boolean; fullWidth?: boolean; useElegantTheme?: boolean }>`
  position: relative;
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.theme.main[
        props.isActive ? (props.useElegantTheme ? 'mutedRedBrown' : 'green') : props.useElegantTheme ? '' : 'grayColor'
      ]};
  border-radius: 8px;
  margin-right: 10px;
  max-width: ${(props) => (props.fullWidth ? '100%' : '240px')};
  min-width: ${(props) => (props.fullWidth ? '100%' : '220px')};
`;

const Header = styled.div<{ isActive: boolean; useElegantTheme?: boolean }>`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.main.white};
  background-color: ${(props) =>
    props.theme.main[
      props.isActive ? (props.useElegantTheme ? 'mutedRedBrown' : 'green') : props.useElegantTheme ? '' : 'grayColor'
    ]};
  padding: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const ContentContainer = styled.div<{ useElegantTheme?: boolean }>`
  padding: 12px 16px 4px;
  text-align: ${(props) => (props.useElegantTheme ? 'left' : 'center')};
`;

const ContentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const ContentTitle = styled.span<{ useElegantTheme?: boolean }>`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${(props) => (props.useElegantTheme ? props.theme.main.mutedRedBrown : props.theme.main.green)};

  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
  text-transform: ${(props) => props.useElegantTheme && 'uppercase'};
  letter-spacing: ${(props) => props.useElegantTheme && '1px'};
`;

const ContentText = styled.span<{ useElegantTheme?: boolean }>`
  font-size: 24px;
  line-height: 33px;
  color: ${(props) => props.theme.main.textColor};
  text-align: left;
  font-family: ${(props) => props.useElegantTheme && 'Petersburg'};
  font-weight: ${(props) => (props.useElegantTheme ? 400 : 700)};
`;

const ContentSubText = styled.span<{ useElegantTheme?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: ${(props) => props.theme.main.textColor};
  text-align: left;
  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
  font-style: ${(props) => (props.useElegantTheme ? 'none' : 'italic')};
`;

const ContentBorder = styled.div<{ isAlle?: boolean }>`
  border: ${(props) => (props.isAlle ? '1px solid #E6E6E6' : '1px dashed #56636d')};
`;
