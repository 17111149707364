import { useMutation } from '@apollo/client';
import { memo, useState } from 'react';

import { WEBPREQUAL } from 'lib/constants';
import { SET_APPLICATION_READY_CHECKOUT } from 'lib/graphql/mutations';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';

import { Button, Container, Icon, RadioButtonGroup, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useTranslation } from 'react-i18next';

enum RadioButtonOption {
  CHECKOUT = 'checkout',
  APPOINTMENT = 'appointment',
}

const CheckingOut = () => {
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { trackSegmentEvent } = useSegment();
  const { t: translate } = useTranslation();
  const { sessionData } = useStore();

  const [setApplicationReady] = useMutation(SET_APPLICATION_READY_CHECKOUT);
  const [radioValue, setRadioValue] = useState<RadioButtonOption>(RadioButtonOption.CHECKOUT);

  const onChangeHandler = (e) => {
    setRadioValue(e?.target?.value);
  };

  const backToMyApproval = () => {
    navigate(`approval`);
  };

  const handleContinue = async () => {
    const { organization, application } = useStore.getState() || {};
    try {
      if (radioValue === RadioButtonOption.CHECKOUT) {
        const { data } = await setApplicationReady({
          variables: {
            input: {
              applicationId: application?.id,
              isReadyForCheckout: true,
            },
          },
        });

        if (data?.setApplicationReadyForCheckout?.success) {
          navigate('checkout-in-person');
          return;
        }
      }

      if (radioValue === RadioButtonOption.APPOINTMENT) {
        trackSegmentEvent('Book Appointment', {
          organizationSlug: organization?.slug,
          organizationName: organization?.name,
          application: WEBPREQUAL,
          applicationId: application?.id,
        });
      }
      navigate('book-an-appointment');
    } catch (error) {
      captureException(error, {
        page: 'Checking out',
        message: 'Set Application Ready Error',
        application: application,
        sessionData: sessionData,
      });
    }
  };

  return (
    <Container showBackButton={true} backUrl={'approval'}>
      <ContentContainer>
        <Icon src={'location-pin'} />
        <Title m={'10px 0px 8px 0px'}>{translate('checkingOut.title')}</Title>

        <Subtitle
          m={'0px 0px 24px 0px'}
          dangerouslySetInnerHTML={{
            __html: translate('checkingOut.subTitle'),
          }}
        />
        <Subtitle m={'8px 0px 8px 0px'}>{translate('checkingOut.message.title')}</Subtitle>
        <RadioButtonGroup
          onChange={onChangeHandler}
          size="large"
          radioButtonOptions={[
            {
              textValue: RadioButtonOption.CHECKOUT,
              radioLabel: translate('checkingOut.select.option1'),
            },
            {
              textValue: RadioButtonOption.APPOINTMENT,
              radioLabel: translate('checkingOut.select.option2'),
            },
          ]}
          value={radioValue}
        />
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={handleContinue}>{translate('buttons.continue')}</Button>
        <Button onClick={backToMyApproval} secondary={true}>
          {translate('buttons.backToMyApproval')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default memo(CheckingOut);
