import React from 'react';
import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';
import { Button, Icon, Subtitle, Title } from 'lib/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StorageService from 'lib/services/Storage';

interface Props {
  show: boolean;
  hideModal: () => void;
  restartWithPractice: () => void;
  restartOnMobile: () => void;
}

export const IncorrectInfoModal = React.memo(({ show, hideModal, restartWithPractice, restartOnMobile }: Props) => {
  const { t: translate } = useTranslation();

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={hideModal}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <Container>
          <Header>
            <IconGap />
            <Title m={'24px 0px'}>{translate('incorrectInfoModal.title')}</Title>
            <Icon src={'close'} onClick={hideModal} />
          </Header>
          <Text>{translate('incorrectInfoModal.text')}</Text>
          <Button onClick={restartWithPractice}>{translate('incorrectInfoModal.button.restartWithPractice')}</Button>
          <Button onClick={restartOnMobile} secondary>
            {translate('incorrectInfoModal.button.restartOnMobile')}
          </Button>
        </Container>
      </Slide>
    </Backdrop>
  );
});

const Container = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background-color: ${(props) => props.theme.main.white};
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 30px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;
    padding: 0 24px 14px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const Text = styled.div`
  margin-bottom: 24px;
  font-weight: 400;
  color: ${(props) => props.theme.main.midnightBlue};
`;
