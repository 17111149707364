import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import useStore from 'lib/hooks/useStore';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useAnalytics } from 'lib/hooks/useAnalytics';
import NavigateService from 'lib/services/Navigate';
import { getDefaultHighLineUrl, getIncomeVerificationRouteName } from 'lib/utils';

import { ApplicationFlowStatuses } from 'pages/AuthChecker/type';
import { useApplicationFlowService } from 'pages/AuthChecker/services';

const useApplicationFlowNavigation = () => {
  const alert = useAlert();
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const { applicationStatusUpdated } = useAnalytics();
  const { organization, setApplication, setIncomeVerification, setSessionApiData } = useStore();
  const { getBorrowerIncomeVerificationWithSearch, getBorrowerIncomeVerification, getApplication } =
    useApplicationFlowService();

  const handleApplicationFlowNavigation = async (response) => {
    const { application, authData, sessionApiData } = useStore.getState() || {};
    const { featureFlags, isCognitoCompleted } = sessionApiData || {};
    const { slug } = authData || {};

    const borrowerId = application?.borrowerId || response?.borrowerId;
    const applicationRef = { ...(application || response), borrowerId };

    switch (response?.status) {
      case ApplicationFlowStatuses.MISSING_INFO:
        navigate('info');
        break;
      case ApplicationFlowStatuses.AWAITING_APPROVAL_BOOST:
        navigate('approval-boost');
        break;

      case ApplicationFlowStatuses.ADDRESS_VERIFY_MINOR:
      case ApplicationFlowStatuses.ADDRESS_VERIFY_MAJOR:
        navigate('address-verify');
        break;
      case ApplicationFlowStatuses.SSN_REQUIRED_1:
        navigate('ssn-collect');
        break;
      case ApplicationFlowStatuses.SSN_REQUIRED_2:
        navigate('ssn');
        break;
      case ApplicationFlowStatuses.FULL_SSN_REQUIRED:
      case ApplicationFlowStatuses.FULL_SSN_REQUIRED_1:
      case ApplicationFlowStatuses.FULL_SSN_REQUIRED_2:
        navigate('full-ssn-collect');
        break;
      case ApplicationFlowStatuses.ITIN_NOT_SUPPORTED:
        navigate('ssn-collect');
        break;
      case ApplicationFlowStatuses.MISSING_REQUESTED_AMOUNT:
        navigate('request-amount');
        break;
      case ApplicationFlowStatuses.MISSING_EMAIL:
        navigate('email-info');
        break;
      case ApplicationFlowStatuses.AWAITING_DEFAULT_HIGH_LINE:
        const missings = response?.missingInfos?.map((missing) => ({
          type: missing,
          value: null,
          url: getDefaultHighLineUrl(missing),
        }));
        setSessionApiData({ defaultHighLineMissings: missings });
        navigate(`default-line/${missings[0].url}`);
        break;
      case ApplicationFlowStatuses.SSN_CONFLICT:
      case ApplicationFlowStatuses.ID_CONFLICT:
        navigate('result');
        break;
      case ApplicationFlowStatuses.TREATMENT_QUESTIONS:
        navigate('treatment-type');
        break;
      case ApplicationFlowStatuses.SSN_VERIFICATION:
        navigate('ssn-verification');
        break;
      case ApplicationFlowStatuses.FROZEN:
        applicationStatusUpdated(response);
        NavigateService.navigate(`/${slug}/file-frozen`);
        break;
      case ApplicationFlowStatuses.UNLICENSED:
        applicationStatusUpdated(response);
        NavigateService.navigate(`/${slug}/ineligible`);
        break;
      case ApplicationFlowStatuses.ID_REVIEW:
      case ApplicationFlowStatuses.EID_REVIEW:
        navigate('id-review');
        break;
      case ApplicationFlowStatuses.REVIEW:
        if (application?.id) {
          await getApplication?.(application?.id);
        }
        applicationStatusUpdated(response);
        navigate('application-review');
        break;
      case ApplicationFlowStatuses.APPROVED:
        if (application?.id) {
          setApplication(application);
          await getApplication?.(application?.id);
        }
        applicationStatusUpdated(response);
        NavigateService.navigate(`/${slug}/approval`);
        break;
      case ApplicationFlowStatuses.COMPLETE_DEMO:
      case ApplicationFlowStatuses.DENIED:
      case ApplicationFlowStatuses.BLACKLIST:
        if (application?.id) {
          setApplication(application);
          await getApplication?.(application?.id);
        }
        applicationStatusUpdated(response);
        // if (response?.ssnconflict) {
        //   navigate('phone-update');
        // } else {
        NavigateService.navigate(`/${slug}/result`);
        // }
        break;
      case ApplicationFlowStatuses.INCOME_VERIFICATION:
        const { incomeVerification } = useStore.getState() || {};
        const incomeVerificationSession = {
          ...incomeVerification,
          errorMessage: incomeVerification?.errorMessage?.split(','),
          providerMessage: incomeVerification?.providerMessage?.split(','),
        };
        if (isCognitoCompleted) {
          break;
        } else if (
          incomeVerificationSession?.status &&
          incomeVerificationSession?.status === 'ERROR' &&
          incomeVerificationSession?.providerMessage?.includes('No_Valid_Account')
        ) {
          alert.info(translate('polling.error.noValidAccount'));
          if (applicationRef?.ivAllow?.paystub) {
            NavigateService.navigate(`/${slug}/upload-paystub/${applicationRef?.id}`);
          } else {
            NavigateService.navigate(`/${slug}/connect-bank-account/${applicationRef?.id}`);
          }
          break;
        } else if (
          incomeVerificationSession?.status &&
          !['COMPLETE', 'ERROR'].includes(incomeVerificationSession?.status)
        ) {
          NavigateService.navigate(`/${slug}/connect-bank-account/${applicationRef?.id}`);
          break;
        } else if (
          incomeVerificationSession?.status === 'ERROR' &&
          incomeVerificationSession?.errorMessage?.includes('Retry') &&
          incomeVerificationSession?.providerMessage?.includes('Institution_Found') &&
          featureFlags?.mxEnabled
        ) {
          NavigateService.navigate(`/${organization?.slug}/connect-bank-account/${applicationRef?.id}`);
          break;
        } else if (
          incomeVerificationSession?.status === 'ERROR' &&
          (incomeVerificationSession?.errorMessage?.includes('Institution_Not_Found') ||
            incomeVerificationSession?.errorMessage?.includes('Account_Not_Supported')) &&
          applicationRef?.ivAllow?.paystub
        ) {
          NavigateService.navigate(`/${slug}/upload-paystub/${applicationRef?.id}`);
          break;
        } else if (
          incomeVerificationSession?.status === 'ERROR' &&
          incomeVerificationSession?.errorMessage?.includes('Retry') &&
          applicationRef?.ivAllow?.paystub &&
          !featureFlags?.mxEnabled
        ) {
          NavigateService.navigate(`/${slug}/retry-plaid-connect/${applicationRef?.id}`);
          break;
        } else if (incomeVerificationSession?.status && incomeVerificationSession?.status === 'ERROR') {
          if (applicationRef?.ivAllow?.paystub) {
            NavigateService.navigate(`/${slug}/upload-paystub/${applicationRef?.id}`);
          } else {
            NavigateService.navigate(`/${slug}/connect-bank-account/${applicationRef?.id}`);
          }
          break;
        } else {
          try {
            const incomeVerificationSearchRes = await getBorrowerIncomeVerificationWithSearch(
              applicationRef?.borrowerId,
              applicationRef?.id,
            );
            if (incomeVerificationSearchRes?.contents?.length > 0) {
              const incomeVerificationRes = incomeVerificationSearchRes?.contents?.[0];
              setIncomeVerification(incomeVerificationRes);

              const isRelatedIVWithApplication = incomeVerificationRes?.relatedApplications?.find(
                (ivApplication) => ivApplication?.applicationId === applicationRef?.id,
              );

              if (incomeVerificationRes?.status === 'CREATED') {
                if (!isRelatedIVWithApplication) {
                  const res = await getBorrowerIncomeVerification();
                  const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
                  NavigateService.navigate(`/${slug}/${baseRoute}/${res?.id}`);
                } else {
                  const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
                  NavigateService.navigate(`/${slug}/${baseRoute}/${incomeVerificationRes?.id}`);
                }
              } else if (incomeVerificationRes?.status === 'COMPLETE') {
                if (!isRelatedIVWithApplication) {
                  const res = await getBorrowerIncomeVerification();
                  const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
                  NavigateService.navigate(`/${slug}/${baseRoute}/${res?.id}`);
                }
              } else if (incomeVerificationRes.status === 'REVIEW' || incomeVerificationRes.status === 'PENDING') {
                if (!isRelatedIVWithApplication) {
                  const res = await getBorrowerIncomeVerification();
                  const baseRoute = getIncomeVerificationRouteName(incomeVerificationRes?.type);
                  NavigateService.navigate(`/${slug}/${baseRoute}/${res?.id}`);
                }
              }
            } else {
              NavigateService.navigate(`/${slug}/connect-bank-account/${applicationRef?.id}`);
            }
            break;
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          } catch (err: any) {
            if (err?.status === 404) {
              NavigateService.navigate(`/${slug}/connect-bank-account/${applicationRef?.id}`);
            }
            break;
          }
        }
      default:
        break;
    }
  };

  return { handleApplicationFlowNavigation };
};

export default useApplicationFlowNavigation;
