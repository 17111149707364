import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, SlideModal } from 'lib/components';
import { ButtonContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import { useViewport } from 'lib/hooks/useViewport';

interface DeclinedCardModal {
  show: boolean;
  hideModal: () => void;
}

const DeclinedCardModal = ({ show, hideModal }: DeclinedCardModal) => {
  const { t: translate } = useTranslation();
  const { downPaymentMethod, setDeclinedCardError } = useStore();
  const { isMobile } = useViewport();

  const handleClick = (e) => {
    if (e?.target?.ariaHidden) {
      hideModal();
      setDeclinedCardError(false);
    }
  };

  return (
    <SlideModal
      handleCloseClick={handleClick}
      show={show}
      hideModal={hideModal}
      isLarge={false}
      titleTranslateKey="confirm.error.downPaymentTitle"
    >
      <>
        <PurchaseContainer>
          {translate('confirm.error.downPayment', {
            last4: downPaymentMethod?.storedCard?.last4,
          })}
        </PurchaseContainer>
        <CustomButtonContainer isMobile={isMobile}>
          <Button onClick={hideModal}>{translate('buttons.okay')}</Button>
        </CustomButtonContainer>
      </>
    </SlideModal>
  );
};

export default DeclinedCardModal;

const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px 16px 32px;
  color: ${(props) => props.theme.main.black};
`;

const CustomButtonContainer = styled(ButtonContainer)<{ isMobile: boolean }>`
  box-shadow: ${(props) => props?.isMobile && 'none'};
`;
