import React, { memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { FormatPhoneNumber, Redirect } from 'lib/utils';

import { Button, Container, Icon, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useMerchantsDetail } from 'lib/hooks/useMerchantDetail';
import { useMerchantCommunicationSettings } from 'lib/hooks/useMerchantCommunicationSettings';
import { useTranslation } from 'react-i18next';

const BookAppointment = () => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const { fetchMerchantDetail, address } = useMerchantsDetail();
  const { fetchMerchantCommunicationSettings, phone, name, website } = useMerchantCommunicationSettings();
  const { organization } = useStore();

  useEffect(() => {
    getMerchantDetail?.();
  }, []);

  const getMerchantDetail = useCallback(async () => {
    const app = useStore.getState()?.selectedApplication;
    const merchantId = app?.merchantId;
    await fetchMerchantDetail(merchantId);
    await fetchMerchantCommunicationSettings(merchantId);
  }, []);

  const gotoWebsite = () => {
    const websiteLink = website ? website : organization?.website;
    const link = websiteLink?.indexOf('://') === -1 ? 'http://' + websiteLink : websiteLink;

    Redirect(link);
  };

  const gotoCall = () => {
    Redirect(`tel:${organization?.phone}`, '_self');
  };

  const backToMyApproval = () => {
    navigate(`approval`);
  };

  return (
    <Container showBackButton={true}>
      <ContentContainer>
        <Icon src={'calendar'} />
        <Title m={'10px 0px 24px 0px'}>{translate('bookAppointment.title')}</Title>

        {name && <StyledTitle>{name}</StyledTitle>}

        {address && (
          <StyledSubtitle>
            <div>{address?.street}</div>
            <div>
              {address?.city} {address?.state?.name}, {address?.zip}
            </div>
          </StyledSubtitle>
        )}

        {(phone || organization?.phone) && (
          <Button secondary={true} onClick={gotoCall}>
            {translate('call', {
              phone: FormatPhoneNumber(phone || organization?.phone),
            })}
          </Button>
        )}

        {(website || organization?.website) && (
          <Button secondary={true} onClick={gotoWebsite}>
            {translate('buttons.website')}
          </Button>
        )}
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={backToMyApproval} secondary={true}>
          {translate('bookAppointment.backToMyApproval')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const StyledTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props?.theme?.main?.textColor};
`;

const StyledSubtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props?.theme?.main?.black};
  margin-bottom: 12px;
`;

export default memo(BookAppointment);
