import NavigateService from 'lib/services/Navigate';

interface LoanDataTypes {
  status: string;
}

export const loanStatusService = (loanData: LoanDataTypes, slug: string) => {
  switch (loanData?.status) {
    case 'OPTION':
    case 'APPROVED':
    case 'INITIATED':
    case 'REVIEW':
    case 'DRAFTED':
      NavigateService.navigate(`/${slug}/choose-plan`);
      break;
    case 'INCOMPLETE':
      NavigateService.navigate(`/${slug}/result`);
      break;
    case 'CANCELLED':
      NavigateService.navigate(`/${slug}/expired`);
      break;
  }
};
