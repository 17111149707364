import styled from 'styled-components';

const getContentContainerPadding = (isNewUi?: boolean) => (isNewUi ? '24px 24px 40px' : '0 24px 20px 24px');

export const ContentContainer = styled.div<{
  isNewUi?: boolean;
  justify?: string;
  noMargin?: boolean;
  noPadding?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
  padding: ${(props) => (props.noPadding ? '0px' : getContentContainerPadding(props.isNewUi))};
  margin-top: ${(props) => (props.noMargin ? '0px' : '15px')};
  background-color: ${(props) => props.theme.main.white};
`;

export const ButtonContainer = styled.div`
  background-color: ${(props) => props.theme.main.white};
  padding: 24px 24px 14px 24px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: inset 0 1px 0 #dadada;
`;

export const AlleContainer = styled(ContentContainer)`
  text-align: center;
  font-family: 'Graphik', sans-serif;
`;

export const FooterText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 24px 0px 12px;

  a {
    color: #00c37d;
    text-decoration: underline;
    font-weight: bold;
  }
`;
