import styled from 'styled-components';
import { AlleContainer } from 'lib/components/Common';
import { Title, Icon, Subtitle, AlleHeader, HaveQuestionsModal } from 'lib/components';
import { theme } from 'config/theme';
import { PaymentMethodConnect } from './components/PaymentMethodConnect';
import useStore from 'lib/hooks/useStore';
import { FormatAmount } from 'lib/utils';

interface Props {
  onConnectCardHandler?: (IFrameEvent) => void;
  isComingFromRecurring: boolean;
}

const AlleAddCard = ({ onConnectCardHandler, isComingFromRecurring }: Props) => {
  const { isDemo, selectedPlan, paymentMethodFees } = useStore();

  const paymentFee = paymentMethodFees?.CREDIT?.percent;

  return (
    <AlleContainer>
      <AlleHeader />
      {!isComingFromRecurring ? (
        <>
          <Title
            color={theme.main.black}
            fontSize="18px"
            fontWeight={500}
            fontFamily="Graphik"
            lineHeight="32px"
            useElegantFontType={true}
          >
            First, add a card to make your down payment
          </Title>
          <Subtitle
            m="16px 0"
            fontFamily="Graphik"
            textAlign="left"
            color={theme.main.black20}
            lineHeight="24px"
            fontSize="16px"
          >
            Your down payment of {FormatAmount(selectedPlan?.downPaymentAmount)} is due today.
          </Subtitle>
        </>
      ) : (
        <Title
          m="0px 0px 16px 0px"
          color={theme.main.black10}
          fontFamily="Graphik"
          fontSize="18px"
          fontWeight={500}
          lineHeight="32px"
          useElegantFontType={true}
        >
          Add another card to make your payments
        </Title>
      )}

      <PaymentMethodConnect onConnect={onConnectCardHandler} isDemo={isDemo || false} view={'alle'} />
      {paymentFee ? (
        <InfoContainer>
          <Icon src="info_circle_black" width={19} height={19} />
          <InfoText>
            Paying with a credit card includes a <b>2.99% processing fee.</b>
          </InfoText>
        </InfoContainer>
      ) : null}
      <HaveQuestionsModal />
    </AlleContainer>
  );
};

const InfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 24px;
`;

const InfoText = styled.span`
  text-align: left;
  margin-left: 12px;
  font-size: 12px;
`;

export default AlleAddCard;
