import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TRACK_VERSION, WEBPREQUAL } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';

import { Button, Subtitle, Title, HaveQuestionsModal } from 'lib/components';
import { theme } from 'config/theme';
import { IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';

const AlleIdReview = ({ handleClick }) => {
  const { initPage } = useNeuroId();
  //   const { trackEvent } = useAnalytics();
  const { trackPage } = useSegment();
  const { pushEvent } = useIframeEvents();

  const { organization, borrower, application } = useStore();

  useEffect(() => {
    trackPage('eligibilityInput', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
      applicationId: application?.id,
      borrowerId: borrower?.id,
      organizationId: organization?.id,
    });

    initPage('id-review');
    pushEvent(IframeEventTypes.PAGE, PageEventActions.ID_REVIEW_PERMISSION);
  }, []);

  return (
    <>
      <Container>
        <Title
          color={theme.main.black10}
          fontSize="24px"
          fontWeight={400}
          lineHeight="32px"
          useElegantFontType={true}
          textAlign="center"
        >
          We need your permission to verify your identity data
        </Title>
        <Subtitle m="16px 0" textAlign="center" color={theme.main.black20} lineHeight="24px" fontSize="16px">
          I authorize the Social Security Administration (SSA) to verify and disclose to Cherry Technologies, Inc.
          through SentiLink Verification Services Corp., their service provider, for the purpose of extending credit
          whether the name, Social Security Number (SSN) and date of birth I have submitted matches information in SSA
          records. My consent is for a one-time validation within the next 90 days.
        </Subtitle>
        <Subtitle m="16px 0" textAlign="center" color={theme.main.black20} lineHeight="24px" fontSize="16px">
          By clicking the Authorize and Continue button, you are signing the consent for SSA to disclose your SSN
          Verification to Cherry Technologies, Inc. and SentiLink Verification Services Corp. You agree that your
          electronic signature has the same legal meaning, validity, and effect as your handwritten signature.
        </Subtitle>

        <Button
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
          m="32px 0 0"
          onClick={handleClick}
        >
          Authorize and Continue
        </Button>

        <HaveQuestionsModalContainer>
          <HaveQuestionsModal />
        </HaveQuestionsModalContainer>
      </Container>
    </>
  );
};

export default AlleIdReview;

const Container = styled.div`
  text-align: center;
  font-family: 'Graphik' !important;
  padding: 32px;
`;

const HaveQuestionsModalContainer = styled.div`
  margin-top: 32px;
`;
