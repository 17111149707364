import { gql } from '@apollo/client/core';

export const GET_AUTH_INIT = gql`
  mutation getUserAuthInit($input: AuthInitInput!) {
    getUserAuthInit(input: $input) {
      code
      success
      message
      token
      refreshToken
      user {
        email
        firstName
        id
        lastName
        phone
        roles
      }
      merchant {
        id
        bankingInfo {
          accountName
          accountNumber
        }
        name
        phone
        address {
          street
          zip
          city
          state {
            code
            name
          }
        }
      }
      organization {
        active
        flow
        id
        isDemo
        name
        outcomeThreshold
        roles
        slug
        website
        phone
        industry
        menuCode
      }
      organizationGroup {
        id
        name
        organizations {
          id
          name
          slug
          merchants {
            id
            name
            address {
              address1
              zip
              city {
                name
                county
                idAddressCity
                state {
                  country {
                    name
                    code
                    idAddressCountry
                  }
                  name
                  code
                  idAddressCountry
                }
              }
              idAddress
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_BORROWER = gql`
  mutation updateBorrower($input: UpdateBorrowerInput) {
    updateBorrower(input: $input) {
      success
      message
      error
      data {
        email
        dob
        createdAt
        address {
          city
          id
          createdAt
          updatedAt
          status
          street
          zip
          state {
            code
            name
          }
        }
        phone
        language
        status
        firstName
        lastName
        id
      }
    }
  }
`;

export const IDENTITY_VERIFICATION = gql`
  mutation identityVerifications($input: IdentityVerificationsInput) {
    identityVerifications(input: $input) {
      borrowerId
      createdAt
      id
      kycOutcomeId
      status
      templateId
      type
      updatedAt
    }
  }
`;

export const IDENTITY_VERIFICATION_CONSENTS = gql`
  mutation identityVerificationConsents($input: IdentityVerificationConsentsInput) {
    identityVerificationConsents(input: $input) {
      success
    }
  }
`;
