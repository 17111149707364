import dayjs from 'dayjs';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { GenericSearchOperators } from 'lib/types';

dayjs.extend(utc);
dayjs.extend(timezone);

export const FetchApplicationListSearch = (borrowerId, organizationId?) => {
  const searchData = [
    {
      key: 'borrowerId',
      value: borrowerId,
      operator: GenericSearchOperators.EQUAL,
    },
    {
      key: 'validity',
      value: 'ACTIVE',
      operator: GenericSearchOperators.EQUAL,
    },
    {
      key: 'expireAt',
      value: dayjs().tz('Europe/London').toISOString(),
      operator: GenericSearchOperators.BIGGER_THAN,
    },
    {
      key: 'status',
      value: 'FROZEN',
      operator: GenericSearchOperators.NEGATION,
    },
  ];

  organizationId &&
    searchData.push({
      key: 'organizationId',
      value: organizationId,
      operator: GenericSearchOperators.EQUAL,
    });

  return searchData;
};
