import { Box } from '@mui/material';
import { Subtitle, SurveyTypeForm } from 'lib/components';
import { FaqDeclinedList } from 'pages/Faq/components';
import FaqKycFailureList from 'pages/Faq/components/FaqKycFailureList';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import KycErrorBase from './KycErrorBase';

interface KycDenialProps {
  isNewDenialUi: boolean;
}

export default function KycFailure({ isNewDenialUi }: KycDenialProps) {
  const { t: translate } = useTranslation();

  return (
    <KycErrorBase
      isNewDenialUi={isNewDenialUi}
      title={translate('result.kycFailure.subtitle')}
      faqList={<FaqKycFailureList />}
      oldPage={
        <>
          <SubtitleContainer>
            <Subtitle>{translate('result.subTitle1')}</Subtitle>
          </SubtitleContainer>

          <SurveyTypeForm
            page="DENIAL"
            title={translate('result.survey.title', {
              price: '$25',
            })}
            description={translate('result.survey.desc', {
              price: '$25',
            })}
          />

          <SubtitleContainer removePadding={true}>
            <FaqDeclinedList />
          </SubtitleContainer>
        </>
      }
    />
  );
}

const SubtitleContainer = styled(Box)<{ removePadding?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props?.removePadding ? '20px -24px 0' : 'initial')};
`;
