import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useNavigation } from 'lib/hooks/useNavigation';
import { useEligibilityService } from 'pages/AuthChecker/services';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import { MX_ERROR_CODES } from 'pages/MXAuth/MXAuth';
import { useTranslation } from 'react-i18next';

const ConnectWithMXRetry = () => {
  const { navigate } = useNavigation();
  const { connectionStatus } = useParams();
  const { t: translate } = useTranslation();
  const { organization } = useStore();

  const [loading, setLoading] = useState(false);

  const { getBorrowerIncomeVerification } = useEligibilityService();

  const goToMX = async () => {
    setLoading(true);
    const response = await getBorrowerIncomeVerification('ASSET_REPORT');
    if (response?.id) {
      setLoading(false);
      navigate(`mx-auth/${response?.id}`);
    }
  };

  const getPageTexts = () => {
    let pageTitle = '';
    let pageSubtitle = '';
    let pageHelperText = '';

    switch (connectionStatus) {
      case MX_ERROR_CODES.DENIED:
      case MX_ERROR_CODES.REJECTED:
      case MX_ERROR_CODES.MX_ERROR:
        pageTitle = translate('connectWithMXRetry.title1');
        pageSubtitle = translate('connectWithMXRetry.subTitle1');
        pageHelperText = translate('connectWithMXRetry.info1');
        break;
      case MX_ERROR_CODES.LOCKED:
      case MX_ERROR_CODES.CANT_LOGIN:
        pageTitle = translate('connectWithMXRetry.title2');
        pageSubtitle = translate('connectWithMXRetry.subTitle2');
        pageHelperText = translate('connectWithMXRetry.info2', {
          name: organization?.slug,
        });
        break;
      default:
        pageTitle = translate('connectWithMXRetry.title3');
        pageSubtitle = translate('connectWithMXRetry.subTitle3');
        pageHelperText = translate('connectWithMXRetry.info3');
    }

    return {
      title: pageTitle,
      subtitle: pageSubtitle,
      helperText: pageHelperText,
    };
  };

  const { title, subtitle, helperText } = getPageTexts();

  return (
    <Container showBackButton={false}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <IconContainer>
            <Icon src={'warning'} width={24} height={24} />
          </IconContainer>

          <Title m={'10px 0px 8px 0px'}>{title}</Title>
          <Subtitle m={'0px 0px 30px 0px'}>{subtitle}</Subtitle>
          <Subtitle dangerouslySetInnerHTML={{ __html: helperText }} />
        </InnerContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={loading} loading={loading} onClick={goToMX}>
          {translate('buttons.connectBank')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default ConnectWithMXRetry;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
