import { InputAdornment } from '@mui/material';
import { Button, Container, Icon, NumberFormatInput, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import React, { useEffect, useRef, useState } from 'react';

import useStore from 'lib/hooks/useStore';
import { FormatAmount } from 'lib/utils';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import { useServices } from 'pages/AuthChecker/services';
import { ApplicationFlowStatuses, StatedInfo } from 'pages/AuthChecker/type';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useDidMountEffect } from 'lib/hooks/useDidMountEffect';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

const RequestAmount = () => {
  const { application, applicationFlow, sessionData, setSessionData } = useStore.getState() || {};
  const [loading, setLoading] = useState(false);
  const { navigate } = useNavigation();
  const { createApplicationFlow } = useServices();
  const [requestedAmount, setRequestedAmount] = useState<number | null>();
  const [formattedAmount, setFormattedAmount] = useState<number | null>();
  const { startCheckApplicationStatus } = useSSEContext();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();

  const [validationError, setValidationError] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.defaultHighLineTreatmentCostLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const regex = /^[0-9\b]+$/;
    if (value && regex.test(value)) {
      setRequestedAmount(Number(value));
      setFormattedAmount(Number(value));
      setValidationError(false);
    }
  };

  const submit = async () => {
    sendActionSegmentEvent(WPQSegmentNames.defaultHighLineTreatmentCostContinue, {
      treatmentCost: requestedAmount,
    });

    if (!requestedAmount) return;

    if (requestedAmount > application?.menu?.maxPurchase) {
      setValidationError(true);
      setErrorMessage(
        translate('requestedAmount.error.message', {
          maxPurchase: FormatAmount(application?.menu?.maxPurchase),
        }),
      );
    } else {
      setValidationError(false);
      setLoading(true);
      request();
    }
  };

  const request = async () => {
    const statedInfos: StatedInfo[] = [];
    requestedAmount && statedInfos.push({ type: 'REQUESTED_AMOUNT', value: String(requestedAmount) });
    if (applicationFlow?.status === ApplicationFlowStatuses.MISSING_REQUESTED_AMOUNT) {
      const res = await createApplicationFlow(statedInfos);
      if (res?.success) {
        startCheckApplicationStatus({});
      }
    } else if (applicationFlow?.status === ApplicationFlowStatuses.TREATMENT_QUESTIONS) {
      const res = await createApplicationFlow(statedInfos, sessionData.treatmentType);
      if (res?.success) {
        startCheckApplicationStatus({});
      }
    } else {
      setSessionData({ requestedAmount });
      navigate('ssn-collect');
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (requestedAmount) {
        submit();
      }
    }
  };

  const renderAdornment = () => {
    if (requestedAmount) {
      return (
        <InputAdornment
          sx={{
            padding: '1px 0 0 0',
            '& .MuiTypography-root': { color: '#000' },
          }}
          position="start"
        >
          $
        </InputAdornment>
      );
    }
    return null;
  };

  return (
    <Container>
      <ContentContainer>
        <Icon src={'payment'} />
        <Title data-testid="requested-amount-title" m={'10px 0px 8px 0px'}>
          {translate('requestedAmount.title')}
        </Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('requestedAmount.subTitle')}</Subtitle>
        <TextField
          id="requested-amount"
          variant="filled"
          data-testid="requested-amount"
          data-neuro-label="requested-amount"
          label={translate('requestedAmount.form.requestedAmount')}
          type="tel"
          value={formattedAmount}
          max={application?.menu?.maxPurchase}
          onChange={handlePriceChange}
          onKeyDown={checkKeyDown}
          error={validationError}
          helperText={validationError ? errorMessage : ''}
          onFocus={() => {
            sendActionSegmentEvent(WPQSegmentNames.defaultHighLineTreatmentCostClicked);
          }}
          onBlur={(e) => {
            sendActionSegmentEvent(WPQSegmentNames.defaultHighLineTreatmentCostFilled, {
              metaData: e.target.defaultValue,
            });
          }}
          InputProps={{
            inputComponent: NumberFormatInput,
            pattern: '[^0-9.]',
            startAdornment: renderAdornment(),
          }}
        />
      </ContentContainer>
      <ButtonContainer>
        <Button
          dataTestId="submit-requested-amount"
          onClick={submit}
          disabled={!requestedAmount || validationError || loading}
          loading={loading}
        >
          {translate('buttons.continue')}
        </Button>
        {application?.flowType !== 'HIGH_LINE' && (
          <Button onClick={request} secondary={true}>
            {translate('buttons.doNotKnowCost')}
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default RequestAmount;
