import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import { WEB_SITE } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { useServices } from 'pages/AuthChecker/services';
import { Box, Button, Container, Icon, LoadingWithSteps, SsnInput, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer, FooterText } from 'lib/components/Common';
import { ITINNumberRegex } from 'lib/utils';
import { ApplicationFlowStatuses, StatedInfo } from 'pages/AuthChecker/type';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useTranslation } from 'react-i18next';
import { getExternalLinkLangParam } from 'lib/utils/ExternalLinkLangParam';

interface FormDataType {
  ssn: string;
}

const FullSsnCollect = () => {
  const alert = useAlert();
  const { initPage } = useNeuroId();
  const { trackEvent } = useAnalytics();
  const { captureException } = useSentry();
  const { t: translate } = useTranslation();
  const { createApplicationFlow } = useServices();
  const {
    setSessionData,
    sessionData,
    setPageIndex,
    organization,
    applicationFlow,
    defaultLanguage,
    organizationRegulations,
  } = useStore();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState('');
  const [fullScreenLoading, setFullScreenLoading] = useState(false);
  const { startCheckApplicationStatus } = useSSEContext();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const ssnMismatchCases = [ApplicationFlowStatuses.FULL_SSN_REQUIRED_1, ApplicationFlowStatuses.FULL_SSN_REQUIRED_2];
  const loadSegmentController = useRef(true);

  useEffect(() => {
    initPage('full-ssn-collect');
    return () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (loadSegmentController.current) {
      const segmentString = WPQSegmentNames.fullSsnLoad;
      sendLoadSegmentEvent(segmentString);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(7);
  }, [setPageIndex]);

  useEffect(() => {
    const isSsnMismatch = ssnMismatchCases.includes(applicationFlow?.status);
    if (applicationFlow?.status === ApplicationFlowStatuses.FULL_SSN_REQUIRED) {
      setFullScreenLoading(false);
      setLoading(false);
      reset({ ssn: '' });
    } else if (isSsnMismatch) {
      setLoading(false);
      setFullScreenLoading(false);
      setHasError(translate('fullSsn.error.doesNotMatch'));
      reset({ ssn: '' });
    } else if (applicationFlow?.status === ApplicationFlowStatuses.ITIN_NOT_SUPPORTED) {
      setHasError(translate('ssnCollect.errors.ssn'));
      setFullScreenLoading(false);
      setLoading(false);
      alert.info(translate('ssnCollect.errors.itin'));
    }
  }, [applicationFlow]);

  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
    reset,
  } = useForm<FormDataType>({
    mode: 'onChange',
  });

  const onSubmit = async (formData: FormDataType) => {
    const { borrower, application } = useStore.getState() || {};
    const { requestedAmount } = sessionData;
    try {
      const isITIN = ITINNumberRegex?.test(formData.ssn);
      if (formData.ssn.charAt(0) === '9' && !isITIN) {
        setHasError(translate('ssnCollect.errors.notItinRecognize'));
        return;
      }
      setFullScreenLoading(true);
      trackEvent(AnalyticEventNames.SSN_CONTINUE);
      setSessionData(formData);
      setLoading(true);
      sendActionSegmentEvent(WPQSegmentNames.fullSsnButtonClicked);

      const statedInfos: StatedInfo[] = [];
      statedInfos.push({ type: 'SSN', value: formData?.ssn });

      if (application?.flowType === 'HIGH_LINE' || applicationFlow?.hasTreatmentQuestions) {
        requestedAmount && statedInfos.push({ type: 'REQUESTED_AMOUNT', value: String(requestedAmount) });
      }

      const res = await createApplicationFlow(statedInfos, sessionData.treatmentType);
      if (res?.success) {
        startCheckApplicationStatus({ applicationId: application?.id, isExisting: true });
      }
    } catch (err) {
      setFullScreenLoading(false);
      setLoading(false);
      captureException(err, {
        page: 'Full SSN Collect',
        message: 'Full SSN Patch Error',
        borrower: borrower,
      });
    }
  };

  const onBlurHandler = () => {
    trackEvent(AnalyticEventNames.SSN_INP_BLUR);
    const segmentString = WPQSegmentNames.fullSsnInputFilled;
    sendActionSegmentEvent(segmentString);
  };

  const focus = () => {
    setHasError('');
    const segmentString = WPQSegmentNames.fullSsnInputClicked;
    sendActionSegmentEvent(segmentString);
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const handleClick = () => {
    handleSubmit(onSubmit)();
  };

  const footerText = translate('ssnCollect.footer.newText', {
    lendingPartners: `<a target="_blank" href=${WEB_SITE}/lending-partners/${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('ssnCollect.footer.lendingPartners')}</a>`,

    termsOfService: `<a target="_blank" href=${WEB_SITE}/terms/${getExternalLinkLangParam(
      defaultLanguage,
    )}> ${translate('ssnCollect.footer.termsOfService')}</a>`,

    originator: organizationRegulations?.originatingEntity || 'Cherry Technologies Inc.',
    entity: organizationRegulations?.originationType === 'LEAD_BANK' ? 'Lead Bank' : organization?.name,
    loanType:
      organizationRegulations?.originationType === 'RISC'
        ? translate('ssnCollect.footer.riscContract')
        : translate('ssnCollect.footer.anInstallment'),
  });

  return (
    <Container isRelative={true} showBackButton={false}>
      <ContentContainer>
        <Icon src={'fingerprint'} />
        <>
          <Title m={'10px 0px 8px 0px'}>{translate('fullSsnCollect.provideSsnTitle')} </Title>
          <Subtitle
            m={'0px 0px 24px 0px'}
            dangerouslySetInnerHTML={{
              __html: translate('fullSsnCollect.provideSsn'),
            }}
          ></Subtitle>
        </>

        <Box>
          <Controller
            name="ssn"
            control={control}
            rules={{
              required: true,
              minLength: 11,
              maxLength: 11,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="ssn"
                  variant="filled"
                  data-testid="ssn"
                  name={'ssn'}
                  data-neuro-label="ssn"
                  type="tel"
                  label={translate('ssnCollect.itinLabel')}
                  onKeyDown={checkKeyDown}
                  InputProps={{
                    inputComponent: SsnInput,
                    disableUnderline: true,
                  }}
                  value={value}
                  onChange={onChange}
                  onFocus={focus}
                  onBlur={onBlurHandler}
                  error={!!error || hasError}
                  helperText={hasError ? hasError : error ? error?.message : null}
                />
              );
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={!isValid || loading}
          loading={loading}
          onClick={handleClick}
          icon={!isValid || loading ? 'lock' : 'lock-active'}
        >
          {translate('buttons.checkEligibility')}
        </Button>
        <FooterText dangerouslySetInnerHTML={{ __html: footerText }} />
      </ButtonContainer>
      {fullScreenLoading && <LoadingWithSteps />}
    </Container>
  );
};

export default FullSsnCollect;
