import styled, { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';

import { theme } from 'config/theme';
import { FormatAmount } from 'lib/utils';
import useStore from 'lib/hooks/useStore';
import { MINIMUM_AMOUNT } from 'lib/constants';
import { useSentry } from 'lib/hooks/useSentry';
import { useViewport } from 'lib/hooks/useViewport';
import { useDebounce } from 'lib/hooks/useDebounce';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';

import { Icon, Loading, TextField, Title } from 'lib/components';
import { PlanCard } from 'pages/Plans/components/PlanCard';
import { useTranslation } from 'react-i18next';

const ChooseAPlan = ({ defaultSelected, setSelectedIndex, selectedIndex, handleCarouselChange }) => {
  const { t: translate } = useTranslation();
  const { isMobile } = useViewport();
  const { captureException } = useSentry();
  const { getProductCalculation } = useProductCalculation();

  const { productCalculations, selectedApplication, setSelectedPaymentPlan, applicationFlow } = useStore();

  const { requestAmount = 0 } = applicationFlow || {};
  const { balanceAvailable = 0 } = selectedApplication || {};
  let selectedAmount: number;
  if (requestAmount > 0) {
    selectedAmount = Math.min(requestAmount, balanceAvailable);
  } else if (balanceAvailable > 0) {
    selectedAmount = balanceAvailable;
  } else {
    selectedAmount = 0;
  }

  const [calculationLoading, setCalculationLoading] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState<number | null>(selectedAmount);
  const debouncedValue = useDebounce(enteredAmount, 500);

  const [plan, setPlan] = useState<number | null>(null);

  const handlePriceChange = (e) => {
    const value = parseFloat(e?.target?.value);
    setEnteredAmount(isNaN(value) ? null : value);
  };

  useEffect(() => {
    setSelectedPaymentPlan(selectedIndex);
  }, [selectedIndex]);

  const fetchProductCalculation = async () => {
    setCalculationLoading(true);
    const { application } = useStore.getState() || {};
    const { id: applicationId } = application || {};

    if (applicationId && debouncedValue) {
      if (
        parseFloat(debouncedValue) >= MINIMUM_AMOUNT &&
        parseFloat(debouncedValue) <= selectedApplication?.balanceAvailable
      ) {
        const response = await getProductCalculation(applicationId, debouncedValue);
        if (response && !!response.length) {
          setCalculationLoading(false);
          setSelectedIndex(defaultSelected);

          if (plan === null) {
            const selectedIndex = response?.findIndex(
              (item) =>
                item?.product?.promoApr === 0 &&
                item?.product?.term ===
                  Math.max(...response?.filter((d) => d?.product?.promoApr === 0)?.map((d) => d?.product?.term)),
            );

            setPlan(selectedIndex !== -1 ? selectedIndex : 0);
            setSelectedIndex(selectedIndex !== -1 ? selectedIndex : 0);
          }
        }
      }
    } else {
      setCalculationLoading(false);
      captureException('Application Id Not Found in Calculation Slider', {
        page: 'Approval Page',
        application,
      });
    }
  };

  useEffect(() => {
    if (
      debouncedValue &&
      debouncedValue !== '' &&
      parseFloat(debouncedValue) >= MINIMUM_AMOUNT &&
      parseFloat(debouncedValue) <= selectedApplication?.balanceAvailable
    ) {
      fetchProductCalculation?.();
    }
  }, [debouncedValue]);

  if (productCalculations?.length === 0) {
    return null;
  }

  const sliderDisplayCount = isMobile ? (selectedIndex === 0 ? 1.2 : 1.4) : productCalculations?.length > 2 ? 2.2 : 2;
  return (
    <ChooseAPlanContainer>
      <Title m={'10px auto 24px'}>{translate('choosePlan.title')}</Title>
      <PurchaseTextContainer>
        <PurchaseText>{translate('choosePlan.subTitle')}</PurchaseText>
      </PurchaseTextContainer>
      <TextFieldContainer>
        <TextField
          id="choose-a-plan-input"
          variant="filled"
          data-testid="purchase"
          data-neuro-label="purchase"
          label={translate('choosePlan.form.purchaseAmount')}
          type="tel"
          value={enteredAmount && enteredAmount}
          max={selectedApplication?.balanceAvailable}
          onChange={handlePriceChange}
          InputProps={{
            pattern: '[^0-9.]',
            startAdornment: (
              <InputAdornment
                sx={{
                  padding: '1px 0 0 0',
                  '& .MuiTypography-root': { color: '#000' },
                }}
                position="start"
              >
                $
              </InputAdornment>
            ),
          }}
        />
      </TextFieldContainer>

      {calculationLoading ? (
        <CenterLoading>
          <Loading size={40} />
        </CenterLoading>
      ) : (
        <>
          {debouncedValue &&
          (debouncedValue < MINIMUM_AMOUNT || debouncedValue > selectedApplication?.balanceAvailable) ? (
            <CalculationSummary>
              <Summary color={theme.main.pinkColor}>
                <Icon src={'info_circle_red'} />
                <br />
                {translate('choosePlan.error.range', {
                  min: FormatAmount(MINIMUM_AMOUNT),
                  max: FormatAmount(Number(selectedApplication?.balanceAvailable)),
                })}
              </Summary>
            </CalculationSummary>
          ) : (
            <>
              {productCalculations?.length > 1 ? (
                <SliderContainer
                  isFirstItemSelected={selectedIndex === 0 || false}
                  isMini={
                    productCalculations?.length >= 4 &&
                    selectedIndex > 1 &&
                    selectedIndex < productCalculations?.length - 2
                  }
                >
                  <Carousel
                    emulateTouch={true}
                    selectedItem={selectedIndex}
                    swipeScrollTolerance={10}
                    showIndicators={true}
                    swipeable={true}
                    centerMode={true}
                    showThumbs={false}
                    centerSlidePercentage={100 / sliderDisplayCount}
                    showArrows={false}
                    showStatus={false}
                    onChange={handleCarouselChange}
                    onClickItem={handleCarouselChange}
                  >
                    {productCalculations?.map((data, index) => (
                      <PlanCard
                        key={`carousel-${index}`}
                        active={selectedIndex === index}
                        data={data}
                        isCheckoutStep={false}
                      />
                    ))}
                  </Carousel>
                </SliderContainer>
              ) : (
                <SoloContainer>
                  <PlanCard key={1} active={true} data={productCalculations?.[0]} isCheckoutStep={false} />
                </SoloContainer>
              )}
            </>
          )}
        </>
      )}
    </ChooseAPlanContainer>
  );
};

export default ChooseAPlan;

const SoloContainer = styled.div`
  padding: 0 24px 20px 24px;
`;

const SliderContainer = styled.div<{ isMini: boolean; isFirstItemSelected: boolean }>`
  width: ${(props) => (props?.isFirstItemSelected ? 'calc(100% - 24px)' : '100%')};
  padding: ${(props) => (props?.isFirstItemSelected ? '0 0px 0 24px' : '0 0px 0 0px')};
  margin-bottom: 75px;
  margin-top: 12px;

  .carousel.carousel-slider {
    overflow: initial !important;
  }

  .carousel .control-dots {
    bottom: -50px !important;
  }

  .carousel .control-dots .dot {
    background: #dadada;
    box-shadow: none;
    opacity: 1;
    width: 8px;
    height: 8px;

    ${({ isMini }) =>
      isMini &&
      css`
        &:first-child {
          width: 4px;
          height: 4px;
          margin-top: -8px;
        }

        &:last-child {
          width: 4px;
          height: 4px;
          margin-top: -8px;
        }
      `}
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    background: '${theme?.main?.green}';
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    margin-top: 50px;
    transition: margin 200ms ease-in-out;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide.selected {
    margin-top: 20px;
    transition: margin 200ms ease-in-out;
  }
`;

const ChooseAPlanContainer = styled.div`
  display: flex;
  background: ${(props) => props.theme.main.darkGrayColor};
  border-radius: 8px;
  padding: 16px 16px 32px 16px;
  flex-direction: column;
`;

const PurchaseTextContainer = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const PurchaseText = styled.span`
  color: ${(props) => props.theme.main.textColor};
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: 0 0 8px;
`;

const TextFieldContainer = styled.div`
  > .MuiFormControl-root {
    width: 100%;
  }
`;

const CalculationSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.main.darkGrayColor};
  border-radius: 8px;
  padding: 16px 16px 32px;
  margin-top: 24px;
`;

const Summary = styled.div<{ color?: string }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: center;
  font-style: ${(props) => (props?.color ? 'normal' : 'italic')};
  color: ${(props) => (props?.color ? props?.color : `${theme.main.midnightBlue}`)};
  margin-bottom: 2px;

  img {
    padding: 24px;
  }

  b {
    font-weight: 700;
  }
`;

const CenterLoading = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;
