import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { TRACK_VERSION, WEB_SITE, WEBPREQUAL } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';

import {
  AlleFullScreenLoading,
  Box,
  Button,
  HaveQuestionsModal,
  SsnInput,
  Subtitle,
  Title,
  UnderlinedTextField as TextField,
} from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { SmallText } from '../Terms/Terms';
import { theme } from 'config/theme';
import { IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';

const AlleSsnCollect = ({ control, isValid, checkKeyDown, handleClick, loading }) => {
  const { initPage } = useNeuroId();
  const { trackEvent } = useAnalytics();
  const { trackPage } = useSegment();
  const { pushEvent } = useIframeEvents();

  const { setPageIndex, organization, borrower, application } = useStore();

  useEffect(() => {
    trackPage('eligibilityInput', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
      applicationId: application?.id,
      borrowerId: borrower?.id,
      organizationId: organization?.id,
    });

    initPage('ssn-collect');
    pushEvent(IframeEventTypes.PAGE, PageEventActions.SSN_COLLECT);
  }, []);

  useEffect(() => {
    setPageIndex(7);
  }, [setPageIndex]);

  const onBlurHandler = () => {
    trackEvent(AnalyticEventNames.SSN_INP_BLUR);
  };

  return (
    <>
      <Container>
        <StepText>STEP 3 OF 3</StepText>
        <Title
          color={theme.main.black10}
          fontSize="24px"
          fontWeight={400}
          lineHeight="32px"
          useElegantFontType={true}
          textAlign="center"
        >
          One more step
        </Title>
        <Subtitle m="16px 0" textAlign="center" color={theme.main.black20} lineHeight="24px" fontSize="16px">
          This will trigger a soft credit check, which will not affect your credit score.
        </Subtitle>
        <Box>
          <Controller
            name="ssn"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              minLength: 11,
              maxLength: 11,
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="ssn"
                variant="filled"
                data-testid="ssn"
                data-neuro-label="ssn"
                type="tel"
                label="Social Security Number"
                onKeyDown={checkKeyDown}
                InputProps={{
                  inputComponent: SsnInput,
                  disableUnderline: true,
                }}
                value={value}
                onChange={onChange}
                onBlur={onBlurHandler}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Box>
        <Button
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
          m="32px 0 0"
          disabled={!isValid || loading}
          loading={loading}
          onClick={handleClick}
        >
          Check Eligibility
        </Button>

        <SmallText>
          {`See `}
          <InfoColorText href={`${WEB_SITE}/lending-partners/`} target="_blank">
            Cherry’s Lending Partners
          </InfoColorText>
          {`. See `}
          <InfoColorText href={`${WEB_SITE}/terms/`} target="_blank">
            Terms of Service
          </InfoColorText>
          {`.`}
          <TextDivider />
          You understand that by clicking on the "Check Eligibility" button, you are providing ‘written instructions’
          under the Fair Credit Reporting Act authorizing Cherry Technologies Inc. to obtain credit bureau reports from
          your personal credit profile and any other information about you solely to prequalify for an installment loan.
          AbbVie is not a broker or a lender and will have no role in the decision concerning your application or the
          origination, funding, servicing, or other aspects of your Cherry loan.
        </SmallText>
        <HaveQuestionsModalContainer>
          <HaveQuestionsModal />
        </HaveQuestionsModalContainer>
      </Container>
      {loading && <AlleFullScreenLoading />}
    </>
  );
};

export default AlleSsnCollect;

const InfoColorText = styled.a`
  color: #9a6b5e;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;
const TextDivider = styled.div`
  margin: 12px 0;
`;

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: 'Graphik', sans-serif;
`;

const HaveQuestionsModalContainer = styled.div`
  margin-top: 32px;
`;

const StepText = styled.div`
  color: ${(props) => props.theme.main.silverGray};
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 4px;
`;
