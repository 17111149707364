import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, SlideModal } from 'lib/components';
import { ButtonContainer } from 'lib/components/Common';
import { useViewport } from 'lib/hooks/useViewport';

interface ExpediteModal {
  show: boolean;
  hideModal: () => void;
}

const ExpediteModal = ({ show, hideModal }: ExpediteModal) => {
  const { t: translate } = useTranslation();
  const { isMobile } = useViewport();

  const handleClick = (e) => {
    if (e?.target?.ariaHidden) {
      hideModal();
    }
  };

  return (
    <SlideModal
      handleCloseClick={handleClick}
      show={show}
      hideModal={hideModal}
      isLarge={false}
      titleTranslateKey="applicationReview.expedite.title"
    >
      <>
        <ExpediteContainer>{translate('applicationReview.expedite.description')}</ExpediteContainer>
        <CustomButtonContainer isMobile={isMobile}>
          <Button onClick={hideModal}>{translate('buttons.okay')}</Button>
        </CustomButtonContainer>
      </>
    </SlideModal>
  );
};

export default ExpediteModal;

const ExpediteContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px 16px 32px;
  color: ${(props) => props.theme.main.black};
`;

const CustomButtonContainer = styled(ButtonContainer)<{ isMobile: boolean }>`
  box-shadow: ${(props) => props?.isMobile && 'none'};
`;
