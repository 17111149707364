import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentContainer } from 'lib/components/Common';
import { Container, Icon, Subtitle, Title } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { ApplyWithPatientPageSteps } from '../type';

interface ResultProps {
  page: ApplyWithPatientPageSteps;
}

const ApplyWithPatientResult = ({ page }: ResultProps) => {
  const { t: translate } = useTranslation();
  const { organization } = useStore();

  const getResultPageTitle = () => {
    switch (page) {
      case 'cancel':
        return translate('applyWithPatientResult.title.cancel');
      case 'error':
        return translate('applyWithPatientResult.title.error');
      case 'submit':
        return translate('applyWithPatientResult.title.submit');
      default:
        return translate('applyWithPatientResult.title.error');
    }
  };

  const getResultPageText = () => {
    switch (page) {
      case 'cancel':
        return translate('applyWithPatientResult.text.cancel', { organization: organization?.name });
      case 'error':
        return translate('applyWithPatientResult.text.error', { organization: organization?.name });
      case 'submit':
        return translate('applyWithPatientResult.text.submit');
      default:
        return translate('applyWithPatientResult.text.error', { organization: organization?.name });
    }
  };

  const getResultPageIcon = () => {
    switch (page) {
      case 'cancel':
        return 'error_outline';
      case 'error':
        return 'warning_outline';
      case 'submit':
        return 'check';
      default:
        return 'warning_outline';
    }
  };

  return (
    <Container showBackButton={false} hideTitle={false}>
      <ContentContainer>
        <Icon src={getResultPageIcon()} />
        <Title m={'10px 0 0 0'}>{getResultPageTitle()}</Title>
        <Subtitle m={'8px 0px 24px 0px'}>{getResultPageText()}</Subtitle>
      </ContentContainer>
    </Container>
  );
};

export default ApplyWithPatientResult;
