import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';
import { InputAdornment } from '@mui/material';

import { CREATE_LOANS } from 'lib/graphql/mutations';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';

import { Button, Container, Icon, NumberFormatInput, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { FormatAmount } from 'lib/utils';
import { loanStatusService } from 'pages/AuthChecker/utilityFunctions';
import { useServices } from 'pages/AuthChecker/services';
import { useTranslation } from 'react-i18next';

const PosCheckout = () => {
  const alert = useAlert();
  const { navigate } = useNavigation();
  const { getApplication } = useServices();
  const [createLoanRequest] = useMutation(CREATE_LOANS);
  const { t: translate } = useTranslation();
  const { setPageIndex, application, selectedLocation, organization, setLoan, selectedApplication } = useStore();

  const [requestedAmount, setRequestedAmount] = useState<number | null>();
  const [formattedAmount, setFormattedAmount] = useState<number | null>();

  const [validationError, setValidationError] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPageIndex(0);
  }, [setPageIndex]);

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const regex = /^[0-9\b]+$/;
    if (value && regex.test(value)) {
      if (Number(value) >= 200 && Number(value) <= Number(application?.maxEligible)) {
        setRequestedAmount(Number(value));
        setFormattedAmount(Number(value));
        setValidationError(false);
      } else {
        setValidationError(true);
      }
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (requestedAmount) {
        posCheckoutHandler();
      }
    }
  };

  const renderAdornment = () => {
    if (requestedAmount) {
      return (
        <InputAdornment
          sx={{
            padding: '1px 0 0 0',
            '& .MuiTypography-root': { color: '#000' },
          }}
          position="start"
        >
          $
        </InputAdornment>
      );
    }
    return null;
  };

  const posCheckoutHandler = async () => {
    if (!requestedAmount) return;

    if (requestedAmount > application?.maxEligible) {
      setValidationError(true);
      setErrorMessage(
        translate('posCheckout.error.message', {
          amount: FormatAmount(application?.menu?.maxPurchase),
        }),
      );
    } else {
      setValidationError(false);
      setLoading(true);
      const params = {
        merchantId: selectedLocation?.id,
        applicationId: application?.id,
        purchaseAmount: Number(requestedAmount),
        selfCheckout: true,
        channel: 'POINT_OF_SALE',
      };
      const { data } = await createLoanRequest({ variables: { input: { ...params } } });
      if (data.createLoan?.data) {
        await getApplication(data.createLoan?.data?.applicationId);
        setLoan(data?.createLoan?.data);
        loanStatusService(data.createLoan?.data, organization?.slug);
      } else {
        alert.info(translate('posCheckout.error.default'));
        setLoading(false);
      }
    }
  };

  const payWithCardHandler = () => {
    navigate(`pos-payment-review`);
  };

  return (
    <>
      <Container showBackButton={false}>
        <ContentContainer justify="space-between">
          <InnerContainer>
            <Icon src={'cherry-logo'} />
            <Title m={'10px 0px 12px 0px'} data-testid="title">
              {translate('posCheckout.title')}
            </Title>
            <TextField
              id="requested-amount"
              variant="filled"
              data-testid="requested-amount"
              data-neuro-label="requested-amount"
              label={translate('posCheckout.form.label')}
              type="tel"
              value={formattedAmount}
              max={application?.maxEligible}
              onChange={handlePriceChange}
              onKeyDown={checkKeyDown}
              error={validationError}
              helperText={translate('posCheckout.form.text', {
                balanceAvailable: FormatAmount(selectedApplication?.balanceAvailable),
              })}
              InputProps={{
                inputComponent: NumberFormatInput,
                pattern: '[^0-9.]',
                startAdornment: renderAdornment(),
              }}
            />
          </InnerContainer>
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_outline'} />
            <InfoText>{translate('posCheckout.info')}</InfoText>
          </InfoContainer>
        </ContentContainer>
        <ButtonContainer>
          <Button onClick={posCheckoutHandler} loading={loading} disabled={loading || validationError}>
            {translate('buttons.continueToPlanSelection')}
          </Button>
          <Button secondary={true} onClick={payWithCardHandler}>
            {translate('buttons.payWithCardInstead')}
          </Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default PosCheckout;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  color: ${(props) => props.theme.main.midnightBlue};
`;
