import { theme } from 'config/theme';
import styled from 'styled-components';

export const Subtitle = styled.span<{
  m?: string;
  p?: string;
  color?: string;
  fontSize?: string;
  fontFamily?: string;
  lineHeight?: string;
  textAlign?: string;
  fontWeight?: string;
  cursor?: string;
  display?: string;
  underlined?: boolean;
}>`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : theme.fonts.primary)}, serif;
  margin: ${(props) => props.m};
  padding: ${(props) => props.p};
  font-style: normal;
  font-weight: ${(props) => (props?.fontWeight ? props?.fontWeight : '400')};
  line-height: ${(props) => (props?.lineHeight ? props?.lineHeight : '19px')};
  font-size: ${(props) => (props?.fontSize ? props?.fontSize : '14px')};
  color: ${(props) => (props?.color ? props?.color : `${theme.main.grayColor}`)};
  text-align: ${(props) => (props?.textAlign ? props?.textAlign : 'left')};
  cursor: ${(props) => (props?.cursor ? props?.cursor : 'default')};
  display: ${(props) => (props?.display ? props?.display : 'block')};
  text-decoration: ${(props) => (props.underlined ? 'underline' : 'none')};
`;
