import { useApolloClient } from '@apollo/client';

import { GET_DOWN_PAYMENT_AMOUNT_CALCULATE } from 'lib/graphql/queries';
import { useSentry } from 'lib/hooks/useSentry';

export const useDownPaymentCalculation = () => {
  const client = useApolloClient();
  const { captureException } = useSentry();

  const getDownPaymentCalculation = async (
    applicationId: string,
    productId: string,
    amount: number,
    grossAmount: number,
  ) => {
    if (productId && amount) {
      const { data } = await client.query({
        query: GET_DOWN_PAYMENT_AMOUNT_CALCULATE,
        variables: {
          input: {
            applicationId,
            productId,
            amount,
            grossAmount,
          },
        },
      });

      if (data?.getDownPaymentAmountCalculate?.variableDownPayment) {
        try {
          return data?.getDownPaymentAmountCalculate;
        } catch (err) {
          captureException('Down Payment Error');
          return data?.getDownPaymentAmountCalculate;
        }
      }
      return [];
    } else {
      return [];
    }
  };

  return { getDownPaymentCalculation };
};
