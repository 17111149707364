import { gql } from '@apollo/client/core';

export const POST_APPLICATION = gql`
  mutation postApplication($input: PostApplicationRequest) {
    postApplication(input: $input) {
      balanceAvailable
      borrowerId
      demo
      expireAt
      id
      ivAllow {
        paystub
      }
      maxEligible
      organizationId
      partner
      purchaseAmount
      selfCheckout
      status
      validity
    }
  }
`;

export const PATCH_MERCHANT_PREFERENCE = gql`
  mutation patchMerchantPreference($input: PatchMerchantPreferenceRequest) {
    patchMerchantPreference(input: $input) {
      id
      merchantId
    }
  }
`;

export const SET_APPLICATION_READY_CHECKOUT = gql`
  mutation SetApplicationReadyForCheckout($input: ApplicationReadyForCheckoutInput) {
    setApplicationReadyForCheckout(input: $input) {
      success
    }
  }
`;

export const POST_APPLICATION_FLOW = gql`
  mutation postApplicationFlow($input: ApplicationInput) {
    postApplicationFlow(input: $input) {
      success
    }
  }
`;

export const PATCH_APPLY_WITH_PATIENT_CONSENT = gql`
  mutation patchApplyWithPatientConsent($input: ApplyWithPatientConsentInput) {
    patchApplyWithPatientConsent(input: $input) {
      organizationId
      merchantId
      firstName
      lastName
      dob
      email
      phone
      last4ssn
      statedIncome
      housingPayment
      requestedAmount
      address {
        aptUnitAddress
        streetAddress
        city
        state
        zip
      }
      status
      borrowerId
      id
      exchangeTokenId
    }
  }
`;
