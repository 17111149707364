import { CallCherry } from 'lib/utils';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';

import { MessageTitle } from '../FrozenCredit/FrozenCredit';
import { useTranslation } from 'react-i18next';

const SsnConflict = () => {
  const { t: translate } = useTranslation();
  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'search-person'} />
        <Title m={'10px 0px 8px 0px'}>{translate('ssnConflict.title')}</Title>
        <Subtitle m={'24px 0px 24px 0px'}>{translate('ssnConflict.subTitle')}</Subtitle>

        <MessageTitle>{translate('ssnConflict.info.title')}</MessageTitle>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('ssnConflict.info.subTitle')}</Subtitle>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={CallCherry} secondary={true} icon={'phone'}>
          {translate('buttons.callSupport')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default SsnConflict;
