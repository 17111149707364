import { useMutation } from '@apollo/client';
import { GET_AUTH_INIT } from 'lib/graphql/mutations';

import { OK, SCOPE, WEB_SITE } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import { Redirect } from 'lib/utils';
import { APP_ENV, SANDBOX_URL } from 'config';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import Rox from 'rox-browser';

export const useInitializeAuth = () => {
  const [getAuthInit] = useMutation(GET_AUTH_INIT);
  const { setOrganization, setIsDemo, setOrganizationGroup, setAuthData } = useStore();
  const { flags } = useFlags();

  const initializeAuth = async (slug: string | number | undefined) => {
    const { data } = await getAuthInit({ variables: { input: { slug, scope: SCOPE } } });
    if (data?.getUserAuthInit?.code === OK) {
      const { organization } = data?.getUserAuthInit || {};
      setOrganization(organization);
      Rox.setCustomStringProperty('organization_id_str', String(organization?.id));
      setOrganizationGroup(data?.getUserAuthInit?.organizationGroup);
      setIsDemo(data?.getUserAuthInit?.organization?.isDemo);

      setAuthData({
        slug,
        loanToken: '',
        token: data?.getUserAuthInit?.token,
        refreshToken: data?.getUserAuthInit?.refreshToken,
      });

      const isSandboxNavigationEnabled = flags.DEMO.sandboxNavigationEnabled.isEnabled();
      if (data?.getUserAuthInit?.organization?.isDemo && APP_ENV !== 'sandbox' && isSandboxNavigationEnabled) {
        window.location.href = `${SANDBOX_URL}/${slug}`;
      }

      return data?.getUserAuthInit;
    } else {
      Redirect(WEB_SITE, '_self');
    }
  };

  return { initializeAuth };
};
