import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { TRACK_VERSION, WEBPREQUAL } from 'lib/constants';
import { IDENTITY_VERIFICATION_CONSENTS } from 'lib/graphql/mutations';
import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';

import { Button, Container, Icon, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useDidMountEffect } from 'lib/hooks/useDidMountEffect';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useTranslation } from 'react-i18next';

const SsnVerification = () => {
  const alert = useAlert();
  const { captureException } = useSentry();
  const { startCheckApplicationStatus } = useSSEContext();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [identityVerificationConsentsRequest] = useMutation(IDENTITY_VERIFICATION_CONSENTS);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.idReviewSSNVerificationLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const identityVerificationHandler = async () => {
    const { borrower } = useStore.getState() || {};
    setLoading(true);
    sendActionSegmentEvent(WPQSegmentNames.idVerificationContinueClicked);
    if (borrower?.id) {
      const { data } = await identityVerificationConsentsRequest({
        variables: { input: { borrowerId: borrower?.id } },
      });
      if (data?.identityVerificationConsents?.success) {
        startCheckApplicationStatus({ isExisting: true });
      } else {
        setLoading(false);
        alert.info(translate('ssnVerification.error.info'));
        captureException('SSN Verification Provider Error', { borrower });
      }
    } else {
      setLoading(false);
      alert.info(translate('ssnVerification.error.message'));
      captureException('Borrower Id Not Found', { borrower });
    }
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'fingerprint'} />
        <Title m={'10px 0px 24px 0px'} data-testid="ssn-verification-title">
          {translate('ssnVerification.title')}
        </Title>
        <UserInfoContainer>
          <UserInfoItem>
            <UserInfoText>{translate('ssnVerification.subTitle')}</UserInfoText>
          </UserInfoItem>
          <UserInfoItem>
            <UserInfoText>{translate('ssnVerification.info')}</UserInfoText>
          </UserInfoItem>
        </UserInfoContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={loading} loading={loading} onClick={identityVerificationHandler}>
          {translate('buttons.authorizeAndContinue')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default SsnVerification;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserInfoItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const UserInfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.main.grayColor};
`;
