import { LanguageEnum } from 'lib/hooks/useStore';
import enTranslations from 'lib/translations/en.json';
import esTranslations from 'lib/translations/en.json';

import { februaryDayControl } from './FebruaryDayControl';
import { lawfulAgeControl } from './LawfulAgeControl';

export const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const phonePattern = /^\(?([2-9][0-8][0-9])\)? ?([2-9][0-9]{2})[-.●]?([0-9]{4})$/;
export const otpPattern = /[0-9]{4,}/;
export const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export const idPattern = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9-]+)$/;
export const ssnPattern = /^(?!(000|666|9))\d{3}\-(?!00)\d{2}\-(?!0000)\d{4}$/;
export const websitePattern =
  /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const trapSpacesForRequiredFields = (value) => !!value.trim();
export const numericPattern = /^([0-9]|#|\_|\*|\-|\ )+$/;

export const exchangeTokenPattern = /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/;

export const poBoxRegex = new RegExp(/\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box\d*)\b/i);
export const houseNumberRegex = /^\d.*|^\D\d.*/;
export const prohibitedStreetRegex = /general\s*delivery|general-?delivery/i;

export const ITINNumberRegex = /(9\d{2})([ \-]?)(5\d|6[0-5]|7\d|8[0-8]|9[0-2,4-9])([ \-]?)(\d{4})/gm;

export const validateDOB = (value: string, language: string) => {
  const selectedTranslations = language === LanguageEnum.EN ? enTranslations : esTranslations;
  if (value.length !== 10 || (value.length === 10 && !februaryDayControl(value))) {
    return selectedTranslations?.validators?.date;
  } else if (value.length === 10 && !lawfulAgeControl(value)) {
    return selectedTranslations?.validators?.age;
  } else {
    return !!value.trim();
  }
};
