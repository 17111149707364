import { gql } from '@apollo/client/core';

export const FETCH_LATEST_CREDIT_LINE_INCREASE = gql`
  query fetchLatestCreditLineIncrease($input: FetchCreditLineIncreasesInput) {
    fetchLatestCreditLineIncrease(input: $input) {
      amount {
        eligible
        approved
        requested
      }
      id
      missingInfos
      status
      source
      subStatus
      statedInfos {
        type
        value
      }
    }
  }
`;

export const FETCH_CREDIT_LINE_INCREASES = gql`
  query fetchCreditLineIncreases($input: FetchCreditLineIncreasesInput) {
    fetchCreditLineIncreases(input: $input) {
      amount {
        eligible
        approved
        requested
      }
      missingInfos
      status
      subStatus
      statedInfos {
        type
        value
      }
    }
  }
`;

export const FETCH_LATEST_CHERRY_ACCESS = gql`
  query fetchLatestCreditLineIncrease($input: FetchCreditLineIncreasesInput) {
    fetchLatestCreditLineIncrease(input: $input) {
      amount {
        eligible
        approved
        requested
      }
      id
      missingInfos
      status
      subStatus
      statedInfos {
        type
        value
      }
    }
  }
`;

export const FETCH_CHERRY_ACCESS = gql`
  query fetchCreditLineIncreases($input: FetchCreditLineIncreasesInput) {
    fetchCreditLineIncreases(input: $input) {
      amount {
        eligible
        approved
        requested
      }
      missingInfos
      status
      subStatus
      statedInfos {
        type
        value
      }
    }
  }
`;
