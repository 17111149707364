import { createBrowserHistory } from 'history';

export const useBrowserHistory = () => {
  const history = createBrowserHistory();

  const urlArray: string[] = history?.location?.pathname?.split('/');
  const pageName = urlArray[2];
  const merchantSlug = urlArray[1];

  return {
    pageName,
    merchantSlug,
  };
};
