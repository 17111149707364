import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Control, Controller } from 'react-hook-form';

import { trapSpacesForRequiredFields, validateDOB } from 'lib/utils/Validators';

import {
  Button,
  DateInput,
  HaveQuestionsModal,
  Subtitle,
  Title,
  UnderlinedTextField as TextField,
} from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { theme } from 'config/theme';
import { IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';
import { LanguageEnum } from 'lib/hooks/useStore';

type AllePersonalInfoProps = {
  control: Control;
  checkKeyDown: (event: KeyboardEvent) => void;
  handleClick: () => void;
  isValid: boolean | undefined;
};

const AllePersonalInfo = ({ control, checkKeyDown, handleClick, isValid }: AllePersonalInfoProps) => {
  const { pushEvent } = useIframeEvents();

  useEffect(() => {
    pushEvent(IframeEventTypes.PAGE, PageEventActions.PATIENT_INFO);
  }, []);

  return (
    <Container>
      <StepText>STEP 1 OF 3</StepText>
      <Title
        color={theme.main.black10}
        fontSize="24px"
        fontWeight={400}
        lineHeight="32px"
        useElegantFontType={true}
        textAlign="center"
      >
        Let’s start with the basics
      </Title>
      <Subtitle m="16px 0" textAlign="center" color={theme.main.black20} lineHeight="24px" fontSize="16px">
        Please make sure this information is accurate.
      </Subtitle>
      <FormInputs>
        <InputContainer width="50%">
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{ required: true, validate: trapSpacesForRequiredFields }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="first-name"
                name="firstName"
                data-testid="firstName"
                data-neuro-label="firstName"
                variant="filled"
                type="text"
                label="First Name"
                value={value}
                onKeyDown={checkKeyDown}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            )}
          />
        </InputContainer>
        <InputContainer width="50%">
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{ required: true, validate: trapSpacesForRequiredFields }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="last-name"
                name="lastName"
                variant="filled"
                data-testid="lastName"
                data-neuro-label="lastName"
                type="text"
                label="Last Name"
                value={value}
                onKeyDown={checkKeyDown}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            )}
          />
        </InputContainer>
        <InputContainer>
          <Controller
            name="dob"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              maxLength: 10,
              minLength: 10,
              validate: (value) => validateDOB(value, LanguageEnum.EN),
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <>
                  <TextField
                    id="dob"
                    variant="filled"
                    name="dob"
                    data-testid="dob"
                    data-neuro-label="dob"
                    type="tel"
                    label="Birth Date (MM/DD/YYYY)"
                    onKeyDown={checkKeyDown}
                    InputProps={{
                      inputComponent: DateInput,
                      disableUnderline: true,
                    }}
                    value={value === '01/01/1900' ? null : value} // default value in db for dob - not available patients
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error ? error?.message : null}
                  />
                </>
              );
            }}
          />
        </InputContainer>
      </FormInputs>
      <ButtonContainer>
        <Button
          m="32px 0 0"
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
          disabled={!isValid}
          onClick={handleClick}
        >
          Continue
        </Button>
      </ButtonContainer>
      <HaveQuestionsModal />
    </Container>
  );
};

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: 'Graphik';
`;

const FormInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
`;

const InputContainer = styled.div<{ width?: string }>`
  width: ${(props) => (props.width ? `calc(${props.width} - 8px)` : '100%')};

  .MuiFormControl-root {
    width: 100%;
  }

  &:last-of-type {
    margin-top: 16px;
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 32px;
`;

const StepText = styled.div`
  color: ${(props) => props.theme.main.silverGray};
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

export default AllePersonalInfo;
