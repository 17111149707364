export enum GenericSearchOperators {
  EQUAL = ':',
  NEGATION = '!',
  BIGGER_THAN = '>',
  LOWER_THAN = '<',
  LIKE = '~',
  IN = '±',
  NOT_IN = '!±',
  EQUAL_OR_GREATER_THAN = '≥',
  EQUAL_OR_LESS_THAN = '≤',
}
