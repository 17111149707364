import { useEffect } from 'react';
import styled from 'styled-components';

import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';

import { Button, Container, Icon, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { FormatAmount } from 'lib/utils';
import { useTranslation } from 'react-i18next';

const PosPaymentReview = () => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const { setPageIndex, sessionData } = useStore();

  useEffect(() => {
    setPageIndex(0);
  }, [setPageIndex]);

  const posPayWithCardHandler = () => {
    navigate(`pos-pay-card`);
  };

  return (
    <>
      <Container showBackButton={false}>
        <ContentContainer>
          <Icon src={'glasses-review'} />
          <Title m={'10px 0px 24px 0px'}>{translate('posPaymentReview.title')}</Title>
          <Header>{translate('posPaymentReview.subTitle')}</Header>
          <PaymentCardContainer>
            <ContentItemContainer>
              <ColumnContainer>
                <ContentTitle>{translate('posPaymentReview.dueNow')}</ContentTitle>
                <ContentText>{FormatAmount(sessionData?.posRemainingAmount)}</ContentText>
              </ColumnContainer>
            </ContentItemContainer>
            <ColumnContainer>
              <ContentSubText
                dangerouslySetInnerHTML={{
                  __html: translate('posPaymentReview.total', {
                    posRemainingAmount: FormatAmount(sessionData?.posRemainingAmount),
                  }),
                }}
              />
            </ColumnContainer>
          </PaymentCardContainer>
        </ContentContainer>
        <ButtonContainer>
          <Button onClick={posPayWithCardHandler}>{translate('buttons.continue')}</Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default PosPaymentReview;

const Header = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.white};
  background-color: ${(props) => props.theme.main.green};
  padding: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const PaymentCardContainer = styled.div`
  padding: 12px 16px 24px;
  border: ${(props) => `1px solid ${props.theme.main.green}`};
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const ContentItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const ContentTitle = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${(props) => props.theme.main.green};
`;

const ContentText = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const CardTitle = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.main.grey};
`;

const ContentSubText = styled.span`
  font-style: italic;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: ${(props) => props.theme.main.midnightBlue};
`;
