import {
  Step,
  StepLabel,
  Typography,
  Stepper,
  Box,
  StepContent,
  StepConnector,
  stepConnectorClasses,
} from '@mui/material';
import { theme } from 'config/theme';
import styled from 'styled-components';

interface StepperProps {
  activeStep: number;
  steps: Step[];
}

interface Step {
  label: string;
  description: string;
}

export const StepperComponent = ({ activeStep, steps }: StepperProps) => {
  const green = theme.main.greenMain;
  const blueLight = theme.main.blueLight30;

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: green,
        borderLeftStyle: 'solid',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: green,
        borderLeftStyle: 'solid',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: blueLight,
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  return (
    <>
      <Box sx={{ maxWidth: 400 }}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{
            '.MuiSvgIcon-root:not(.Mui-completed)': {
              color: 'white',
              borderRadius: '50%',
              border: `1px solid ${theme.main.blueLight30}`,
            },
            '.MuiStepConnector-line': {
              borderLeftStyle: 'dashed',
            },
          }}
          connector={<QontoConnector />}
        >
          {steps.map((step, index) => (
            <CustomStep key={`step.label`} order={index} activeStep={activeStep}>
              <StepLabel>
                <CustomLabel order={index} activeStep={activeStep}>
                  {step.label}
                </CustomLabel>
              </StepLabel>
              <StepContent>
                <CustomDescription>{step.description}</CustomDescription>
              </StepContent>
            </CustomStep>
          ))}
        </Stepper>
      </Box>
    </>
  );
};

const stepTextColor = (index, activeStep) => {
  if (index > activeStep) {
    return theme.main.blueLight30;
  } else {
    return theme.main.midnightBlue;
  }
};

const CustomLabel = styled.span<{ order: number; activeStep: number }>`
  color: ${(props) => stepTextColor(props.order, props.activeStep)} !important;
  font-family: 'Open Sans' !important;
`;

const CustomStep = styled(Step)<{ order: number; activeStep: number }>`
  .MuiStepContent-root {
    border-left-style: dashed;
  }
  .MuiStepConnector-line {
    border-left-style: dashed;
  }
  .MuiStepLabel-root.Mui-disabled {
    text {
      fill: ${theme.main.blueLight30};
    }
  }
  .MuiStepLabel-root {
    .Mui-completed {
      color: ${theme.main.greenMain};
    }
    .Mui-active {
      color: ${theme.main.midnightBlue} !important;
      font-family: 'Open Sans', serif;
      border: 0 !important;
      font-weight: 700 !important;
      .MuiStepIcon-text {
        fill: 'white !important';
        font-size: 12px !important;
        font-weight: 700;
        font-family: 'Open Sans', serif;
      }
    }
  }
  .MuiStepLabel-label.Mui-completed {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Open Sans', serif;
  }
  .MuiStepLabel-label.Mui-active {
    font-size: 16px !important;
    font-family: 'Open Sans', serif;
  }
`;

const CustomDescription = styled(Typography)`
  font-size: 14px !important;
  font-weight: 400 !important;
  color: ${theme.main.black};
  font-family: 'Open Sans', serif !important;
`;
