import styled from 'styled-components';
import { AlleContainer } from 'lib/components/Common';
import { Title, Button, AlleHeader, HaveQuestionsModal, Icon } from 'lib/components';
import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { FormatAmount, calculatePaymentFeeAmount } from 'lib/utils';
import AllePaymentMethodCard from './components/AllePaymentMethodCard';

const AlleDownPayment = ({
  debitCardMethodContents,
  creditCardMethodContents,
  navigateToPaymentMethod,
  selectPaymentMethodHandler,
  addPaymentMethodHandler,
  selectedPaymentMethod,
  loading,
}) => {
  const { selectedPlan, paymentMethodFees, applicationRegulations } = useStore();
  const downPaymentFee = paymentMethodFees?.[selectedPaymentMethod?.storedCard?.type || '']?.percent || 0;
  const downPaymentFeeAmount =
    selectedPaymentMethod?.type === 'CARD' && downPaymentFee > 0
      ? calculatePaymentFeeAmount(
          paymentMethodFees,
          selectedPaymentMethod?.storedCard?.type,
          selectedPlan?.downPaymentAmount,
        )
      : 0;

  const originator = applicationRegulations?.originatingEntity || 'Cherry Technologies Inc.';

  return (
    <AlleContainer>
      <AlleHeader />
      <Title color={theme.main.black} fontSize="18px" fontWeight={500} fontFamily="Graphik" lineHeight="32px">
        Choose how to pay your down payment
      </Title>
      <DueContainer>
        <DueLabel>DUE NOW</DueLabel>
        <DuePrice>{FormatAmount(selectedPlan?.downPaymentAmount)}</DuePrice>
        <DueText>
          <b>{FormatAmount(selectedPlan?.downPaymentAmount)}</b> down payment
        </DueText>
        {selectedPaymentMethod?.type === 'CARD' && downPaymentFeeAmount > 0 && (
          <DueText>
            plus <b>{FormatAmount(downPaymentFeeAmount)}</b> credit card fee ({downPaymentFee}%)
          </DueText>
        )}
      </DueContainer>
      {debitCardMethodContents()?.map((paymentMethod, index) => (
        <AllePaymentMethodCard
          key={index}
          paymentMethod={paymentMethod}
          selectPaymentMethod={selectPaymentMethodHandler}
          selectedPaymentMethod={selectedPaymentMethod}
          isPreferred={true}
        />
      ))}
      {creditCardMethodContents()?.map((paymentMethod, index) => (
        <AllePaymentMethodCard
          key={index}
          paymentMethod={paymentMethod}
          selectPaymentMethod={selectPaymentMethodHandler}
          selectedPaymentMethod={selectedPaymentMethod}
        />
      ))}

      {selectedPaymentMethod?.type === 'CARD' && selectedPaymentMethod?.storedCard?.type !== 'DEBIT' && (
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_black'} />
          <InfoText>Use a debit card to avoid the {downPaymentFee}% processing fee.</InfoText>
        </InfoContainer>
      )}
      <ButtonContainer>
        <DisclaimerText>
          By clicking "Agree and Continue" I authorize {originator} to charge my account, debit card, or credit card on
          file for today's down payment in the amount of {FormatAmount(selectedPlan?.downPaymentAmount)}. I understand
          that if a credit card is used, a 2.99% processing fee will be applied.
        </DisclaimerText>
        <Button
          onClick={navigateToPaymentMethod}
          disabled={!selectedPaymentMethod || loading}
          loading={loading}
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
        >
          Agree and Continue
        </Button>
        <Button
          secondary={true}
          onClick={addPaymentMethodHandler}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
        >
          Add Payment Method
        </Button>
      </ButtonContainer>
      <HaveQuestionsModal />
    </AlleContainer>
  );
};

export default AlleDownPayment;

const DueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f6f5;
  padding: 24px;
  margin: 16px 0px 40px;
`;

const DueLabel = styled.span`
  font-family: 'Graphik';
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 12px;
`;
const DuePrice = styled.span`
  font-family: Petersburg;
  font-size: 46px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const DueText = styled.span`
  font-family: 'Graphik';
  font-size: 14px;
  font-weight: 400;
`;

const ButtonContainer = styled.div`
  margin-bottom: 32px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0 24px 0;
`;

const InfoText = styled.span`
  color: ${(props) => props.theme.main.midnightBlue};
  font-family: 'Graphik';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 12px;
`;

const DisclaimerText = styled.div`
  color: ${(props) => props.theme.main.alleBlack};
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 24px;
  text-align: left;
`;
