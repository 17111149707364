import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import { Accordion, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';

import faqKycList from './faqKyc.json';

const FaqKycList = () => {
  const { defaultLanguage } = useStore();
  const { t: translate } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>('panel0');

  const handleChange = (panel: string) => {
    setExpanded(panel);
  };

  return (
    <ContentContainer>
      <Title m={'10px 0 0 0'}>{translate('faqList.title')}</Title>
      <Accordion data={faqKycList?.[defaultLanguage || LANGUAGES[0]]} onChange={handleChange} expanded={expanded} />
    </ContentContainer>
  );
};

export default FaqKycList;
