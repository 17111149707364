import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { FormatPhoneNumber } from 'lib/utils';
import { Icon, Subtitle, Title } from 'lib/components';

export const DemoMode = React.memo(() => {
  const { t: translate } = useTranslation();
  const { organization } = useStore();
  const [isHide, setIsHide] = useState<boolean>(false);

  const startDemo = () => {
    setIsHide(true);
  };

  return (
    <DemoModeContainer isHide={isHide}>
      <DemoModeHeader>
        <Icon width={16} height={16} src={'cherry-logo-white'} />
        {organization?.name ? (
          <>
            <Title m={'8px 0 0 0'} fontSize="12px" lineHeight="16px" color={theme.main.white}>
              {translate('demoMode.headerTitle')}
            </Title>
            <HeaderTitle>{organization?.name}</HeaderTitle>
          </>
        ) : null}
      </DemoModeHeader>
      <DemoModeInnerContainer>
        <DemoModeContent>
          <StyledTitle m={'24px 0px 12px 0px'}>{translate('demoMode.title')}</StyledTitle>
          <Icon m={'0 auto'} width={56} height={56} src={'error_outline'} />

          <Title m={'24px 0px 12px 0px'}>{translate('demoMode.welcomeTitle')}</Title>
          <Subtitle
            m={'0px 0px 12px 0px'}
            color={`${theme.main.darkBlue}`}
            fontSize="16px"
            lineHeight="22px"
            dangerouslySetInnerHTML={{
              __html: translate('demoMode.subTitle'),
            }}
          />

          <Title m={'24px 0px 12px 0px'}>{translate('demoMode.ready')}</Title>
          <Subtitle m={'0px 0px 12px 0px'} color={`${theme.main.darkBlue}`} fontSize="16px" lineHeight="22px">
            {translate('demoMode.contactText')}
          </Subtitle>

          <Title m={'24px 0px 12px 0px'}>{translate('demoMode.patient')}</Title>
          <Subtitle m={'0px 0px 36px 0px'} color={`${theme.main.darkBlue}`} fontSize="16px" lineHeight="22px">
            {translate('demoMode.patientText', {
              name: organization?.name || translate('demoMode.thisPractice'),
            })}{' '}
            {organization?.phone ? translate('demoMode.at') : null}
            <LinkText href={`tel:${organization?.phone}`}>
              {organization?.phone && FormatPhoneNumber(organization?.phone)}
            </LinkText>{' '}
            {translate('demoMode.apply')}
          </Subtitle>
        </DemoModeContent>

        <DemoModeFooter>
          <StyledButton onClick={startDemo}>{translate('buttons.startDemo')}</StyledButton>
        </DemoModeFooter>
      </DemoModeInnerContainer>
    </DemoModeContainer>
  );
});

const DemoModeFooter = styled.div`
  padding: 0 5%;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    border-top: 1px solid ${(props) => props.theme.main.midnightBlue};
  }
`;

const DemoModeContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 5%;
`;

const DemoModeContainer = styled.div<{ isHide?: boolean }>`
  position: absolute;
  width: 100%;
  min-height: 100vh;
  z-index: ${(props) => props.theme.constants.zIndex};
  background: ${(props) => props.theme.main.demoModeColor};
  display: ${(props) => (props?.isHide ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;

const DemoModeHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: ${(props) => props.theme.main.midnightBlue};

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    justify-content: flex-start;
  }
`;

const DemoModeInnerContainer = styled.div<{ type?: string }>`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 400px;
  min-height: 625px;
  height: auto;
  text-align: left;
  margin-bottom: 50px;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    margin-top: 0;
    margin-bottom: 0;
    min-height: 100%;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const StyledTitle = styled(Title)`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.005em;
`;

const LinkText = styled.a`
  color: ${(props) => props.theme.main.black};
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
`;

const StyledButton = styled.button`
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.005em;
  line-height: 22px;
  border-radius: 4px;
  padding: 18px;
  margin-top: 24px;
  width: 100%;
  border: 2px solid ${theme.main.midnightBlue};
  background: ${theme.main.demoModeColor};

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    font-size: 18px;
    padding: 16px;
    margin: 36px auto;
  }
`;

const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: ${(props) => props.theme.main.white};

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    font-size: 16px;
    line-height: 22px;
  }
`;
