import { Breadcrumb, EventHint } from '@sentry/react';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const shouldIgnoreError = (error: any, hint: EventHint): boolean => {
  // @ts-ignore for error
  return isRecaptchaBadTimeoutRejection(error, hint) || isNetworkError(error);
};

// https://github.com/getsentry/sentry-javascript/issues/2514
const isRecaptchaBadTimeoutRejection = (_: ErrorEvent, hint: EventHint): boolean => {
  return hint?.originalException === 'Timeout';
};

const typeErrorFetchFailedValues = new Set([
  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  'Load failed',
]);

const isNetworkError = (error: ErrorEvent): boolean => {
  // @ts-ignore for error
  const exception = error?.exception?.values?.[0];
  const now = Date?.now();

  if (exception?.type !== 'TypeError' || !typeErrorFetchFailedValues?.has(exception?.value as string)) {
    return false;
  }

  // @ts-ignore for error
  if (!error?.breadcrumbs) {
    return false;
  }

  // @ts-ignore for error
  for (let i = error?.breadcrumbs?.length - 1; i >= 0; i--) {
    // @ts-ignore for error
    const breadcrumb = error?.breadcrumbs?.[i];
    if (!breadcrumb) continue;

    // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
    if (breadcrumb?.timestamp && now - breadcrumb?.timestamp * 1000 > 5000) {
      break;
    }

    if (isErroneousBreadcrumb(breadcrumb)) {
      return true;
    }
  }

  return false;
};

const isErroneousBreadcrumb = (breadcrumb: Breadcrumb): boolean => {
  if (breadcrumb?.level !== 'error' || (breadcrumb?.category !== 'xhr' && breadcrumb?.category !== 'fetch')) {
    return false;
  }

  const url = breadcrumb?.data?.url as string | undefined;
  if (!url) return false;

  return url === 'urlThatIsOftenBlocked' || url?.startsWith('startOfUrlThatIsOftenBlocked');
};
