import { gql } from '@apollo/client/core';

export const ADD_PAYMENT_METHOD = gql`
  mutation addPaymentMethod($input: AddPaymentMethodInput) {
    addPaymentMethod(input: $input) {
      firstName
      lastName
      zip
      status
      success
      type
      storedCard {
        network
        type
        last4
        expireMonth
        expireYear
        status
        id
        createdAt
      }
      rccAccount {
        accountNumber
        routingNumber
        bankName
        createdAt
        id
      }
      achAccount {
        accountNumber
        accountType
        bankName
        createdAt
        id
      }
      id
      createdAt
    }
  }
`;

export const SAVE_DOWN_PAYMENT = gql`
  mutation saveDownPayment($input: SaveDownPaymentInput) {
    saveDownPayment(input: $input) {
      applicationId
      menuId
      moduleString
      term
      promoTerm
      apr
      promoApr
      fallbackApr
      maxEligible
      minEligible
      creditLineMaxEligible
      mdf
      promoMdf
      downPaymentType
      downPayment
      chosenDownPaymentAmount
      periodLength
      tierId
      parentId
      id
      createdAt
      status
    }
  }
`;

export const CONFIRM_POINT_OF_SALE = gql`
  mutation confirmPointOfSale($input: ConfirmPointOfSaleInput) {
    confirmPointOfSale(input: $input) {
      id
      organizationId
    }
  }
`;

export const INTENT_POINT_OF_SALE = gql`
  mutation intentPointOfSale($input: IntentPointOfSaleInput) {
    intentPointOfSale(input: $input) {
      clientSecret
    }
  }
`;

export const UPDATE_POINT_OF_SALE_STATUS = gql`
  mutation UpdatePointOfSaleStatus($input: UpdatePointOfSaleStatusInput) {
    updatePointOfSaleStatus(input: $input) {
      id
      organizationId
    }
  }
`;

export const UPDATE_PAYMENT_METHOD_ADDRESS = gql`
  mutation updatePaymentMethodAddress($input: UpdatePaymentMethodsAddressInput) {
    updatePaymentMethodAddress(input: $input) {
      borrowerId
      zip
      addressLine1
      addressLine2
      city
      state
      status
      type
      id
    }
  }
`;
