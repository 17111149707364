import { useCallback, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { UPDATE_BORROWER } from 'lib/graphql/mutations';

import useStore from 'lib/hooks/useStore';
import { WEBPREQUAL } from 'lib/constants';
import { useSentry } from 'lib/hooks/useSentry';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { useSegment } from 'lib/hooks/useSegment';
import { emailPattern } from 'lib/utils/Validators';
import { useNavigation } from 'lib/hooks/useNavigation';

import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import AlleEmailCollection from './AlleEmailCollection';
import { useTranslation } from 'react-i18next';

interface State {
  email: string;
}

const EmailCollection = () => {
  const alert = useAlert();
  const { initPage } = useNeuroId();
  const { trackPage } = useSegment();
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const [searchParams] = useSearchParams();
  const { t: translate } = useTranslation();
  const { setPageIndex, setBorrower, isAlle } = useStore();

  const [updateBorrowerEmail, { loading }] = useMutation(UPDATE_BORROWER);

  const type = searchParams.get('type');

  useEffect(() => {
    setPageIndex(4);
  }, [setPageIndex]);

  useEffect(() => {
    initPage('email-collection');
  }, []);

  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
  } = useForm<State>({
    mode: 'onChange',
  });

  const onSubmit = async (formData: State) => {
    try {
      const { borrower, incomeVerification } = useStore.getState() || {};

      trackPage('emailCollection', {
        application: WEBPREQUAL,
        borrowerId: borrower?.id,
        email: formData?.email,
      });

      if (formData?.email) {
        const { data } = await updateBorrowerEmail({
          variables: { input: { email: formData?.email, id: borrower?.id, source: 'GREET' } },
        });

        if (data?.updateBorrower?.success) {
          setBorrower({ ...borrower, email: formData?.email });
          const route = type === 'paystubs' ? 'paystubs-file-upload' : 'payrolls-file-upload';
          navigate(`${route}/${incomeVerification?.id}`);
        } else {
          alert.info(translate('emailCollection.error.message'));
        }
      } else {
        alert.info(translate('emailCollection.error.info'));
      }
    } catch (err) {
      captureException(err, { message: 'Email Collection - Update Borrower Info', email: formData?.email });
    }
  };

  const checkKeyDown = (e) => {
    if (e?.code === 'Enter' || Number(e?.keyCode) === 13) {
      e?.preventDefault();
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const handleClick = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  if (isAlle) {
    return (
      <AlleEmailCollection
        control={control}
        loading={loading}
        isValid={isValid}
        handleClick={handleClick}
        checkKeyDown={checkKeyDown}
      />
    );
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'mail'} />
        <Title m={'10px 0px 8px 0px'}>{translate('emailCollection.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('emailCollection.subTitle')}</Subtitle>
        <Box>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: emailPattern,
                message: translate('emailCollection.error.rule'),
              },
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="email-input"
                  variant="filled"
                  data-testid="email"
                  data-neuro-label="email"
                  label={translate('emailCollection.form.label')}
                  type="email"
                  onKeyDown={checkKeyDown}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              );
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={!isValid || loading} loading={loading} onClick={handleClick}>
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default EmailCollection;
