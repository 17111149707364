import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient, useMutation } from '@apollo/client';

import { WEBPREQUAL } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';

import { FETCH_CREDIT_LINE_INCREASES, FETCH_LATEST_CREDIT_LINE_INCREASE } from 'lib/graphql/queries';
import { CREATE_CREDIT_LINE_INCREASE } from 'lib/graphql/mutations';

export enum CreditLineIncreaseStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  MISSING_INFO = 'MISSING_INFO',
  REVIEW = 'REVIEW',
}

export enum CreditLineIncreaseSubStatus {
  MORE = 'MORE',
  PARTIAL = 'PARTIAL',
  ERROR = 'ERROR',
  FRAUD = 'FRAUD',
  MISSING_INFO = 'MISSING_INFO',
  PROCESSING = 'PROCESSING',
  DENIED = 'DENIED',
  APPROVED = 'APPROVED',
}

interface StatedInfos {
  type: string;
  value: string;
}

export const useCreditLineIncrease = () => {
  const { trackSegmentEvent } = useSegment();
  const navigate = useNavigate();
  const client = useApolloClient();

  const { application, authData, borrower, setAppLoading, setSessionApiData, setCliMissingInfo } = useStore();
  const { slug } = authData || {};

  const [loading, setLoading] = useState(false);
  const [showCreditLineIncreaseModal, setShowCreditLineIncreaseModal] = useState(false);
  const [creditLineIncrease, setCreditLineIncrease] = useState();

  const [fetchLoading, setFetchLoading] = useState(false);
  const [createLineIncrease] = useMutation(CREATE_CREDIT_LINE_INCREASE);
  const [createLoading, setCreateLoading] = useState(false);

  const getNavigatingUrl = (key) => {
    switch (key) {
      case 'EMAIL':
        return 'email';
      case 'HOUSING_PAYMENT':
        return 'housing-rent';
      case 'STATED_INCOME':
        return 'income';
    }
  };

  const trackEvent = (cli: { status: CreditLineIncreaseStatus; subStatus: CreditLineIncreaseSubStatus }) => {
    let eventName = '';
    let lineIncreaseOutcome = '';
    if (cli.status === CreditLineIncreaseStatus.APPROVED && cli.subStatus === CreditLineIncreaseSubStatus.APPROVED) {
      eventName = 'line_increase_approved';
      lineIncreaseOutcome = 'Full Approval';
    } else if (
      cli.status === CreditLineIncreaseStatus.APPROVED &&
      cli.subStatus === CreditLineIncreaseSubStatus.PARTIAL
    ) {
      eventName = 'line_increase_partially_approved';
      lineIncreaseOutcome = 'Partial Approval';
    } else if (cli.status === CreditLineIncreaseStatus.APPROVED && cli.subStatus === CreditLineIncreaseSubStatus.MORE) {
      eventName = 'line_increase_approved_more';
    } else if (cli.status === CreditLineIncreaseStatus.DENIED) {
      eventName = 'line_increase_denied';
      lineIncreaseOutcome = 'Denial';
    } else if (cli.status === CreditLineIncreaseStatus.REVIEW) {
      eventName = 'line_increase_review';
      lineIncreaseOutcome = 'Manual Review';
    }

    trackSegmentEvent(eventName, {
      borrowerId: borrower?.id,
      applicationId: application?.id,
      application: WEBPREQUAL,
      lineIncreaseOutcome,
    });
  };

  const pollLatestCreditLineIncrease = async (source: string | null = null) => {
    try {
      setLoading(true);
      const latestCreditLineIncrease = await fetchLatestCreditLineIncrease(application?.id);
      if (latestCreditLineIncrease?.id) {
        setCreditLineIncrease(latestCreditLineIncrease);
        const missingInfo = latestCreditLineIncrease?.missingInfos?.map((mInfo) => {
          return { key: mInfo, value: null, isCompleted: false };
        });
        setSessionApiData({ creditLineIncrease: latestCreditLineIncrease });
        setCliMissingInfo({ missingInfo });
        if (
          [
            CreditLineIncreaseStatus.APPROVED,
            CreditLineIncreaseStatus.DENIED,
            CreditLineIncreaseStatus.REVIEW,
          ].includes(latestCreditLineIncrease?.status) &&
          ![CreditLineIncreaseSubStatus.PROCESSING, CreditLineIncreaseSubStatus.MISSING_INFO].includes(
            latestCreditLineIncrease?.subStatus,
          )
        ) {
          if (
            latestCreditLineIncrease?.status === CreditLineIncreaseStatus.APPROVED &&
            latestCreditLineIncrease?.subStatus === CreditLineIncreaseSubStatus.MORE &&
            source === 'APPROVAL_SCREEN'
          ) {
            setAppLoading(false);
            navigate(`/${slug}/request-increase`);
          } else if (
            latestCreditLineIncrease?.status === CreditLineIncreaseStatus.REVIEW &&
            latestCreditLineIncrease?.subStatus === 'PAYROLL' &&
            application?.mickeyMouse
          ) {
            navigate(`/${slug}/plaid-payrolls-complete`);
          } else if (
            latestCreditLineIncrease?.status === CreditLineIncreaseStatus.REVIEW &&
            latestCreditLineIncrease?.subStatus === 'PAYSTUB' &&
            application?.mickeyMouse
          ) {
            navigate(`/${slug}/plaid-paystubs-complete`);
          } else {
            trackEvent(latestCreditLineIncrease);
            setAppLoading(false);
            navigate(`/${slug}/approval`);
            setLoading(false);

            if (latestCreditLineIncrease?.status === CreditLineIncreaseStatus.REVIEW) {
              setShowCreditLineIncreaseModal(true);
            }
          }
        } else if (latestCreditLineIncrease.subStatus === CreditLineIncreaseSubStatus.PROCESSING) {
          setTimeout(() => pollLatestCreditLineIncrease(), 5000);
        } else if (
          latestCreditLineIncrease?.status === CreditLineIncreaseStatus.MISSING_INFO &&
          latestCreditLineIncrease?.subStatus === CreditLineIncreaseSubStatus.MISSING_INFO
        ) {
          setSessionApiData({ creditLineIncrease: latestCreditLineIncrease });
          const firstMissingInfo = latestCreditLineIncrease?.missingInfos?.[0];
          setAppLoading(false);

          navigate(`/${slug}/additional-info/${getNavigatingUrl(firstMissingInfo)}`);
        } else {
          setTimeout(() => pollLatestCreditLineIncrease, 5000);
        }
      } else {
        setAppLoading(false);
        navigate(`/${slug}/request-increase`);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const fetchCreditLineIncreases = async (applicationId) => {
    try {
      setFetchLoading(true);
      const { data } = await client.query({
        query: FETCH_CREDIT_LINE_INCREASES,
        variables: { input: { applicationId } },
      });
      setFetchLoading(false);
      return data?.fetchCreditLineIncreases;
    } catch (e) {
      setFetchLoading(false);
    }
  };

  const fetchLatestCreditLineIncrease = async (applicationId) => {
    try {
      setFetchLoading(true);
      const { data } = await client.query({
        query: FETCH_LATEST_CREDIT_LINE_INCREASE,
        variables: { input: { applicationId } },
      });
      setFetchLoading(false);
      return data?.fetchLatestCreditLineIncrease;
    } catch (e) {
      setFetchLoading(false);
    }
  };

  const createLineExpansion = async (requestedAmount, applicationId, statedInfos?: StatedInfos) => {
    try {
      setCreateLoading(true);
      setAppLoading(true);
      const { data } = await createLineIncrease({
        variables: { input: { requestedAmount, applicationId, channel: 'WPQ', statedInfos } },
      });
      const missingInfo = data?.creditLineIncrease?.missingInfos?.map((mInfo) => {
        return { key: mInfo, value: null, isCompleted: false };
      });
      setCliMissingInfo({ missingInfo });
      setCreateLoading(false);
      setAppLoading(false);
      return data?.createCreditLineIncrease;
    } catch (e) {
      setCreateLoading(false);
    }
  };

  return {
    pollLatestCreditLineIncrease,
    loading,
    creditLineIncrease,
    showCreditLineIncreaseModal,
    createLoading,
    createLineExpansion,
    fetchLoading,
    fetchCreditLineIncreases,
    fetchLatestCreditLineIncrease,
    setShowCreditLineIncreaseModal,
  };
};
