import * as Sentry from '@sentry/react';
import { YELLOW_AI_SECRET } from 'config';

type YmBorrower = {
  id: string;
  phone: string;
  name?: string;
  borrowerIVRToken: string;
};

interface YmConfig {
  bot: string;
  host: string;
  payload: {
    userType: string;
  };
  hideChatButton: boolean;
}

interface YellowMessengerPlugin {
  openBot: () => void;
}

declare global {
  interface Window {
    YellowMessengerPlugin: YellowMessengerPlugin;
    ymConfig: YmConfig;
  }
}

type ConfigPayload = {
  userType: 'patient';
  website: 'wpq';
  loggedIn: boolean;
  phone?: string;
  name?: string;
  borrowerIVRToken?: string;
};

/**
 * Initialize Yellow Messenger Chatbot
 */
export const initChatWidgetYellowAI = async () => {
  const payload: ConfigPayload = {
    userType: 'patient',
    website: 'wpq',
    loggedIn: false,
  };

  try {
    loadConfigs(payload);
  } catch (err) {
    Sentry.captureException(err, { level: 'error', extra: { errorContext: 'initChatWidgetYellowAI' } });
  }
};

/**
 * Update (reload) Yellow Messenger Chatbot - for verified users
 */
export const updateChatWidgetYellowAI = async (borrowerDetails: YmBorrower) => {
  const { phone, name, borrowerIVRToken } = borrowerDetails;
  const payload: ConfigPayload = {
    userType: 'patient',
    website: 'wpq',
    loggedIn: true,
    phone,
    name,
    borrowerIVRToken,
  };

  try {
    loadConfigs(payload);
  } catch (err) {
    Sentry.captureException(err, { level: 'error', extra: { errorContext: 'updateChatWidgetYellowAI' } });
  }
};

function loadConfigs(payload: ConfigPayload) {
  const ymConfig = {
    bot: YELLOW_AI_SECRET,
    host: 'https://r4.cloud.yellow.ai',
    payload: payload,
  };

  loadYellowMessenger(ymConfig);
}

function loadYellowMessenger({ bot, host, payload }): void {
  window.ymConfig = {
    bot: bot,
    host: host,
    payload: { ...payload },
    hideChatButton: true,
  };
  if (window?.YellowMessengerPlugin) {
    // eslint-disable-next-line
    // @ts-ignore
    window.YellowMessengerPlugin.init(window.ymConfig);
  } else {
    (function () {
      try {
        const w = window;
        const ic = null;

        if ('function' === typeof ic) {
          // eslint-disable-next-line
          // @ts-ignore
          ic('reattach_activator');
          // eslint-disable-next-line
          // @ts-ignore
          ic('update', window.ymConfig);
        } else {
          const d = document;
          const i = function () {
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments);
          };

          function l() {
            const e = d.createElement('script');
            e.type = 'text/javascript';
            e.async = false;
            e.src = 'https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js';
            const t = d.getElementsByTagName('script')[0];
            // eslint-disable-next-line
            // @ts-ignore
            t.parentNode.insertBefore(e, t);
          }

          i.q = [];
          i.c = function (e) {
            i.q.push(e);
          };
          // eslint-disable-next-line
          // @ts-ignore
          w.YellowMessenger = i;
          // eslint-disable-next-line
          // @ts-ignore
          if (w.attachEvent) {
            // eslint-disable-next-line
            // @ts-ignore
            d.attachEvent('onload', l);
          } else {
            l();
          }
        }
      } catch (e) {
        console.error('err', e);
      }
    })();
  }
}

export const showChatBotYellowAI = () => {
  window?.YellowMessengerPlugin?.openBot();
};
