import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Icon, SlideModal } from 'lib/components';
import { ButtonContainer } from 'lib/components/Common';
import { useViewport } from 'lib/hooks/useViewport';
import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';

interface ThanksForClickingModal {
  show: boolean;
  hideModal: () => void;
}

const ThanksForClickingModal = ({ show, hideModal }: ThanksForClickingModal) => {
  const { t: translate } = useTranslation();
  const { isMobile } = useViewport();
  const { organization } = useStore();

  const handleClick = (e) => {
    if (e?.target?.ariaHidden) {
      hideModal();
    }
  };

  return (
    <SlideModal
      handleCloseClick={handleClick}
      show={show}
      hideModal={hideModal}
      isLarge={false}
      titleTranslateKey="approvalCard.thansforClicking.title"
    >
      <>
        <PurchaseContainer>
          <IconContainer>
            <Icon src={'under_const'} width={60} height={60} />
          </IconContainer>
          <span>
            <Trans
              i18nKey={'approvalCard.thansforClicking.description'}
              components={{
                bold: <strong />,
                element: <br />,
              }}
              values={{ name: organization?.name }}
            ></Trans>
          </span>
        </PurchaseContainer>
        <CustomButtonContainer isMobile={isMobile}>
          <Button onClick={hideModal}>{translate('buttons.gotItThanks')}</Button>
        </CustomButtonContainer>
      </>
    </SlideModal>
  );
};

export default ThanksForClickingModal;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0px 16px 32px;
  color: ${(props) => props.theme.main.black};
  justify-content: center;

  span {
    color: ${theme.main.midnightBlue};
    font-size: 16px;
    font-weight: 400;
  }
`;

const CustomButtonContainer = styled(ButtonContainer)<{ isMobile: boolean }>`
  box-shadow: ${(props) => props?.isMobile && 'none'};
`;
