import { API_URL } from 'config';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { useIncomeVerificationLatest } from 'pages/PlaidAuth/services/useIncomeVerificationLatest';
import { useTranslation } from 'react-i18next';

export enum MX_ERROR_CODES {
  OAUTH_ERROR = 'OAuth',
  DENIED = '2',
  REJECTED = '4',
  LOCKED = '5',
  CANT_LOGIN = '7',
  DISCONTINUED = '11',
  MX_ERROR = '14',
}

const TIMEOUT_FOR_ERRORS = {
  [MX_ERROR_CODES.LOCKED]: 7000,
  [MX_ERROR_CODES.CANT_LOGIN]: 7000,
  [MX_ERROR_CODES.DISCONTINUED]: 5000,
  [MX_ERROR_CODES.MX_ERROR]: 5000,
};

const ONE_TIME_ALLOWED_ERRORS = [MX_ERROR_CODES.OAUTH_ERROR, MX_ERROR_CODES.MX_ERROR];

const MXAuth = () => {
  const { navigate, navigateBack } = useNavigation();
  const { loanId } = useParams();
  const { getIncomeVerificationLatest } = useIncomeVerificationLatest();
  const { t: translate } = useTranslation();

  const { setAppLoading, defaultLanguage, setSessionApiData } = useStore();

  const updateErrorCountInSession = (errorStatus) => {
    const { sessionApiData } = useStore.getState();
    const { mxErrors } = sessionApiData || {};

    const updatedErrorList = [...(mxErrors || []), errorStatus];

    setSessionApiData({
      mxErrors: updatedErrorList,
    });
  };

  const isErrorOccuredBefore = (errorStatus) => {
    const { sessionApiData } = useStore.getState();
    const { mxErrors } = sessionApiData || {};

    return mxErrors.filter((error) => error === errorStatus)?.length > 1;
  };

  const handleErrors = (errorStatus) => {
    if (Object.keys(TIMEOUT_FOR_ERRORS).includes(errorStatus)) {
      setTimeout(() => {
        setAppLoading(true);

        if (errorStatus === MX_ERROR_CODES.DISCONTINUED) {
          navigate(`upload-paystub/${loanId}`);
        } else {
          navigate(`retry-mx-connect/${errorStatus}/${loanId}`);
        }
      }, TIMEOUT_FOR_ERRORS[errorStatus]);
    } else {
      if (errorStatus !== MX_ERROR_CODES.REJECTED && errorStatus !== MX_ERROR_CODES.DENIED) {
        navigate(`retry-mx-connect/${errorStatus}/${loanId}`);
      }
    }
  };

  const handleOauthError = () => {
    const { borrower } = useStore.getState() || {};

    updateErrorCountInSession(MX_ERROR_CODES.OAUTH_ERROR);
    if (isErrorOccuredBefore(MX_ERROR_CODES.OAUTH_ERROR)) {
      navigate(`upload-paystub/${loanId}`);
    } else {
      getIncomeVerificationLatest(borrower?.id);
    }
  };

  const handleMXErrors = (error) => {
    const errorStatus = error.metadata.connection_status.toString();

    const isOneTimeAllowedError = ONE_TIME_ALLOWED_ERRORS.includes(errorStatus);

    if (isOneTimeAllowedError) {
      updateErrorCountInSession(errorStatus);

      if (isErrorOccuredBefore(errorStatus)) {
        navigate(`upload-paystub/${loanId}`);
      } else {
        handleErrors(errorStatus);
      }
    } else {
      handleErrors(errorStatus);
    }
  };

  useEffect(() => {
    setAppLoading(true);
    window?.addEventListener('message', receiveMessage, false);
    return () => {
      setAppLoading(false);
      window?.removeEventListener('message', receiveMessage);
    };
  }, []);

  const receiveMessage = async ({ origin, data }) => {
    if (origin === API_URL) {
      const { application, borrower } = useStore.getState() || {};

      const iFrameEvent = JSON.parse(data);

      switch (iFrameEvent.event) {
        case 'expired':
          await getIncomeVerificationLatest(borrower?.id);
          break;
        case 'complete':
          setAppLoading(true);
          getIncomeVerificationLatest(borrower?.id);
          break;
        case 'institution-complete':
          setAppLoading(true);
          if (iFrameEvent?.data?.isInstitutionFound) {
            getIncomeVerificationLatest(borrower?.id);
          } else {
            navigate(`upload-paystub/${application?.id}`);
          }
          break;
        case 'loading':
          setAppLoading(true);
          break;
        case 'loaded':
          setAppLoading(false);
          break;
        case 'mx-event':
          break;
        case 'mx-error':
          handleMXErrors(iFrameEvent?.data);
          break;
        case 'mx-oauthError':
          handleOauthError();
          break;
        case 'close':
          navigateBack();
          break;
      }
    }
  };

  return (
    <Container>
      <iframe
        id="add-mx-auth"
        data-testid="the-frame"
        title={translate('MXAuth.title')}
        width="100%"
        height="100%"
        frameBorder="0"
        src={`${API_URL}/v1/asset-reports/mx-iframe/${loanId}?language=${defaultLanguage}`}
        data-hj-allow-iframe=""
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    height: calc(100vh - 90px);
  }

  iframe {
    height: 100%;
    width: 100%;
  }
`;

export default MXAuth;
