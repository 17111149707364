import styled from 'styled-components';
import dayjs from 'dayjs';

import { AlleContainer } from 'lib/components/Common';
import { AlleHeader, Button, HaveQuestionsModal, Title } from 'lib/components';
import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { calculatePaymentFeeAmount, FormatAmount } from 'lib/utils';
import AllePaymentMethodCard from './components/AllePaymentMethodCard';
import { DATE_FORMAT } from 'lib/constants';

const AllePaymentMethodList = ({
  checkingACHMethodContents,
  savingACHMethodContents,
  debitCardMethodContents,
  bankRCCMethodContents,
  creditCardMethodContents,
  selectPaymentMethodHandler,
  paymentMethods,
  installmentMethod,
  isPreferredType,
  loading,
  navigateToPlanSummary,
  addPaymentMethodHandler,
}) => {
  const { selectedPlan, paymentMethodFees, applicationRegulations } = useStore();
  const startingDate = dayjs(selectedPlan?.paymentDates?.[0]).format(DATE_FORMAT);
  const creditCardFee = paymentMethodFees?.CREDIT?.percent;
  const originator = applicationRegulations?.originatingEntity || 'Cherry Technologies Inc.';

  const downPaymentFee = paymentMethodFees?.[installmentMethod?.storedCard?.type || '']?.percent || 0;
  const downPaymentFeeAmount =
    installmentMethod?.type === 'CARD' && downPaymentFee > 0
      ? calculatePaymentFeeAmount(
          paymentMethodFees,
          installmentMethod?.storedCard?.type,
          selectedPlan?.downPaymentAmount,
        )
      : 0;

  return (
    <AlleContainer>
      <AlleHeader />
      <Title color={theme.main.black} fontSize="18px" fontWeight={500} fontFamily="Graphik" lineHeight="32px">
        Next, choose how to pay your future recurring payments
      </Title>
      <DueContainer>
        <DueLabel>DUE LATER</DueLabel>
        <DuePrice>{FormatAmount(selectedPlan?.installmentAmount)}</DuePrice>
        <DueText>
          {selectedPlan?.product?.term} payments of <b>{FormatAmount(selectedPlan?.installmentAmount)}</b>
        </DueText>
        <DueText>starting {startingDate}</DueText>
        {installmentMethod?.type === 'CARD' && downPaymentFeeAmount > 0 && (
          <DueText>
            plus <b>{FormatAmount(downPaymentFeeAmount)}</b> credit card fee ({creditCardFee}%)
          </DueText>
        )}
      </DueContainer>

      {paymentMethods?.length > 0 ? <PaymentTitle>Select a payment method for recurring payments:</PaymentTitle> : null}

      <>
        {checkingACHMethodContents()?.map((paymentMethod, index) => (
          <AllePaymentMethodCard
            key={`CHECKING-ACH-${index}-${paymentMethod.id}`}
            paymentMethod={paymentMethod}
            selectPaymentMethod={selectPaymentMethodHandler}
            selectedPaymentMethod={installmentMethod}
            isPreferred={isPreferredType === 'checking'}
          />
        ))}

        {debitCardMethodContents()?.map((paymentMethod, index) => (
          <AllePaymentMethodCard
            key={`DEBIT-CARD-${index}-${paymentMethod.id}`}
            paymentMethod={paymentMethod}
            selectPaymentMethod={selectPaymentMethodHandler}
            selectedPaymentMethod={installmentMethod}
            isPreferred={isPreferredType === 'debit'}
          />
        ))}

        {savingACHMethodContents()?.map((paymentMethod, index) => (
          <AllePaymentMethodCard
            key={`SAVING-ACH-${index}-${paymentMethod.id}`}
            paymentMethod={paymentMethod}
            selectPaymentMethod={selectPaymentMethodHandler}
            selectedPaymentMethod={installmentMethod}
            isPreferred={isPreferredType === 'savings'}
          />
        ))}

        {bankRCCMethodContents()?.map((paymentMethod, index) => (
          <AllePaymentMethodCard
            key={`RCC-${index}-${paymentMethod.id}`}
            paymentMethod={paymentMethod}
            selectPaymentMethod={selectPaymentMethodHandler}
            selectedPaymentMethod={installmentMethod}
            isPreferred={false}
          />
        ))}

        {creditCardMethodContents()?.map((paymentMethod, index) => (
          <AllePaymentMethodCard
            key={`CREDIT-CARD-${index}-${paymentMethod.id}`}
            paymentMethod={paymentMethod}
            selectPaymentMethod={selectPaymentMethodHandler}
            selectedPaymentMethod={installmentMethod}
            isPreferred={false}
          />
        ))}
      </>

      <ButtonContainer>
        <Disclaimer>
          I authorize {originator} to charge my account, debit card, or credit card on file for the monthly recurring
          payment of {FormatAmount(selectedPlan?.installmentAmount)} starting {startingDate}. I understand that if a
          credit card is used, a 2.99% processing fee will be applied, plus any other fees assessed to my account.
        </Disclaimer>
        <Button
          onClick={navigateToPlanSummary}
          disabled={!installmentMethod || loading}
          loading={loading}
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
        >
          Agree and Continue
        </Button>
        <Button
          secondary={true}
          onClick={addPaymentMethodHandler}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
        >
          Add Recurring Payment Method
        </Button>
      </ButtonContainer>
      <HaveQuestionsModal />
    </AlleContainer>
  );
};

export default AllePaymentMethodList;

const DueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f6f5;
  padding: 24px;
  margin: 16px 0px 40px;
`;

const DueLabel = styled.span`
  font-family: 'Graphik', sans-serif;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 12px;
`;
const DuePrice = styled.span`
  font-family: Petersburg, sans-serif;
  font-size: 46px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const DueText = styled.span`
  font-family: 'Graphik', sans-serif;
  font-size: 14px;
  font-weight: 400;
`;

const PaymentTitle = styled.span`
  font-family: 'Graphik', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
  text-align: left;
  color: ${(props) => props.theme.main.slateGray};
`;

const ButtonContainer = styled.div`
  margin-bottom: 32px;
`;

const Disclaimer = styled.div`
  font-family: 'Graphik', sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  margin: 12px 0 24px 0;
`;
