import { gql } from '@apollo/client/core';

export const CREATE_CREDIT_LINE_INCREASE = gql`
  mutation createCreditLineIncrease($input: CreateApplicationCreditLineIncreaseInput) {
    createCreditLineIncrease(input: $input) {
      id
      amount {
        eligible
        approved
        requested
      }
      missingInfos
      status
      subStatus
      statedInfos {
        type
        value
      }
    }
  }
`;
