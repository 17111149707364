import React, { useEffect, useRef, useState } from 'react';

import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { TRACK_VERSION, WEBPREQUAL } from 'lib/constants';

import { Button, Container, Icon, StepperComponent, Subtitle, Title } from 'lib/components';
import { FaqManualReviewList } from 'pages/Faq/components';
import AlleApproval from 'pages/Approval/AlleApproval';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { theme } from 'config/theme';
import styled from 'styled-components';
import { useViewport } from 'lib/hooks/useViewport';
import ExpediteModal from './modals/ExpediteModal';
import dayjs from 'dayjs';

const ApplicationReview = () => {
  const { trackPage, trackSegmentEvent } = useSegment();
  const { t: translate } = useTranslation();
  const { isAlle } = useStore();
  const { sendLoadSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { isMobile } = useViewport();
  const [expediteReviewVisibility, setExpediteReviewVisibility] = useState(false);
  const [isExpediteModalShown, setIsExpediteModalShown] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const hideExpediteReviewModal = () => setExpediteReviewVisibility(false);

  const showExpediteReviewModal = () => {
    setExpediteReviewVisibility(true);
    localStorage?.setItem('isUserClickedExpedite', 'true');
    setIsExpediteModalShown(true);
  };

  const steps = [
    {
      label: translate('applicationReview.received.title'),
      description: translate('applicationReview.received.description'),
    },
    {
      label: translate('applicationReview.review.title'),
      description: translate('applicationReview.review.description'),
    },
    {
      label: translate('applicationReview.evaluating.title'),
      description: translate('applicationReview.evaluating.description'),
    },
    {
      label: translate('applicationReview.decision.title'),
      description: ``,
    },
  ];
  useEffect(() => {
    const { application } = useStore.getState() || {};
    const expediteStatus = localStorage?.getItem('isUserClickedExpedite');
    setIsExpediteModalShown(Boolean(expediteStatus));
    trackPage('manualReviewLanding', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
      applicationId: application?.id,
    });

    trackSegmentEvent('manualReviewLanding', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
      applicationId: application?.id,
    });

    const diffFromCreatedTime = dayjs().diff(application?.createdAt, 'hour');
    if (diffFromCreatedTime < 3) {
      setActiveStep(0);
      sendLoadSegmentEvent(WPQSegmentNames.manualReviewTrackerApplicationReceived);
    } else if (diffFromCreatedTime < 10) {
      sendLoadSegmentEvent(WPQSegmentNames.manualReviewTrackerAssignedForReview);
      setActiveStep(1);
    } else {
      sendLoadSegmentEvent(WPQSegmentNames.manualReviewTrackerEvaulating);
      setActiveStep(2);
    }
  }, []);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.manualReviewLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  if (isAlle) {
    return <AlleApproval />;
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer isMobile={isMobile}>
        <Icon src={'glasses-review'} />

        <Title m={'10px 0px 8px 0px'}>{translate('applicationReview.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'} color={theme.main.midnightBlue}>
          {translate('applicationReview.subTitle')}
        </Subtitle>
        <StepperContainer>
          <StepperComponent activeStep={activeStep} steps={steps} />
          {/* <ComingUpSoon>{translate('applicationReview.comingUp')}</ComingUpSoon>
          <Button onClick={showExpediteReviewModal} disabled={isExpediteModalShown}>
            {translate('applicationReview.button.expedite')}
          </Button> */}
        </StepperContainer>
        <FaqManualReviewList />
        {expediteReviewVisibility ? (
          <ExpediteModal show={expediteReviewVisibility} hideModal={hideExpediteReviewModal} />
        ) : null}
      </ContentContainer>
    </Container>
  );
};

export default React.memo(ApplicationReview);

const ComingUpSoon = styled.div`
  color: ${theme.main.black};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 16px 0px;
  width: 100%;
`;

const StepperContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 1.23656px 2.30138px 0 rgba(0, 0, 0, 0.02), 0 3.12736px 5.82036px 0 rgba(0, 0, 0, 0.03),
    0 6.37951px 11.87298px 0 rgba(0, 0, 0, 0.04), 0 13.14059px 24.4561px 0 rgba(0, 0, 0, 0.05),
    0 6px 12px 0 rgba(0, 0, 0, 0.07);
`;

const ContentContainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 0px 24px 20px 24px;
  margin-top: 0px;
  padding-top: 15px;
  background-color: ${(props) => (props?.isMobile ? theme.main.whiteGray : theme.main.white)};
`;
