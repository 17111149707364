import { useApolloClient, useMutation } from '@apollo/client';
import { PATCH_LOANS } from 'lib/graphql/mutations';

import { GET_PAYMENT_METHODS } from 'lib/graphql/queries';
import { PaymentMethodSearch } from 'lib/graphql/searches';
import useStore from 'lib/hooks/useStore';
import * as Sentry from '@sentry/react';

export const usePlans = () => {
  const client = useApolloClient();
  const { setPaymentMethods } = useStore();

  const [patchLoanRequest] = useMutation(PATCH_LOANS);

  const getPaymentMethods = async () => {
    const { borrower } = useStore.getState() || {};

    const { data } = await client.query({
      query: GET_PAYMENT_METHODS,
      variables: {
        input: {
          borrowerId: borrower?.id,
          search: PaymentMethodSearch,
        },
      },
    });

    if (data?.getPaymentMethods?.contents) {
      setPaymentMethods(data?.getPaymentMethods?.contents);
      return data?.getPaymentMethods;
    } else {
      Sentry.captureException('Get Payment Methods Error', {
        level: 'error',
        extra: {
          errorMessage: 'Get Payment Methods Error',
          page: 'src/pages/Plans/services/usePlans.tsx',
          id: borrower?.id || 'No Borrower',
          search: PaymentMethodSearch,
        },
      });
      return [];
    }
  };

  const patchLoan = async (params) => {
    const { data } = await patchLoanRequest({ variables: { input: { ...params } } });

    return data.patchLoan;
  };

  return { getPaymentMethods, patchLoan };
};
