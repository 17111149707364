import { GenericSearchOperators } from 'lib/types';

export const BorrowerIncomeVerificationSearch = (borrowerId, applicationId) => {
  return [
    {
      key: 'borrowerId',
      value: String(borrowerId),
      operator: GenericSearchOperators.EQUAL,
    },
    {
      key: 'applicationId',
      value: String(applicationId),
      operator: GenericSearchOperators.EQUAL,
    },
  ];
};
