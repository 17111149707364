import styled from 'styled-components';

import { Button, Container, Icon, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useTranslation } from 'react-i18next';

const VerifyIncomeSuccess = () => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();

  const continueHandler = () => {
    navigate(`find-bank-account`);
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <IconContainer>
          <Icon src={'cherry-logo'} width={24} height={24} />
          <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
          <Icon m={'0px 4px'} width={4} height={4} src={'dot'} />
          <Icon m={'0px 12px 0px 4px'} width={4} height={4} src={'dot'} />
          <Icon src={'bank'} />
        </IconContainer>
        <Title m={'10px 0px 24px 0px'}>{translate('verifyIncomeSuccess.title')}</Title>
        <SuccessIconContainer>
          <Icon src={'circle_check_outline'} width={80} height={80} m="48px 0px 0px" />
        </SuccessIconContainer>
      </ContentContainer>

      <ButtonContainer>
        <Button onClick={continueHandler}>{translate('buttons.continue')}</Button>
      </ButtonContainer>
    </Container>
  );
};

export default VerifyIncomeSuccess;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SuccessIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;
