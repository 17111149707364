import { formatAmount } from '@frontend/cherry-library';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  debounce,
  Skeleton,
  SkeletonProps,
  Stack,
  StackProps,
  TextField,
  Typography,
  TypographyProps,
} from '@mui/material';
import styled from 'styled-components';
import { ReactComponent as CherryLogo } from 'assets/icons/cherry-icon.svg';
import { ReactComponent as CherryWordmark } from 'assets/icons/cherry-wordmark.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as AlertIcon } from 'assets/icons/alert-icon.svg';
import { styled as muiStyled } from '@mui/system';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { IPlan } from 'pages/Plans/types';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import useStore from 'lib/hooks/useStore';
import dayjs from 'dayjs';
import {
  ButtonClickedEventActions,
  IframeEventTypes,
  PageEventActions,
  useIframeEvents,
} from 'lib/hooks/useIframeEvents';

const ContinueButton = muiStyled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 12,
  padding: '16px 16px',
  fontFamily: 'Graphik',
  lineHeight: 1.5,
  backgroundColor: '#090909',
  textAlign: 'center',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#090909',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#090909',
  },
  '&:focus': {},
});

interface FaqItem {
  question: string;
  answer: string;
  id: number;
}

const fakeProductPlans = [3, 6, 12, 18, 24].map((term, index) => ({
  product: {
    id: index,
    term,
  },
}));

const faqItems: FaqItem[] = [
  {
    id: 1,
    question: 'What if I don’t know the purchase price of my desired product or treatment?',
    answer:
      'Not a problem! Find an <a href="https://alle.com//search?moreFilters=offersFinancing">Allē Provider</a> that accepts Payment Plans using the "Pay Over Time" filter and they will offer you a quote. Treatment is individualized, so price may vary, but your provider will confirm the price of treatment before you check out.You can also view a range of procedure costs by browsing our <a href="https://alle.com/our-brands">Allē treatment guides</a>.',
  },
  {
    id: 2,
    question: 'How do I let a provider know that I want to use a payment plan?',
    answer: `Just book an appointment and tell your provider that you’d like to pay for treatment with Allē Payment Plans powered by Cherry. (Now that you've been approved, your provider will be able to view your approved balance.)<n />Please note: per state laws, customers who live in AL, GA, HI, LA, MD, NV, and ND are unable to pay for treatments using Payment Plans at out-of-state providers.`,
  },
  {
    id: 3,
    question: 'How can I set up an appointment?',
    answer:
      'First, use the <a href="https://alle.com//search?moreFilters=offersFinancing">Allē Provider Directory</a> to find a practice that lets patients pay over time. Then, reach out to that practice directly and schedule an appointment to move forward with confirming your contract.',
  },
  {
    id: 4,
    question: 'Can I pay my balance off early?',
    answer:
      'You can. Cherry doesn’t have any prepayment penalties. In fact, paying early means you may be able to avoid paying some of the interest that can accrue over the life of the contract.',
  },
  {
    id: 5,
    question: 'What is the finance charge?',
    answer:
      'The finance charge is the total dollar amount the payment plan will cost if all payments are made on time. The amount is already included in your monthly payment.',
  },
  {
    id: 6,
    question: 'Who do I contact for support?',
    answer: `If you experience any technical difficulties with Allē, or have issues with:<ul><li>Allē Wallet</li><li>Allē for Business platform</li><li>Allē Membership or account</li><li>Allē checkout</li></ul>Please contact Allē customer support at 888.912.1572, Monday–Friday, 8 AM–6 PM CT.
If you experience issues with:<ul><li>Eligibility criteria and declined applications</li><li>Information on application, including rates, fees, and charges</li><li>Completing payments</li></ul>Please contact Cherry Support at 888.612.2142, Monday–Friday, 8 AM–8 PM CT, or Saturday, 8 AM–5 PM CT.`,
  },
];

export default function AllePaymentEstimator() {
  const { pushEvent } = useIframeEvents();
  const { getProductCalculation } = useProductCalculation();
  const [treatmentCost, setTreatmentCost] = useState<string>('');
  const [selectedPlanIndex, setSelectedPlanIndex] = useState<number>(0);
  const [productPlans, setProductPlans] = useState<IPlan[] | null>([]);
  const treatmentCostNumericRef = useRef<number>(1000);
  const { application } = useStore();

  const selectedProductPlan = productPlans?.[selectedPlanIndex];
  const isProductPlansLoading = productPlans === null;
  const hasFoundAvailablePlans = (productPlans ?? [])?.length > 0;

  const calculateProduct = useCallback(
    debounce(async () => {
      const response = await getProductCalculation(application?.id, treatmentCostNumericRef.current);
      setProductPlans(response);
    }, 500),
    [application],
  );

  const isTreatmentCostValid = () => {
    return (
      !isNaN(treatmentCostNumericRef.current) &&
      treatmentCostNumericRef.current >= 200 &&
      treatmentCostNumericRef.current <= application?.maxEligible
    );
  };

  useEffect(() => {
    pushEvent(IframeEventTypes.PAGE, PageEventActions.APPROVAL_PAGE);
  }, []);

  const addCommaBetweenThousands = (value: number | string) => {
    return Intl.NumberFormat('en-US').format(parseInt(value.toString(), 10));
  };

  const onTreatmentCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProductPlans(null);
    const inputValue = event.target.value;
    const inputValueWithoutNonDecimalCharacters = inputValue.replace(/\D+/g, '');
    treatmentCostNumericRef.current = parseInt(inputValueWithoutNonDecimalCharacters, 10);
    const inputNumberWithCommas = addCommaBetweenThousands(inputValueWithoutNonDecimalCharacters);
    setTreatmentCost(inputValueWithoutNonDecimalCharacters.length > 0 ? `$${inputNumberWithCommas}` : '');
    if (!isTreatmentCostValid()) {
      setProductPlans([]);
      return;
    }
    calculateProduct();
  };

  const createOnSelectPlanHandler = (index: number) => () => {
    setSelectedPlanIndex(index);
  };

  const onContinueClick = () => {
    pushEvent(IframeEventTypes.BUTTON_CLICKED, ButtonClickedEventActions.PAYMENT_ESTIMATOR_CONTINUE_BUTTON_CLICKED);
  };

  return (
    <AlleContainer gap={5}>
      <AlleCard>
        <AlleCardIcon width={280} height={280}></AlleCardIcon>
        <AlleCardContent direction="row" justifyContent="space-between" alignItems="center">
          <AlleCardInfo>
            <AlleCardAmount fontSize={24}>{formatAmount(application?.balanceAvailable)}</AlleCardAmount>
            <AlleCardAvailableText fontSize={12}>
              Available Until {dayjs(application?.expireAt).subtract(6, 'hours').format('MMM DD')}
            </AlleCardAvailableText>
          </AlleCardInfo>
          <AlleCardPoweredByContainer gap={0.5}>
            <AlleCardPoweredByLabel fontSize={8}>Powered By</AlleCardPoweredByLabel>
            <AlleCardPoweredByContent gap={0.5} direction="row" alignItems="center">
              <AlleCardCherryLogo width={18} height={18}></AlleCardCherryLogo>
              <CherryWordmark width={40}></CherryWordmark>
            </AlleCardPoweredByContent>
          </AlleCardPoweredByContainer>
        </AlleCardContent>
      </AlleCard>
      <YouAreApprovedContainer gap={2}>
        <YouAreApprovedTitle fontSize={24}>You’re approved!</YouAreApprovedTitle>
        <YouAreApprovedSubtitle fontSize={16}>
          You can now use your available balance at participating Allē providers.
        </YouAreApprovedSubtitle>
      </YouAreApprovedContainer>
      <ContinueButton onClick={onContinueClick} disableRipple variant="contained" sx={{ textTransform: 'uppercase' }}>
        Continue
      </ContinueButton>
      <PaymentEstimatorContainer gap={2}>
        <PaymentEstimatorHeaderContainer gap={1}>
          <PaymentEstimatorTitleContainer gap={0.5}>
            <PaymentEstimatorTitle fontSize={24}>Estimate monthly payments</PaymentEstimatorTitle>
            <PaymentEstimatorPartnershipContainer gap={0.5} direction="row" justifyContent="center" alignItems="center">
              <PaymentEstimatorPartnerText color="rgba(0,0,0,0.5)" fontSize={11}>
                with
              </PaymentEstimatorPartnerText>
              <PaymentEstimatorPartnerLogoContainer gap={0.2} direction="row" alignItems="flex-end">
                <PaymentEstimatorPartnerLogo width={16} height={16}></PaymentEstimatorPartnerLogo>
                <PaymentEstimatorPartnerName width={40}></PaymentEstimatorPartnerName>
              </PaymentEstimatorPartnerLogoContainer>
            </PaymentEstimatorPartnershipContainer>
          </PaymentEstimatorTitleContainer>
          <PaymentEstimatorSubtitle fontSize={16}>
            Enter your expected treatment cost to preview your monthly payment estimate.
          </PaymentEstimatorSubtitle>
        </PaymentEstimatorHeaderContainer>
        <PaymentEstimatorTreatmentCostContainer gap={1}>
          <PaymentEstimatorTreatmentCostLabel fontSize={16} fontWeight={500}>
            Treatment Cost
          </PaymentEstimatorTreatmentCostLabel>
          <PaymentEstimatorTreatmentCostInput
            onChange={onTreatmentCostChange}
            value={treatmentCost}
            placeholder="Enter treatment total"
            variant="outlined"
            InputProps={{
              endAdornment: !isTreatmentCostValid() ? <AlertIcon /> : null,
            }}
            error={!isTreatmentCostValid()}
            helperText={
              !isTreatmentCostValid() &&
              `Enter an amount between $200 and $${addCommaBetweenThousands(application?.maxEligible)}`
            }
          ></PaymentEstimatorTreatmentCostInput>
        </PaymentEstimatorTreatmentCostContainer>
        <PaymentEstimatorPlanSelectionContainer gap={1}>
          <PaymentEstimatorPlanSelectionLabel fontSize={16} fontWeight={500}>
            Payment plan duration (in months)
          </PaymentEstimatorPlanSelectionLabel>
          <PaymentEstimatorPlanSelection gap={0.5} direction="row">
            {hasFoundAvailablePlans
              ? productPlans?.map((plan, index) => (
                  <PaymentEstimatorPlanSelectionOption
                    key={plan.product.id}
                    isSelected={selectedPlanIndex === index}
                    onClick={createOnSelectPlanHandler(index)}
                    flex={1}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <GraphikTypography fontSize={14}>{plan.product.term}mo</GraphikTypography>
                  </PaymentEstimatorPlanSelectionOption>
                ))
              : fakeProductPlans.map((plan, index) => (
                  <PaymentEstimatorPlanSelectionOption
                    key={plan.product.id}
                    isSelected={selectedPlanIndex === index}
                    onClick={createOnSelectPlanHandler(index)}
                    flex={1}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <GraphikTypography fontSize={14}>{plan.product.term}mo</GraphikTypography>
                  </PaymentEstimatorPlanSelectionOption>
                ))}
          </PaymentEstimatorPlanSelection>
        </PaymentEstimatorPlanSelectionContainer>
        <PaymentEstimatorPlanCard gap={1}>
          <PaymentEstimatorPlanCardTitleContainer alignItems="center">
            {isProductPlansLoading ? (
              <PaymentEstimatorSkeleton width={120} height={24} />
            ) : (
              <PaymentEstimatorPlanCardTitle fontWeight={500} fontSize={20}>
                {formatAmount(selectedProductPlan?.installmentAmount ?? 0)} per month
              </PaymentEstimatorPlanCardTitle>
            )}
          </PaymentEstimatorPlanCardTitleContainer>
          <PaymentEstimatorPlanCardItem>
            <PaymentEstimatorPlanCardItemText>Down payment</PaymentEstimatorPlanCardItemText>
            {isProductPlansLoading ? (
              <PaymentEstimatorSkeleton width={60} height={14} />
            ) : (
              <PaymentEstimatorPlanCardItemText>
                {formatAmount(selectedProductPlan?.downPaymentAmount ?? 0)}
              </PaymentEstimatorPlanCardItemText>
            )}
          </PaymentEstimatorPlanCardItem>
          <PaymentEstimatorPlanCardItem>
            <PaymentEstimatorPlanCardItemText>
              Total finance charge {!isProductPlansLoading && `(${selectedProductPlan?.product.apr ?? 0}% APR)`}
            </PaymentEstimatorPlanCardItemText>
            {isProductPlansLoading ? (
              <PaymentEstimatorSkeleton width={60} height={14} />
            ) : (
              <PaymentEstimatorPlanCardItemText>
                {formatAmount(selectedProductPlan?.financeCharge ?? 0)}
              </PaymentEstimatorPlanCardItemText>
            )}
          </PaymentEstimatorPlanCardItem>
          <PaymentEstimatorPlanCardItem>
            <PaymentEstimatorPlanCardItemText>Total cost</PaymentEstimatorPlanCardItemText>
            {isProductPlansLoading ? (
              <PaymentEstimatorSkeleton width={60} height={14} />
            ) : (
              <PaymentEstimatorPlanCardItemText>
                {formatAmount(selectedProductPlan?.totalAmount ?? 0)}
              </PaymentEstimatorPlanCardItemText>
            )}
          </PaymentEstimatorPlanCardItem>
        </PaymentEstimatorPlanCard>
        <PaymentEstimatorPreviewText fontSize={12} color="#787676">
          This is only a preview. Specific terms will be chosen later during checkout.
        </PaymentEstimatorPreviewText>
      </PaymentEstimatorContainer>
      <PaymentEstimatorFAQContainer>
        <PaymentEstimatorFAQTitle fontSize={24}>Frequently Asked Questions</PaymentEstimatorFAQTitle>
        {faqItems.map((faqItem) => (
          <PaymentEstimatorFAQItem key={faqItem.id}>
            <PaymentEstimatorFAQSummary expandIcon={<ChevronDownIcon />}>
              <FaqQuestion color="#090909" variant="h5" fontSize={16} fontWeight={500}>
                {faqItem.question}
              </FaqQuestion>
            </PaymentEstimatorFAQSummary>
            <PaymentEstimatorFAQDetails>
              <FaqAnswer variant="body1" color="#090909">
                <Trans
                  defaults={faqItem.answer}
                  components={{
                    li: <li style={{ listStyleType: 'disc' }}></li>,
                    ul: <ul />,
                    a: <FaqLink target="_blank" />,
                    n: <div style={{ height: 12 }} />,
                  }}
                />
              </FaqAnswer>
            </PaymentEstimatorFAQDetails>
          </PaymentEstimatorFAQItem>
        ))}
      </PaymentEstimatorFAQContainer>
    </AlleContainer>
  );
}

const AlleContainer = styled(Stack)`
  padding: 24px 24px 60px;
  color: #020202;
  max-width: 358px;
  margin: 0 auto;
`;

const GraphikTypography = styled((props: TypographyProps) => <Typography {...props} fontFamily="Graphik" />)``;

const PetersburgTypography = styled((props: TypographyProps) => <Typography {...props} fontFamily="Petersburg" />)``;

const AlleCard = styled(Box)`
  background: radial-gradient(65.6% 86.86% at 44.43% 100.08%, #273846 0%, #0e202f 100%);
  border-radius: 16px;
  height: 186px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding: 14px 16px;
  color: white;
`;

const AlleCardContent = styled(Stack)`
  z-index: 1;
  position: relative;
`;

const AlleCardIcon = styled(CherryLogo)`
  position: absolute;
  bottom: -84px;
  right: -72px;
  & path {
    fill: rgba(255, 255, 255, 0.08);
  }
`;

const AlleCardInfo = styled(Stack)`
  text-align: left;
`;

const AlleCardAmount = styled(GraphikTypography)``;
const AlleCardAvailableText = styled(GraphikTypography)``;

const AlleCardPoweredByContainer = styled(Stack)``;

const AlleCardPoweredByLabel = styled(GraphikTypography)`
  text-transform: uppercase;
`;
const AlleCardPoweredByContent = styled(Stack)``;

const AlleCardCherryLogo = styled(CherryLogo)`
  & path {
    fill: white;
  }
`;

const YouAreApprovedContainer = styled(Stack)`
  text-align: center;
`;
const YouAreApprovedTitle = styled(PetersburgTypography)``;
const YouAreApprovedSubtitle = styled(GraphikTypography)``;

const PaymentEstimatorContainer = styled(Stack)`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e5e0dc;
`;

const PaymentEstimatorHeaderContainer = styled(Stack)`
  text-align: center;
`;

const PaymentEstimatorTitleContainer = styled(Stack)``;

const PaymentEstimatorTitle = styled(PetersburgTypography)`
  line-height: 1.2 !important;
`;

const PaymentEstimatorSubtitle = styled(GraphikTypography)``;

const PaymentEstimatorPartnershipContainer = styled(Stack)``;

const PaymentEstimatorPartnerText = styled(GraphikTypography)``;

const PaymentEstimatorPartnerLogoContainer = styled(Stack)``;
const PaymentEstimatorPartnerLogo = styled(CherryLogo)``;
const PaymentEstimatorPartnerName = styled(CherryWordmark)`
  & path {
    fill: #020202;
  }
`;

const PaymentEstimatorTreatmentCostContainer = styled(Stack)``;

const PaymentEstimatorTreatmentCostLabel = styled(GraphikTypography)``;
const PaymentEstimatorTreatmentCostInput = styled(TextField)`
  .MuiOutlinedInput-input {
    padding: 12px 16px;
    font-size: 14px;
    font-family: Graphik, sans-serif;
    color: ${(props) => (props.error ? '#f44336' : '#020202')};
  }

  fieldset {
    border-radius: 8px;
    border: 1px solid #b3b0ae;
  }
`;

const PaymentEstimatorPlanSelectionContainer = styled(Stack)``;
const PaymentEstimatorPlanSelectionLabel = styled(GraphikTypography)``;
const PaymentEstimatorPlanSelection = styled(Stack)`
  border-radius: 12px;
  border: 1px solid #dedad7;
  background-color: #f7f6f5;
  padding: 4px 4px;
  overflow: auto;
`;
const PaymentEstimatorPlanSelectionOption = styled(Stack)<{ isSelected: boolean }>`
  background-color: ${(props) => (props.isSelected ? '#090909' : 'transparent')};
  color: ${(props) => (props.isSelected ? 'white' : '#020202')};
  font-weight: 400;
  border-radius: 8px;
  padding: 8px;
`;

const PaymentEstimatorPlanCard = styled(Stack)`
  padding: 16px;
  background-color: #f7f6f5;
  border-radius: 8px;
`;

const PaymentEstimatorPlanCardTitle = styled(GraphikTypography)``;

const PaymentEstimatorPlanCardItemText = styled((props: TypographyProps) => (
  <GraphikTypography fontSize={14} {...props} />
))``;

const PaymentEstimatorPreviewText = styled(GraphikTypography)``;

const PaymentEstimatorFAQContainer = styled(Stack)``;
const PaymentEstimatorFAQTitle = styled(PetersburgTypography)`
  text-align: center;
  padding: 24px 0;
  border-bottom: 1px solid rgba(9, 9, 9, 0.08);
`;
const PaymentEstimatorFAQItem = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;
    padding: 24px 0;
    margin: 0 !important;
  }

  &.MuiAccordion-root.Mui-expanded::before {
    opacity: 1;
  }

  .MuiAccordionSummary-root {
    padding: 0;
  }

  .MuiAccordionDetails-root {
    padding: 0;
  }
`;

const PaymentEstimatorFAQSummary = styled(AccordionSummary)``;

const PaymentEstimatorFAQDetails = styled(AccordionDetails)``;

const FaqLink = styled.a`
  color: #090909;
  text-decoration: underline;
`;

const FaqQuestion = styled(GraphikTypography)``;

const FaqAnswer = styled(GraphikTypography)``;

const PaymentEstimatorSkeleton = styled((props: SkeletonProps) => <Skeleton variant="rectangular" {...props} />)`
  border-radius: 4px;
  min-width: 60px;
`;

const PaymentEstimatorPlanCardTitleContainer = styled(Stack)``;

const PaymentEstimatorPlanCardItem = styled((props: StackProps) => (
  <Stack {...props} direction="row" alignItems="center" justifyContent="space-between" />
))``;
