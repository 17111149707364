import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ContentContainer } from 'lib/components/Common';
import { Button, HaveQuestionsModal, Icon, Subtitle, Title } from 'lib/components';
import { theme } from 'config/theme';
import { IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';
import { WEB_SITE } from 'lib/constants';
import CheckboxItem from 'pages/ConnectBankAccount/components/CheckboxItem';

type AlleGetStartedProps = {
  onContinue: () => Promise<void>;
};

const AlleGetStarted = ({ onContinue }: AlleGetStartedProps) => {
  const { pushEvent } = useIframeEvents();
  const [consent, setConsent] = useState<boolean>(false);

  useEffect(() => {
    pushEvent(IframeEventTypes.PAGE, PageEventActions.GET_STARTED);
  }, []);

  return (
    <Container>
      <StepText>STEP 1 OF 3</StepText>
      <Title
        color={theme.main.black10}
        fontSize="24px"
        fontWeight={400}
        lineHeight="32px"
        useElegantFontType={true}
        textAlign="center"
      >
        Start your application
      </Title>
      <Subtitle
        m="16px 0"
        textAlign="center"
        color={theme.main.black20}
        lineHeight="24px"
        fontFamily="Graphik"
        fontSize="16px"
      >
        In the next screens, we’ll check your eligibility for payment plans with our partner Cherry. The info you
        provide will be used to verify your identity.
      </Subtitle>
      <Subtitle
        m="16px 0"
        textAlign="center"
        color={theme.main.black20}
        lineHeight="24px"
        fontFamily="Graphik"
        fontSize="16px"
      >
        Plans available starting at 0% APR. Apply today with no impact to your credit score.
      </Subtitle>
      <List>
        <ListItem>
          <ListItemIconContainer>
            <Icon src="alle-list-view" width={19} height={19} />
          </ListItemIconContainer>
          <span>Choose your plan</span>
        </ListItem>
        <ListItem>
          <ListItemIconContainer>
            <Icon src="alle-credit-card" width={19} height={19} />
          </ListItemIconContainer>
          <span>Manage your payments</span>
        </ListItem>
        <ListItem>
          <ListItemIconContainer>
            <Icon src="alle-heart" width={22} height={22} />
          </ListItemIconContainer>
          <span>Love your look!</span>
        </ListItem>
      </List>
      <LeftAlignContainer>
        <CheckboxItem
          label=""
          checked={consent}
          activeColor="black"
          onChange={() => setConsent(!consent)}
          removeBorder={true}
        />
        <ConsentText>
          I consent to the{' '}
          <ConsentHyperlink href={`${WEB_SITE}/e-sign-disclosure/`} target="_blank">
            Cherry E-Sign Disclosures and Consent.
          </ConsentHyperlink>
          <ConsentRequired> (Required)</ConsentRequired>
        </ConsentText>
      </LeftAlignContainer>
      <Button
        disabled={!consent}
        black={true}
        uppercase={true}
        thinText={true}
        fontWeight={400}
        fontSize={11}
        fontFamily="Graphik"
        m="32px 0 0"
        onClick={onContinue}
      >
        Continue
      </Button>
      <FooterInfo>
        By continuing, you acknowledge that you have read and agree to the{' '}
        <InfoColorText href={`${WEB_SITE}/terms/#patriot-act`} target="_blank">
          USA PATRIOT Act Notice
        </InfoColorText>{' '}
        as well as Cherry’s{' '}
        <InfoColorText href={`${WEB_SITE}/privacy`} target="_blank">
          Privacy Policy
        </InfoColorText>{' '}
        and{' '}
        <InfoColorText href={`${WEB_SITE}/terms/`} target="_blank">
          Terms of Service
        </InfoColorText>
        .
      </FooterInfo>
      <HaveQuestionsModal />
    </Container>
  );
};

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: 'Graphik', sans-serif;
`;

const StepText = styled.div`
  color: ${(props) => props.theme.main.silverGray};
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

const List = styled.ul`
  color: ${(props) => props.theme.main.black20};
  line-height: 24px;
  letter-spacing: -0.2px;
  text-align: left;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
`;

const ListItemIconContainer = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: ${(props) => props.theme.main.beige};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FooterInfo = styled.div`
  color: ${(props) => props.theme.main.slateGray};
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 22px 0 32px;
`;

const InfoColorText = styled.a`
  color: #9a6b5e;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`;

const LeftAlignContainer = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-left: -9px;
`;

const ConsentText = styled.span`
  color: ${(props) => props.theme.main.black20};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 24px;

  @media (min-width: 538px) {
    // just for the sake of single/double line
    margin-top: 8px;
  }
`;
const ConsentHyperlink = styled.a`
  color: ${(props) => props.theme.main.black20};
  text-decoration-line: underline;
  cursor: pointer;
`;

const ConsentRequired = styled.span`
  color: ${(props) => props.theme.main.slateGray};
`;

export default AlleGetStarted;
