export enum LineExpansionAdditionalInfoActiveSteps {
  HOUSING_PAYMENT = 'HOUSING_PAYMENT',
  STATED_INCOME = 'STATED_INCOME',
  EMAIL = 'EMAIL',
}

export enum CreditLineIncreaseReviewItems {
  EMAIL = 'EMAIL',
  HOUSING_PAYMENT = 'HOUSING_PAYMENT',
  STATED_INCOME = 'STATED_INCOME',
  REQUESTED_AMOUNT = 'REQUESTED_AMOUNT',
}

export interface LineExpansionAdditionalInfoProps {
  type: LineExpansionAdditionalInfoActiveSteps;
}
