import { useApolloClient } from '@apollo/client';

import { GET_BORROWER_INCOME_VERIFICATION_LATEST } from 'lib/graphql/queries';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { useEligibilityService, useServices } from 'pages/AuthChecker/services';
import { useCreditLineIncrease } from 'lib/hooks/useCreditLineIncrease';

import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';

export enum PLAID_ERRORS {
  INVALID_CREDENTIALS = 'invalid-credentials',
  INSUFFICIENT_CREDENTIALS = 'insufficient-credentials',
  USER_SETUP_REQUIRED = 'user-setup-required',
  ITEM_LOCKED = 'item-locked',
  NO_VALID_ACCOUNT = 'no-valid-account',
  INSTITUTION_NOT_RESPONDING = 'institution-not-responding',
  INTERNAL_SERVER_ERROR = 'internal-server-error',
  INSTITUTION_NO_LONGER_SUPPORTED = 'institution-not-supported',
  INSTITUTION_DOWN = 'institution-down',
  ITEM_NOT_SUPPORTED = 'item-not-supported',
  RATE_LIMIT = 'rate-limit',
  INVALID_MFA = 'invalid-mfa',
  MFA_NOT_SUPPORTED = 'mfa-not-supported',
  NO_ACCOUNTS = 'no-accounts',
}

const ONE_TIME_ALLOWED_ERRORS: string[] = [
  PLAID_ERRORS.INSUFFICIENT_CREDENTIALS,
  PLAID_ERRORS.USER_SETUP_REQUIRED,
  PLAID_ERRORS.ITEM_LOCKED,
];

const NAVIGATE_TO_DOCS_ERRORS = [PLAID_ERRORS.INVALID_MFA, PLAID_ERRORS.MFA_NOT_SUPPORTED];

export const useIncomeVerificationLatest = () => {
  const client = useApolloClient();
  const { navigate } = useNavigation();
  const { setIncomeVerification, application, setSessionApiData } = useStore();
  const { pollLatestCreditLineIncrease } = useCreditLineIncrease();

  const { IvCheck } = useEligibilityService();
  const { startCheckApplicationStatus } = useSSEContext();

  const { getApplication } = useServices();

  const checkIncomeStatus = async (res, borrowerId) => {
    setIncomeVerification(res);
    switch (res?.status) {
      case 'COMPLETE':
        if (application?.mickeyMouse && application?.status === 'APPROVED') {
          await pollLatestCreditLineIncrease();
        } else {
          startCheckApplicationStatus({ isExisting: true });
        }
        break;
      case 'ERROR':
        if (application?.mickeyMouse && application?.status === 'APPROVED') {
          const applicationLatest = await getApplication(application?.id);
          IvCheck(applicationLatest);
        } else {
          startCheckApplicationStatus({
            applicationId: application?.id,
            isExisting: true,
            subscribeFlow: true,
            skipSameStatusControl: true,
          });
        }
        break;
      case 'EXPIRED':
        startCheckApplicationStatus({ isExisting: true });
        break;
      case 'CREATED':
        setTimeout(() => {
          getIncomeVerificationLatest(borrowerId);
        }, 4000);
        break;
      case 'PENDING':
        if (res?.type === 'PAYSTUB') {
          startCheckApplicationStatus({ isExisting: true });
        } else {
          setTimeout(() => {
            getIncomeVerificationLatest(borrowerId);
          }, 4000);
        }
        break;
      default:
        setTimeout(() => {
          getIncomeVerificationLatest(borrowerId);
        }, 4000);
    }
  };

  const checkErrorStatus = (res, borrowerId, applicationId) => {
    const { providerMessage, status } = res;
    const errorCode = PLAID_ERRORS[providerMessage];
    setIncomeVerification(res);

    const clearSessionErrors = () => {
      setSessionApiData({
        plaidErrors: [],
      });
    };

    const isErrorOccuredBefore = (errorStatus) => {
      const { sessionApiData } = useStore.getState();
      const { plaidErrors } = sessionApiData || {};

      return (
        plaidErrors?.filter((error) => ONE_TIME_ALLOWED_ERRORS.includes(error) && error === errorStatus)?.length > 1
      );
    };

    switch (status) {
      case 'ERROR':
        const isOneTimeAllowedError = ONE_TIME_ALLOWED_ERRORS.includes(errorCode);
        if (errorCode) {
          if (isOneTimeAllowedError) {
            if (isErrorOccuredBefore(errorCode)) {
              navigate(`upload-paystub/${applicationId}`);
              clearSessionErrors();
            } else {
              navigate(`plaid-error-handler/${errorCode}`);
            }
          } else if (NAVIGATE_TO_DOCS_ERRORS.includes(errorCode)) {
            navigate(`upload-paystub/${applicationId}`);
          } else {
            navigate(`plaid-error-handler/${errorCode}`);
          }
        }
        break;
      default:
        setTimeout(() => {
          getIncomeVerificationLatestErrorStatus(borrowerId, applicationId);
        }, 4000);
    }
  };

  const getIncomeVerificationLatest = async (borrowerId: string) => {
    const { data } = await client.query({
      query: GET_BORROWER_INCOME_VERIFICATION_LATEST,
      variables: {
        input: {
          borrowerId,
        },
      },
    });

    if (data?.borrowerIncomeVerificationsLatest) {
      checkIncomeStatus(data?.borrowerIncomeVerificationsLatest, borrowerId);
    }
    return [];
  };

  const getIncomeVerificationLatestErrorStatus = async (borrowerId: string, applicationId: string) => {
    const { data } = await client.query({
      query: GET_BORROWER_INCOME_VERIFICATION_LATEST,
      variables: {
        input: {
          borrowerId,
        },
      },
    });

    if (data?.borrowerIncomeVerificationsLatest) {
      checkErrorStatus(data?.borrowerIncomeVerificationsLatest, borrowerId, applicationId);
    }
    return [];
  };

  return { getIncomeVerificationLatest, getIncomeVerificationLatestErrorStatus };
};
