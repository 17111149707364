import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect } from 'react';
import { Control, Controller, UseFormHandleSubmit } from 'react-hook-form';
import { US_STATES } from 'lib/constants';
import { numericPattern, trapSpacesForRequiredFields } from 'lib/utils/Validators';
import { Box, Button, HaveQuestionsModal, Subtitle, Title, UnderlinedTextField as TextField } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { FormDataType, IAutoComplete, ISelectedAddress, USStates } from 'pages/PersonalAddressInfo/types';
import styled from 'styled-components';
import { theme } from 'config/theme';
import { List, ListItem } from '@mui/material';
import { IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';

type AllePersonalAddressInfoProps = {
  control: Control;
  autocompleteData: IAutoComplete[];
  setAddressValues: (selectedAddress: ISelectedAddress) => void;
  setSearchTerm: (searchTerm: string) => void;
  checkKeyDown: (event: KeyboardEvent) => void;
  isValid: boolean | undefined;
  loading: boolean;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  handleSubmit: UseFormHandleSubmit<any>;
  onSubmit: (formData: FormDataType) => Promise<void>;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const CustomListbox = React.forwardRef(function CustomListbox(props: any, ref) {
  const { children, ...other } = props;

  return (
    <List ref={ref} {...other}>
      {/* Header */}
      <ListItem>
        <DropdownTitle>Suggestions</DropdownTitle>
      </ListItem>
      <ListContainer>
        {/* List Items */}
        {children}
      </ListContainer>
    </List>
  );
});

const AllePersonalAddressInfo = ({
  control,
  autocompleteData,
  setAddressValues,
  setSearchTerm,
  checkKeyDown,
  isValid,
  loading,
  handleSubmit,
  onSubmit,
}: AllePersonalAddressInfoProps) => {
  const { pushEvent } = useIframeEvents();

  useEffect(() => {
    pushEvent(IframeEventTypes.PAGE, PageEventActions.PATIENT_ADDRESS_INFO);
  }, []);

  const isFormVisible = control._formValues.street?.length > 0;

  return (
    <Container>
      <StepText>STEP 2 OF 3</StepText>
      <Title
        color={theme.main.black10}
        fontSize="24px"
        fontWeight={400}
        lineHeight="32px"
        useElegantFontType={true}
        textAlign="center"
      >
        You’re almost done
      </Title>
      <Subtitle m="16px" textAlign="center" color={theme.main.black20} lineHeight="24px" fontSize="16px">
        Just a few more details. Please enter your home address.
      </Subtitle>
      <Box>
        <Controller
          name="street"
          control={control}
          defaultValue=""
          rules={{ required: true, validate: trapSpacesForRequiredFields }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <Autocomplete
              options={autocompleteData}
              value={value}
              onChange={(event, newValue: ISelectedAddress) => {
                setAddressValues(newValue);
              }}
              id="disable-clearable"
              disableClearable={true}
              renderInput={(params) => {
                delete params.inputProps.className;
                return (
                  <TextField
                    {...params}
                    id="street-address"
                    variant="filled"
                    data-testid="street"
                    label="Street Address"
                    name="street"
                    value={value}
                    onChange={(e) => {
                      setSearchTerm(e?.target?.value);
                      onChange(e?.target?.value);
                    }}
                    onKeyDown={checkKeyDown}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error ? error?.message : null}
                  />
                );
              }}
            />
          )}
        />
        {isFormVisible && (
          <Controller
            name="unit"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="apt-unit"
                variant="filled"
                data-testid="unit"
                label="Apt / Unit (Optional)"
                name="address1"
                autoComplete="new-password"
                value={value}
                onKeyDown={checkKeyDown}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            )}
          />
        )}
        {isFormVisible && (
          <RowContainer>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: trapSpacesForRequiredFields }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="city-address"
                  variant="filled"
                  data-testid="city"
                  data-name="city"
                  label="City"
                  value={value}
                  onKeyDown={checkKeyDown}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />
            <Controller
              name="state"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="state"
                  variant="filled"
                  data-testid="state"
                  data-name="state"
                  label="State"
                  select={true}
                  value={value}
                  onKeyDown={checkKeyDown}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error?.message : null}
                >
                  {US_STATES.map((option: USStates) => (
                    <MenuItem data-testid="stateItem" key={option?.value} value={option?.value}>
                      {option?.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </RowContainer>
        )}
        {isFormVisible && (
          <RowContainer>
            <Controller
              name="zip"
              control={control}
              defaultValue=""
              rules={{ required: true, maxLength: 5, minLength: 5 }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="zip-code"
                  variant="filled"
                  data-testid="zip"
                  data-name="zip"
                  type="tel"
                  inputProps={{
                    maxLength: 5,
                  }}
                  label="ZIP Code"
                  value={value}
                  onKeyPress={(event) => {
                    if (!numericPattern.test(event?.key)) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={checkKeyDown}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />
          </RowContainer>
        )}
      </Box>
      <ButtonContainer>
        <Button
          disabled={!isValid || loading}
          loading={loading}
          onClick={handleSubmit(onSubmit)}
          m="32px 0 0"
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
        >
          Continue
        </Button>
      </ButtonContainer>
      <HaveQuestionsModal />
    </Container>
  );
};

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: 'Graphik';
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  div[data-name='city'] {
    flex: 1;
    text-align: left;
  }

  div[data-name='state'] {
    flex: 1;
  }

  div[data-name='zip'] {
    flex: 0.5;
  }

  @media (max-width: ${(props) => props.theme.size.mobileL}) {
    width: 100%;
  }
`;

const DropdownTitle = styled.div`
  color: ${(props) => props.theme.main.silverGray};
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const ListContainer = styled.div`
  color: ${(props) => props.theme.main.black20};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 32px;
`;

const StepText = styled.div`
  color: ${(props) => props.theme.main.silverGray};
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

export default AllePersonalAddressInfo;
