import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'config/theme';
import { FormatAmount } from 'lib/utils';
import useStore from 'lib/hooks/useStore';
import { DATE_FORMAT } from 'lib/constants';
import { IPlan } from 'pages/Plans/types';

interface PlanCardProps {
  data: IPlan;
  active: boolean;
  useElegantTheme?: boolean;
  handleCustomDownPayment?: () => void;
  isCheckoutStep?: boolean;
}

export const PlanCard = ({
  data,
  active,
  handleCustomDownPayment,
  isCheckoutStep = true,
  useElegantTheme,
}: PlanCardProps) => {
  const { t: translate } = useTranslation();
  const { isAlle, sessionApiData } = useStore();
  const { featureFlags } = sessionApiData || {};

  const customHeaderText = data?.product?.parentId ? 'Custom ' : '';
  const hasCustomDownPaymentButton = !!data?.variableDownPayment?.hasOptionToModify && !data?.product?.parentId;
  const isDownPaymentEnabledOnCloudBees = featureFlags?.downPaymentEnabled || false;

  return (
    <Container data-testid="plan-card" useElegantTheme={useElegantTheme}>
      <Shadow>
        <Header isActive={active} useElegantTheme={useElegantTheme}>
          {translate('plans.planCard.header', {
            customHeaderText: customHeaderText,
            term: data?.product?.term,
          })}
        </Header>
        <ContentContainer isActive={active} isCheckoutStep={isCheckoutStep} useElegantTheme={useElegantTheme}>
          <ContentItemContainer>
            <ContentTitle useElegantTheme={useElegantTheme}>
              {isCheckoutStep ? translate('plans.planCard.step1') : translate('plans.planCard.step2')}
            </ContentTitle>
            <ContentText useElegantTheme={useElegantTheme}>{FormatAmount(data?.downPaymentAmount)}</ContentText>
            {hasCustomDownPaymentButton && isDownPaymentEnabledOnCloudBees ? (
              <ButtonContainer isDisabled={!active} onClick={handleCustomDownPayment}>
                {translate('buttons.changeDownPayment')}
              </ButtonContainer>
            ) : null}
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentBorder isAlle={isAlle} />
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentTitle useElegantTheme={useElegantTheme}>
              {isCheckoutStep ? translate('plans.planCard.content1') : translate('plans.planCard.content2')}
            </ContentTitle>

            <ContentText useElegantTheme={useElegantTheme}>
              {translate('plans.planCard.installmentAmount', {
                amount: FormatAmount(data?.installmentAmount),
              })}
            </ContentText>

            {isCheckoutStep ? (
              <ContentSubText useElegantTheme={useElegantTheme}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate('plans.planCard.subText1', {
                      term: data?.product?.term,
                      date: dayjs(data?.paymentDates?.[0]).format(DATE_FORMAT),
                    }),
                  }}
                />
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate('plans.planCard.subText2', {
                      apr: data?.promo ? data?.product?.promoApr : data?.product?.apr,
                    }),
                  }}
                />
              </ContentSubText>
            ) : (
              <ContentSubText useElegantTheme={useElegantTheme}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate('plans.planCard.footer1', {
                      term: data?.product?.term,
                    }),
                  }}
                />
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate('plans.planCard.footer2', {
                      financeCharge: FormatAmount(Number(data?.financeCharge)),
                    }),
                  }}
                />
                <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: translate('plans.planCard.footer3', {
                      apr: data?.promo ? data?.product?.promoApr : data?.product?.apr,
                    }),
                  }}
                />
              </ContentSubText>
            )}
          </ContentItemContainer>
        </ContentContainer>
      </Shadow>
    </Container>
  );
};

const Container = styled.div<{ useElegantTheme?: boolean }>`
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  max-width: ${(props) => (props?.useElegantTheme ? 260 : 240)}px;
  min-width: ${(props) => (props?.useElegantTheme ? 220 : 200)}px;
  padding-bottom: 24px;
`;

const Shadow = styled.div`
  box-shadow: 6px 24px 18px -16px rgb(121 131 136 / 75%);
  border-radius: 0 0 8px 8px;
`;

const Header = styled.div<{ isActive: boolean; useElegantTheme?: boolean }>`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: ${(props) => props.theme.main.white};
  background-color: ${(props) =>
    props.theme.main[
      props.isActive
        ? props.useElegantTheme
          ? 'mutedRedBrown'
          : 'green'
        : props.useElegantTheme
        ? 'lightGrayishBeige'
        : 'grayColor'
    ]};

  padding: 5px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  ${(props) => props?.useElegantTheme && 'font-family: Graphik'}
`;

const ContentContainer = styled.div<{ isActive: boolean; isCheckoutStep: boolean; useElegantTheme?: boolean }>`
  padding: 12px 16px 4px;
  text-align: ${(props) => (props.useElegantTheme ? 'left' : 'center')};

  border-left: 1px solid
    ${(props) =>
      props.theme.main[
        props.isActive
          ? props.useElegantTheme
            ? 'mutedRedBrown'
            : 'green'
          : props.useElegantTheme
          ? 'lightGrayishBeige'
          : 'grayColor'
      ]};
  border-right: 1px solid
    ${(props) =>
      props.theme.main[
        props.isActive
          ? props.useElegantTheme
            ? 'mutedRedBrown'
            : 'green'
          : props.useElegantTheme
          ? 'lightGrayishBeige'
          : 'grayColor'
      ]};

  border-bottom: 1px solid
    ${(props) =>
      props.theme.main[
        props.isActive
          ? props.useElegantTheme
            ? 'mutedRedBrown'
            : 'green'
          : props.useElegantTheme
          ? 'lightGrayishBeige'
          : 'grayColor'
      ]};

  border-radius: 0 0 8px 8px;

  ${(props) =>
    !props.isCheckoutStep && {
      background: `${theme.main.white}`,
      textAlign: 'left',
    }}
`;

const ContentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const ContentTitle = styled.span<{ useElegantTheme?: boolean; isActive?: boolean }>`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;

  color: ${(props) =>
    props.theme.main[
      props.isActive
        ? props.useElegantTheme
          ? 'mutedRedBrown'
          : 'green'
        : props.useElegantTheme
        ? 'lightGrayishBeige'
        : 'green'
    ]};

  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
  text-transform: ${(props) => props.useElegantTheme && 'uppercase'};
  letter-spacing: ${(props) => props.useElegantTheme && '1px'};
`;

const ContentText = styled.span<{ useElegantTheme?: boolean }>`
  font-size: 24px;
  line-height: 33px;
  color: ${(props) => props.theme.main.textColor};
  font-family: ${(props) => props.useElegantTheme && 'Petersburg'};
  font-weight: ${(props) => (props.useElegantTheme ? 400 : 700)};
`;

const ContentSubText = styled.span<{ useElegantTheme?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: ${(props) => props.theme.main.textColor};
  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
  font-style: ${(props) => (props.useElegantTheme ? 'none' : 'italic')};
`;

const ContentBorder = styled.div<{ isAlle?: boolean }>`
  border: ${(props) => (props.isAlle ? '1px solid #E6E6E6' : `1px dashed ${theme.main.grayColor}`)};
`;

const ButtonContainer = styled.div.attrs(() => ({
  'data-testid': 'change-down-payment-btn',
}))<{ isDisabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #879097;
  border: 1px solid #879097;
  border-radius: 4px;
  padding: 8px 16px;
  gap: 8px;
  width: auto;
  margin: 8px 0 0;
  text-align: center;

  ${(props) =>
    props.isDisabled && {
      pointerEvents: 'none',
      background: props.theme.main.grayColor,
      borderColor: props.theme.main.grayColor,
      color: props.theme.main.white,
    }}
`;
