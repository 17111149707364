import { useApolloClient } from '@apollo/client';
import { useState } from 'react';

import { GET_MERCHANT_DETAIL } from 'lib/graphql/queries';
import { useSentry } from 'lib/hooks/useSentry';

export interface AddressType {
  id: string;
  createdAt: string;
  street: string;
  zip: string;
  city: string;
  state: { name: string; code: string };
}

export const useMerchantsDetail = () => {
  const client = useApolloClient();
  const { captureException } = useSentry();

  const [address, setAddress] = useState<AddressType | null>(null);
  const [phone, setPhone] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [website, setWebsite] = useState<string | null>(null);

  const fetchMerchantDetail = async (merchantId: string) => {
    try {
      if (merchantId && merchantId !== '0') {
        const { data: getMerchantDetailData = {} } = await client.query({
          query: GET_MERCHANT_DETAIL,
          variables: {
            input: {
              merchantIds: [merchantId],
            },
          },
        });

        setAddress(getMerchantDetailData?.getMerchantDetail?.data?.[0]?.address);
        setPhone(getMerchantDetailData?.getMerchantDetail?.data?.[0]?.phone);
        setName(getMerchantDetailData?.getMerchantDetail?.data?.[0]?.name);
        setWebsite(getMerchantDetailData?.getMerchantDetail?.data?.[0]?.website);
      }
    } catch (err) {
      captureException(err, { message: 'Choose Plan - Get Merchant Detail Error', merchantId });
    }
  };

  return { address, phone, name, website, fetchMerchantDetail };
};
