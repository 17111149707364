import { theme } from '@frontend/cherry-library';
import { Button, Container, Icon, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import AlleApproval from '../Approval/AlleApproval';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigation } from 'lib/hooks/useNavigation';
import styled from 'styled-components';
import { Redirect } from 'lib/utils';
import { CONFIRM_LOAN_RESPONSE_CODES, CONFIRM_LOAN_STATUSES } from 'lib/constants';
import { useEffect, useRef } from 'react';

const CardDecline = () => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const { isAlle, confirmLoanResponseCode, confirmLoanStatus, organization, downPaymentMethod } = useStore();
  const loadSegmentController = useRef(true);
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();

  const last4 = downPaymentMethod?.storedCard?.last4 || '****';
  const isDisabled = confirmLoanStatus === CONFIRM_LOAN_STATUSES.INVALID;
  const { loadSegmentEventName, clickSegmentEventName } = getSegmentEventNames(isDisabled, confirmLoanResponseCode);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(loadSegmentEventName);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const goToAddPaymentMethod = () => {
    sendActionSegmentEvent(clickSegmentEventName);
    navigate('add-card');
  };

  const callPractice = () => {
    sendActionSegmentEvent(clickSegmentEventName);
    Redirect(`tel:${organization?.phone}`, '_self');
  };

  const getTitle = () => {
    if (isDisabled) {
      return translate('confirmLoan.checkoutDisabled.title');
    }
    return translate('confirmLoan.cardDeclined.title');
  };

  const getMessage = () => {
    if (confirmLoanResponseCode === CONFIRM_LOAN_RESPONSE_CODES.INSUFFICIENT_FUNDS) {
      return (
        <Trans
          i18nKey={'confirmLoan.cardDeclined.insufficientMessage'}
          components={{
            bold: <strong />,
          }}
          values={{ last4: last4 }}
        ></Trans>
      );
    }
    if (
      confirmLoanResponseCode === CONFIRM_LOAN_RESPONSE_CODES.DO_NOT_HONOR ||
      confirmLoanResponseCode === CONFIRM_LOAN_RESPONSE_CODES.RISK_BLOCKED
    ) {
      return (
        <Trans
          i18nKey={'confirmLoan.cardDeclined.bankDeclinedMessage'}
          components={{
            bold: <strong />,
          }}
          values={{ last4: last4 }}
        ></Trans>
      );
    }
    return translate('confirmLoan.cardDeclined.cardDeclinedMessage', {
      last4: last4,
    });
  };

  const getFirstItem = () => {
    if (confirmLoanResponseCode === CONFIRM_LOAN_RESPONSE_CODES.INSUFFICIENT_FUNDS) {
      return translate('confirmLoan.cardDeclined.insufficientFirstItem');
    }
    if (
      confirmLoanResponseCode === CONFIRM_LOAN_RESPONSE_CODES.DO_NOT_HONOR ||
      confirmLoanResponseCode === CONFIRM_LOAN_RESPONSE_CODES.RISK_BLOCKED
    ) {
      return translate('confirmLoan.cardDeclined.bankDeclinedFirstItem');
    }
    return translate('confirmLoan.cardDeclined.cardDeclinedFirstItem');
  };

  const getSecondItem = () => {
    if (confirmLoanResponseCode === CONFIRM_LOAN_RESPONSE_CODES.INSUFFICIENT_FUNDS) {
      return translate('confirmLoan.cardDeclined.insufficientSecondItem');
    }
    if (
      confirmLoanResponseCode === CONFIRM_LOAN_RESPONSE_CODES.DO_NOT_HONOR ||
      confirmLoanResponseCode === CONFIRM_LOAN_RESPONSE_CODES.RISK_BLOCKED
    ) {
      return translate('confirmLoan.cardDeclined.bankDeclinedSecondItem');
    }
    return translate('confirmLoan.cardDeclined.cardDeclinedSecondItem');
  };

  if (isAlle) {
    return <AlleApproval />;
  }

  return (
    <Container showBackButton={false}>
      <CardDeclineContentContainer noMargin>
        <Icon src={'payment-fail'} />
        <Title m={'8px 0px'}>{getTitle()}</Title>

        <CardDeclineMessageContainer>
          <CardDeclineMessage>{getMessage()}</CardDeclineMessage>

          <CardDeclineListContainer>
            <CardDeclineMessageItem>{getFirstItem()}</CardDeclineMessageItem>
            <CardDeclineMessageItem>{getSecondItem()}</CardDeclineMessageItem>
          </CardDeclineListContainer>
        </CardDeclineMessageContainer>

        {!isDisabled ? (
          <CardDeclineInfoContainer>
            <Icon width={24} height={24} src={'info_circle_outline'} />
            <CardDeclineInfoText>{translate('confirmLoan.cardDeclined.warning')}</CardDeclineInfoText>
          </CardDeclineInfoContainer>
        ) : null}
      </CardDeclineContentContainer>

      <ButtonContainer>
        {isDisabled ? (
          <>
            <NewCheckoutInfo>
              <Trans
                i18nKey={'confirmLoan.checkoutDisabled.newLinkMessage'}
                components={{
                  bold: <strong />,
                }}
              ></Trans>
            </NewCheckoutInfo>

            <Button onClick={callPractice} icon={'phone-white'}>
              {translate('buttons.newCall')}
            </Button>
          </>
        ) : (
          <Button onClick={goToAddPaymentMethod}>{translate('buttons.addPaymentMethod')}</Button>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default CardDecline;

const getSegmentEventNames = (isDisabled: boolean, confirmLoanResponseCode: string) => {
  let loadSegmentEventName: string;
  let clickSegmentEventName: string;

  if (isDisabled) {
    switch (confirmLoanResponseCode) {
      case CONFIRM_LOAN_RESPONSE_CODES.INSUFFICIENT_FUNDS:
        loadSegmentEventName = WPQSegmentNames.checkoutDisabledInsufficientFundsLoad;
        clickSegmentEventName = WPQSegmentNames.checkoutDisabledInsufficientFundsCallPracticeClicked;
        break;

      case CONFIRM_LOAN_RESPONSE_CODES.DO_NOT_HONOR:
      case CONFIRM_LOAN_RESPONSE_CODES.RISK_BLOCKED:
        loadSegmentEventName = WPQSegmentNames.checkoutDisabledDoNotHonorLoad;
        clickSegmentEventName = WPQSegmentNames.checkoutDisabledDoNotHonorCallPracticeClicked;
        break;

      default:
        loadSegmentEventName = WPQSegmentNames.checkoutDisabledGenericLoad;
        clickSegmentEventName = WPQSegmentNames.checkoutDisabledGenericCallPracticeClicked;
        break;
    }
  } else {
    switch (confirmLoanResponseCode) {
      case CONFIRM_LOAN_RESPONSE_CODES.INSUFFICIENT_FUNDS:
        loadSegmentEventName = WPQSegmentNames.cardDeclinedInsufficientFundsLoad;
        clickSegmentEventName = WPQSegmentNames.cardDeclinedInsufficientFundsAddPaymentClicked;
        break;

      case CONFIRM_LOAN_RESPONSE_CODES.DO_NOT_HONOR:
      case CONFIRM_LOAN_RESPONSE_CODES.RISK_BLOCKED:
        loadSegmentEventName = WPQSegmentNames.cardDeclinedDoNotHonorLoad;
        clickSegmentEventName = WPQSegmentNames.cardDeclinedDoNotHonorAddPaymentClicked;
        break;

      default:
        loadSegmentEventName = WPQSegmentNames.cardDeclinedGenericLoad;
        clickSegmentEventName = WPQSegmentNames.cardDeclinedGenericAddPaymentClicked;
        break;
    }
  }

  return { loadSegmentEventName, clickSegmentEventName };
};

const CardDeclineContentContainer = styled(ContentContainer)`
  padding: 24px;
`;

const CardDeclineMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  color: ${theme.main.midnightBlue};
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const CardDeclineMessage = styled.span``;

const CardDeclineListContainer = styled.ol`
  padding-left: 28px;
  gap: 16px;
  margin: 0;
`;

const CardDeclineMessageItem = styled.li`
  margin: 0 0 16px;

  ::marker {
    letter-spacing: 2px;
  }
`;

const CardDeclineInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`;

const CardDeclineInfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  color: ${theme.main.midnightBlue};
`;

const NewCheckoutInfo = styled.div`
  color: ${theme.main.midnightBlue};
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
`;
