import { useNavigation } from 'lib/hooks/useNavigation';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { MessageTitle } from '../FrozenCredit/FrozenCredit';
import { useTranslation } from 'react-i18next';

const Incomplete = () => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();

  const applyAgain = () => {
    navigate(``);
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'warning_outline'} />
        <Title m={'10px 0 8px 0'}>{translate('incomplete.title')}</Title>
        <Subtitle m={'24px 0px 24px 0px'}>{translate('incomplete.subTitle')}</Subtitle>

        <MessageTitle>{translate('incomplete.info.title')}</MessageTitle>
        <Subtitle m={'0 0 24px 0'}>{translate('incomplete.info.subTitle')}</Subtitle>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={applyAgain}>{translate('buttons.applyAgain')}</Button>
      </ButtonContainer>
    </Container>
  );
};

export default Incomplete;
