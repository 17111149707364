import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import React from 'react';
import styled from 'styled-components';

import { theme as configTheme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { Button, Icon, IconGap, ModalHeader, Title } from 'lib/components';
import { ButtonContainer } from 'lib/components/Common';
import { CreditLineIncreaseStatus } from 'lib/hooks/useCreditLineIncrease';
import { useTranslation } from 'react-i18next';

interface CreditLineIncreaseModal {
  show: boolean;
  hideModal: () => void;
  creditLineIncrease: {
    id: number;
    requestedAmount: number;
    eligibleAmount: number;
    status: string;
    subStatus: string;
    amount: {
      approved: number;
      requested: number;
    };
  };
}

const CreditLineIncreaseModal = ({ show, hideModal, creditLineIncrease }: CreditLineIncreaseModal) => {
  const { t: translate } = useTranslation();
  const { application } = useStore.getState() || {};
  const backToMyApproval = () => {
    hideModal();
  };

  const closeModalOnClickAway = (e) => {
    if (e?.target?.ariaHidden) {
      hideModal();
    }
  };

  const getTitle = () => {
    switch (creditLineIncrease?.status) {
      case CreditLineIncreaseStatus.REVIEW:
        return translate('creditLineIncreaseModal.requestInReview');
      default:
        return '';
    }
  };

  const getInfoText = () => {
    switch (creditLineIncrease?.status) {
      case CreditLineIncreaseStatus.REVIEW:
        return translate('creditLineIncreaseModal.reviewText', {
          amountRequested: creditLineIncrease?.amount?.requested,
          balanceAvailable: application?.balanceAvailable,
        });
      default:
        return '';
    }
  };

  return (
    <Backdrop
      sx={{ color: configTheme.main.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      onClick={closeModalOnClickAway}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer isLarge={false}>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>{getTitle()}</Title>
            <Icon hover={true} src={'close'} onClick={hideModal} />
          </ModalHeader>
          <PurchaseContainer>
            <p>{getInfoText()}</p>
          </PurchaseContainer>
          <ButtonContainer>
            <Button onClick={backToMyApproval}>{translate('buttons.okay')}</Button>
          </ButtonContainer>
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

export default CreditLineIncreaseModal;

const ModalContainer = styled.div<{ isLarge: boolean }>`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0 -36px 67px rgba(0, 0, 0, 0.07), 0 -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0 -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 12px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;

    @media (max-height: ${(props) => (props.isLarge ? '700px' : '530px')}) {
      top: 30px;
      overflow-y: scroll;
    }
  }
`;

const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px 16px 32px;
  color: ${(props) => props.theme.main.black};
`;
