import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { API_URL } from 'config';
import { AlleFullScreenLoading, Loading } from 'lib/components';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';

interface ThreeDFrameProps {
  onConnect: (boolean) => void;
  paymentId: string;
  handleClose: (boolean) => void;
}

export const ThreeDFrame = ({ paymentId, onConnect, handleClose }: ThreeDFrameProps) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const { captureException } = useSentry();
  const { isAlle } = useStore();

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  const receiveMessage = ({ origin, data }) => {
    try {
      if (origin === API_URL) {
        const iFrameEvent = JSON.parse(data);
        switch (iFrameEvent?.status) {
          case 'expired':
          case 'error':
            setTimeout(() => {
              handleClose(false);
              alert.error(iFrameEvent?.message);
            }, 3000);
            break;
          case 'failed':
            setTimeout(() => {
              onConnect(false);
            }, 3000);
            break;
          case 'success':
            setTimeout(() => {
              onConnect(true);
            }, 3000);
            break;
          case 'loading':
            setLoading(true);
            break;
        }
      }
    } catch (err) {
      onConnect(false);
      setLoading(false);
      captureException(err, { errorContext: 'receiveMessage => PaymentMethodConnect', data });
    }
  };

  const onIframeLoadHandler = () => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  if (!paymentId) {
    captureException({}, { errorContext: 'borrowerId Not Found => PaymentMethodConnect' });
    return (
      <>
        {isAlle ? (
          <AlleFullScreenLoading isTextPresent={false} />
        ) : (
          <ErrorContainer>
            <Loading />
          </ErrorContainer>
        )}
      </>
    );
  }

  return (
    <Container>
      {paymentId && (
        <iframe
          id="3d-secure-method"
          data-testid="the-frame"
          title={'3ds Security'}
          width="100%"
          height="100%"
          frameBorder="0"
          src={`${API_URL}/v1/payments/${paymentId}/three-d/iframe`}
          onLoad={onIframeLoadHandler}
        />
      )}
      {loading && (
        <>
          {isAlle ? (
            <AlleFullScreenLoading isTextPresent={false} />
          ) : (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 5px;
`;

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  left: 0;
  right: 0;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
`;
