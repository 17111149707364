import React from 'react';

import useStore from 'lib/hooks/useStore';
import { CallCherry, Redirect } from 'lib/utils';

import { Button, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';

import styled from 'styled-components';
import { theme } from 'config/theme';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { WEB_SITE } from 'lib/constants';

const AlleCancelled = () => {
  const { organization } = useStore();
  const { sendActionSegmentEvent } = useSegmentContext();

  const handleOnClick = () => {
    sendActionSegmentEvent(WPQSegmentNames.purchaseCancelWebsiteClicked);
    Redirect(WEB_SITE);
  };

  return (
    <ContentContainer>
      <PageTitle m={'10px 0px 8px 0px'} color={theme.main.black10} fontSize="18px" fontWeight={600} lineHeight="32px">
        Purchase cancelled
      </PageTitle>
      <Paragraph m={'0px 0px 8px 0px'}>It looks like this purchase was cancelled.</Paragraph>

      <PageTitle m="24px 0 8px 0" color={theme.main.black10} fontSize="18px" fontWeight={600} lineHeight="32px">
        What happens next?
      </PageTitle>
      <Paragraph m={'0px 0px 24px 0px'}>
        Reach out to {organization?.name} and ask them to send you a new link..
      </Paragraph>
      <Button onClick={CallCherry} black={true} uppercase={true} thinText={true} fontWeight={400} fontSize={11}>
        Call
      </Button>
      <OutlinedButton onClick={handleOnClick}>Visit Website</OutlinedButton>
    </ContentContainer>
  );
};

const PageTitle = styled(Title)`
  font-family: 'Graphik', sans-serif !important;
`;

const Paragraph = styled(Subtitle)`
  font-family: 'Graphik', sans-serif !important;
  font-size: 16px;
`;

const OutlinedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.main.white};
  border: 1px solid ${(props) => props.theme.main.grayColor};
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  font-family: 'Graphik', sans-serif !important;
`;

export default AlleCancelled;
