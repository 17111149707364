import { gql } from '@apollo/client/core';

export const BORROWER_INCOME_VERIFICATION = gql`
  mutation borrowerIncomeVerifications($input: BorrowerIncomeVerificationsInput) {
    borrowerIncomeVerifications(input: $input) {
      assetReport {
        borrowerId
        id
        status
      }
      borrowerId
      errorMessage
      id
      providerMessage
      relatedApplications {
        applicationId
      }
      source
      status
      type
    }
  }
`;

export const BORROWER_NOTIFY_ID_REVIEW = gql`
  mutation notifyIdReview($input: NotifyIdReviewInput) {
    notifyIdReview(input: $input) {
      success
    }
  }
`;

export const START_OTP_FLOW = gql`
  mutation startOtpFlow($input: StartOtpFlowInput) {
    startOtpFlow(input: $input) {
      success
      status
      message
      error
    }
  }
`;

export const START_SNA_OTP_FLOW = gql`
  mutation StartSNAOtpFlow($input: StartSNAOtpFlowInput) {
    startSNAOtpFlow(input: $input) {
      success
      status
      message
      error
      snaUrl
    }
  }
`;

export const SUBMIT_OTP = gql`
  mutation submitOtp($input: SubmitOtpInput) {
    submitOtp(input: $input) {
      success
      status
      message
      error
      authorization {
        token
        refreshToken
      }
      borrower {
        id
        createdAt
        updatedAt
        status
        firstName
        lastName
        phone
        email
        dob
        ssn
        language
      }
    }
  }
`;

export const VERIFY_SNA_OTP_FLOW = gql`
  mutation VerifySNAOtpFlow($input: VerifySNAOtpFlowInput) {
    verifySNAOtpFlow(input: $input) {
      success
      status
      message
      error
      authorization {
        token
        refreshToken
      }
      borrower {
        id
        createdAt
        updatedAt
        status
        firstName
        lastName
        phone
        email
        dob
        ssn
        language
      }
    }
  }
`;
