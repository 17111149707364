import { InputAdornment } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDebounce } from 'lib/hooks/useDebounce';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { CalculationData } from 'lib/utils';
import { DEBOUNCE_DELAY, DEFAULT_SELECTED_PLAN_INDEX, MINIMUM_AMOUNT } from 'lib/constants';

import { Button, Container, Icon, Loading, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { EstimatedPayment } from './EstimatedPayment';
import { useTranslation } from 'react-i18next';

const PreviewMyPayments = () => {
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { getProductCalculation } = useProductCalculation();
  const { t: translate } = useTranslation();

  const { selectedApplication } = useStore();
  const maxPrice = selectedApplication?.balanceAvailable;

  const [pageLoading, setPageLoading] = useState(true);
  const [calculationLoading, setCalculationLoading] = useState(false);
  const [enteredAmount, setEnteredAmount] = useState<number | null>(maxPrice);
  const debouncedValue = useDebounce(enteredAmount, DEBOUNCE_DELAY);

  const [productCalculationData, setProductCalculationData] = useState<CalculationData[] | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<number | null>(null);
  const [selectedProductCalculation, setSelectedProductCalculation] = useState<CalculationData | null>(null);

  const backToMyApproval = useCallback(() => {
    navigate(`approval`);
  }, [navigate]);

  useEffect(() => {
    if (debouncedValue && debouncedValue !== '' && debouncedValue >= MINIMUM_AMOUNT && debouncedValue <= maxPrice) {
      fetchProductCalculation?.();
    }
  }, [debouncedValue, maxPrice]);

  useEffect(() => {
    if (selectedPlan !== null && productCalculationData) {
      setSelectedProductCalculation(productCalculationData?.[selectedPlan]);
    }
  }, [selectedPlan, productCalculationData]);

  const fetchProductCalculation = async () => {
    setCalculationLoading(true);
    const { application } = useStore.getState() || {};
    const { id: applicationId } = application || {};

    if (applicationId && debouncedValue) {
      try {
        const response = await getProductCalculation(applicationId, debouncedValue);
        if (response && response.length) {
          setCalculationLoading(false);
          setProductCalculationData(response);
          if (selectedPlan === null) {
            setPageLoading(true);
            const selectedIndex = response.findIndex(
              (item) =>
                item?.product?.promoApr === 0 &&
                item?.product?.term ===
                  Math.max(...response?.filter((d) => d?.product?.promoApr === 0)?.map((d) => d?.product?.term)),
            );

            setSelectedPlan(selectedIndex !== -1 ? selectedIndex : DEFAULT_SELECTED_PLAN_INDEX);
          }

          setPageLoading(false);
          setSelectedProductCalculation(response?.[selectedPlan ?? 0]);
        }
      } catch (error) {
        captureException('Error fetching product calculation', error);
      }
    } else {
      setPageLoading(false);
      setCalculationLoading(false);
      setProductCalculationData([]);
      captureException('Application Id Not Found in Calculation Slider');
    }
  };

  const handlePriceChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const parsedValue = parseFloat(value);
      setEnteredAmount(isNaN(parsedValue) ? null : parsedValue);
    },
    [setEnteredAmount],
  );

  const termChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as number;
      setSelectedPlan(value);
    },
    [setSelectedPlan],
  );

  if (pageLoading) {
    return (
      <CenterLoading>
        <Loading size={40} />
      </CenterLoading>
    );
  }

  if (!productCalculationData || !maxPrice || selectedPlan === null || !selectedProductCalculation) {
    return null;
  }

  return (
    <Container showBackButton={true}>
      <ContentContainer>
        <Icon src={'calculator_alt'} />
        <Title m={'10px 0px 24px 0px'}>{translate('previewMyPayments.title')}</Title>

        <PurchaseContainer>
          <PurchaseText>{translate('previewMyPayments.subTitle')}</PurchaseText>
          <TextField
            id="purchase-amount-input"
            variant="filled"
            data-testid="purchase"
            data-neuro-label="purchase"
            label={translate('previewMyPayments.form.label')}
            type="tel"
            value={enteredAmount ?? ''}
            max={maxPrice}
            onChange={handlePriceChange}
            InputProps={{
              pattern: '[^0-9.]',
              startAdornment: (
                <InputAdornment
                  sx={{
                    padding: '1px 0 0 0',
                    '& .MuiTypography-root': { color: '#000' },
                  }}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
            }}
          />

          <Subtitle m="24px 0 12px">{translate('previewMyPayments.form.title')}</Subtitle>
          <TextField
            style={{ textAlign: 'left' }}
            label={translate('previewMyPayments.form.label2')}
            id="number-of-months"
            variant="filled"
            data-testid="number-of-months"
            select={true}
            defaultValue={selectedPlan}
            onChange={termChange}
          >
            {productCalculationData?.map((option, index) => {
              const termsValue = option?.product?.term;
              return (
                <MenuItem data-testid="numberOfMonthsItem" key={termsValue} value={index}>
                  {translate('previewMyPayments.form.months', {
                    termsValue: termsValue,
                  })}
                </MenuItem>
              );
            })}
          </TextField>

          {productCalculationData?.[selectedPlan] && debouncedValue ? (
            <EstimatedPayment
              selectedProductCalculation={selectedProductCalculation}
              maxPrice={maxPrice}
              term={productCalculationData?.[selectedPlan]?.product?.term}
              amount={debouncedValue}
              loading={calculationLoading}
            />
          ) : null}
        </PurchaseContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={backToMyApproval} secondary={true}>
          {translate('buttons.backToMyApproval')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default PreviewMyPayments;

const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px 0 32px;
`;

const PurchaseText = styled.span`
  color: ${(props) => props.theme.main.grayColor};
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: 0 0 8px;
`;

const CenterLoading = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
