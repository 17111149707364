import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { WEBPREQUAL } from 'lib/constants';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { numericPattern } from 'lib/utils/Validators';
import AlleAddRemoteCheck from 'pages/AddRemoteCheck/AlleAddRemoteCheck';
// eslint-disable-next-line  @typescript-eslint/no-var-requires
const validateAccountAndRouting = require('us-bank-account-validator');

interface State {
  routingNumber: string;
  accountNumber: string;
}

const AddRemoteCheck = () => {
  const { trackPage } = useSegment();
  const { t: translate } = useTranslation();
  const { navigate, navigateBack } = useNavigation();
  const { setSessionData, isAlle } = useStore();

  useEffect(() => {
    const { application } = useStore.getState() || {};
    trackPage('addRemoteCheck', {
      application: WEBPREQUAL,
      applicationId: application?.id,
    });
  }, []);

  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
  } = useForm<State>({
    mode: 'onChange',
  });

  const validAccountNumber = (value) => {
    if (validateAccountAndRouting.accountNumber(value).isValid) return true;
    return false;
  };
  const validRoutingNumber = (value) => {
    if (validateAccountAndRouting.routingNumber(value).isValid) return true;
    return false;
  };

  const onSubmit = async (params: State) => {
    setSessionData(params);
    navigate(`remote-check-review`);
  };

  const goBackHandler = () => {
    navigateBack();
  };

  if (isAlle) {
    return (
      <AlleAddRemoteCheck
        control={control}
        validRoutingNumber={validRoutingNumber}
        validAccountNumber={validAccountNumber}
        isValid={isValid}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        goBackHandler={goBackHandler}
      />
    );
  }

  return (
    <Container showBackButton={true}>
      <ContentContainer>
        <Icon src={'remotecheck'} />
        <Title m={'10px 0px 8px 0px'}>{translate('addRemoteCheck.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('addRemoteCheck.subTitle')}</Subtitle>
        <Subtitle
          m={'0px 0px 24px 0px'}
          dangerouslySetInnerHTML={{ __html: translate('addRemoteCheck.subTitle2') }}
        ></Subtitle>
        <Box>
          <Controller
            name="routingNumber"
            control={control}
            defaultValue=""
            rules={{ required: true, validate: validRoutingNumber }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="routing-number"
                variant="filled"
                data-testid="routingNumber"
                type="tel"
                label={translate('addRemoteCheck.form.routingNumber')}
                autoComplete="off"
                value={value}
                onKeyPress={(event) => {
                  if (!numericPattern.test(event?.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? translate('addRemoteCheck.error.routingNumber') : null}
              />
            )}
          />
          <Controller
            name="accountNumber"
            control={control}
            rules={{ required: true, validate: validAccountNumber }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="account-number"
                variant="filled"
                data-testid="accountNumber"
                type="tel"
                label={translate('addRemoteCheck.form.accountNumber')}
                autoComplete="off"
                value={value}
                onKeyPress={(event) => {
                  if (!numericPattern.test(event?.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? translate('addRemoteCheck.error.accountNumber') : null}
              />
            )}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          {translate('buttons.continue')}
        </Button>
        <Button secondary={true} onClick={goBackHandler}>
          {translate('buttons.back')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default AddRemoteCheck;
