import { gql } from '@apollo/client/core';

export const GET_LOAN = gql`
  query getLoan($input: LoanInput) {
    getLoan(input: $input) {
      amount
      applicationId
      autoPay
      avoidance
      borrowerId
      ceaseAndDesist
      channel
      createdAt
      createdBy
      demo
      displayId
      dispute
      doNotCall
      doNotReport
      downPaymentAmount
      downPaymentAttempt
      downPaymentPaymentMethodId
      financeCharge
      financialHardship
      forbearance
      fraud
      fundedAt
      grossAmount
      id
      installmentAmount
      installmentPaymentMethodId
      merchantFund
      merchantId
      merchantRevenue
      partner
      productId
      purchaseAmount
      selfCheckout
      status
      subStatus
      totalAmount
    }
  }
`;

export const GET_FUNDED_CONTRACTS = gql`
  query fetchLoanListForApplication($input: LoansForApplicationRequest) {
    fetchLoanListForApplication(input: $input) {
      total
      contents {
        id
        paymentSchedules {
          dueAt
        }
      }
    }
  }
`;
