import React, { useState } from 'react';
import styled from 'styled-components';
import useStore from 'lib/hooks/useStore';
import { HelpMeModal } from '../HelpMeModal';
import { useHelpModal } from 'lib/hooks/useHelpModal';
import { useTranslation } from 'react-i18next';
import { PageEventActions, IframeEventTypes, useIframeEvents } from 'lib/hooks/useIframeEvents';

export const HaveQuestionsModal = (): JSX.Element | null => {
  const { isAlle } = useStore();
  const { onEmailPressed, onCallPressed, logoutPressed } = useHelpModal();
  const { t: translate } = useTranslation();
  const { pushEvent } = useIframeEvents();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const toggleModalOpenState = () => {
    setModalOpen(!isModalOpen);
    if (isAlle) {
      pushEvent(IframeEventTypes.PAGE, PageEventActions.HAVE_QUESTIONS);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  if (isAlle) {
    return (
      <>
        <LinkButton onClick={toggleModalOpenState}>{translate('buttons.haveQuestions')}</LinkButton>
        <HelpMeModal
          show={isModalOpen}
          hideModal={closeModal}
          emailPressed={onEmailPressed}
          callPressed={onCallPressed}
          logoutPressed={logoutPressed}
          title={translate('buttons.haveQuestions')}
          center={true}
        />
      </>
    );
  }

  return null;
};

const LinkButton = styled.button`
  color: ${(props) => props.theme.main.alleBlack};
  text-align: center;
  font-family: 'Graphik', sans-serif;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  border: none;
  background-color: ${(props) => props.theme.main.white};
`;
