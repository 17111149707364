import { useState } from 'react';
import ApplyWithPatientReview from 'pages/ApplyWithPatient/ApplyWithPatientReview/ApplyWithPatientReview';
import ApplyWithPatientResult from 'pages/ApplyWithPatient/ApplyWithPatientResult';
import { ApplyWithPatientPageSteps } from 'pages/ApplyWithPatient/type';

const ApplyWithPatient = () => {
  const [step, setStep] = useState<ApplyWithPatientPageSteps>('review');

  const getApplyWithPatientPage = () => {
    switch (step) {
      case 'review':
        return <ApplyWithPatientReview setApplyWithPatientStep={setStep} />;
      case 'submit':
        return <ApplyWithPatientResult page="submit" />;
      case 'error':
        return <ApplyWithPatientResult page="error" />;
      case 'cancel':
        return <ApplyWithPatientResult page="cancel" />;
      default:
        return <ApplyWithPatientReview setApplyWithPatientStep={setStep} />;
    }
  };

  return getApplyWithPatientPage();
};

export default ApplyWithPatient;
