import React from 'react';
import styled from 'styled-components';

import { Icon } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { LetsGoScreenType } from 'lib/hooks/FeatureManagement/Flags';
import { useTranslation } from 'react-i18next';

interface Props {
  type?: LetsGoScreenType;
  onClickLetsGo: () => void;
}

export const CustomizedGetStarted = ({ onClickLetsGo, type = LetsGoScreenType.ZERO_APR }: Props) => {
  const { t: translate } = useTranslation();
  const { organization } = useStore();

  const getText = () => {
    switch (type) {
      case LetsGoScreenType.ZERO_APR:
        return (
          <Wrapper>
            <BaseText className="text-3xl font-semibold">{translate('customizedGetStarted.zeroApr.title')}</BaseText>
            <BaseText className="text-2xl">{translate('customizedGetStarted.zeroApr.subTitle')}</BaseText>
          </Wrapper>
        );

      case LetsGoScreenType.NO_IMPACT_TO_CREDIT:
        return (
          <Wrapper>
            <BaseText className="text-2xl font-bold">
              <span
                dangerouslySetInnerHTML={{
                  __html: translate('customizedGetStarted.noImpactToCredit.title'),
                }}
              />
              <Icon className="ml-2" width={24} height={24} src="lock-secure" />
            </BaseText>
          </Wrapper>
        );

      case LetsGoScreenType.FAST_APPLY:
        return (
          <Wrapper>
            <BaseText
              className="text-2xl font-bold"
              dangerouslySetInnerHTML={{
                __html: translate('customizedGetStarted.fastApply.title'),
              }}
            />
          </Wrapper>
        );

      case LetsGoScreenType.SOCIAL_PROOF:
        return (
          <>
            <Wrapper>
              <BaseText
                className="text-2xl font-bold"
                dangerouslySetInnerHTML={{
                  __html: translate('customizedGetStarted.socialProof.title'),
                }}
              />
            </Wrapper>
            <Icon className="mx-auto mt-4" width={158} height={32} src="five-stars" />
          </>
        );

      case LetsGoScreenType.PRACTICE_ENDORSEMENT:
        return (
          <Wrapper>
            <BaseText className="text-2xl font-bold">{organization?.name}</BaseText>
            <BaseText
              className="text-2xl"
              dangerouslySetInnerHTML={{
                __html: translate('customizedGetStarted.practiceEndorsement.title'),
              }}
            />
          </Wrapper>
        );

      default:
        break;
    }
  };

  const getBottomText = () => {
    switch (type) {
      case LetsGoScreenType.NO_IMPACT_TO_CREDIT:
        return <BaseText>{translate('customizedGetStarted.noImpactToCredit.subTitle')}</BaseText>;

      default:
        return <BaseText>{translate('customizedGetStarted.default')}</BaseText>;
    }
  };

  const getOrgName = () => {
    switch (type) {
      case LetsGoScreenType.PRACTICE_ENDORSEMENT:
        return <>&#8205;</>;

      default:
        return organization?.name;
    }
  };

  return (
    <Background>
      <TextContainer data-testid="title">
        {getText()}

        <LetsGoButton onClick={onClickLetsGo} data-testid="submit">
          {translate('buttons.continue')}
        </LetsGoButton>
        <BaseText className="text-xl">{getOrgName()}</BaseText>
      </TextContainer>
      <WhiteBackground>
        <ApplyButtonContent>
          <LogoRow>
            <Icon src="cherry-logo" width={28} height={28} />
            <Icon src="wordmark" width={67} height={22} m="3px 0 0 0" />
          </LogoRow>

          {getBottomText()}
        </ApplyButtonContent>
      </WhiteBackground>
    </Background>
  );
};

const Wrapper = styled.div``;

const BaseText = styled.div`
  color: ${(props) => props.theme.main.black};
  text-align: center;
  font-family: Open Sans, sans-serif;
`;

const LetsGoButton = styled.div`
  display: flex;
  width: 327px;
  height: 97px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.main.green};
  background: ${(props) => props.theme.main.green};
  box-shadow: 0px 1.23656px 2.30138px 0px rgba(0, 195, 125, 0.02), 0px 3.12736px 5.82036px 0px rgba(0, 195, 125, 0.03),
    0px 6.37951px 11.87298px 0px rgba(0, 195, 125, 0.04), 0px 13.14059px 24.4561px 0px rgba(0, 195, 125, 0.05),
    0px 36px 67px 0px rgba(0, 195, 125, 0.07);
  color: ${(props) => props.theme.main.white};
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  margin: 40px 0;
  cursor: pointer;
`;

const Background = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  background: ${(props) => props.theme.main.white};
  overflow: hidden;
`;

const WhiteBackground = styled.div`
  transform: translateX(-12%);
  width: 130%;
  display: flex;
  justify-content: center;
  border-radius: 100% 100% 0 0;
  background: ${(props) => props.theme.main.white}
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.04), 0px -24px 50px 0px rgba(0, 0, 0, 0.07);
`;

const ApplyButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
  padding: 24px;
  max-width: 80%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const LogoRow = styled.div`
  margin-top: 40px;
  margin-bottom: 16px;
  display: flex;
  align-items: inherit;
  gap: 3px;
`;
