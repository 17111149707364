import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const SsnInput = forwardRef<HTMLElement, CustomProps | any>(function TextMaskCustom(props, ref) {
  const { onChange, name, ...other } = props;
  const islast4ssn = name === 'last4ssn';

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const onAcceptHandler = (value: string, mask: any) => {
    props.onChange({ target: { name: props.name, value: returningValue(mask) } });
  };

  const returningValue = (mask) => {
    if (islast4ssn) {
      return mask.value.replace(/[X-]/g, '');
    } else {
      return mask.value;
    }
  };

  return (
    <IMaskInput
      {...other}
      mask={islast4ssn ? '0000' : '000-00-0000'}
      definitions={{ '#': /[1-9]/ }}
      inputRef={ref}
      onAccept={onAcceptHandler}
      overwrite={true}
    />
  );
});
