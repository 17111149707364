import styled from 'styled-components';

import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useTranslation } from 'react-i18next';

const CheckoutInStore = () => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const { organization } = useStore();

  const renderSMSOwner = () => {
    const { borrower } = useStore.getState() || {};
    return `${borrower?.firstName} ${borrower?.lastName}`;
  };

  const backToMyApproval = () => {
    navigate('approval');
  };

  return (
    <Container showBackButton={true} backUrl={`checking-out`}>
      <ContentContainer>
        <Icon src={'checkout-register-pos'} />
        <Title m={'10px 0px 0px 0px'}>{translate('checkoutInStore.title')}</Title>
        <Subtitle m={'10px 0px 24px 0px'}>
          {translate('checkoutInStore.subTitle', {
            name: organization?.name,
          })}
        </Subtitle>
        <SmsContainer>
          <SmsOwner>{renderSMSOwner()}</SmsOwner>
          <SmsBubbleContainer>{translate('checkoutInStore.cherryPay')}</SmsBubbleContainer>
          <IconContainer>
            <Icon src={'tail'} />
          </IconContainer>
        </SmsContainer>
        <Subtitle m={'45px 0px 0px 0px'}>{translate('checkoutInStore.chooseAPlan')}</Subtitle>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={backToMyApproval} secondary={true}>
          {translate('buttons.backToMyApproval')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default CheckoutInStore;

const SmsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
`;

const SmsBubbleContainer = styled.div`
  padding: 12px 16px;
  border-radius: 25px;
  background-color: ${(props) => props.theme.main.green};
  color: ${(props) => props.theme.main.white};
  font-weight: 400;
  font-size: 19px;
  line-height: 23px;
  text-align: right;
  align-self: end;
  margin-top: 7px;
`;

const IconContainer = styled.div`
  position: absolute;
  right: -10px;
  bottom: -12px;
`;

const SmsOwner = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: right;
  color: ${(props) => props.theme.main.lightGrayColor};
`;
