import React from 'react';

import { Button, Container } from 'lib/components';
import { ButtonContainer } from 'lib/components/Common';
import { useNavigation } from 'lib/hooks/useNavigation';

import FaqList from './components';
import { useTranslation } from 'react-i18next';

const Faq = () => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();

  const backToMyApproval = () => {
    navigate('approval');
  };

  return (
    <Container showBackButton={true} backUrl={`approval`}>
      <FaqList />
      <ButtonContainer>
        <Button onClick={backToMyApproval} secondary={true}>
          {translate('buttons.backToMyApproval')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default Faq;
