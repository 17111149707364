import { useMutation } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { UPDATE_BORROWER } from 'lib/graphql/mutations';

import { US_STATES, WEBPREQUAL } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { poBoxRegex, prohibitedStreetRegex } from 'lib/utils';
import { numericPattern, trapSpacesForRequiredFields } from 'lib/utils/Validators';
import useAddressAutocomplete from 'lib/hooks/useAddressAutocomplete';

import { useServices } from 'pages/AuthChecker/services';
import { ApplicationFlowStatuses } from 'pages/AuthChecker/type';

import { Box, Button, Container, Icon, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';

import { useBorrowerKycAddress } from './services';
import { FormDataType, ISelectedAddress, USStates } from './types';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import MovingModal from './MovingModal';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

type Address = {
  address: string;
  borrowerKYCOutcomeId: string;
  city: string;
  state: string;
  zip: string;
};

enum ModalType {
  QUESTION = 'QUESTION',
  CONFIRMATION = 'CONFIRMATION',
  PREVIOUS_ADDRESS = 'PREVIOUS_ADDRESS',
}

const PersonalAddressVerify = () => {
  const alert = useAlert();
  const { initPage } = useNeuroId();
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { captureException } = useSentry();
  const { trackSegmentEvent, trackIdentify } = useSegment();
  const [enteredAddress, setEnteredAddress] = useState<Address>();
  const { createApplicationFlow } = useServices();
  const [showMovingModal, setShowMovingModal] = useState(false);
  const loadSegmentController = useRef(true);

  const { t: translate } = useTranslation();
  const { setSessionData, setPageIndex, organization, sessionData, applicationFlow } = useStore();
  const [modalType, setModalType] = useState<ModalType>(ModalType.QUESTION);

  const [loading, setLoading] = useState(false);

  const { getBorrowerKycAddress } = useBorrowerKycAddress();
  const { startCheckApplicationStatus } = useSSEContext();

  const [updateBorrower] = useMutation(UPDATE_BORROWER);
  const { sendLoadSegmentEvent } = useSegmentContext();

  const { setSearchTerm, autocompleteData, loading: autoCompleteLoading } = useAddressAutocomplete();

  const info = sessionData;
  const isKYCReasonA2 = applicationFlow?.status?.includes(ApplicationFlowStatuses.ADDRESS_VERIFY_MAJOR);
  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: isKYCReasonA2 ? {} : info,
  });

  useEffect(() => {
    getInitialRequest();
    if (isKYCReasonA2) {
      setShowMovingModal(true);
    }
    return () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (loadSegmentController.current && isKYCReasonA2) {
      sendLoadSegmentEvent(WPQSegmentNames.recentlyMovedModalLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(7);
  }, [setPageIndex]);

  useEffect(() => {
    initPage('address-verify');
  }, []);

  const getInitialRequest = async () => {
    const { borrower } = useStore.getState() || {};
    const response = await getBorrowerKycAddress(borrower?.id);
    setEnteredAddress(response);
    if (response?.borrowerKYCOutcomeId) {
      setAddressValues({
        ...response,
        street: response?.address,
      });
    }
  };

  const setAddressValues = (selectedAddress: ISelectedAddress) => {
    if (selectedAddress) {
      setValue('street', selectedAddress?.street, { shouldValidate: true });
      setValue('city', selectedAddress?.city, { shouldValidate: true });
      setValue('state', selectedAddress?.state, { shouldValidate: true });
      setValue('addressLine2', selectedAddress?.addressLine2, { shouldValidate: true });
      setValue('zip', selectedAddress?.zip, { shouldValidate: true });
    }
  };

  const clearAddressValues = () => {
    setValue('street', '', { shouldValidate: false });
    setValue('city', '', { shouldValidate: false });
    setValue('state', '', { shouldValidate: false });
    setValue('zip', '', { shouldValidate: false });
  };

  const onSubmit = async (formData: FormDataType) => {
    const { application } = useStore.getState() || {};
    try {
      setLoading(true);
      setSessionData(formData);
      const { borrower } = useStore.getState();

      if (poBoxRegex.test(formData?.street)) {
        alert.info(translate('personalAddressVerify.error.pobox'));
      } else if (prohibitedStreetRegex.test(formData?.street)) {
        alert.info(translate('personalAddressVerify.error.street'));
      } else {
        trackEvent(AnalyticEventNames.BI_SEE_IF_QUALIFY);

        trackIdentify({ ...borrower, ...sessionData, ...formData });
        trackSegmentEvent('Application Submitted', {
          organizationSlug: organization?.slug,
          organizationName: organization?.name,
          application: WEBPREQUAL,
          value: 30,
          userId: borrower?.id,
        });

        if (borrower?.id) {
          const { data } = await updateBorrower({
            variables: {
              input: {
                source: 'GREET',
                id: borrower?.id,
                address: {
                  city: formData?.city,
                  zip: formData?.zip,
                  stateCode: formData?.state,
                  street: formData?.street,
                  addressLine2: formData?.addressLine2,
                },
                dob: sessionData?.dob,
                firstName: sessionData?.firstName,
                lastName: sessionData?.lastName,
                ...(modalType === ModalType.PREVIOUS_ADDRESS && { addressType: 'PREVIOUS' }),
              },
            },
          });

          if (data?.updateBorrower?.success) {
            const res = await createApplicationFlow();
            if (res?.success) {
              startCheckApplicationStatus(application?.id);
            }
          } else {
            setLoading(false);
            alert.info(translate('personalAddressVerify.error.default'));
          }
        } else {
          setLoading(false);
          captureException('Error', { message: 'Borrower ID not found', session: sessionData });

          alert.info(translate('personalAddressVerify.error.default'));
          navigate(``);
        }
      }
    } catch (err) {
      captureException(err, { message: 'Address Verify Catch Error', session: sessionData });
      alert.info(translate('personalAddressVerify.error.default'));
      setLoading(false);
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const title = () => {
    return modalType === ModalType.PREVIOUS_ADDRESS
      ? translate('personalAddressVerify.title1')
      : translate('personalAddressVerify.title2');
  };

  const confirmEnteredAddress = () => {
    if (enteredAddress) {
      const enteredInfo: FormDataType = {
        city: enteredAddress?.city,
        zip: enteredAddress?.zip,
        state: enteredAddress?.state,
        street: enteredAddress?.address,
      };
      onSubmit(enteredInfo);
    }
  };

  const hideMovingModal = () => {
    setShowMovingModal(false);
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'location-pin-bad-mismatch'} />
        <Title m={'10px 0px 32px 0px'} data-testid="address-verify-title">
          {title()}
        </Title>
        <Box>
          <Controller
            name="street"
            control={control}
            defaultValue=""
            rules={{ required: true, validate: trapSpacesForRequiredFields }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <Autocomplete
                options={autocompleteData}
                value={value}
                onChange={(event, newValue: ISelectedAddress) => {
                  setAddressValues(newValue);
                }}
                id="disable-clearable"
                disableClearable={true}
                renderInput={(params) => {
                  delete params.inputProps.className;
                  return (
                    <TextField
                      {...params}
                      id="street-address"
                      variant="filled"
                      data-testid="street"
                      label={translate('personalAddressVerify.form.label.street')}
                      name="street"
                      onKeyDown={checkKeyDown}
                      value={value}
                      onChange={(e) => {
                        setSearchTerm(e?.target?.value);
                        onChange(e?.target?.value);
                      }}
                      onBlur={onBlur}
                      error={!!error}
                      helperText={error ? error?.message : null}
                    />
                  );
                }}
              />
            )}
          />
          <Controller
            name="addressLine2"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="apt-addressLine2"
                variant="filled"
                data-testid="addressLine2"
                data-neuro-label="address"
                label={translate('personalAddressVerify.form.label.unit')}
                name="addressLine2"
                autoComplete="new-password"
                value={value}
                onKeyDown={checkKeyDown}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            )}
          />
          <RowContainer>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: trapSpacesForRequiredFields }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="city"
                  variant="filled"
                  data-testid="city"
                  data-neuro-label="city"
                  data-name="city"
                  label={translate('personalAddressVerify.form.label.city')}
                  value={value}
                  onKeyDown={checkKeyDown}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />
            <Controller
              name="state"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="state"
                  variant="filled"
                  data-testid="state"
                  data-name="state"
                  label={translate('personalAddressVerify.form.label.state')}
                  select={true}
                  value={value}
                  onKeyDown={checkKeyDown}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error?.message : null}
                >
                  {US_STATES.map((option: USStates) => (
                    <MenuItem data-testid="stateItem" key={option?.value} value={option?.value}>
                      {option?.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </RowContainer>
          <Controller
            name="zip"
            control={control}
            defaultValue=""
            rules={{ required: true, maxLength: 5, minLength: 5 }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="zip-code"
                variant="filled"
                data-testid="zip"
                data-neuro-label="zip"
                data-name="zip"
                type="tel"
                inputProps={{
                  maxLength: 5,
                }}
                label={translate('personalAddressVerify.form.label.zip')}
                value={value}
                onKeyPress={(event) => {
                  if (!numericPattern.test(event?.key)) {
                    event.preventDefault();
                  }
                }}
                onKeyDown={checkKeyDown}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            )}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={!isValid || loading || autoCompleteLoading}
          loading={loading || autoCompleteLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
      <MovingModal
        show={showMovingModal}
        hideModal={hideMovingModal}
        enteredAddress={enteredAddress}
        setModalType={setModalType}
        modalType={modalType}
        confirmAddress={confirmEnteredAddress}
        clearForm={clearAddressValues}
      />
    </Container>
  );
};

export default PersonalAddressVerify;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;

  div[data-name='city'] {
    flex: 4;
    text-align: left;
  }

  div[data-name='state'] {
    flex: 2;
    margin-left: 8px;
  }

  @media (max-width: ${(props) => props.theme.size.mobileL}) {
    width: 100%;
  }
`;
