import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import { Accordion, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';

import faqList from './faqWhatHappensNext.json';
import { theme } from 'config/theme';
import styled from 'styled-components';
import { useViewport } from 'lib/hooks/useViewport';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';

const FaqWhatHappenNext = () => {
  const { defaultLanguage } = useStore();
  const { t: translate } = useTranslation();
  const { isMobile } = useViewport();
  const [expanded, setExpanded] = useState<string | false>('0');
  const { sendActionSegmentEvent } = useSegmentContext();

  const handleChange = (panel: string) => {
    const panelNumber = Number(panel.replace('panel', ''));
    panelNumber && sendActionSegmentEvent(`WPQ.OUTCOME.APPROVAL_WHAT_HAPPENS_NEXT_FAQ_${panelNumber + 1}_CLICKED`);
    setExpanded(panel);
  };

  const accordionBackGroundColor = () => {
    return isMobile ? theme.main.whiteGray : theme.main.white;
  };

  return (
    <CustomContentContainer IsMobile={isMobile}>
      <Title m={'10px 0 0 0'}>{translate('faqList.title')}</Title>
      <Accordion
        bgColor={accordionBackGroundColor()}
        data={faqList?.[defaultLanguage || LANGUAGES[0]]}
        onChange={handleChange}
        expanded={expanded}
      />
    </CustomContentContainer>
  );
};

const CustomContentContainer = styled(ContentContainer)<{ IsMobile: boolean }>`
  background-color: ${(props) => (props?.IsMobile ? theme.main.whiteGray : theme.main.white)};
`;

export default FaqWhatHappenNext;
