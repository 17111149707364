import styled from 'styled-components';

import { Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useTranslation } from 'react-i18next';

const CantFindBankAccount = () => {
  const { navigateBack } = useNavigation();
  const { t: translate } = useTranslation();

  const connectBankAccountHandler = () => {
    navigateBack();
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <IconContainer>
            <Icon src={'cherry-logo'} width={24} height={24} />
            <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 4px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 12px 0px 4px'} width={4} height={4} src={'dot'} />
            <Icon src={'bank'} />
          </IconContainer>
          <Title m={'10px 0px 24px 0px'}>{translate('cantFindBankAccount.title')}</Title>
          <Subtitle m={'10px 0px 24px 0px'}>{translate('cantFindBankAccount.subTitle')}</Subtitle>
          <SubtitleItem>
            <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
            <Subtitle m={'0px 0px 0px 0px'}>{translate('cantFindBankAccount.payroll')}</Subtitle>
          </SubtitleItem>
          <SubtitleItem>
            <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
            <Subtitle m={'0px 0px 0px 0px'}>{translate('cantFindBankAccount.paystubs')}</Subtitle>
          </SubtitleItem>
          <Subtitle m={'24px 0px 24px 0px'}>{translate('cantFindBankAccount.message.title')}</Subtitle>
        </InnerContainer>
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>{translate('cantFindBankAccount.message.subTitle')}</InfoText>
        </InfoContainer>
      </ContentContainer>
      <ButtonContainer>
        <ButtonText onClick={connectBankAccountHandler}>{translate('buttons.connectMyBankAccount')}</ButtonText>
      </ButtonContainer>
    </Container>
  );
};

export default CantFindBankAccount;

const SubtitleItem = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const ButtonText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.main.green};
  justify-content: center;
  display: flex;
  cursor: pointer;
  margin: 24px 0 12px;
  text-decoration: underline;
`;
