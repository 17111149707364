import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormControl, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';

import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';

import { emailPattern, FormatAmount } from 'lib/utils';
import { MINIMUM_AMOUNT, TRACK_VERSION, WEBPREQUAL } from 'lib/constants';
import { useCherryAccess } from 'lib/hooks/useCherryAccess';

import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { Box, Button, Container, Icon, NumberFormatInput, Subtitle, TextField, Title } from 'lib/components';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useTranslation } from 'react-i18next';

enum CherryAccessReviewItems {
  EMAIL = 'EMAIL',
  HOUSING_SITUATION = 'HOUSING_SITUATION',
  HOUSING_PAYMENT = 'HOUSING_PAYMENT',
  STATED_INCOME = 'STATED_INCOME',
  REQUESTED_AMOUNT = 'REQUESTED_AMOUNT',
}

export const Review = ({ missingInfos }) => {
  const { trackSegmentEvent } = useSegment();
  const { t: translate } = useTranslation();
  const { application, sessionApiData } = useStore.getState() || {};
  const { cherryAccess } = sessionApiData || {};

  const { loading, createAccess, createLoading } = useCherryAccess();
  const { startCheckApplicationStatus } = useSSEContext();

  const [requestedAmount, setRequestedAmount] = useState(cherryAccess?.amount);
  const [housingPayment, setHousingPayment] = useState<number | null>(null);
  const [housingSituation, setHousingSituation] = useState<string | null>(null);

  const [statedIncome, setStatedIncomePayment] = useState<number | null>(null);
  const [email, setEmail] = useState<number | null>(null);

  const [isMaxError, setMaxError] = useState(false);
  const [isMinError, setMinError] = useState(false);
  const [isEmailError, setEmailError] = useState(false);
  const [isSituationError, setIsSituationError] = useState(false);

  const [isEditInProcess, setIsEditInProcess] = useState(false);

  const handleMaxError = () => {
    setMaxError(true);
  };

  const handleMinError = () => {
    setMinError(true);
  };

  const handleMailError = () => {
    setEmailError(true);
  };

  const handleSituationError = () => {
    setIsSituationError(true);
  };

  const resetErrors = () => {
    setMaxError(false);
    setMinError(false);
    setEmailError(false);
    setIsSituationError(false);
  };

  useEffect(() => {
    trackSegmentEvent('cherry_access_review_viewed', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
    });
  }, []);

  const next = async () => {
    trackSegmentEvent('cherry_access_completed', { application: WEBPREQUAL });
    const statedInfos = missingInfos?.map((mInfo) => {
      let value;
      switch (mInfo.key) {
        case CherryAccessReviewItems.REQUESTED_AMOUNT:
          value = requestedAmount ? requestedAmount : mInfo?.value;
          break;
        case CherryAccessReviewItems.HOUSING_PAYMENT:
          value = housingPayment ? housingPayment : mInfo?.value;
          break;
        case CherryAccessReviewItems.HOUSING_SITUATION:
          value = housingSituation ? housingSituation : mInfo?.value;
          break;
        case CherryAccessReviewItems.STATED_INCOME:
          value = statedIncome ? statedIncome : mInfo?.value;
          break;
        case CherryAccessReviewItems.EMAIL:
          value = email ? email : mInfo?.value;
      }

      return { type: mInfo.key, value: String(value) };
    });

    await createAccess(application?.id, statedInfos);
    await startCheckApplicationStatus({});
  };

  const getMissingInfoTitle = (key: string) => {
    switch (key) {
      case CherryAccessReviewItems.REQUESTED_AMOUNT:
        return translate('cherryAccess.review.estimatedTreatmentCost');
      case CherryAccessReviewItems.HOUSING_PAYMENT:
        return translate('cherryAccess.review.monthlyHousingCost');
      case CherryAccessReviewItems.HOUSING_SITUATION:
        return translate('cherryAccess.review.housingSituation');
      case CherryAccessReviewItems.EMAIL:
        return translate('cherryAccess.review.primaryEmail');
      case CherryAccessReviewItems.STATED_INCOME:
        return translate('cherryAccess.review.annualHouseholdIncome');
    }
  };

  const onChanged = (key, value) => {
    switch (key) {
      case CherryAccessReviewItems.REQUESTED_AMOUNT:
        trackSegmentEvent('cherry_access_request_amount', { eventValue: value, application: WEBPREQUAL });
        setRequestedAmount(value);
        break;
      case CherryAccessReviewItems.HOUSING_PAYMENT:
        setHousingPayment(value);
        break;
      case CherryAccessReviewItems.HOUSING_SITUATION:
        setHousingSituation(value);
        break;
      case CherryAccessReviewItems.STATED_INCOME:
        setStatedIncomePayment(value);
        break;
      case CherryAccessReviewItems.EMAIL:
        setEmail(value);
        break;
    }
  };

  const toggleIsEditInProcess = () => setIsEditInProcess(!isEditInProcess);

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'glasses-review'} />
        <Title m={'10px 0px 8px 0px'}>{translate('cherryAccess.review.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('cherryAccess.review.subTitle')}</Subtitle>
        <Box>
          {missingInfos?.map((mInfo, index) => (
            <CherryAccessInfo
              key={`${mInfo?.key}-${index}`}
              itemKey={mInfo.key}
              title={getMissingInfoTitle(mInfo.key)}
              value={mInfo?.value}
              index={index}
              onChanged={onChanged}
              toggleIsEditInProcess={toggleIsEditInProcess}
              isEditInProcess={isEditInProcess}
              handleMailError={handleMailError}
              isEmailError={isEmailError}
              isSituationError={isSituationError}
              handleSituationError={handleSituationError}
              resetErrors={resetErrors}
              handleMaxError={handleMaxError}
              handleMinError={handleMinError}
              isMaxError={isMaxError}
              isMinError={isMinError}
            />
          ))}
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <InnerContainer>
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_outline'} />
            <InfoText>{translate('cherryAccess.review.info')}</InfoText>
          </InfoContainer>
        </InnerContainer>
        <Button
          onClick={next}
          loading={createLoading || loading}
          disabled={
            createLoading || loading || isEmailError || isSituationError || isMaxError || isMinError || isEditInProcess
          }
        >
          {translate('buttons.submit')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const CherryAccessInfo = ({
  title,
  value,
  itemKey,
  index,
  onChanged,
  toggleIsEditInProcess,
  isEditInProcess,
  handleMaxError,
  handleMinError,
  isMaxError,
  isMinError,
  resetErrors,
  handleMailError,
  isEmailError,
  isSituationError,
  handleSituationError,
}) => {
  const { application } = useStore.getState() || {};
  const [canEditable, setCanEditable] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const { t: translate } = useTranslation();

  const getMissingInfoValue = () => {
    switch (itemKey) {
      case 'EMAIL':
      case 'HOUSING_SITUATION':
        return inputValue;
      default:
        return FormatAmount(Number(inputValue));
    }
  };

  const onEditOrSaveBtnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!canEditable) {
      setCanEditable(true);
      toggleIsEditInProcess();
      return;
    }

    if (itemKey === CherryAccessReviewItems.REQUESTED_AMOUNT) {
      if (inputValue && inputValue > application?.menu?.maxPurchase) {
        handleMaxError?.();
      } else if (inputValue && inputValue <= (application?.balanceAvailable ?? MINIMUM_AMOUNT)) {
        handleMinError?.();
      } else {
        onChanged(itemKey, inputValue);
      }
    } else if (itemKey === CherryAccessReviewItems.EMAIL) {
      if (inputValue && !emailPattern.test(inputValue)) {
        handleMailError?.();
      } else {
        onChanged(itemKey, inputValue);
      }
    } else if (itemKey === CherryAccessReviewItems.HOUSING_SITUATION) {
      if (['Own', 'Rent', 'Other'].includes(inputValue)) {
        onChanged(itemKey, inputValue);
      } else {
        handleSituationError?.();
      }
    } else {
      onChanged(itemKey, inputValue);
    }

    setCanEditable(false);
    toggleIsEditInProcess();
  };

  const onInputChange = (e) => {
    const inputVal = e?.target?.value;
    resetErrors?.();

    if (itemKey === CherryAccessReviewItems.EMAIL || itemKey === CherryAccessReviewItems.HOUSING_SITUATION) {
      setInputValue(inputVal);
    } else {
      setInputValue(Number(inputVal));
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const isEmail = itemKey === CherryAccessReviewItems.EMAIL;
  const isHousingSituation = itemKey === CherryAccessReviewItems.HOUSING_SITUATION;
  const isNumeric = [
    CherryAccessReviewItems.HOUSING_PAYMENT,
    CherryAccessReviewItems.STATED_INCOME,
    CherryAccessReviewItems.REQUESTED_AMOUNT,
  ].includes(itemKey);
  const inputType = isNumeric ? 'tel' : isEmail ? 'email' : 'text';

  return (
    <>
      <InfoItem hasError={isMaxError || isMinError || isEmailError || isSituationError}>
        <InfoItemInputContainer>
          {canEditable && !isHousingSituation ? (
            <TextField
              id="purchase-amount-input"
              variant="filled"
              data-testid="purchase"
              data-neuro-label="purchase"
              label={title}
              type={inputType}
              value={inputValue}
              onChange={onInputChange}
              onKeyDown={checkKeyDown}
              InputProps={
                isNumeric && {
                  inputComponent: NumberFormatInput,
                  pattern: '[^0-9.]',
                  sx: {
                    '& .MuiInputLabel-root': { fontSize: '10px !important', color: '#56636D' },
                    '& label': { fontSize: '10px !important', color: '#56636D' },
                  },
                  startAdornment: (
                    <InputAdornment
                      sx={{
                        padding: '1px 0 0 0',
                        '& .MuiTypography-root': { color: '#000' },
                      }}
                      position="start"
                    >
                      $
                    </InputAdornment>
                  ),
                }
              }
            />
          ) : null}

          {canEditable && isHousingSituation ? (
            <FormControl sx={{ m: 0, minWidth: 240 }}>
              <InputLabel id="housing-situation-select-label">
                {translate('cherryAccess.review.housingSituation')}
              </InputLabel>
              <Select
                labelId="housing-situation-select-label"
                id="housing-situation-select"
                value={inputValue}
                label="Housing Situation"
                onChange={onInputChange}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value={'Rent'}>{translate('cherryAccess.review.menu.item1')}</MenuItem>
                <MenuItem value={'Own'}>{translate('cherryAccess.review.menu.item2')}</MenuItem>
                <MenuItem value={'Other'}>{translate('cherryAccess.review.menu.item3')}</MenuItem>
              </Select>
            </FormControl>
          ) : null}

          {!canEditable ? (
            <>
              <FormInfoText>{title}</FormInfoText>
              <InfoTextBold>{getMissingInfoValue()}</InfoTextBold>
            </>
          ) : null}
        </InfoItemInputContainer>
        <InfoEditButtonContainer>
          <Button p="8px 16px" mb={true} onClick={onEditOrSaveBtnClick} disabled={isEditInProcess && !canEditable}>
            {canEditable ? translate('buttons.save') : translate('buttons.edit')}
          </Button>
        </InfoEditButtonContainer>
      </InfoItem>

      <ErrorContainer>
        {itemKey === 'REQUESTED_AMOUNT' && isMinError ? (
          <ErrorMessage>
            {translate('cherryAccess.review.errors.isMin', {
              amount: FormatAmount(application?.balanceAvailable ? application?.balanceAvailable : MINIMUM_AMOUNT),
            })}
          </ErrorMessage>
        ) : null}

        {itemKey === 'REQUESTED_AMOUNT' && isMaxError ? (
          <ErrorMessage>
            {translate('cherryAccess.review.errors.isMin', {
              amount: FormatAmount(application?.menu?.maxPurchase),
            })}
          </ErrorMessage>
        ) : null}

        {itemKey === 'EMAIL' && isEmailError ? (
          <ErrorMessage>{translate('cherryAccess.review.errors.isEmail')}</ErrorMessage>
        ) : null}

        {itemKey === 'HOUSING_SITUATION' && isSituationError ? (
          <ErrorMessage>{translate('cherryAccess.review.errors.other')}</ErrorMessage>
        ) : null}
      </ErrorContainer>
    </>
  );
};

const InfoItem = styled.div<{ hasError?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ hasError }) => (hasError ? '12px' : '24px')};
  height: 60px;
`;

const InfoItemInputContainer = styled.div``;

const FormInfoText = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  color: ${(props) => props.theme.main.black};
`;

const InfoTextBold = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.black};
`;

const InfoEditButtonContainer = styled.div`
  width: 55px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const ErrorContainer = styled.div`
  flex: 1;
`;

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.main.redColor};
  margin-bottom: 12px;
`;
