import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { WEBPREQUAL } from 'lib/constants';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useEligibilityService } from 'pages/AuthChecker/services';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { INCOME_PROVIDER } from 'pages/ConnectBankAccount/ConnectBankAccount';
import AlleConnectWithPlaidRetry from './AlleConnectWithPlaidRetry';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

const ConnectWithPlaidRetry = () => {
  const { trackEvent } = useAnalytics();

  const { organization, isAlle } = useStore();
  const { navigate } = useNavigation();
  const { trackIncomeVerification } = useSegment();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();

  const { getBorrowerIncomeVerification } = useEligibilityService();

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.incomeVerificationNotConnectBankLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const navigateToUploadPaystub = () => {
    const { application } = useStore.getState() || {};
    sendActionSegmentEvent(WPQSegmentNames.almostThereRepromptPaystubPayrollBank);
    navigate(`upload-paystub/${application?.id}`);
  };

  const goToPlaid = async () => {
    const { application } = useStore.getState() || {};
    sendActionSegmentEvent(WPQSegmentNames.almostThereRepromptConnectBank);
    trackEvent(AnalyticEventNames.CWP_LINK_ACCOUNT);
    trackIncomeVerification(organization, application, INCOME_PROVIDER.PLAID);

    setLoading(true);
    const response = await getBorrowerIncomeVerification('ASSET_REPORT');
    if (response?.id) {
      setLoading(false);
      navigate(`plaid-auth/${response?.id}`);
    }
  };

  if (isAlle) {
    return (
      <AlleConnectWithPlaidRetry
        goToPlaid={goToPlaid}
        loading={loading}
        navigateToUploadPaystub={navigateToUploadPaystub}
      />
    );
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <IconContainer>
            <Icon src={'cherry-logo'} width={24} height={24} />
            <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 4px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 12px 0px 4px'} width={4} height={4} src={'dot'} />
            <Icon src={'bank'} />
          </IconContainer>

          <Title m={'10px 0px 8px 0px'}>{translate('connectWithPlaidRetry.title')}</Title>
          <Subtitle m={'0px 0px 30px 0px'}>{translate('connectWithPlaidRetry.subTitle')}</Subtitle>
        </InnerContainer>
        <InnerContainer>
          <InfoContainer>
            <Icon width={16} height={20} src={'lock-secure'} />
            <InfoText>
              {translate('connectWithPlaidRetry.info', {
                name: organization?.name,
              })}
            </InfoText>
          </InfoContainer>
        </InnerContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={loading} loading={loading} onClick={goToPlaid}>
          {translate('buttons.connectBank')};
        </Button>
        <ButtonText onClick={navigateToUploadPaystub}>{translate('buttons.connectPayrollPaystubsInstead')}</ButtonText>
      </ButtonContainer>
    </Container>
  );
};

export default ConnectWithPlaidRetry;

const ButtonText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.main.green};
  margin: 24px 0;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
