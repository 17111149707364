import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { emailPattern } from 'lib/utils';
import { useSegment } from 'lib/hooks/useSegment';
import { TRACK_VERSION, WEBPREQUAL } from 'lib/constants';

import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { useTranslation } from 'react-i18next';
import useStore from 'lib/hooks/useStore';

export const Email = ({ saveEmailInfo }) => {
  const { trackSegmentEvent } = useSegment();
  const { sessionApiData } = useStore();
  const { t: translate } = useTranslation();
  const { missingInfo } = sessionApiData || {};
  const initialEmailInfo = missingInfo?.find((data) => data?.key === 'EMAIL');

  const [isFormValid, setIsFormValid] = useState(initialEmailInfo?.value?.length > 0);

  const { handleSubmit, control, getValues, setError, clearErrors, watch } = useForm({
    mode: 'onSubmit',
    defaultValues: { email: initialEmailInfo?.value },
  });

  useEffect(() => {
    trackSegmentEvent('cherry_access_email_viewed', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
    });
  }, []);

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (isFormValid) {
        next();
      }
    }
  };

  const next = () => {
    const { email } = getValues();
    if (emailPattern.test(email)) {
      saveEmailInfo(email);
    } else {
      setIsFormValid(false);
      setError('email', { type: 'pattern', message: translate('cherryAccess.email.error') });
    }
  };

  const emailValue = watch('email');

  const onFormValueChange = (e, callback) => {
    clearErrors('email');
    const searchTerm = '@';
    const value = e?.target?.value;

    let isActive = false;
    if (value) {
      if (value.indexOf(searchTerm) >= 0 && emailPattern.test(value)) {
        isActive = true;
      } else if (value.indexOf(searchTerm) === -1) {
        isActive = false;
      }
    }

    setIsFormValid(isActive);
    callback(e);
  };

  return (
    <Container showBackButton={true} backUrl={'approval-boost/additional-info/housing-rent'}>
      <ContentContainer>
        <Icon src={'mail'} />
        <Title m={'10px 0px 8px 0px'}>{translate('cherryAccess.email.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('cherryAccess.email.subTitle')}</Subtitle>
        <Box>
          <Controller
            name="email"
            control={control}
            defaultValue={initialEmailInfo?.value}
            rules={{
              required: true,
              pattern: {
                value: emailPattern,
                message: translate('cherryAccess.email.form.emailMessage'),
              },
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="email"
                  data-testid="email"
                  data-neuro-label="email"
                  variant="filled"
                  label={translate('cherryAccess.email.form.emailLabel')}
                  type="text"
                  value={value}
                  onChange={(e) => onFormValueChange(e, onChange)}
                  onKeyDown={checkKeyDown}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              );
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={next} disabled={!isFormValid || (!emailValue && emailValue !== '')}>
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};
