export const getDefaultHighLineUrl = (key) => {
  switch (key) {
    case 'HOUSING_PAYMENT':
      return 'housing-rent';
    case 'STATED_INCOME':
      return 'stated-income';
    case 'EMAIL':
      return 'email';
  }
};
