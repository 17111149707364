const deleteAllCookies = () => {
  try {
    const cookies = document?.cookie?.split(';');

    for (const cookie of cookies) {
      const eqPos = cookie?.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  } catch (err) {
    console.warn(err);
  }
};

const getUserToken = () => {
  if (navigator.cookieEnabled) {
    const userInfo = localStorage?.getItem('@userInfo');
    const parsedInfo = Boolean(userInfo) ? JSON.parse(userInfo!) : {};
    return parsedInfo && parsedInfo?.token;
  }
};

const clearUserData = () => {
  if (navigator.cookieEnabled) {
    localStorage?.removeItem('token');
    localStorage?.removeItem('loanToken');
    localStorage?.removeItem('refreshToken');
    sessionStorage?.clear();
    deleteAllCookies();
  }
};

const clearAuthData = () => {
  if (navigator.cookieEnabled) {
    localStorage?.removeItem('token');
    localStorage?.removeItem('loanToken');
    localStorage?.removeItem('refreshToken');
    localStorage?.removeItem('@slug');
  }
};

const setSessionApiData = (data) => {
  if (navigator.cookieEnabled) {
    const sessionData = sessionStorage?.getItem('@sessionApiData') || undefined;
    const parsedApiData = sessionData ? JSON.parse(sessionData) : {};
    const mergedApiData = { ...parsedApiData, ...data };
    sessionStorage.setItem('@sessionApiData', JSON.stringify(mergedApiData));
  }
};

const getSessionApiData = () => {
  if (navigator.cookieEnabled) {
    const sessionApiData = sessionStorage?.getItem('@sessionApiData') || undefined;
    const parsedApiData = sessionApiData ? JSON.parse(sessionApiData) : {};
    return parsedApiData;
  }
  return {};
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const setUserData = (data: any) => {
  if (navigator.cookieEnabled) {
    localStorage?.setItem('@userInfo', JSON.stringify(data));
  }
};

const getUserData = () => {
  if (navigator.cookieEnabled) {
    const userInfo = localStorage?.getItem('@userInfo');
    const parsedInfo = userInfo ? JSON.parse(userInfo) : {};

    return parsedInfo;
  }
  return {};
};

export default {
  getUserToken,
  clearUserData,
  setUserData,
  getUserData,
  clearAuthData,
  setSessionApiData,
  getSessionApiData,
};
