import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { HouseRent } from './views/HouseRent';
import { StatedIncome } from './views/StatedIncome';
import EmailInfo from './views/Email';
import { StatedInfo } from 'pages/AuthChecker/type';
import { useServices } from 'pages/AuthChecker/services';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import useStore from 'lib/hooks/useStore';
import { DefaultHighlineActiveSteps, DefaultHighlineProps } from './type';

const DefaultHighLine = ({ type }: DefaultHighlineProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [missingInfos, setMissingInfos] = useState<StatedInfo[]>();
  const navigate = useNavigate();
  const { createApplicationFlow } = useServices();
  const { startCheckApplicationStatus } = useSSEContext();
  const { authData, sessionApiData } = useStore();
  const { slug } = authData || {};

  useEffect(() => {
    const { defaultHighLineMissings } = sessionApiData || {};
    if (defaultHighLineMissings) {
      setMissingInfos(defaultHighLineMissings);
    }
  }, []);

  useEffect(() => {
    if (missingInfos) {
      const index = missingInfos.findIndex((missingInfo) => missingInfo.value === null);
      if (index > -1) {
        setLoading(false);
        navigate(`/${slug}/default-line/${missingInfos[index].url}`);
      } else {
        polling();
      }
    }
  }, [missingInfos]);

  const setMissingInfo = (info: string, type: DefaultHighlineProps['type']) => {
    setLoading(true);
    if (missingInfos) {
      const index = missingInfos.findIndex((missingInfo) => missingInfo.type === type);
      const tempArray = [...missingInfos];
      const isZeroAllowed =
        type === DefaultHighlineActiveSteps.HOUSING_PAYMENT || type === DefaultHighlineActiveSteps.STATED_INCOME;
      tempArray[index].value = isZeroAllowed && info === '0' ? '1' : info;
      setMissingInfos(tempArray);
    }
  };

  const polling = async () => {
    if (missingInfos) {
      const missingsArray = [...missingInfos];
      missingsArray.forEach(function (v) {
        delete v.url;
      });
      const res = await createApplicationFlow(missingsArray);
      if (res?.success) {
        startCheckApplicationStatus({});
      }
    }
  };

  const renderActiveStep = () => {
    if (type === DefaultHighlineActiveSteps.STATED_INCOME) {
      return <StatedIncome loading={loading} setMissingInfo={setMissingInfo} />;
    } else if (type === DefaultHighlineActiveSteps.HOUSING_PAYMENT) {
      return <HouseRent loading={loading} setMissingInfo={setMissingInfo} />;
    } else if (type === DefaultHighlineActiveSteps.EMAIL) {
      return <EmailInfo loading={loading} setMissingInfo={setMissingInfo} />;
    } else {
      return <></>;
    }
  };

  return renderActiveStep();
};

export default DefaultHighLine;
