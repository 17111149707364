import { WEB_SITE } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import { getExternalLinkLangParam } from 'lib/utils/ExternalLinkLangParam';
import { useTranslation } from 'react-i18next';

export const useApplyWithPatientReview = () => {
  const { t: translate } = useTranslation();
  const { organization, defaultLanguage, organizationRegulations } = useStore();

  const footerTextItem1 = `<li>${translate('applyWithPatientReview.footer.listItem1', {
    eSignDisclosure: `<a target="_blank" href=${WEB_SITE}/e-sign-disclosure/${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('applyWithPatientReview.footer.eSignDisclosure')}</a>`,
    usaPatriotAct: `<a target="_blank" href=${WEB_SITE}/terms/#patriot-act${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('applyWithPatientReview.footer.usaPatriotAct')}</a>`,
    privacyPolicy: `<a target="_blank" href=${WEB_SITE}/privacy-policy/${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('applyWithPatientReview.footer.privacyPolicy')}</a>`,
    termsOfService: `<a target="_blank" href=${WEB_SITE}/terms/${getExternalLinkLangParam(defaultLanguage)}>${translate(
      'applyWithPatientReview.footer.termsOfService',
    )}</a>`,
  })}</li>`;

  const footerTextItem2 = `<li>${translate('applyWithPatientReview.footer.listItem2', {
    originator: organizationRegulations?.originatingEntity || 'Cherry Technologies Inc.',
    entity: organizationRegulations?.originationType === 'LEAD_BANK' ? 'Lead Bank' : organization?.name,
    loanType:
      organizationRegulations?.originationType === 'RISC'
        ? translate('ssnCollect.footer.riscContract')
        : translate('ssnCollect.footer.anInstallment'),
  })}</li>`;
  const footerTextItem3 = `<li>${translate('applyWithPatientReview.footer.listItem3', {
    lendingPartners: `<a target="_blank" href=${WEB_SITE}/lending-partners/${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('applyWithPatientReview.footer.lendingPartners')}</a>`,
    originator: organizationRegulations?.originatingEntity || 'Cherry Technologies Inc.',
  })}</li>`;
  const footerTextItem4 = `<li>${translate('applyWithPatientReview.footer.listItem4')}</li>`;

  const footerText = translate('applyWithPatientReview.footer.text', {
    footerList: `<ul style="padding-inline-start: 24px">${footerTextItem1} ${footerTextItem2} ${footerTextItem3} ${footerTextItem4}</ul>`,
  });

  return { footerText };
};
