import useStore from './useStore';
import axios from 'axios';
import { API_URL } from 'config';
import { useSentry } from './useSentry';

export const useGetAgreementData = () => {
  const { setAgreement } = useStore();
  const { captureException } = useSentry();

  const getAgreementData = async () => {
    const { loan, defaultLanguage, authData } = useStore.getState();

    try {
      const { loanToken } = authData || {};
      const response = await axios.get(`${API_URL}/v1/loans/${loan?.id}/agreements?language=${defaultLanguage}`, {
        headers: {
          'Content-Type': 'text/html',
          Authorization: `Bearer ${loanToken}`,
        },
      });
      setAgreement(response?.data);
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (err: any) {
      captureException('Get Agreement Error', {
        error: err,
        page: 'PaymentMethodList',
        message: 'Payment Method List Error',
        loanId: loan?.id || 'No Loan',
      });
      setAgreement('Agreement fetch error');
    }
  };

  return { getAgreementData };
};
