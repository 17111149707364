import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

const routes = [{ name: 'GetStarted', path: ':id' }];

export const useMatchedRoute = () => {
  const { pathname } = useLocation();
  for (const route of routes) {
    if (matchPath({ path: route.path }, pathname)) {
      return route;
    }
  }
};
