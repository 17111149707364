import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';

import { AlleHeader, Button, HaveQuestionsModal, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';

import { PlanCard } from './components/PlanCard';
import { FormatAmount } from 'lib/utils';
import { theme } from 'config/theme';

type AllePlanReviewProps = {
  customHeaderText: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  selectedPlan: any;
  amount: number;
  loading: boolean | undefined;
  customButtonText: string;
  chooseAnotherPlanHandler: () => void;
  navigateDownPayment: () => Promise<void>;
};

const AllePlanReview = ({
  customHeaderText,
  selectedPlan,
  amount,
  loading,
  chooseAnotherPlanHandler,
  navigateDownPayment,
  customButtonText,
}: AllePlanReviewProps) => {
  return (
    <ContentContainer justify="space-between">
      <AlleHeader />
      <InnerContainer>
        <PageTitle m={'10px 0px 0px 0px'} color={theme.main.black10} fontSize="18px" fontWeight={600} lineHeight="32px">
          Plan details
        </PageTitle>
        <Paragraph m={'10px 0px 32px 0px'}>
          Your {customHeaderText?.toLowerCase()} {selectedPlan?.product?.term}-month payment plan for your{' '}
          {FormatAmount(amount)} purchase is ready to review.
        </Paragraph>
        <SoloContainer>
          <PlanCard key={1} active={true} data={selectedPlan} fullWidth={true} useElegantTheme={true} />
        </SoloContainer>
      </InnerContainer>
      {selectedPlan?.promo &&
      selectedPlan?.product?.promoApr !== null &&
      selectedPlan?.product?.fallbackApr !== null ? (
        <InnerContainer>
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_black'} />
            <InfoText>
              APR increases to {selectedPlan?.product?.fallbackApr || selectedPlan?.product?.apr}% permanently if
              payment is not received within 5 days of any due date.
            </InfoText>
          </InfoContainer>
        </InnerContainer>
      ) : null}
      <Button
        onClick={navigateDownPayment}
        loading={loading}
        disabled={loading}
        black={true}
        uppercase={true}
        thinText={true}
        fontWeight={400}
        fontSize={11}
        m="16px 0"
      >
        Confirm and Continue
      </Button>
      <OutlinedButton onClick={chooseAnotherPlanHandler}>{customButtonText}</OutlinedButton>
      <HaveQuestionsModal />
    </ContentContainer>
  );
};

const PageTitle = styled(Title)`
  font-family: 'Graphik' !important;
`;

const Paragraph = styled(Subtitle)`
  font-family: 'Graphik' !important;
  font-size: 16px;
`;

const SoloContainer = styled.div`
  margin: auto auto 24px;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    margin: unset;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0 8px;
  font-family: 'Graphik' !important;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const OutlinedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.main.white};
  border: 1px solid ${(props) => props.theme.main.grayColor};
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 32px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  font-family: 'Graphik', sans-serif !important;
`;

export default AllePlanReview;
