import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useApolloClient, useMutation } from '@apollo/client';

import { GET_APPLICATION_CHERRY_ACCESS } from 'lib/graphql/queries';
import { POST_APPLICATION_FLOW } from 'lib/graphql/mutations';

import useStore from 'lib/hooks/useStore';
import { useNavigation } from './useNavigation';

enum CherryAccessStatus {
  MISSING_STATED_INFO = 'MISSING_STATED_INFO',
}

export interface StatedInfos {
  type: string;
  value: string;
}

export const useCherryAccess = () => {
  const { navigate } = useNavigation();
  const client = useApolloClient();

  const { application, setAppLoading, setSessionApiData } = useStore.getState() || {};

  const [loading, setLoading] = useState(false);
  const [cherryAccess, setCherryAccess] = useState();

  const [fetchLoading, setFetchLoading] = useState(false);

  const [postApplicationFlow] = useMutation(POST_APPLICATION_FLOW);
  const [createLoading, setCreateLoading] = useState(false);

  const getNavigatingUrl = (key: string) => {
    switch (key) {
      case 'EMAIL':
        return 'email';
      case 'HOUSING_PAYMENT':
      case 'HOUSING_SITUATION':
        return 'housing-rent';
      case 'STATED_INCOME':
        return 'income';
      case 'REQUESTED_AMOUNT':
        return 'requested-amount';
    }
  };

  const pollLatestCherryAccess = async (source: string | null = null) => {
    try {
      setLoading(true);
      const latestCherryAccess = await fetchLatestCherryAccess(application?.id);

      if (latestCherryAccess?.id) {
        setCherryAccess(latestCherryAccess);
        const missingInfo = latestCherryAccess?.missingInfos?.map((mInfo) => {
          return { key: mInfo, value: null, isCompleted: false };
        });

        setSessionApiData({ cherryAccess: latestCherryAccess, missingInfo });

        if (latestCherryAccess?.status === CherryAccessStatus.MISSING_STATED_INFO) {
          setAppLoading(false);
          setSessionApiData({ cherryAccess: latestCherryAccess });
          const firstMissingInfo = latestCherryAccess?.missingInfos?.[0];

          navigate(`approval-boost/additional-info/${getNavigatingUrl(firstMissingInfo)}`);
        } else {
          setTimeout(() => pollLatestCherryAccess, 5000);
        }
      } else {
        setAppLoading(false);
        navigate(`approval-boost`);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const createAccess = async (applicationId, statedInfos?: StatedInfos) => {
    try {
      setCreateLoading(true);

      const { data } = await postApplicationFlow({
        variables: { input: { applicationId, statedInfos } },
      });

      const missingInfo = data?.cherryAccess?.missingInfos?.map((mInfo) => {
        return { key: mInfo, value: null, isCompleted: false };
      });

      setSessionApiData({ missingInfo });
      return data?.postApplicationFlow;
    } catch (e) {
      setCreateLoading(false);
    }
  };

  const fetchLatestCherryAccess = async (applicationId) => {
    try {
      setFetchLoading(true);
      const { data } = await client.query({
        query: GET_APPLICATION_CHERRY_ACCESS,
        variables: { input: { applicationId } },
      });

      setFetchLoading(false);
      return data?.getApplicationCherryAccess;
    } catch (e) {
      setFetchLoading(false);
    }
  };

  return {
    pollLatestCherryAccess,
    loading,
    cherryAccess,
    createLoading,
    createAccess,
    fetchLoading,
    fetchLatestCherryAccess,
  };
};
