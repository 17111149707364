import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const PhoneInput = React.forwardRef<HTMLElement, CustomProps | any>(function TextMaskCustom(props, ref) {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const onAcceptHandler = (value: string, mask: any) => {
    onChange({ target: { name: props?.name, value: mask._unmaskedValue } });
  };

  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{ '#': /[1-9]/ }}
      inputRef={ref}
      onAccept={onAcceptHandler}
      overwrite={true}
    />
  );
});
