import styled from 'styled-components';

export const ModalHeader = styled.div<{ marginBottom?: number }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => props.marginBottom ?? 0}px;
`;

export const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;
