import * as Sentry from '@sentry/react';

export const initChatNiceWidget = () => {
  const serverHost = 'https://home-c68.nice-incontact.com';
  const busNo = 4606496;
  const poc = '0b4f7d31-e624-4bc5-8e23-be851302f14a';

  try {
    // eslint-disable-next-line
    // @ts-ignore
    window?.icPatronChat?.init({
      serverHost,
      bus_no: busNo,
      poc,
      customChatButton: false,
    });
  } catch (err) {
    Sentry.captureException(err, { level: 'error', extra: { errorContext: 'openChat => ChatWidgetButton.tsx' } });
  }
};
