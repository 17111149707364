import { useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { UPDATE_BORROWER } from 'lib/graphql/mutations';

import { WEB_SITE } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { useServices } from 'pages/AuthChecker/services';

import { Box, Button, Container, Icon, SsnInput as Input, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer, FooterText } from 'lib/components/Common';
import AlleSsnInput from './AlleSSNInput';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useTranslation } from 'react-i18next';
import { getExternalLinkLangParam } from 'lib/utils/ExternalLinkLangParam';

interface FormDataType {
  ssn: string;
}

const SsnInput = () => {
  const alert = useAlert();
  const { initPage } = useNeuroId();
  const { trackPage } = useSegment();
  const { trackEvent } = useAnalytics();
  const { captureException } = useSentry();
  const { t: translate } = useTranslation();
  const { setSessionData, setPageIndex, applicationFlow, organization, isAlle, defaultLanguage } = useStore();

  const { startCheckApplicationStatus } = useSSEContext();
  const [updateBorrowerSSN] = useMutation(UPDATE_BORROWER);
  const { createApplicationFlow } = useServices();
  const { sendLoadSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.ssnLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    initPage('ssn');
  }, []);

  useEffect(() => {
    setPageIndex(7);
  }, [setPageIndex]);

  useEffect(() => {
    if (applicationFlow?.status === 'SSN_REQUIRED_2') {
      trackEvent(AnalyticEventNames.SSN_CANT_VERIFY);
      setLoading(false);
      alert.info(translate('ssnInput.error.message'));
    }
  }, [applicationFlow]);

  const titleText = () => {
    if (applicationFlow?.status === 'SSN_REQUIRED_2') {
      trackEvent(AnalyticEventNames.SSN_CANT_VERIFY);
    }

    return translate('ssnInput.title');
  };

  const subtitleText = () => {
    return translate('ssnInput.subTitle');
  };

  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
  } = useForm<FormDataType>({
    mode: 'onChange',
  });

  const onSubmit = async (formData: FormDataType) => {
    try {
      const { borrower } = useStore.getState() || {};
      trackEvent(AnalyticEventNames.SSN_CONTINUE);

      setSessionData(formData);

      setLoading(true);
      const { data } = await updateBorrowerSSN({
        variables: {
          input: {
            id: borrower?.id,
            ssn: formData?.ssn,
            source: 'GREET',
          },
        },
      });

      if (data?.updateBorrower?.success) {
        const res = await createApplicationFlow();
        if (res?.success) {
          startCheckApplicationStatus({});
        }
      } else {
        alert.info(data?.updateBorrower?.message || 'An error occurred');
        setLoading(false);
      }
    } catch (err) {
      captureException(err, {
        page: 'SSN Input',
        message: 'SSN Patch Error',
      });
    }
  };

  const onBlurHandler = () => {
    trackEvent(AnalyticEventNames.SSN_INP_BLUR);
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const handleClick = () => {
    handleSubmit(onSubmit)();
  };

  if (isAlle) {
    return (
      <AlleSsnInput
        control={control}
        loading={loading}
        checkKeyDown={checkKeyDown}
        handleClick={handleClick}
        isValid={isValid}
        onBlurHandler={onBlurHandler}
      />
    );
  }
  const footerText = translate('ssnInput.footer.text', {
    lendingPartners: `<a target="_blank" href=${WEB_SITE}/lending-partners/${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('ssnInput.footer.lendingPartners')}</a>`,

    termsOfService: `<a target="_blank" href=${WEB_SITE}/terms/${getExternalLinkLangParam(
      defaultLanguage,
    )}> ${translate('ssnInput.footer.termsOfService')}</a>`,

    name: organization?.name,
  });

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'fingerprint'} />
        <Title m={'10px 0px 8px 0px'} data-testid="ssn-conflict-title">
          {titleText()}
        </Title>
        <Subtitle m={'0px 0px 24px 0px'}>{subtitleText()}</Subtitle>
        <Box>
          <Controller
            name="ssn"
            control={control}
            defaultValue=""
            rules={{ required: true, minLength: 11, maxLength: 11 }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="ssn"
                variant="filled"
                data-testid="ssn"
                data-neuro-label="ssn"
                type="tel"
                label={translate('ssnInput.form.label')}
                InputProps={{
                  inputComponent: Input,
                  disableUnderline: true,
                }}
                value={value}
                onKeyDown={checkKeyDown}
                onChange={onChange}
                onBlur={onBlurHandler}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={!isValid || loading}
          loading={loading}
          onClick={handleClick}
          icon={!isValid || loading ? 'lock' : 'lock-active'}
        >
          {translate('buttons.checkEligibility')}
        </Button>
        <FooterText dangerouslySetInnerHTML={{ __html: footerText }} />
      </ButtonContainer>
    </Container>
  );
};

export default SsnInput;

const InfoColorText = styled.a`
  color: ${(props) => props.theme.main.green};
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
`;

const TextDivider = styled.div`
  margin: 12px 0;
`;
