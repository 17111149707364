import React, { useEffect } from 'react';
import styled from 'styled-components';
import CheckboxItem from 'pages/ConnectBankAccount/components/CheckboxItem';
import faqConnectWithPlaidAlle from 'pages/Faq/components/faqConnectWithPlaidAlle.json';

import { Button, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { FaqListModal } from 'lib/components/FaqListModal';
import { theme } from 'config/theme';
import { IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';

interface Props {
  isOptions: boolean;
  goToPlaid: () => void;
  getSubmitButtonText: () => string;
  handleCheckboxClick: (index: number) => void;
  loading: boolean;
  isSubmitAllowed: () => boolean;
  isRetry: boolean;
  displayFaqModal: boolean;
  uploadPaystub: () => void;
  setDisplayFaqModal: (value: boolean) => void;
  checkboxValues: boolean[];
  featureFlags: {
    mxEnabled: boolean;
  };
  organization: {
    name: string;
  };
}

const DefaultConnectBankAccount = ({
  isOptions,
  goToPlaid,
  loading,
  uploadPaystub,
  featureFlags,
  organization,
  getSubmitButtonText,
  isSubmitAllowed,
  handleCheckboxClick,
  checkboxValues,
  isRetry,
  displayFaqModal,
  setDisplayFaqModal,
}: Props) => {
  const { pushEvent } = useIframeEvents();

  useEffect(() => {
    pushEvent(IframeEventTypes.PAGE, PageEventActions.CONNECT_BANK_ACCOUNT);
  }, []);

  const renderButton = () => {
    if (isOptions) {
      return <ButtonText onClick={uploadPaystub}>Verify with Paystubs/Payroll</ButtonText>;
    }

    return null;
  };

  return (
    <Container>
      <Title
        color={theme.main.black10}
        fontSize="24px"
        fontWeight={400}
        lineHeight="32px"
        useElegantFontType={true}
        textAlign="center"
      >
        Verify your income
      </Title>
      <Subtitle fontSize="16px" m="16px 0" textAlign="center" color={theme.main.black20} lineHeight="24px">
        In the next few steps, you will verify your income by connecting your bank account via Plaid, our digital
        finance partner. Connecting your bank account is the fastest way to get approved.
      </Subtitle>
      {!isRetry && (
        <>
          <Subtitle color={theme.main.black20} fontSize="16px">
            Please confirm that:
          </Subtitle>

          <CheckboxItem
            label="My account is at least 3 months old"
            checked={checkboxValues[0]}
            activeColor={'black'}
            onChange={() => handleCheckboxClick(0)}
            removeBorder={true}
            labelColor={theme.main.black20}
          />
          <CheckboxItem
            label="I deposit income into this account"
            checked={checkboxValues?.[1]}
            activeColor={'black'}
            onChange={() => handleCheckboxClick(1)}
            removeBorder={true}
            labelColor={theme.main.black20}
          />
          <CheckboxItem
            label="I make more than $1,000 per month"
            activeColor={'black'}
            checked={checkboxValues[2]}
            onChange={() => handleCheckboxClick(2)}
            removeBorder={true}
            labelColor={theme.main.black20}
          />
        </>
      )}
      <Button
        disabled={!isSubmitAllowed() || loading}
        loading={loading}
        onClick={goToPlaid}
        black={true}
        uppercase={true}
        thinText={true}
        fontWeight={400}
        fontSize={11}
        m="32px 0 0"
      >
        {getSubmitButtonText()}
      </Button>
      {renderButton()}
      <ButtonText onClick={() => setDisplayFaqModal(true)}>Have a Question?</ButtonText>
      <InfoContainer>
        <Icon width={16} height={20} src={'lock-secure'} />
        <InfoText>
          {`We secure your information with the same 256-bit encryption that banks use. Neither Cherry nor ${organization?.name} stores your login.`}
        </InfoText>
      </InfoContainer>
      <FaqListModal
        hideModal={() => setDisplayFaqModal(false)}
        show={displayFaqModal}
        faqList={faqConnectWithPlaidAlle}
        title="Have a Question?"
        titleMargin={'auto'}
      />
    </Container>
  );
};

export default DefaultConnectBankAccount;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: left;

  margin: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  text-align: left;
`;

const ButtonText = styled.div`
  color: ${(props) => props.theme.main.alleBlack};
  margin: 24px 0;
  cursor: pointer;
  text-align: center;
  font-family: 'Graphik';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
`;

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: 'Graphik';
`;
