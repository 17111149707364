import { theme } from 'config/theme';
import styled from 'styled-components';

import { Icon } from 'lib/components';
import { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useViewport } from 'lib/hooks/useViewport';
import html2canvas from 'html2canvas';

interface AddWalletProps {
  openThanksModal: (status: boolean) => void;
  page: string;
}

const AddWallet = ({ openThanksModal, page }: AddWalletProps) => {
  const [isAppleDevice, setIsAppleDevice] = useState(false);
  const { t: translate } = useTranslation();
  const { sendActionSegmentEvent } = useSegmentContext();
  const { isMobile } = useViewport();

  useEffect(() => {
    setIsAppleDevice(isIOS);
  }, []);

  const addAppleWallet = () => {
    const segmentName =
      page === 'approval'
        ? WPQSegmentNames.approvalCardAppleWalletClicked
        : WPQSegmentNames.whatHappenNextAppleWalletClicked;
    sendActionSegmentEvent(segmentName);
    openThanksModal(true);
  };

  const addGoogleWallet = () => {
    const segmentName =
      page === 'approval'
        ? WPQSegmentNames.approvalCardGoogleWalletClicked
        : WPQSegmentNames.whatHappenNextGoogleWalletClicked;
    sendActionSegmentEvent(segmentName);
    openThanksModal(true);
  };

  const saveCard = async () => {
    const approvalCard = document.getElementById('capture');
    if (approvalCard) {
      try {
        const canvas = await html2canvas(approvalCard);
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'my-approval.png';
        link.click();
      } catch (error) {
        console.error('Error capturing card:', error);
      }
    }

    const segmentName =
      page === 'approval' ? WPQSegmentNames.approvalCardSaveClicked : WPQSegmentNames.whatHappenNextImageSaveClicked;
    sendActionSegmentEvent(segmentName);
  };

  return (
    <>
      <AddWalletContainer>
        <WalletContainer>
          {isAppleDevice ? (
            <Icon src={'apple_wallet'} height={50} onClick={addAppleWallet} />
          ) : (
            <Icon src={'google_wallet'} height={52} onClick={addGoogleWallet} />
          )}
        </WalletContainer>
        <SaveButton onClick={saveCard} isMobile={isMobile}>
          {translate('buttons.save')}
          <Icon src={'download'} width={20} height={20} />
        </SaveButton>
      </AddWalletContainer>
    </>
  );
};
export default AddWallet;

const WalletContainer = styled.div`
  width: 53%;
  display: grid;
  justify-content: start;
  align-items: center;
  img {
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

const AddWalletContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  width: 100%;
`;

const SaveButton = styled.div<{ isMobile: boolean }>`
  display: flex;
  gap: 8px;
  max-height: 50px;
  flex-direction: row;
  width: ${(props) => (props.isMobile ? '45%' : '25%')};
  align-items: center;
  border-radius: 8px;
  background: ${theme.main.midnightBlue};
  padding: 13px 16px;
  justify-content: center;
  color: ${theme.main.white};
  cursor: pointer;
  img {
    cursor: pointer;
  }
  text-align: center;
  font-size: 16px;
  font-weight: 600;
`;
