import { useMutation } from '@apollo/client';
import { APP_ENV } from 'config';
import { SET_EXCHANGE_TOKEN } from 'lib/graphql/mutations';
import useStore from 'lib/hooks/useStore';
import { Redirect } from 'lib/utils';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

type OnReceiveToken = (token: string) => void;
type GoToPatientPortalParams =
  | {
      onReceiveToken?: OnReceiveToken;
    }
  | undefined;

export const useNavigatePatientPortal = () => {
  const [setExchangeToken] = useMutation(SET_EXCHANGE_TOKEN);
  const { organization, borrower } = useStore.getState();
  const alert = useAlert();
  const { t: translate } = useTranslation();

  const goToPatientPortal = async ({ onReceiveToken }: GoToPatientPortalParams = {}) => {
    const { data } = await setExchangeToken({
      variables: {
        input: {
          target: 'BORROWER',
          payload: {
            organizationId: organization?.id,
            borrowerId: borrower.id,
          },
        },
      },
    });

    onReceiveToken?.(data?.setExchangeToken?.exchangeTokenId);

    if (data?.setExchangeToken?.exchangeTokenId) {
      const url = APP_ENV === 'master' ? 'https://patient.withcherry.com' : `https://${APP_ENV}-patient.withcherry.com`;
      Redirect(`${url}/${data?.setExchangeToken?.exchangeTokenId}`);
    } else {
      alert.info(translate('errors.default'));
    }
  };

  return { goToPatientPortal };
};
