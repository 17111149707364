import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import React from 'react';
import styled from 'styled-components';

import { theme as configTheme } from 'config/theme';
import { Button, Icon, IconGap, ModalHeader, Title } from 'lib/components';
import { ButtonContainer } from 'lib/components/Common';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { useCreditLineIncrease } from 'lib/hooks/useCreditLineIncrease';
import { useTranslation } from 'react-i18next';

interface MickeyMouseModal {
  show: boolean;
  hideModal: () => void;
}

const MickeyMouseModal = ({ show, hideModal }: MickeyMouseModal) => {
  const { navigate } = useNavigation();
  const { createLineExpansion } = useCreditLineIncrease();
  const { t: translate } = useTranslation();
  const { application } = useStore.getState() || {};

  const goToIncomeVerification = async () => {
    const data = await createLineExpansion(0, application?.id);
    if (data && data?.status === 'INCOME_VERIFICATION') {
      navigate(`connect-bank-account/${application?.id}`);
    } else {
      hideModal();
    }
  };

  const closeModalOnClickAway = (e) => {
    if (e?.target?.ariaHidden) {
      hideModal();
    }
  };

  return (
    <Backdrop
      sx={{ color: configTheme.main.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      onClick={closeModalOnClickAway}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer isLarge={false}>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>{translate('mickeyMouseModal.title')}</Title>
            <Icon hover={true} src={'close'} onClick={hideModal} />
          </ModalHeader>
          <PurchaseContainer>
            <p>{translate('mickeyMouseModal.subTitle')}</p>
          </PurchaseContainer>
          <ButtonContainer>
            <Button onClick={goToIncomeVerification}>{translate('buttons.continue')}</Button>
          </ButtonContainer>
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

export default MickeyMouseModal;

const ModalContainer = styled.div<{ isLarge: boolean }>`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0 -36px 67px rgba(0, 0, 0, 0.07), 0 -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0 -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 12px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;

    @media (max-height: ${(props) => (props.isLarge ? '700px' : '530px')}) {
      top: 30px;
      overflow-y: scroll;
    }
  }
`;

const PurchaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px 16px 32px;
  color: ${(props) => props.theme.main.black};
`;
