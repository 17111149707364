import { useApolloClient } from '@apollo/client';

import { GET_BORROWER_INCOME_VERIFICATION_LATEST } from 'lib/graphql/queries';
import { useCreditLineIncrease } from 'lib/hooks/useCreditLineIncrease';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import useStore from 'lib/hooks/useStore';

import { useEligibilityService, useServices } from 'pages/AuthChecker/services';

export const useIncomeVerificationLatest = () => {
  const client = useApolloClient();
  const { setIncomeVerification, application } = useStore();
  const { pollLatestCreditLineIncrease } = useCreditLineIncrease();

  const { IvCheck } = useEligibilityService();
  const { getApplication } = useServices();
  const { startCheckApplicationStatus } = useSSEContext();

  const checkIncomeStatus = async (res, borrowerId) => {
    setIncomeVerification(res);
    switch (res?.status) {
      case 'COMPLETE':
        if (application?.mickeyMouse && application?.status === 'APPROVED') {
          await pollLatestCreditLineIncrease();
        } else {
          startCheckApplicationStatus({ applicationId: application?.id, isExisting: true });
        }
        break;
      case 'ERROR':
        if (application?.mickeyMouse && application?.status === 'APPROVED') {
          const applicationLatest = await getApplication(application?.id);
          IvCheck(applicationLatest);
        } else {
          startCheckApplicationStatus({ applicationId: application?.id, isExisting: true });
        }
        break;
      case 'EXPIRED':
        startCheckApplicationStatus({ applicationId: application?.id, isExisting: true });

        break;
      case 'CREATED':
        setTimeout(() => {
          getIncomeVerificationLatest(borrowerId);
        }, 4000);
        break;
      case 'PENDING':
        if (res.type === 'PAYSTUB') {
          startCheckApplicationStatus({ applicationId: application?.id, isExisting: true });
        } else {
          setTimeout(() => {
            getIncomeVerificationLatest(borrowerId);
          }, 4000);
        }
        break;
      default:
        setTimeout(() => {
          getIncomeVerificationLatest(borrowerId);
        }, 4000);
    }
  };

  const getIncomeVerificationLatest = async (borrowerId: string) => {
    const { data } = await client.query({
      query: GET_BORROWER_INCOME_VERIFICATION_LATEST,
      variables: {
        input: {
          borrowerId,
        },
      },
    });

    if (data?.borrowerIncomeVerificationsLatest) {
      checkIncomeStatus(data?.borrowerIncomeVerificationsLatest, borrowerId);
    }
    return [];
  };

  return { getIncomeVerificationLatest };
};
