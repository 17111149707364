import { useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { BORROWER_INCOME_VERIFICATION } from 'lib/graphql/mutations';

import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';

import { Button, Container, Icon, RadioButtonGroup, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { INCOME_PROVIDER } from 'pages/ConnectBankAccount/ConnectBankAccount';
import { useServices } from 'pages/AuthChecker/services';
import AlleUploadPaystub from './AlleUploadPaystub';
import { ButtonClickedEventActions, IframeEventTypes, useIframeEvents } from 'lib/hooks/useIframeEvents';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

enum RadioButtonEnums {
  PAYROLL = 'payroll',
  PAYSTUBS = 'paystubs',
}

const UploadPaystub = () => {
  const { idLoan } = useParams();
  const { setIncomeVerification, application, isAlle } = useStore();
  const { navigate } = useNavigation();
  const { getBorrower } = useServices();
  const { pushEvent } = useIframeEvents();
  const { startCheckApplicationStatus } = useSSEContext();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const paystubType = useRef('');
  const { t: translate } = useTranslation();

  const [radioValue, setRadioValue] = useState<RadioButtonEnums>(RadioButtonEnums.PAYROLL);

  const [borrowerIncomeVerification, { loading }] = useMutation(BORROWER_INCOME_VERIFICATION);

  const titleText = () => {
    const { borrower, incomeVerification } = useStore.getState() || {};

    if (incomeVerification?.errorMessage === 'Institution_Not_Found') {
      paystubType.current = 'notFind';
      return translate('uploadPaystub.title1');
    } else if (incomeVerification?.errorMessage === 'Account_Not_Supported') {
      return translate('uploadPaystub.title2');
    } else {
      paystubType.current = 'almost';
      return translate('uploadPaystub.title3', {
        name: borrower?.firstName,
      });
    }
  };

  useEffect(() => {
    if (loadSegmentController.current) {
      const { incomeVerification } = useStore.getState() || {};
      if (incomeVerification?.errorMessage === 'Institution_Not_Found') {
        sendLoadSegmentEvent(WPQSegmentNames.incomeVerificationNotFindBankLoad);
      } else if (incomeVerification?.errorMessage === 'Account_Not_Supported') {
        sendLoadSegmentEvent(WPQSegmentNames.incomeVerificationRepromtPageLoad);
      } else {
        sendLoadSegmentEvent(WPQSegmentNames.incomeVerificationAlmostThereLoad);
      }
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const incomeVerificationRequest = async (type: string) => {
    const { application, borrower } = useStore.getState() || {};

    const {
      data: { borrowerIncomeVerifications },
    } = await borrowerIncomeVerification({
      variables: {
        input: {
          borrowerId: borrower?.id,
          applicationId: application?.id,
          type,
          provider: INCOME_PROVIDER.PLAID,
        },
      },
    });
    return borrowerIncomeVerifications;
  };

  const uploadPayrollHandler = async () => {
    try {
      if (isAlle) {
        pushEvent(IframeEventTypes.BUTTON_CLICKED, ButtonClickedEventActions.CONNECT_PAYROLL_BUTTON_CLICKED);
      }
      const { borrower } = useStore.getState() || {};
      if (paystubType.current === 'notFind') {
        sendActionSegmentEvent(WPQSegmentNames.ivPayrolContinueClicked);
      } else {
        sendActionSegmentEvent(WPQSegmentNames.almostTherePayrollContinueClicked);
      }
      const borrowerLatest = await getBorrower(borrower?.id);
      const response = await incomeVerificationRequest('PAYROLL');
      if (response) {
        setIncomeVerification(response);
      }
      if (!borrowerLatest?.email) {
        navigate(`email-collection?type=payroll`);
      } else {
        navigate(`payrolls-file-upload/${response.id}`);
      }

      return response;
    } catch (err) {
      console.error('error', err);
    }
  };

  const uploadPaystubHandler = async () => {
    try {
      if (isAlle) {
        pushEvent(IframeEventTypes.BUTTON_CLICKED, ButtonClickedEventActions.SUBMIT_PAYSTUB_BUTTON_CLICKED);
      }
      const { borrower } = useStore.getState() || {};

      const borrowerLatest = await getBorrower(borrower?.id);

      if (paystubType.current === 'notFind') {
        sendActionSegmentEvent(WPQSegmentNames.ivPaystubContinueClicked);
      } else {
        sendActionSegmentEvent(WPQSegmentNames.almostTherePaystubContinueClicked);
      }
      const response = await incomeVerificationRequest('PAYSTUB');
      if (response) {
        setIncomeVerification(response);
      }

      if (!borrowerLatest?.email) {
        navigate(`email-collection?type=paystubs`);
      } else {
        navigate(`paystubs-file-upload/${response.id}`);
      }

      return response;
    } catch (err) {
      // err
    }
  };

  const addBankAccountHandler = () => {
    if (paystubType.current === 'notFind') {
      sendActionSegmentEvent(WPQSegmentNames.ivBankAccountInsteadClicked);
    } else {
      sendActionSegmentEvent(WPQSegmentNames.almostThereInsteadConnectClicked);
    }

    if (isAlle) {
      pushEvent(IframeEventTypes.BUTTON_CLICKED, ButtonClickedEventActions.CONNECT_BANK_ACCOUNT_INSTEAD_BUTTON_CLICKED);
    }
    if (!loading) {
      navigate(`connect-bank-account/${idLoan}`);
    }
  };

  const onChangeHandler = (e) => {
    if (paystubType.current === 'notFind') {
      if (e?.target?.value === 'paystubs') {
        sendActionSegmentEvent(WPQSegmentNames.ivPaystubsRadioClicked);
      } else {
        sendActionSegmentEvent(WPQSegmentNames.ivPayrollRadioButtonClicked);
      }
    } else {
      if (e?.target?.value === 'paystubs') {
        sendActionSegmentEvent(WPQSegmentNames.almostTherePaystubClicked);
      } else {
        sendActionSegmentEvent(WPQSegmentNames.almostTherePayrollClicked);
      }
    }

    setRadioValue(e?.target?.value);
  };

  const goToOriginalApproval = () => {
    startCheckApplicationStatus({ subscribeFlow: true, applicationId: application?.id });
  };

  const isSelectedEqualToPayStub = RadioButtonEnums.PAYSTUBS === radioValue;
  const isSelectedEqualToPayroll = RadioButtonEnums.PAYROLL === radioValue;

  if (isAlle) {
    return (
      <AlleUploadPaystub
        uploadPaystubHandler={uploadPaystubHandler}
        uploadPayrollHandler={uploadPayrollHandler}
        loading={loading}
        addBankAccountHandler={addBankAccountHandler}
        isSelectedEqualToPayroll={isSelectedEqualToPayroll}
        isSelectedEqualToPayStub={isSelectedEqualToPayStub}
        radioValue={radioValue}
        titleText={titleText}
        onChangeHandler={onChangeHandler}
      />
    );
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <IconContainer>
            <Icon src={'cherry-logo'} width={24} height={24} />
            <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 4px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 12px 0px 4px'} width={4} height={4} src={'dot'} />
            <Icon src={'bank'} />
          </IconContainer>

          <Title
            m={'10px 0px 8px 0px'}
            dangerouslySetInnerHTML={{
              __html: titleText(),
            }}
          />

          <Subtitle m={'0px 0px 30px 0px'}>{translate('uploadPaystub.subTitle')}</Subtitle>

          <Subtitle m={'0px 0px 10px 0px'}>{translate('uploadPaystub.info.title')}</Subtitle>
          <RadioButtonGroup
            onChange={onChangeHandler}
            value={radioValue}
            radioButtonOptions={[
              {
                textValue: RadioButtonEnums?.PAYROLL,
                radioLabel: translate('uploadPaystub.radio.label1'),
                subText: translate('uploadPaystub.radio.text1'),
                isPreferred: true,
              },
              {
                textValue: RadioButtonEnums?.PAYSTUBS,
                radioLabel: translate('uploadPaystub.radio.label2'),
                subText: translate('uploadPaystub.radio.text2'),
              },
            ]}
            size={'large'}
          />
        </InnerContainer>
        {isSelectedEqualToPayStub && (
          <InnerContainer>
            <InfoContainer>
              <Icon width={20} height={20} src={'info_circle_outline'} />
              <InfoText
                dangerouslySetInnerHTML={{
                  __html: translate('uploadPaystub.info.subTitle'),
                }}
              />
            </InfoContainer>
          </InnerContainer>
        )}

        {isSelectedEqualToPayroll && (
          <InnerContainer>
            <InfoContainer>
              <Icon width={20} height={20} src={'info_circle_outline'} />

              <InfoText
                dangerouslySetInnerHTML={{
                  __html: translate('uploadPaystub.info.message'),
                }}
              />
            </InfoContainer>
          </InnerContainer>
        )}
      </ContentContainer>
      <ButtonContainer>
        {isSelectedEqualToPayStub && (
          <Button disabled={loading} loading={loading} onClick={uploadPaystubHandler}>
            {translate('buttons.submitPaystubs')}
          </Button>
        )}

        {isSelectedEqualToPayroll && (
          <Button disabled={loading} loading={loading} onClick={uploadPayrollHandler}>
            {translate('buttons.connectPayroll')}
          </Button>
        )}
        {application?.mickeyMouse && (
          <Button loading={loading} secondary={true} onClick={goToOriginalApproval}>
            {translate('buttons.goToOriginalApproval')}
          </Button>
        )}

        <ButtonText onClick={addBankAccountHandler}>{translate('buttons.connectMyBankAccount')}</ButtonText>
      </ButtonContainer>
    </Container>
  );
};

export default UploadPaystub;

const ButtonText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.main.green};
  margin: 24px 0;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
