import { useEffect, useRef } from 'react';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { redirectToCustomerPortal } from 'lib/utils';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useDidMountEffect } from 'lib/hooks/useDidMountEffect';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

const Expired = () => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const { organization, isAlle } = useStore();
  const { sendLoadSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  const navigateToStart = () => {
    isAlle ? redirectToCustomerPortal(isAlle) : navigate(``);
  };

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.expiredLinkLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigateToStart();
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        {!isAlle && <Icon src={'chain'} />}

        <Title m={'10px 0 24px 0'}>{translate('expired.title')}</Title>
        <Subtitle m={'10px 0 24px 0'}>
          {translate('expired.title', {
            name: organization?.name,
          })}
        </Subtitle>

        <Subtitle m={'0 0 24px 0'}>
          {isAlle ? <>{translate('expired.cherryPortal')}</> : <>{translate('expired.merchantPage')}</>}
        </Subtitle>
      </ContentContainer>
      <ButtonContainer>
        <Button black={isAlle ? true : false} onClick={navigateToStart}>
          {translate('buttons.home')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default Expired;
