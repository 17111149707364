export const lawfulAgeControl = (enteredDate: string) => {
  const splittedDate = enteredDate.split('/');
  const day = Number(splittedDate[1]);
  const month = Number(splittedDate[0]) - 1;
  const year = Number(splittedDate[2]);

  const today = new Date();

  const ageDiff = today.getFullYear() - year;
  const is18OrOlder =
    ageDiff > 18 ||
    (ageDiff === 18 && (today.getMonth() > month || (today.getMonth() === month && today.getDate() >= day)));

  return is18OrOlder;
};
