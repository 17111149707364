import { Location } from 'lib/types';

export interface Application {
  ivAllow: {
    paystub: boolean;
  };
  id: number;
  borrowerId: number;
  status: string;
  maxEligible: number;
  incomeVerificationId: number;
  productId?: number;
  balanceAvailable: number;
  expireAt: string;
}

export enum ApplicationFlowStatuses {
  MISSING_INFO = 'MISSING_INFO',
  ITIN_NOT_SUPPORTED = 'ITIN_NOT_SUPPORTED',
  MISSING_EMAIL = 'MISSING_EMAIL',
  MISSING_REQUESTED_AMOUNT = 'MISSING_REQUESTED_AMOUNT',
  TREATMENT_QUESTIONS = 'TREATMENT_QUESTIONS',
  AWAITING_DEFAULT_HIGH_LINE = 'AWAITING_DEFAULT_HIGH_LINE',
  AWAITING_APPROVAL_BOOST = 'AWAITING_APPROVAL_BOOST',
  PROCESSING = 'PROCESSING',
  ADDRESS_VERIFY_MINOR = 'ADDRESS_VERIFY_MINOR',
  ADDRESS_VERIFY_MAJOR = 'ADDRESS_VERIFY_MAJOR',
  SSN_REQUIRED_1 = 'SSN_REQUIRED_1',
  SSN_REQUIRED_2 = 'SSN_REQUIRED_2',
  FULL_SSN_REQUIRED = 'FULL_SSN_REQUIRED',
  FULL_SSN_REQUIRED_1 = 'FULL_SSN_REQUIRED_1',
  FULL_SSN_REQUIRED_2 = 'FULL_SSN_REQUIRED_2',
  SSN_CONFLICT = 'SSN_CONFLICT',
  ID_CONFLICT = 'ID_CONFLICT',
  SSN_VERIFICATION = 'SSN_VERIFICATION',
  FROZEN = 'FROZEN',
  ID_REVIEW = 'ID_REVIEW',
  EID_REVIEW = 'EID_REVIEW',
  INCOME_VERIFICATION = 'INCOME_VERIFICATION',
  REVIEW = 'REVIEW',
  APPROVED = 'APPROVED',
  COMPLETE_DEMO = 'COMPLETE_DEMO',
  DENIED = 'DENIED',
  BLACKLIST = 'BLACKLIST',
  OPTION = 'OPTION',
  EXPIRED = 'EXPIRED',
  UNLICENSED = 'UNLICENSED',
}

export enum LoanStatuses {
  AWAITING = 'AWAITING',
  CANCELLED = 'CANCELLED',
}

export interface BorrowerAddress {
  addressId: number;
  address1: string;
  address2: string;
  zip: string;
  description: string;
  city: {
    cityId: number;
    name: string;
    state: {
      stateId: number;
      name: string;
      code: string;
      country: {
        countryId: number;
        name: string;
        code: string;
      };
    };
  };
}

export interface Borrower {
  idBorrower?: number;
  idNumber?: number;
  firstName?: string;
  lastName?: string;
  suffix?: string;
  phone?: string;
  dob?: string;
  govID?: number;
  createdAt?: string;
  lastAccess?: string;
  fundedLoan?: true;
  address?: BorrowerAddress;

  bankNames?: string[];
  email?: null;
  kycStatus?: string;
  ssnUW?: string;
  verificationRequired?: false;
}

export interface KycResponse {
  borrower: Borrower;
  status: string;
}

export interface FlowResponse {
  id: number;
  borrowerId: number;
  applicationId: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  kycdenial: boolean;
  ssnconflict: boolean;
}

export interface IncomeVerificationFlow {
  borrowerId: string;
  applicationId: string;
  organizationId?: string;
  slug: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  pointOfSaleId?: string;
  treatmentAmount?: number;
  merchantId?: string;
  locations?: Location[];
}

export interface OauthWpqFlow {
  borrowerId: string;
  applicationId: string;
  slug: string;
  oauthStateId: string | null;
}

export interface PartnerLinkFlow {
  borrowerId: string;
  partner: string;
  applicationId: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  organizationData: any;
}

export interface OauthFinderFlow {
  borrowerId: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  organizationData: any;
}

export interface DefaultFlow {
  borrowerId: string;
  idLoan: string;
  slug: string;
  phone?: string;
}

export interface StatedInfo {
  type: 'STATED_INCOME' | 'HOUSING_PAYMENT' | 'EMAIL' | 'REQUESTED_AMOUNT' | 'SSN';
  value: string;
  url?: string;
}
