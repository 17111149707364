import React from 'react';
import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';

import { theme as configTheme } from 'config/theme';

import { FormatPrice } from 'lib/utils';
import { ButtonContainer } from 'lib/components/Common';
import { Button, Icon, IconGap, ModalHeader, Title } from 'lib/components';
import { useTranslation } from 'react-i18next';

interface InfoModal {
  show: boolean;
  hideModal: () => void;
  onApproved: () => void;
  amount: number | null | undefined;
  type: 'HOUSING_RENTAL' | 'STATED_INCOME';
}

const InfoModal = ({ amount, show, hideModal, onApproved, type }: InfoModal) => {
  const { t: translate } = useTranslation();

  const closeModalOnClickAway = (e) => {
    if (e?.target?.ariaHidden) {
      hideModal();
    }
  };

  return (
    <Backdrop
      sx={{ color: configTheme.main.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      onClick={closeModalOnClickAway}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer isLarge={false}>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>{translate('cherryAccess.infoModal.title')}</Title>
            <Icon hover={true} src={'close'} onClick={hideModal} />
          </ModalHeader>
          {type === 'HOUSING_RENTAL' ? (
            <PurchaseContainer>
              {translate('cherryAccess.infoModal.monthly.title', {
                amount: FormatPrice(Number(amount)),
              })}
            </PurchaseContainer>
          ) : (
            <PurchaseContainer
              dangerouslySetInnerHTML={{
                __html: translate('cherryAccess.infoModal.annual.title', {
                  amount: FormatPrice(Number(amount)),
                  industry: translate('cherryAccess.infoModal.household'),
                }),
              }}
            />
          )}
          <ButtonContainer>
            <Button onClick={onApproved} secondary={true}>
              {translate('buttons.yesRight')}
            </Button>
            <Button onClick={hideModal} secondary={true}>
              {translate('buttons.no')}
            </Button>
          </ButtonContainer>
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

export default InfoModal;

const ModalContainer = styled.div<{ isLarge: boolean }>`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0 -36px 67px rgba(0, 0, 0, 0.07), 0 -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0 -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 12px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;

    @media (max-height: ${(props) => (props.isLarge ? '700px' : '530px')}) {
      top: 30px;
      overflow-y: scroll;
    }
  }
`;

const PurchaseContainer = styled.div`
  display: block;
  flex-direction: column;
  color: ${(props) => props.theme.main.black};
  border-radius: 8px;
  padding: 16px 16px 32px;
`;
