import { InputAdornment } from '@mui/material';
import { Box, Button, Container, Icon, NumberFormatInput, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { WEBPREQUAL } from 'lib/constants';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import InfoModal from '../modals/InfoModal';
import CancelModal from './CancelModal';
import { useCreditLineIncrease } from 'lib/hooks/useCreditLineIncrease';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { isMissingInfoNextButtonDisabled } from 'lib/utils/IsMissingInfoNextButtonDisabled';

export const HouseRent = ({
  saveHouseRent,
  onCancelModalApprovedClick,
}: {
  saveHouseRent: (houseRent: string) => void;
  onCancelModalApprovedClick: () => void;
}) => {
  const { t: translate } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const { createLoading } = useCreditLineIncrease();

  const [houseRent, setHouseRent] = useState<number | null>(null);
  const { trackSegmentEvent } = useSegment();
  const { application, borrower } = useStore.getState() || {};
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.highLineHousingCostLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const toggleCancelModal = () => setShowCancelModal(!showCancelModal);

  const onCancelApproved = () => {
    trackSegmentEvent('Canceled Line Increase', {
      borrowerId: borrower?.id,
      applicationId: application?.id,
      cancelScreen: 'Housing Cost',
      application: WEBPREQUAL,
    });
    setShowCancelModal(false);
    onCancelModalApprovedClick();
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (houseRent !== null) {
        setShowModal(true);
      }
    }
  };

  const onAmountApproved = () => {
    saveHouseRent(String(houseRent));
  };

  const hideModal = () => setShowModal(false);

  const next = () => {
    sendActionSegmentEvent(WPQSegmentNames.highLineHousingCostContinue);
    if (houseRent !== null && (houseRent < 100 || houseRent > 10000)) {
      setShowModal(true);
    } else {
      saveHouseRent(String(houseRent));
    }
  };

  const onChange = (e) => {
    const value = e?.target?.value;
    const regex = /^[0-9\b]+$/;
    if (value && regex.test(value)) {
      setHouseRent(Number(value));
    } else {
      setHouseRent(null);
    }
  };

  const isNextButtonDisabled = isMissingInfoNextButtonDisabled(houseRent, createLoading);

  return (
    <Container>
      <ContentContainer>
        <Icon src={'home'} />
        <Title m={'10px 0px 8px 0px'}>{translate('cherryAccess.houseRent.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('cherryAccess.houseRent.subTitle')}</Subtitle>
        <Box>
          <TextField
            id="housing-cost"
            data-testid="housing-cost"
            data-neuro-label="housing-cost"
            label={translate('cherryAccess.houseRent.form.housingCostLabel')}
            type="tel"
            value={houseRent}
            onChange={onChange}
            onKeyDown={checkKeyDown}
            onFocus={() => {
              sendActionSegmentEvent(WPQSegmentNames.highLineHousingCostClicked);
            }}
            onBlur={(e) => {
              sendActionSegmentEvent(WPQSegmentNames.highLineHousingCostFilled, {
                metaData: e.target.defaultValue,
              });
            }}
            InputProps={{
              inputComponent: NumberFormatInput,
              pattern: '[^0-9.]',
              startAdornment: (
                <InputAdornment
                  sx={{
                    padding: '1px 0 0 0',
                    '& .MuiTypography-root': { color: '#000' },
                  }}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={next} disabled={isNextButtonDisabled} loading={createLoading}>
          {translate('buttons.continue')}
        </Button>
        <LinkText onClick={toggleCancelModal}>{translate('buttons.nevermind')}</LinkText>
      </ButtonContainer>
      {showModal && (
        <InfoModal
          show={showModal}
          hideModal={hideModal}
          onApproved={onAmountApproved}
          type={'HOUSING_RENTAL'}
          amount={houseRent}
        />
      )}
      {showCancelModal && (
        <CancelModal show={showCancelModal} hideModal={toggleCancelModal} onApproved={onCancelApproved} />
      )}
    </Container>
  );
};

const LinkText = styled.a`
  color: ${(props) => props.theme.main.green};
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
  display: block;
`;
