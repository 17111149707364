import { useEffect } from 'react';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { AlleFullScreenLoading } from 'lib/components';
import { ALLE_COGNITO_PARAMS } from 'lib/constants';
import { API_URL } from 'config';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useMutation } from '@apollo/client';
import { BORROWER_NOTIFY_ID_REVIEW } from 'lib/graphql/mutations';
import { useSentry } from 'lib/hooks/useSentry';

export const AlleCognito = () => {
  const { navigate } = useNavigation();
  const { startCheckApplicationStatus } = useSSEContext();
  const { borrower, defaultLanguage, setSessionApiData } = useStore.getState() || {};
  const [notifyIdReviewRequest] = useMutation(BORROWER_NOTIFY_ID_REVIEW);
  const { applicationFlow, reviewOutcome } = useStore();
  const { captureException } = useSentry();

  const url = `${API_URL}/v1/borrowers/${borrower?.id}/cognito-iframe?language=${defaultLanguage}`;

  let windowContext: Window;
  let isFlowCompleted = false;

  useEffect(() => {
    windowContext = window?.open(url, 'cognito', ALLE_COGNITO_PARAMS) || window;

    window?.addEventListener('message', handleCognitoWindowEvent, false);
    const intervalId = setInterval(checkWindowStatus, 1000);
    return () => {
      window?.removeEventListener('message', handleCognitoWindowEvent);
      clearInterval(intervalId);
    };
  }, []);

  const checkWindowStatus = () => {
    if (!isFlowCompleted && windowContext?.closed) {
      captureException('Flow Closed', {
        ...(applicationFlow && { borrowerId: borrower?.id }),
        ...(reviewOutcome && { reviewOutcome: reviewOutcome }),
        isFlowCompleted,
      });
      navigate('id-review');
    }
  };

  const handleCognitoWindowEvent = async (event) => {
    try {
      const res = JSON.parse(event?.data);
      console.warn('Event name:', res?.event);
      if (res?.event === 'closed') {
        isFlowCompleted = true;
        setSessionApiData({
          isCognitoCompleted: true,
        });
        const {
          data: { notifyIdReview },
        } = await notifyIdReviewRequest({
          variables: { input: { borrowerId: applicationFlow?.borrowerId } },
        });
        if (notifyIdReview?.success) {
          windowContext?.close();
          await startCheckApplicationStatus({});
        }
      } else {
        captureException('Flow Not Closed', {
          ...(applicationFlow && { borrowerId: borrower?.id }),
          ...(reviewOutcome && { reviewOutcome: reviewOutcome }),
          isFlowCompleted,
        });
      }
    } catch {
      console.warn('JSON parse error');
    }
  };

  return <AlleFullScreenLoading isTextPresent={false} />;
};
export default AlleCognito;
