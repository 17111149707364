import { gql } from '@apollo/client/core';

export const GET_MERCHANT_DETAIL = gql`
  query getMerchantDetail($input: MerchantDetailInput) {
    getMerchantDetail(input: $input) {
      success
      data {
        name
        phone
        address {
          id
          createdAt
          street
          zip
          city
          state {
            name
            code
          }
        }
      }
    }
  }
`;

export const GET_MERCHANT_COMMUNICATION_SETTINGS = gql`
  query getMerchantCommunicationSettings($input: GetMerchantCommunicationSettings) {
    getMerchantCommunicationSettings(input: $input) {
      merchantId
      displayName
      phone
      website
      success
    }
  }
`;
