import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { CREDIT_CARD_DOWN_PAYMENT } from 'lib/constants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

const Terms = () => {
  const { navigate } = useNavigation();
  const { organization, setPageIndex, features } = useStore();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();

  const hasCreditCardPaymentActive = features?.includes(CREDIT_CARD_DOWN_PAYMENT);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.eligibilityLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const navigateToPhoneInputPage = () => {
    navigate(`phone`);
    sendActionSegmentEvent(WPQSegmentNames.eligibilityClicked);
  };

  useEffect(() => {
    setPageIndex(1);
  }, [setPageIndex]);

  const listItem = [
    {
      id: 'valid-age',
      icon: 'user_adult_18',
      text: translate('terms.toBeAnAdult'),
    },
    {
      id: 'bank-issued-card',
      icon: 'credit_card_black',
      text: translate('terms.bankIssued', {
        hasCreditCardPaymentActive: hasCreditCardPaymentActive
          ? translate('terms.creditOrDebitCard')
          : translate('terms.debitCard'),
      }),
    },
  ];

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <InnerContainer>
          <Icon src={'user_check_black'} />
          <Title m={'10px 0px 0px 0px'}>{translate('terms.eligibilityChecklist')}</Title>
          <Subtitle m={'10px 0px 24px 0px'}>{translate('terms.inOrderToApplyYouMust')}...</Subtitle>
          {listItem?.map((item) => {
            const { id, icon, text } = item || {};
            return (
              <Flex key={id} margin="0 0 24px 0">
                <Icon src={icon} width={32} height={32} />
                <Span dangerouslySetInnerHTML={{ __html: text }} />
              </Flex>
            );
          })}
        </InnerContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={navigateToPhoneInputPage}>{translate('buttons.continue')}</Button>

        <SmallText>
          {translate('terms.footer.text', {
            organizationName: organization?.name,
          })}
        </SmallText>
      </ButtonContainer>
    </Container>
  );
};

export default Terms;

const Flex = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  margin: ${(props) => props?.margin || 0};
`;

const Span = styled.span`
  color: ${(props) => props.theme.main.primary};
  margin-left: ${(props) => props.theme.constants.s};
`;

export const SmallText = styled.div<{ margin?: string; isGray?: boolean }>`
  margin: ${(props) => props?.margin || '24px 0 12px 0'};
  color: ${(props) => (props.isGray ? props.theme.main.grayColor : props.theme.main.midnightBlue)};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;

const InnerContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
