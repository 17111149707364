import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useApolloClient, useMutation } from '@apollo/client';
import { WEBPREQUAL } from 'lib/constants';
import { BORROWER_INCOME_VERIFICATION } from 'lib/graphql/mutations';
import { GET_BORROWER_INCOME_VERIFICATION_LATEST } from 'lib/graphql/queries';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';

import { Button, Container, Icon, RadioButtonGroup, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { INCOME_PROVIDER } from 'pages/ConnectBankAccount/ConnectBankAccount';
import { PLAID_ERRORS } from 'pages/PlaidAuth/services';
import { useServices } from 'pages/AuthChecker/services';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

enum ConnectOptionEnums {
  BANK = 'bank',
  PAYROLL = 'payroll',
  PAYSTUBS = 'paystubs',
}

const MX_ENABLED_ON_SECOND_RETRY_ERRORS = [PLAID_ERRORS.INTERNAL_SERVER_ERROR];

const MX_ENABLED_ON_THIRD_RETRY_ERRORS = [PLAID_ERRORS.ITEM_NOT_SUPPORTED];

const CONNECT_OPTIONS_DISPLAYED_ERRORS: string[] = [
  PLAID_ERRORS.INSTITUTION_NOT_RESPONDING,
  PLAID_ERRORS.INTERNAL_SERVER_ERROR,
  PLAID_ERRORS.INSTITUTION_NO_LONGER_SUPPORTED,
  PLAID_ERRORS.INSTITUTION_DOWN,
  PLAID_ERRORS.RATE_LIMIT,
  PLAID_ERRORS.ITEM_NOT_SUPPORTED,
];
const RETRY_DISPLAYED_ERRORS: string[] = [
  PLAID_ERRORS.INVALID_CREDENTIALS,
  PLAID_ERRORS.INSUFFICIENT_CREDENTIALS,
  PLAID_ERRORS.USER_SETUP_REQUIRED,
  PLAID_ERRORS.ITEM_LOCKED,
  PLAID_ERRORS.NO_VALID_ACCOUNT,
];

const PlaidErrorHandler = () => {
  const client = useApolloClient();
  const { errorCode } = useParams();
  const { organization, setIncomeVerification, setSessionApiData } = useStore();
  const { getBorrower } = useServices();
  const loadSegmentController = useRef(true);

  const { navigate } = useNavigation();
  const [borrowerIncomeVerification] = useMutation(BORROWER_INCOME_VERIFICATION);
  const { trackIncomeVerification, trackSegmentEvent } = useSegment();
  const { trackEvent } = useAnalytics();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const { t: translate } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<ConnectOptionEnums>(ConnectOptionEnums.BANK);

  useEffect(() => {
    getIncomeVerificationLatest();
  }, []);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.incomeVerificationNotConnectBankLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const onChangeHandler = (e) => {
    setSelectedMethod(e?.target?.value);
  };

  const handleConnectBankClick = async () => {
    const { application } = useStore.getState() || {};

    trackEvent(AnalyticEventNames.CWP_LINK_ACCOUNT);
    setLoading(true);

    if (shouldRetryWithMX(errorCode)) {
      trackIncomeVerification(organization, application, INCOME_PROVIDER.MX);

      const response = await incomeVerificationRequest('ASSET_REPORT', INCOME_PROVIDER.MX);
      if (response?.id) {
        setLoading(false);
        navigate(`mx-auth/${response?.id}`);
        resetErrorCounts();
      }
    } else {
      trackIncomeVerification(organization, application, INCOME_PROVIDER.PLAID);
      goToPlaid();
    }
  };

  const goToPlaid = async () => {
    sendActionSegmentEvent(WPQSegmentNames.almostThereRepromptConnectBank);
    const response = await incomeVerificationRequest('ASSET_REPORT');
    if (response?.id) {
      setLoading(false);
      navigate(`plaid-auth/${response?.id}`);
    }
  };

  const resetErrorCounts = () => {
    setSessionApiData({
      plaidErrors: [],
    });
  };

  const incomeVerificationRequest = async (type: string, provider?: string) => {
    const { application, borrower } = useStore.getState();
    const {
      data: { borrowerIncomeVerifications },
    } = await borrowerIncomeVerification({
      variables: {
        input: {
          borrowerId: borrower?.id,
          applicationId: application?.id,
          type,
          provider: provider || INCOME_PROVIDER.PLAID,
        },
      },
    });
    return borrowerIncomeVerifications;
  };

  const uploadPaystubHandler = async () => {
    try {
      const { application, borrower } = useStore.getState() || {};

      trackSegmentEvent('Paystub Submission Began', {
        organizationSlug: organization?.slug,
        organizationName: organization?.name,
        application: WEBPREQUAL,
        applicationId: application?.id,
        value: 40,
      });
      const borrowerLatest = await getBorrower(borrower?.id);
      const response = await incomeVerificationRequest('PAYSTUB');
      if (response) {
        setIncomeVerification(response);
      }

      if (!borrowerLatest?.email) {
        navigate(`email-collection?type=paystubs`);
      } else {
        navigate(`paystubs-file-upload/${response.id}`);
      }
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const uploadPayrollHandler = async () => {
    try {
      const { application, borrower } = useStore.getState() || {};

      trackSegmentEvent('Payroll Connection Began', {
        organizationSlug: organization?.slug,
        organizationName: organization?.name,
        application: WEBPREQUAL,
        applicationId: application?.id,
        value: 40,
      });
      const borrowerLatest = await getBorrower(borrower?.id);
      const response = await incomeVerificationRequest('PAYROLL');
      if (response) {
        setIncomeVerification(response);
      }

      if (!borrowerLatest?.email) {
        navigate(`email-collection?type=payroll`);
      } else {
        navigate(`payrolls-file-upload/${response.id}`);
      }

      return response;
    } catch (err) {
      console.error('error', err);
    }
  };

  const getIncomeVerificationLatest = async () => {
    const { borrower } = useStore.getState() || {};

    const { data } = await client.query({
      query: GET_BORROWER_INCOME_VERIFICATION_LATEST,
      variables: {
        input: {
          borrowerId: borrower?.id,
        },
      },
    });

    const latestIncomeVerification = data?.borrowerIncomeVerificationsLatest;

    if (latestIncomeVerification) {
      setIncomeVerification(latestIncomeVerification);
    }
  };

  const getButtonLabel = () => {
    let buttonLabel;

    switch (selectedMethod) {
      case ConnectOptionEnums.BANK:
        buttonLabel = translate('plaidErrorHandler.button.bank');
        break;
      case ConnectOptionEnums.PAYROLL:
        buttonLabel = translate('plaidErrorHandler.button.payroll');
        break;
      case ConnectOptionEnums.PAYSTUBS:
        buttonLabel = translate('plaidErrorHandler.button.paystubs');
        break;
      default:
        buttonLabel = translate('plaidErrorHandler.button.default');
        break;
    }
    return buttonLabel;
  };

  const handleContinue = () => {
    switch (selectedMethod) {
      case ConnectOptionEnums.BANK:
        return handleConnectBankClick();
      case ConnectOptionEnums.PAYROLL:
        return uploadPayrollHandler();
      case ConnectOptionEnums.PAYSTUBS:
        return uploadPaystubHandler();
    }
  };

  const shouldRetryWithMX = (errorStatus) => {
    const { sessionApiData } = useStore.getState();
    const { plaidErrors } = sessionApiData || {};

    return (
      errorStatus === PLAID_ERRORS.INSTITUTION_NO_LONGER_SUPPORTED ||
      plaidErrors?.filter((error) => MX_ENABLED_ON_SECOND_RETRY_ERRORS.includes(error) && error === errorStatus)
        ?.length > 1 ||
      plaidErrors?.filter((error) => MX_ENABLED_ON_THIRD_RETRY_ERRORS.includes(error) && error === errorStatus)
        ?.length > 2
    );
  };

  const getMethodSelectPageContext = () => {
    let title;
    let subtitlePart1;
    let subtitlePart2;

    const COMMON_ERROR_LIST: string[] = [
      PLAID_ERRORS.INSTITUTION_NOT_RESPONDING,
      PLAID_ERRORS.INSTITUTION_NO_LONGER_SUPPORTED,
      PLAID_ERRORS.INSTITUTION_DOWN,
      PLAID_ERRORS.ITEM_NOT_SUPPORTED,
      PLAID_ERRORS.RATE_LIMIT,
    ];

    if (errorCode && COMMON_ERROR_LIST.includes(errorCode)) {
      title = translate('plaidErrorHandler.title1');
      subtitlePart1 = translate('plaidErrorHandler.subTitle1');
      subtitlePart2 = translate('plaidErrorHandler.subMessage1');
    } else if (errorCode === PLAID_ERRORS.INTERNAL_SERVER_ERROR) {
      title = translate('plaidErrorHandler.title2');
      subtitlePart1 = translate('plaidErrorHandler.subTitle2');
    }

    return {
      title,
      subtitlePart1,
      subtitlePart2,
    };
  };

  const isBankOptionPreferred = () => errorCode === PLAID_ERRORS.INTERNAL_SERVER_ERROR;

  const getRetryPageContext = () => {
    let retryTitle;
    let retrySubtitlePart1;
    let retrySubtitlePart2;

    switch (errorCode) {
      case PLAID_ERRORS.INVALID_CREDENTIALS:
        retryTitle = translate('plaidErrorHandler.invalidCredentials.title1');
        retrySubtitlePart1 = translate('plaidErrorHandler.invalidCredentials.title2');
        retrySubtitlePart2 = translate('plaidErrorHandler.invalidCredentials.title3');
        break;
      case PLAID_ERRORS.INSUFFICIENT_CREDENTIALS:
        retryTitle = translate('plaidErrorHandler.insufficientCredentials.title1');
        retrySubtitlePart1 = translate('plaidErrorHandler.insufficientCredentials.title2');
        retrySubtitlePart2 = translate('plaidErrorHandler.insufficientCredentials.title3');
        break;
      case PLAID_ERRORS.USER_SETUP_REQUIRED:
        retryTitle = translate('plaidErrorHandler.userSetupRequired.title1');
        retrySubtitlePart1 = translate('plaidErrorHandler.userSetupRequired.title2');
        retrySubtitlePart2 = translate('plaidErrorHandler.userSetupRequired.title3', {
          slug: organization?.slug,
        });
        break;
      case PLAID_ERRORS.ITEM_LOCKED:
        retryTitle = translate('plaidErrorHandler.itemLocked.title1');
        retrySubtitlePart1 = translate('plaidErrorHandler.itemLocked.title2');
        retrySubtitlePart2 = translate('plaidErrorHandler.itemLocked.title3', {
          slug: organization?.slug,
        });
        break;
      default:
        retryTitle = translate('plaidErrorHandler.default.title1');
        retrySubtitlePart1 = translate('plaidErrorHandler.default.title2');
    }

    return {
      retryTitle,
      retrySubtitlePart1,
      retrySubtitlePart2,
    };
  };

  const renderPage = () => {
    let content = <Container showBackButton={false} />;

    if (errorCode && RETRY_DISPLAYED_ERRORS.includes(errorCode)) {
      const { retryTitle, retrySubtitlePart1, retrySubtitlePart2 } = getRetryPageContext();

      content = (
        <Container showBackButton={false}>
          <ContentContainer justify="space-between">
            <InnerContainer>
              <IconContainer>
                <Icon src={'warning_outline'} width={24} height={24} />
              </IconContainer>

              <Title m={'10px 0px 8px 0px'}>{retryTitle}</Title>
              <Subtitle m={'0px 0px 30px 0px'}>{retrySubtitlePart1}</Subtitle>
              {retrySubtitlePart2 ? (
                <Subtitle dangerouslySetInnerHTML={{ __html: retrySubtitlePart2 }} m={'0px 0px 30px 0px'} />
              ) : null}
            </InnerContainer>
            <InnerContainer>
              <InfoContainer>
                <Icon width={16} height={20} src={'lock-secure'} />
                <InfoText>
                  {translate('plaidErrorHandler.info', {
                    name: organization?.name,
                  })}
                </InfoText>
              </InfoContainer>
            </InnerContainer>
          </ContentContainer>
          <ButtonContainer>
            <Button disabled={loading} loading={loading} onClick={goToPlaid}>
              {translate('buttons.connectBank')}
            </Button>
          </ButtonContainer>
        </Container>
      );
    } else if (errorCode && CONNECT_OPTIONS_DISPLAYED_ERRORS.includes(errorCode)) {
      const { title, subtitlePart1, subtitlePart2 } = getMethodSelectPageContext();
      content = (
        <Container showBackButton={false}>
          <ContentContainer justify="space-between">
            <InnerContainer>
              <IconContainer>
                <Icon src={'cherry-logo'} width={24} height={24} />
                <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
                <Icon m={'0px 4px'} width={4} height={4} src={'dot'} />
                <Icon m={'0px 12px 0px 4px'} width={4} height={4} src={'dot'} />
                <Icon src={'bank'} />
              </IconContainer>

              <Title m={'10px 0px 8px 0px'}>{title}</Title>
              <Subtitle m={'0px 0px 30px 0px'}>{subtitlePart1}</Subtitle>
              {subtitlePart2 && <Subtitle>{subtitlePart2}</Subtitle>}
              <Subtitle m={'24px 0px 10px 0px'}>{translate('plaidErrorHandler.message')}</Subtitle>
              <RadioButtonGroup
                onChange={onChangeHandler}
                radioButtonOptions={[
                  {
                    textValue: ConnectOptionEnums?.BANK,
                    radioLabel: translate('plaidErrorHandler.radio.label1'),
                    subText: translate('plaidErrorHandler.radio.text1'),
                    isPreferred: isBankOptionPreferred(),
                  },
                  {
                    textValue: ConnectOptionEnums?.PAYROLL,
                    radioLabel: translate('plaidErrorHandler.radio.label2'),
                    subText: translate('plaidErrorHandler.radio.text2'),
                  },
                  {
                    textValue: ConnectOptionEnums?.PAYSTUBS,
                    radioLabel: translate('plaidErrorHandler.radio.label3'),
                    subText: translate('plaidErrorHandler.radio.text3'),
                  },
                ]}
                value={selectedMethod}
                size={'large'}
              />
            </InnerContainer>
            <InnerContainer>
              <InfoContainer>
                <Icon width={20} height={20} src={'info_circle_outline'} />
                <InfoText>
                  {translate('plaidErrorHandler.info', {
                    name: organization?.name,
                  })}
                </InfoText>
              </InfoContainer>
            </InnerContainer>
          </ContentContainer>
          <ButtonContainer>
            <Button disabled={loading} loading={loading} onClick={handleContinue}>
              {getButtonLabel()}
            </Button>
          </ButtonContainer>
        </Container>
      );
    }

    return content;
  };

  return renderPage();
};

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;
export default PlaidErrorHandler;
