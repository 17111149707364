import React from 'react';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { MINIMUM_AMOUNT } from 'lib/constants';
import { Icon, Loading, Title } from 'lib/components';
import { CalculationData, FormatAmount } from 'lib/utils';
import { useTranslation } from 'react-i18next';

interface EstimatedPaymentProps {
  selectedProductCalculation: CalculationData;
  maxPrice: number;
  term: number;
  amount: number;
  loading: boolean;
}

export const EstimatedPayment = ({
  selectedProductCalculation,
  maxPrice,
  term,
  amount,
  loading,
}: EstimatedPaymentProps) => {
  const { t: translate } = useTranslation();

  const aprRate = selectedProductCalculation?.promo
    ? selectedProductCalculation?.product?.promoApr
    : selectedProductCalculation?.product?.apr;

  if (amount && (amount < MINIMUM_AMOUNT || amount > maxPrice)) {
    return (
      <CalculationSummary>
        <Summary color={theme.main.pinkColor}>
          <Icon src={'info_circle_red'} />
          <br />
          {translate('estimatedPayment.error.maxPrice', {
            maxPrice: FormatAmount(Number(maxPrice)),
          })}
        </Summary>
      </CalculationSummary>
    );
  }

  if (term === 36 && amount < 3000) {
    return (
      <CalculationSummary>
        <Summary color={theme.main.pinkColor}>
          <Icon src={'info_circle_red'} />
          <br />
          {translate('estimatedPayment.error.month36')}
        </Summary>
      </CalculationSummary>
    );
  }

  if (term === 48 && amount < 4000) {
    return (
      <CalculationSummary>
        <Summary color={theme.main.pinkColor}>
          <Icon src={'info_circle_red'} />
          <br />
          {translate('estimatedPayment.error.month48')}
        </Summary>
      </CalculationSummary>
    );
  }

  if (term === 60 && amount < 5000) {
    return (
      <CalculationSummary>
        <Summary color={theme.main.pinkColor}>
          <Icon src={'info_circle_red'} />
          <br />
          {translate('estimatedPayment.error.month60')}
        </Summary>
      </CalculationSummary>
    );
  }

  if (loading) {
    return (
      <CenterLoading>
        <Loading size={30} />
      </CenterLoading>
    );
  }

  return (
    <CalculationSummary>
      <Title m="24px 0 0 0" fontSize="14px">
        {translate('estimatedPayment.title')}
      </Title>

      <Title fontSize="24px" color={`${theme.main.green}`} m="0 0 10px">
        {translate('estimatedPayment.amountTitle', {
          amount: FormatAmount(Number(selectedProductCalculation?.installmentAmount)),
        })}
      </Title>
      <Summary
        dangerouslySetInnerHTML={{
          __html: translate('estimatedPayment.summary1', {
            amount: FormatAmount(Number(selectedProductCalculation?.downPaymentAmount)),
          }),
        }}
      />

      <Summary
        dangerouslySetInnerHTML={{
          __html: translate('estimatedPayment.summary2', {
            amount: FormatAmount(Number(selectedProductCalculation?.financeCharge)),
          }),
        }}
      />
      <Summary>
        <span
          dangerouslySetInnerHTML={{
            __html: translate('estimatedPayment.summary3', {
              aprRate: aprRate,
            }),
          }}
        />

        {selectedProductCalculation?.product?.fallbackApr && selectedProductCalculation?.product?.fallbackApr > 0 ? (
          <>
            {translate('estimatedPayment.summary4', {
              apr: selectedProductCalculation?.product?.fallbackApr,
            })}
          </>
        ) : null}
      </Summary>
    </CalculationSummary>
  );
};

const CenterLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: 20px;
`;

const CalculationSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.main.darkGrayColor};
  border-radius: 8px;
  padding: 16px 16px 32px;
  margin-top: 24px;
`;

const Summary = styled.div<{ color?: string }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: center;
  font-style: ${(props) => (props?.color ? 'normal' : 'italic')};
  color: ${(props) => (props?.color ? props?.color : theme.main.textColor)};
  margin-bottom: 2px;

  img {
    padding: 24px;
  }

  b {
    font-weight: 700;
  }
`;
