import { InputAdornment } from '@mui/material';
import styled from 'styled-components';

import { Box, Icon, NumberFormatInput, TextField } from 'lib/components';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

interface CustomizeDownPaymentProps {
  amount: number | null;
  checkKeyDown: (e) => void;
  handleClickIncrease: () => void;
  handleClickDecrease: () => void;
  handleClickReset: () => void;
  onChange: (e) => void;
  error: string;
  minEligible: number;
  maxEligible: number;
  loading: boolean;
}

const CustomizeDownPayment = ({
  amount,
  checkKeyDown,
  handleClickIncrease,
  handleClickDecrease,
  handleClickReset,
  onChange,
  error,
  minEligible,
  maxEligible,
  loading,
}: CustomizeDownPaymentProps) => {
  const { sendActionSegmentEvent } = useSegmentContext();

  const { t: translate } = useTranslation();
  return (
    <div>
      <DownPaymentContainer>
        <ButtonContainer
          disabled={loading || amount === minEligible || !!error}
          onClick={handleClickDecrease}
          data-testid={'custom-down-payment-button-minus'}
        >
          <Icon src={loading || amount === minEligible || !!error ? 'minus_circle_gray' : 'minus_circle'} />
          {translate('customizePlan.form.payLess')}
        </ButtonContainer>
        <InputContainer>
          <Box>
            <TextField
              id="custom-down-payment-input"
              data-testid={'custom-down-payment-input'}
              variant="filled"
              label={translate('customizePlan.form.label')}
              type="tel"
              value={amount?.toFixed(2)}
              onChange={onChange}
              onFocus={() => {
                sendActionSegmentEvent(WPQSegmentNames.customDownPaymentEnterAmountClicked);
              }}
              onBlur={(e) => {
                sendActionSegmentEvent(WPQSegmentNames.customDownPaymentAmountFilled, {
                  metaData: e.target.defaultValue,
                });
              }}
              onKeyDown={checkKeyDown}
              error={!!error}
              helperText={error ? error : null}
              sx={{
                '& .MuiFormHelperText-root': {
                  textAlign: 'justify',
                  margin: '2px',
                },
                '& .MuiFilledInput-root': {
                  paddingLeft: 0,
                },

                input: {
                  fontWeight: 'bold',
                },
                label: {
                  color: '#56636D',
                  marginLeft: '16px',
                },
              }}
              InputProps={{
                inputComponent: NumberFormatInput,
                readOnly: false,
                disableUnderline: true,
                pattern: '[^0-9.]',
                startAdornment: (
                  <InputAdornment
                    sx={{
                      padding: '1px 0 0 54px',
                      '& .MuiTypography-root': {
                        color: '#000',
                        fontWeight: 'bold',
                      },
                    }}
                    position="start"
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </InputContainer>
        <ButtonContainer
          disabled={loading || amount === maxEligible || !!error}
          data-testid={'custom-down-payment-button-plus'}
          onClick={handleClickIncrease}
        >
          <Icon src={loading || amount === maxEligible || !!error ? 'plus_circle_gray' : 'plus_circle'} />
          {translate('customizePlan.form.payMore')}
        </ButtonContainer>
      </DownPaymentContainer>
      <ButtonText onClick={handleClickReset}>{translate('customizePlan.form.resetDefaultAmount')}</ButtonText>
    </div>
  );
};

export default CustomizeDownPayment;

const DownPaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`;

const InputContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0 8px;
  max-width: 220px;
`;

const ButtonText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.main.green};
  cursor: pointer;
`;

const ButtonContainer = styled.div<{ disabled: boolean }>`
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.main.grayColor};
  margin-top: 3.5px;
  cursor: pointer;

  > img {
    cursor: pointer;
  }

  ${(props) =>
    props.disabled && {
      pointerEvents: 'none',
      cursor: 'none',
    }}
`;
