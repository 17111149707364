import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';

import { theme } from 'config/theme';
import { Icon, Subtitle, Title } from 'lib/components';
import { useTranslation } from 'react-i18next';

export const PointOfSaleModal = ({ show, hideModal, yesCancelApplicationPressed }) => {
  const { t: translate } = useTranslation();
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={hideModal}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>{translate('header.modal.pointOfSales.title')}</Title>
            <Icon src={'close'} onClick={hideModal} />
          </ModalHeader>

          <Subtitle m={'0px 0px 24px 0px'} textAlign="center" display="flex">
            {translate('header.modal.pointOfSales.content')}
          </Subtitle>

          <ModalButton onClick={yesCancelApplicationPressed}>
            {translate('header.modal.pointOfSales.cancelApplication')}
          </ModalButton>
          <ModalButton onClick={hideModal}>{translate('header.modal.pointOfSales.dismiss')}</ModalButton>
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

const ModalContainer = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background-color: ${(props) => props.theme.main.white};
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 30px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  img {
    cursor: pointer;
  }
`;

const ModalButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.main.white};
  border: 1px solid ${theme.main.grayColor};
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;
