import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { theme } from 'config/theme';
import { useNavigation } from 'lib/hooks/useNavigation';
import { Redirect } from 'lib/utils';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import AlleApproval from '../Approval/AlleApproval';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { Trans, useTranslation } from 'react-i18next';
import { getExternalLinkLangParam } from 'lib/utils/ExternalLinkLangParam';
import { Box, Stack, Typography } from '@mui/material';

const FrozenCredit = () => {
  const { navigate } = useNavigation();
  const { isAlle, defaultLanguage } = useStore();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();
  const { sessionApiData } = useStore.getState();
  const { featureFlags } = sessionApiData || {};
  const isNewDenialUi = featureFlags?.isNewDenialUi || false;

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.frozenCreditLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const applyAgain = () => {
    sendActionSegmentEvent(WPQSegmentNames.frozenApplyAgainClicked);
    navigate(``);
  };

  const howToUnfreezeCredit = () => {
    sendActionSegmentEvent(WPQSegmentNames.howToUnfreezeClicked);
    Redirect(`https://withcherry.com/unfreeze-your-credit/${getExternalLinkLangParam(defaultLanguage)}`);
  };

  if (isAlle) {
    return <AlleApproval />;
  }

  return (
    <Container showBackButton={false}>
      {isNewDenialUi ? (
        <>
          <ContentContainer>
            <Icon src={'frozen-credit-alt'} />
            <BaseTypography fontSize={20} fontWeight={700} my={1} variant="h1" data-testid="frozen-credit-title">
              {translate('frozenCredit.title')}
            </BaseTypography>
            <BaseTypography mb={3} fontSize={14} variant="body2">
              {translate('frozenCredit.subtitle')}
            </BaseTypography>
            <InfoBox fontSize={14} p={'12px 16px'}>
              <BaseTypography fontSize={16} fontWeight={700} variant="h2" mb={1}>
                {translate('frozenCredit.infoBox.title')}
              </BaseTypography>
              <Stack spacing={2}>
                <BaseTypography>{translate('frozenCredit.infoBox.firstParagraph')}</BaseTypography>
                <BaseTypography>
                  <Trans
                    i18nKey="frozenCredit.infoBox.secondParagraph"
                    components={{
                      b: <BaseTypography fontWeight={600} display="inline" />,
                    }}
                  />
                </BaseTypography>
                <BaseTypography>{translate('frozenCredit.infoBox.thirdParagraph')}</BaseTypography>
              </Stack>
            </InfoBox>
          </ContentContainer>
        </>
      ) : (
        <>
          <ContentContainer>
            <Icon src={'frozen-credit-alt'} />
            <Title m={'10px 0px 8px 0px'} data-testid="frozen-credit-title">
              {translate('frozenCredit.title')}
            </Title>
            <Subtitle m={'24px 0px 24px 0px'}>{translate('frozenCredit.subTitle')}</Subtitle>
            <MessageTitle>{translate('frozenCredit.info.title')}</MessageTitle>
            <Subtitle m={'0px 0px 24px 0px'}>{translate('frozenCredit.info.subTitle')}</Subtitle>
          </ContentContainer>
          <ButtonContainer>
            <Button disabled={false} onClick={applyAgain}>
              {translate('buttons.applyAgain')}
            </Button>
            <Button disabled={false} onClick={howToUnfreezeCredit} secondary={true}>
              {translate('buttons.howtoUnfreezeCredit')}
            </Button>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export const MessageTitle = styled.span`
  font-family: ${theme.fonts.primary}, serif;
  color: ${(props) => props.theme.main.grayColor};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  font-style: normal;
  margin: 24px 0 8px 0;
`;

const BaseTypography = styled(Typography)`
  line-height: normal !important;
  font-style: normal;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const InfoBox = styled(Box)`
  background: ${(props) => props.theme.main.greenLight};
  border: 2px solid ${(props) => props.theme.main.greenLightDark};
  border-radius: 8px;
`;

export default FrozenCredit;
