class Navigate {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private _navigate: any;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  initNavigate = (instance: any) => {
    this._navigate = instance;
  };

  get navigate() {
    return this._navigate;
  }
}

export default new Navigate();
