import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { theme } from 'config/theme';

import { Container, Icon } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import useStore from 'lib/hooks/useStore';
import AlleApproval from '../Approval/AlleApproval';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { Trans } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const Unlicensed = () => {
  const { isAlle, borrower, organization } = useStore();
  const { sendLoadSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.unlicensedLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  if (isAlle) {
    return <AlleApproval />;
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'warning_outline'} />
        <BaseTypography fontSize={20} fontWeight={700} my={1} variant="h1" data-testid="frozen-credit-title">
          <Trans i18nKey="unlicensed.title" />
        </BaseTypography>
        <BaseTypography mb={3} fontSize={14} variant="body2">
          <Trans
            i18nKey="unlicensed.description"
            values={{ name: organization?.name, state: borrower?.address?.state?.name }}
            components={{
              bold: <strong />,
              br: <br />,
            }}
          />
        </BaseTypography>
      </ContentContainer>
    </Container>
  );
};

export const MessageTitle = styled.span`
  font-family: ${theme.fonts.primary}, serif;
  color: ${(props) => props.theme.main.grayColor};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  font-style: normal;
  margin: 24px 0 8px 0;
`;

const BaseTypography = styled(Typography)`
  line-height: normal !important;
  font-style: normal;
  font-family: 'Open Sans' !important;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const InfoBox = styled(Box)`
  background: ${(props) => props.theme.main.greenLight};
  border: 2px solid ${(props) => props.theme.main.greenLightDark};
  border-radius: 8px;
`;

export default Unlicensed;
