import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';

import { Redirect } from 'lib/utils';
import { LANGUAGES } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import { SET_EXCHANGE_TOKEN } from 'lib/graphql/mutations';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';

import { FaqListModal } from 'lib/components/FaqListModal';
import { Button, Container, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import faqList from 'pages/Faq/components/faq.json';
import { APP_ENV } from 'config';
import whatHappensNow from 'assets/images/what-happens-now.png';

const WhatHappensNow = () => {
  const alert = useAlert();
  const { organization, borrower, defaultLanguage } = useStore();
  const { t: translate } = useTranslation();
  const [displayFaqModal, setDisplayFaqModal] = useState(false);
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  const [setExchangeToken] = useMutation(SET_EXCHANGE_TOKEN);

  const showModal = () => {
    sendActionSegmentEvent(WPQSegmentNames.whatHappensHaveQuestion);
    setDisplayFaqModal(true);
  };

  const hideModal = () => {
    setDisplayFaqModal(false);
  };

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.whatHappensLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const goToPatientPortal = async () => {
    sendLoadSegmentEvent(WPQSegmentNames.whatHappensNowApprovalVisitPatientPortalClicked);

    const { data } = await setExchangeToken({
      variables: {
        input: {
          target: 'BORROWER',
          payload: {
            organizationId: organization?.id,
            borrowerId: borrower.id,
          },
        },
      },
    });

    if (data && data?.setExchangeToken && data?.setExchangeToken?.exchangeTokenId) {
      const url = APP_ENV === 'master' ? 'https://patient.withcherry.com' : `https://${APP_ENV}-patient.withcherry.com`;
      Redirect(`${url}/${data?.setExchangeToken?.exchangeTokenId}`);
    } else {
      alert.info(translate('errors.default'));
    }
  };

  return (
    <Container showBackButton={true} backUrl={'approval'}>
      <ContentContainer justify="flex-start">
        <Title m={'10px 0px 8px 0px'}>{translate('whatHappensNow.title')}</Title>
        <Subtitle m={'0 0px 24px 0px'}>
          {translate('whatHappensNow.subTitle', {
            name: organization?.name,
          })}
        </Subtitle>

        <StyledImage src={whatHappensNow} />

        <ApprovalBox>
          <Title m={'0 0 8px 0'} fontSize="16px">
            {translate('whatHappensNow.approvalTitle')}
          </Title>
          <Subtitle m={'0 0 16px 0'}>{translate('whatHappensNow.approvalSubtitle')}</Subtitle>
          <Button mb={true} onClick={goToPatientPortal}>
            {translate('buttons.accessMyPatientPortal')}
          </Button>
        </ApprovalBox>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={showModal} secondary={true}>
          {translate('buttons.haveQuestions')}
        </Button>
      </ButtonContainer>

      <FaqListModal
        hideModal={hideModal}
        show={displayFaqModal}
        faqList={faqList?.[defaultLanguage || LANGUAGES[0]]}
        title={translate('whatHappensNow.commonQuestions')}
        titleMargin={'auto'}
        modalHeight={'80%'}
        modalWidth={'550px'}
        modalTop={'50px'}
        scrollEnabled={true}
      />
    </Container>
  );
};

const StyledImage = styled.img`
  width: 80%;
  height: auto;
  margin: 0 auto;
  padding: 0;
`;

const ApprovalBox = styled.div`
  border-radius: 8px;
  background-color: #e6f9f2;
  padding: ${(props) => props.theme.constants.m};
  margin-top: ${(props) => props.theme.constants.l};
`;

export default WhatHappensNow;
