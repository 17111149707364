import React, { useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';

import { FormatAmount } from 'lib/utils';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { MINIMUM_AMOUNT, TRACK_VERSION, WEBPREQUAL } from 'lib/constants';

import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { Box, Button, Container, Icon, NumberFormatInput, Subtitle, TextField, Title } from 'lib/components';
import { useTranslation } from 'react-i18next';

export const RequestedAmount = ({ saveRequestedAmount }) => {
  const { trackSegmentEvent } = useSegment();
  const { t: translate } = useTranslation();
  const { application, sessionApiData } = useStore.getState() || {};
  const { missingInfo } = sessionApiData || {};
  const initialRequestedAmountInfo = missingInfo?.find((data) => data?.key === 'REQUESTED_AMOUNT');

  const [errorMessage, setErrorMessage] = useState('');
  const [requestedAmount, setRequestedAmount] = useState<number | null>(
    Number(initialRequestedAmountInfo?.value) || null,
  );
  const [formattedAmount, setFormattedAmount] = useState<number | null>(
    Number(initialRequestedAmountInfo?.value) || null,
  );
  const [validationError, setValidationError] = useState<boolean>();

  useEffect(() => {
    trackSegmentEvent('cherry_access_requested_amount_viewed', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
    });
  }, []);

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (requestedAmount) {
        next();
      }
    }
  };

  const next = () => {
    const minimum = application?.balanceAvailable ? application?.balanceAvailable : MINIMUM_AMOUNT;
    if (requestedAmount && requestedAmount > application?.menu?.maxPurchase) {
      setValidationError(true);
      setErrorMessage(
        translate('requestedAmount.error.message', {
          maxPurchase: FormatAmount(application?.maxPurchase),
        }),
      );
    } else if (requestedAmount && requestedAmount < minimum) {
      setValidationError(true);

      setErrorMessage(
        translate('requestedAmount.error.messageElseIf', {
          maxPurchase: FormatAmount(application?.balanceAvailable || MINIMUM_AMOUNT),
        }),
      );
    } else {
      trackSegmentEvent('cherry_access_request_amount', { eventValue: requestedAmount, application: WEBPREQUAL });
      setValidationError(false);
      saveRequestedAmount(requestedAmount);
    }
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    const regex = /^[0-9\b]+$/;
    if (value && regex?.test(value)) {
      setRequestedAmount(Number(value));
      setFormattedAmount(Number(value));
      setValidationError(false);
    } else {
      setRequestedAmount(null);
    }
  };

  return (
    <Container showBackButton={true} backUrl={'approval-boost/additional-info/email'}>
      <ContentContainer>
        <Icon src={'payment'} />
        <Title m={'10px 0px 8px 0px'}>{translate('requestedAmount.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('requestedAmount.subContent')}</Subtitle>
        <Box>
          <TextField
            id="requested-amount"
            data-testid="requested-amount"
            data-neuro-label="requested-amount"
            variant="filled"
            label={translate('requestedAmount.form.requestedAmount')}
            type="tel"
            value={formattedAmount}
            max={application?.menu?.maxPurchase}
            min={application?.balanceAvailable || MINIMUM_AMOUNT}
            onChange={handlePriceChange}
            onKeyDown={checkKeyDown}
            error={validationError}
            helperText={validationError ? errorMessage : ''}
            InputProps={{
              inputComponent: NumberFormatInput,
              pattern: '[^0-9.]',
              startAdornment: (
                <InputAdornment
                  sx={{
                    padding: '1px 0 0 0',
                    '& .MuiTypography-root': { color: '#000' },
                  }}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={next} disabled={!requestedAmount}>
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};
