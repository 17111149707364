import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { DATE_FORMAT } from 'lib/constants';
import { SAVE_DOWN_PAYMENT } from 'lib/graphql/mutations';
import { useDebounce } from 'lib/hooks/useDebounce';
import { useDownPaymentCalculation } from 'lib/hooks/useDownpaymentCalculation';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { CalculationData, FormatAmount } from 'lib/utils';

import { Button, Container, Icon, Loading, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useProductCalculation } from 'lib/hooks/useProductCalculation';
import { useSegment } from 'lib/hooks/useSegment';
import CustomizeDownPayment from './components/CustomizeDownPayment';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useTranslation } from 'react-i18next';

enum DownPaymentError {
  Maximum = 'The maximum down payment allowed for your purchase is',
  Minimum = 'The minimum down payment allowed for your purchase is',
  Default = 'Enter a valid down payment',
}

const CustomizePlan = () => {
  const alert = useAlert();
  const { captureException } = useSentry();
  const { navigate, navigateBack } = useNavigation();
  const { getProductCalculation } = useProductCalculation();
  const { getDownPaymentCalculation } = useDownPaymentCalculation();
  const { t: translate } = useTranslation();

  const DownPaymentError = {
    Maximum: translate('customizePlan.error.maximum'),
    Minimum: translate('customizePlan.error.minimum'),
    Default: translate('customizePlan.error.default'),
  };
  const { trackSegmentEvent } = useSegment();

  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  const { selectedPlan, setPageIndex, selectedPaymentPlan, setSelectedPaymentPlan } = useStore();

  const [amount, setAmount] = useState<number>(selectedPlan?.downPaymentAmount);
  const [error, setError] = useState<string>('');
  const [calculationLoading, setCalculationLoading] = useState(false);

  const [selectedDownPaymentPlan, setSelectedDownPaymentPlan] = useState<CalculationData>();
  const [saveDownPayment, { loading }] = useMutation(SAVE_DOWN_PAYMENT);
  const { loan, borrower } = useStore.getState() || {};
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  const debouncedValue = useDebounce(amount, 750);

  useEffect(() => {
    setPageIndex(2);
  }, [setPageIndex]);

  const goBack = () => {
    sendActionSegmentEvent(WPQSegmentNames.customDownPaymentCancelClicked);
    navigate(`plans`);
  };

  useEffect(() => {
    if (amount) {
      calculateAmount?.(amount);
    }
  }, []);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.postCheckoutCustomDownPaymentLoad, {
        application: 'checkout',
      });
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  // eslint-disable-next-line  @typescript-eslint/no-non-null-asserted-optional-chain
  const minEligible = selectedDownPaymentPlan?.variableDownPayment?.minEligible!;
  // eslint-disable-next-line  @typescript-eslint/no-non-null-asserted-optional-chain
  const maxEligible = selectedDownPaymentPlan?.variableDownPayment?.maxEligible!;
  // eslint-disable-next-line  @typescript-eslint/no-non-null-asserted-optional-chain
  const changeRange = selectedDownPaymentPlan?.variableDownPayment?.changeRange!;

  const calculateAmount = React.useCallback(async (enteredAmount: number) => {
    const response = await getDownPaymentCalculation(
      selectedPlan?.product?.applicationId,
      selectedPlan?.product?.id,
      enteredAmount!,
      selectedPlan?.grossAmount,
    );

    if (response?.hasOwnProperty('variableDownPayment') && response?.hasOwnProperty('product')) {
      setSelectedDownPaymentPlan(response);
      setCalculationLoading(false);
    } else {
      setCalculationLoading(true);
    }
  }, []);

  const handleClickDecrease = React.useCallback(() => {
    setError('');
    setCalculationLoading(true);
    const value = amount - changeRange < minEligible ? minEligible : amount - changeRange;

    sendActionSegmentEvent(WPQSegmentNames.customDownPaymentLessClicked, {
      change_type: 'Pay Less Button',
      custom_amount: value,
    });

    if (!isNaN(value)) {
      setAmount(value);
      setTimeout(() => {
        calculateAmount?.(value);
      }, 500);
    }
  }, [amount, changeRange]);

  const handleClickIncrease = React.useCallback(() => {
    setCalculationLoading(true);
    setError('');
    const value = amount + changeRange > maxEligible ? maxEligible : amount + changeRange;

    sendActionSegmentEvent(WPQSegmentNames.customDownPaymentMoreClicked, {
      change_type: 'Pay More Button',
      custom_amount: value,
    });

    if (!isNaN(value)) {
      setAmount(value);
      setTimeout(() => {
        calculateAmount?.(value);
      }, 500);
    }
  }, [amount, changeRange]);

  const handleSaveDownPayment = async () => {
    const formattedMaxAmount = FormatAmount(maxEligible);
    const formattedMinAmount = FormatAmount(minEligible);
    setCalculationLoading(false);
    if (!!amount) {
      sendActionSegmentEvent(WPQSegmentNames.customDownPaymentSaveClicked, {
        change_status: 'Saved down payment',
        custom_amount: amount,
      });
      if (amount > maxEligible) {
        setError(`${DownPaymentError.Maximum} ${formattedMaxAmount}`);
      } else if (amount < minEligible) {
        setError(`${DownPaymentError.Minimum} ${formattedMinAmount}`);
      } else {
        setError('');
        try {
          const { data } = await saveDownPayment({
            variables: {
              input: {
                applicationId: selectedDownPaymentPlan?.product?.applicationId,
                loanId: loan?.id,
                parentId: selectedDownPaymentPlan?.product?.parentId
                  ? selectedDownPaymentPlan?.product?.parentId
                  : selectedDownPaymentPlan?.product?.id,
                amount: selectedDownPaymentPlan?.grossAmount,
                chosenDownPaymentAmount: selectedDownPaymentPlan?.downPaymentAmount,
              },
            },
          });

          if (data?.saveDownPayment?.applicationId) {
            try {
              const { application } = useStore.getState() || {};
              const purchaseAmount = loan?.purchaseAmount;
              const applicationId = application?.id;

              setSelectedPaymentPlan(selectedPaymentPlan + 1);

              const response = await getProductCalculation(applicationId, purchaseAmount);
              if (response && response.length > 0) {
                navigate(`plans`);
              } else {
                throw new Error('Customized choose plan - no response');
              }
            } catch (err) {
              captureException('Product Calculation Error', {
                error: err,
                page: 'Customize Plan',
                message: 'Product Calculation Error',
                loanId: loan?.id || 'No Loan',
                applicationId: data?.saveDownPayment?.applicationId || 'No Application',
              });
              navigateBack();
            }
          } else {
            alert.info(translate('customizePlan.error.saveDownPayment'));
            navigateBack();
          }
        } catch (err) {
          alert.info(translate('customizePlan.error.saveDownPayment'));
          captureException('Downpayment Error', {
            error: err,
            page: 'Customize Plan',
            message: 'Down payment error.',
            session: selectedDownPaymentPlan,
          });
          navigateBack();
        }
      }
    } else {
      setError(`${DownPaymentError.Minimum} ${formattedMinAmount}`);
    }
  };

  const checkKeyDown = (e) => {
    if (e?.code === 'Enter' || Number(e?.keyCode) === 13) {
      e?.preventDefault();
      if (amount) {
        setCalculationLoading(false);
        handleSaveDownPayment?.();
      }
    }
  };

  const handleClickReset = React.useCallback(() => {
    setError('');
    setCalculationLoading(true);
    const value = selectedPlan?.downPaymentAmount;

    setAmount(value);
    trackSegmentEvent('Customize Down Payment Amount', {
      application: 'checkout',
      loan_id: loan?.id,
      borrower_id: borrower?.id,
      change_type: 'Set default amount',
      custom_amount: value,
    });

    setTimeout(() => {
      calculateAmount?.(value);
    }, 500);
  }, []);

  const handleCustomDownPaymentChange = (e) => {
    setCalculationLoading(true);
    setError('');
    clearTimeout(timer);

    const value = parseFloat(e?.target?.value);

    const newTimer = setTimeout(() => {
      trackSegmentEvent('Customize Down Payment Amount', {
        application: 'checkout',
        loan_id: loan?.id,
        borrower_id: borrower?.id,
        change_type: 'Enter Custom Amount',
        custom_amount: value,
      });
      return () => clearTimeout(timer);
    }, 500);

    if (Boolean(value)) {
      setAmount(value);

      const formattedMaxAmount = FormatAmount(maxEligible);
      const formattedMinAmount = FormatAmount(minEligible);
      if (value > maxEligible) {
        setError(`${DownPaymentError.Maximum} ${formattedMaxAmount}`);
      } else if (value < minEligible) {
        setError(`${DownPaymentError.Minimum} ${formattedMinAmount}`);
      }
    } else {
      setError(DownPaymentError.Default);
      setCalculationLoading(false);
    }

    setTimer(newTimer);
  };

  useEffect(() => {
    const value = parseFloat(debouncedValue);

    if (!value || value < minEligible || value > maxEligible) {
      return;
    }

    calculateAmount?.(value);
  }, [debouncedValue]);

  return (
    <Container showBackButton={true} backUrl={`plans`}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'payment'} />
          <Title m={'10px 0px 0px 0px'}>{translate('customizePlan.title')}</Title>
          <Subtitle m={'10px 0px 12px 0px'}>
            {translate('customizePlan.subTitle', {
              minEligible: FormatAmount(minEligible),
              maxEligible: FormatAmount(maxEligible),
            })}
          </Subtitle>
          <CustomizeDownPayment
            onChange={handleCustomDownPaymentChange}
            amount={debouncedValue}
            checkKeyDown={checkKeyDown}
            handleClickIncrease={handleClickIncrease}
            handleClickDecrease={handleClickDecrease}
            handleClickReset={handleClickReset}
            error={error}
            minEligible={minEligible}
            maxEligible={maxEligible}
            loading={calculationLoading}
          />
          <ContentItemContainer>
            <ContentTitle>{translate('customizePlan.contentTitle')}</ContentTitle>
            {error || calculationLoading ? (
              error ? (
                <ErrorContainer>{DownPaymentError.Default}</ErrorContainer>
              ) : (
                <LoadingContainer>
                  <Loading size={40} />
                </LoadingContainer>
              )
            ) : (
              <>
                {selectedDownPaymentPlan ? (
                  <>
                    <ContentText>
                      {translate('customizePlan.downPayment.selectedAmount', {
                        amount: FormatAmount(selectedDownPaymentPlan?.payments?.[0]?.amount),
                      })}
                    </ContentText>

                    <ContentSubText
                      margin="2px 0 0"
                      fontSize="12px"
                      lineHeight="16px"
                      dangerouslySetInnerHTML={{
                        __html: translate('customizePlan.downPayment.selectedTerm', {
                          term: selectedDownPaymentPlan?.product?.term,
                          date: dayjs(selectedDownPaymentPlan?.paymentDates?.[0]).format(DATE_FORMAT),
                          apr: selectedDownPaymentPlan?.promo
                            ? selectedDownPaymentPlan?.product?.promoApr
                            : selectedDownPaymentPlan?.product?.apr,
                        }),
                      }}
                    />

                    <ContentSubText
                      margin="8px 0 0"
                      fontSize="14px"
                      lineHeight="19px"
                      dangerouslySetInnerHTML={{
                        __html: translate('customizePlan.downPayment.selectedFinanceCharge', {
                          financeCharge: FormatAmount(Number(selectedDownPaymentPlan?.financeCharge)),
                        }),
                      }}
                    />
                  </>
                ) : null}
              </>
            )}
          </ContentItemContainer>
        </InnerContainer>
        <InnerContainer>
          {selectedDownPaymentPlan && selectedDownPaymentPlan?.product?.promoApr !== null ? (
            <InfoContainer>
              <Icon width={20} height={20} src={'info_circle_outline'} />
              <InfoText
                dangerouslySetInnerHTML={{
                  __html: translate('customizePlan.downPayment.apr', {
                    apr: selectedDownPaymentPlan?.product?.fallbackApr || selectedDownPaymentPlan?.product?.apr,
                  }),
                }}
              />
            </InfoContainer>
          ) : null}
        </InnerContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={!!error || calculationLoading || loading}
          loading={calculationLoading || loading}
          onClick={handleSaveDownPayment}
        >
          {translate('buttons.saveDownPayment')}
        </Button>
        <Button secondary={true} onClick={goBack}>
          {translate('buttons.cancel')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default React.memo(CustomizePlan);

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 24px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    padding: 34px 0 24px;
  }
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const ContentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  background: ${(props) => props.theme.main.darkGrayColor};
  border-radius: 4px;
  margin-top: 28px;
  padding: 16px;
  text-align: center;
`;

const ContentTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.main.green};
  margin-bottom: 8px;
`;

const ContentText = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  color: ${(props) => props.theme.main.textColor};
  margin-bottom: 4px;
`;

const ContentSubText = styled.span<{ margin?: string; fontSize?: string; lineHeight?: string }>`
  font-style: italic;
  letter-spacing: 0.005em;
  color: ${(props) => props.theme.main.textColor};
  margin: ${(props) => props.margin};
  font-size: ${(props) => props.fontSize || '12px'};
  line-height: ${(props) => props.lineHeight || '16px'};
`;

const ErrorContainer = styled.div`
  font-family: 'Open Sans', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
`;

const LoadingContainer = styled.div.attrs(() => ({
  'data-testid': 'down-payment-loading',
}))`
  text-align: center;
  margin: 0 auto;
`;
