import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { FaqListModal } from 'lib/components/FaqListModal';
import { LANGUAGES, WEBPREQUAL } from 'lib/constants';

import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import { useEligibilityService } from 'pages/AuthChecker/services';
import { INCOME_PROVIDER } from 'pages/ConnectBankAccount/ConnectBankAccount';
import faqConnectWithPlaid from 'pages/Faq/components/faqConnectWithPlaid.json';
import useStore from 'lib/hooks/useStore';
import AlleMultiScreenConnectBankAccount from './AlleMultipleConnectBankAccount';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';

interface Props {
  isOptions: boolean;
  uploadPaystub: () => void;
  goToOriginalApproval: () => void;
  featureFlags: {
    mxEnabled: boolean;
  };
  organization: {
    name: string;
  };
}

const INCOME_VERIFICATION_RETRY_ERROR = 'Retry';
const MX_RELATED_ERROR = 'Unsupported_Error';

const MultiScreenConnectBankAccount = ({
  isOptions,
  uploadPaystub,
  featureFlags,
  organization,
  goToOriginalApproval,
}: Props) => {
  const { navigate } = useNavigation();
  const { trackIncomeVerification, trackPage } = useSegment();
  const { trackEvent } = useAnalytics();
  const { t: translate } = useTranslation();
  const { getBorrowerIncomeVerification } = useEligibilityService();
  const { isAlle, defaultLanguage } = useStore();
  const { application } = useStore.getState() || {};
  const { sendActionSegmentEvent } = useSegmentContext();

  const [pageStep, setPageStep] = useState(1);
  const [displayFaqModal, setDisplayFaqModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const goToPlaid = async () => {
    const { application } = useStore.getState() || {};

    const incomeProvider = featureFlags?.mxEnabled ? INCOME_PROVIDER.MX : INCOME_PROVIDER.PLAID;
    trackEvent(AnalyticEventNames.CWP_LINK_ACCOUNT);
    trackIncomeVerification(organization, application, incomeProvider, true);

    setLoading(true);
    const response = await getBorrowerIncomeVerification('ASSET_REPORT');
    if (response?.id) {
      setLoading(false);

      if (featureFlags?.mxEnabled) {
        navigate(`mx-auth/${response?.id}`);
      } else {
        navigate(`plaid-auth/${response?.id}`);
      }
    }
  };

  useEffect(() => {
    const { incomeVerification } = useStore.getState() || {};

    if (
      incomeVerification?.errorMessage === INCOME_VERIFICATION_RETRY_ERROR ||
      incomeVerification?.errorMessage === MX_RELATED_ERROR
    ) {
      setSecondPageStep();
    }
  }, []);

  const renderButton = () => {
    if (isOptions) {
      return (
        <ButtonText onClick={uploadPaystub}>{translate('multiScreenConnectBankAccount.button.verifyOther')}</ButtonText>
      );
    }

    return null;
  };

  const setFirstPageStep = () => setPageStep(1);

  const handleHaveaQuestion = () => {
    setDisplayFaqModal(true);
    sendActionSegmentEvent(WPQSegmentNames.ivHaveQuestionClicked);
  };

  const setSecondPageStep = () => {
    const { application } = useStore.getState() || {};
    setPageStep(2);

    trackPage('incomeVerificationLanding', {
      application: WEBPREQUAL,
      applicationId: application?.id,
      screenVariation: 'multiScreen2',
      incomeProvider: featureFlags?.mxEnabled ? INCOME_PROVIDER.MX : INCOME_PROVIDER.PLAID,
    });
  };

  const renderPageContent = () => {
    let content;
    if (pageStep === 1) {
      content = (
        <>
          <ContentContainer justify="space-between">
            <InnerContainer>
              <IconContainer>
                <Icon src={'cherry-logo'} width={24} height={24} />
                <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
                <Icon m={'0px 4px'} width={4} height={4} src={'dot'} />
                <Icon m={'0px 12px 0px 4px'} width={4} height={4} src={'dot'} />
                <Icon src={'bank'} />
              </IconContainer>
              <Title m={'10px 0px 8px 0px'}>{translate('multiScreenConnectBankAccount.title')}</Title>
              <Subtitle m={'0px 0px 24px 0px'}>{translate('multiScreenConnectBankAccount.subTitle')}</Subtitle>
              <Subtitle m={'0px 0px 24px 0px'}>{translate('multiScreenConnectBankAccount.subMessage')}</Subtitle>
            </InnerContainer>
          </ContentContainer>
          <InfoContainer>
            <Icon width={16} height={20} src={'info_circle_outline'} />
            <InfoText>
              {translate('multiScreenConnectBankAccount.info', {
                name: organization?.name,
              })}
            </InfoText>
          </InfoContainer>
          <ButtonContainer>
            <Button disabled={loading} loading={loading} onClick={setSecondPageStep}>
              <ConnectText>{translate('buttons.continue')}</ConnectText>
            </Button>
            {renderButton()}
            <ButtonText onClick={handleHaveaQuestion}>{translate('buttons.haveAQuestion')}</ButtonText>
          </ButtonContainer>
          <FaqListModal
            hideModal={() => setDisplayFaqModal(false)}
            show={displayFaqModal}
            faqList={faqConnectWithPlaid?.[defaultLanguage || LANGUAGES[0]]}
            title={translate('buttons.haveAQuestion')}
            titleMargin={'auto'}
          />
        </>
      );
    } else {
      content = (
        <>
          <ContentContainer justify="space-between">
            <InnerContainer>
              <IconContainer>
                <Icon src={'cherry-logo'} width={24} height={24} />
                <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
                <Icon m={'0px 4px'} width={4} height={4} src={'dot'} />
                <Icon m={'0px 12px 0px 4px'} width={4} height={4} src={'dot'} />
                <Icon src={'bank'} />
              </IconContainer>
              <Title m={'10px 0px 8px 0px'}>{translate('multiScreenConnectBankAccount.questions.title')}</Title>
              <Subtitle m={'0px 0px 24px 0px'}>
                {translate('multiScreenConnectBankAccount.questions.subTitle')}
              </Subtitle>
              <Subtitle m={'0px 0px 24px 0px'}>
                {translate('multiScreenConnectBankAccount.questions.verifyMessage', {
                  type: featureFlags?.mxEnabled ? 'MX' : 'Plaid',
                })}
              </Subtitle>
            </InnerContainer>
          </ContentContainer>
          <InfoContainer>
            <Icon width={16} height={20} src={'lock-secure'} />
            <InfoText>
              {translate('multiScreenConnectBankAccount.questions.info', {
                name: organization?.name,
              })}
            </InfoText>
          </InfoContainer>
          <ButtonContainer>
            <Button disabled={loading} loading={loading} onClick={goToPlaid}>
              <Icon width={20} height={20} src={'lock-secure-white'} />
              <ConnectText>{translate('multiScreenConnectBankAccount.button.connectBankAccount')}</ConnectText>
            </Button>
            {application?.mickeyMouse && (
              <Button disabled={loading} secondary={true} onClick={goToOriginalApproval}>
                <ConnectText>{translate('buttons.goToOriginalApproval')}</ConnectText>
              </Button>
            )}
            {renderButton()}
            <ButtonText onClick={() => setDisplayFaqModal(true)}>{translate('buttons.haveAQuestion')}</ButtonText>
          </ButtonContainer>
          <FaqListModal
            hideModal={() => setDisplayFaqModal(false)}
            show={displayFaqModal}
            faqList={faqConnectWithPlaid?.[defaultLanguage || LANGUAGES[0]]}
            title={translate('buttons.haveAQuestion')}
            titleMargin={'auto'}
          />
        </>
      );
    }

    return content;
  };

  if (isAlle) {
    return (
      <AlleMultiScreenConnectBankAccount
        isOptions={isOptions}
        loading={loading}
        uploadPaystub={uploadPaystub}
        goToPlaid={goToPlaid}
        featureFlags={featureFlags}
        organization={organization}
        setFirstPageStep={setFirstPageStep}
        setSecondPageStep={setSecondPageStep}
        pageStep={pageStep}
        displayFaqModal={displayFaqModal}
        setDisplayFaqModal={setDisplayFaqModal}
      />
    );
  }

  return (
    <Container showBackButton={pageStep === 2} goBackCallback={setFirstPageStep}>
      {renderPageContent()}
    </Container>
  );
};

export default MultiScreenConnectBankAccount;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const ButtonText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.main.green};
  margin: 24px 0;
  cursor: pointer;
`;

const ConnectText = styled.div`
  margin-left: 10px;
`;
