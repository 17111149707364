import React from 'react';
import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';

import { theme } from 'config/theme';
import { Button, Icon, Subtitle, Title } from 'lib/components';
import { useTranslation } from 'react-i18next';

type WrapperProps = {
  children: React.ReactElement;
  show: boolean;
  withSlideAnimation?: boolean;
};

const Wrapper = ({ children, show, withSlideAnimation = true }: WrapperProps) =>
  withSlideAnimation ? (
    <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
      {children}
    </Slide>
  ) : (
    <>{children}</>
  );

interface ModalProps {
  show: boolean;
  hideModal: () => void;
}

export const LegalModal = ({ show, hideModal }: ModalProps) => {
  const { t: translate } = useTranslation();
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={hideModal}>
      <Wrapper show={show}>
        <ModalContainer>
          <ModalHeader>
            <Title m="24px 0px">{translate('personalInfo.modal.title')}</Title>
            <IconContainer>
              <Icon src={'close'} />
            </IconContainer>
          </ModalHeader>
          <Subtitle color={theme.main.black} m="0 0 24px 0">
            {translate('personalInfo.modal.subTitle')}
          </Subtitle>
          <Button secondary={true}>{translate('buttons.gotIt')}</Button>
        </ModalContainer>
      </Wrapper>
    </Backdrop>
  );
};
export default LegalModal;

const ModalContainer = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background-color: ${(props) => props.theme.main.white};
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 30px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  position: absolute;
  cursor: pointer;
  right: 24px;
  z-index: 24;

  img {
    cursor: pointer;
  }
`;
