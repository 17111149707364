import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useApolloClient, useMutation } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';

import useStore from 'lib/hooks/useStore';
import { GET_ORGANIZATION_ACCOUNT_INFORMATION } from 'lib/graphql/queries';

import { Container } from 'lib/components';
import CheckoutForm from './CheckoutForm';
import { INTENT_POINT_OF_SALE } from 'lib/graphql/mutations';

const PosPayCard = () => {
  const client = useApolloClient();
  const { setPageIndex } = useStore();
  const [intentPointOfSale] = useMutation(INTENT_POINT_OF_SALE);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    setPageIndex(0);
  }, [setPageIndex]);

  useEffect(() => {
    startPaymentIntent?.();
    getOrganizationAccountInformation?.();
  }, []);

  const startPaymentIntent = async () => {
    const { organization, sessionData } = useStore.getState();

    const { data } = await intentPointOfSale({
      variables: { input: { organizationId: organization?.id, amount: sessionData?.posRemainingAmount } },
    });

    if (data?.intentPointOfSale?.clientSecret) {
      setClientSecret(data?.intentPointOfSale?.clientSecret);
    }
  };

  const getOrganizationAccountInformation = async () => {
    const { organization } = useStore.getState();
    const { data: { fetchOrganizationAccountInformation } = {} } = await client.query({
      query: GET_ORGANIZATION_ACCOUNT_INFORMATION,
      variables: {
        input: {
          organizationId: organization?.id,
        },
      },
    });
    if (fetchOrganizationAccountInformation?.organizationAccountId) {
      setStripePromise(
        loadStripe(fetchOrganizationAccountInformation?.cherryPublicKey, {
          stripeAccount: fetchOrganizationAccountInformation?.organizationAccountId,
        }),
      );
    }
  };

  return (
    <Container showBackButton={false}>
      <Elements stripe={stripePromise}>
        <CheckoutForm clientSecret={clientSecret} />
      </Elements>
    </Container>
  );
};

export default PosPayCard;
