import React from 'react';
import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';

import { theme } from 'config/theme';
import { Icon, Title } from 'lib/components';
import { useTranslation } from 'react-i18next';

interface DidntGetCodeModalProps {
  show: boolean;
  hideModal: () => void;
  reSendCode: () => void;
  callMeWithCode: () => void;
}

export const DidntGetCodeModal = React.memo(
  ({ show, hideModal, reSendCode, callMeWithCode }: DidntGetCodeModalProps) => {
    const { t: translate } = useTranslation();
    return (
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={hideModal}>
        <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
          <HelpMeContainer>
            <HelpMeHeader>
              <IconGap />
              <Title m={'24px 0px'}>{translate('otp.didntGetACode')}</Title>
              <Icon src={'close'} onClick={hideModal} />
            </HelpMeHeader>
            <HelpMeButton onClick={reSendCode}>
              <Icon src={'message_writing'} m={'0px 10px'} />
              {translate('buttons.resendCode')}
            </HelpMeButton>
            <HelpMeButton onClick={callMeWithCode}>
              <Icon src={'phone'} m={'0px 10px'} />
              {translate('buttons.callMeWithCode')}
            </HelpMeButton>
          </HelpMeContainer>
        </Slide>
      </Backdrop>
    );
  },
);

const HelpMeContainer = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background-color: ${(props) => props.theme.main.white};
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 30px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: 250px;
    border-radius: 6px;
    padding: 0 24px 0;
  }
`;

const HelpMeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HelpMeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.main.white};
  border: 1px solid ${theme.main.grayColor};
  box-sizing: border-box;
  padding: 15px;
  margin-top: 10px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;
