import React from 'react';
import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';
import { useTranslation } from 'react-i18next';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

import { theme } from 'config/theme';
import { Icon, IconGap, ModalHeader, Title } from 'lib/components';

interface ModalProps {
  show: boolean;
  children: ReactJSXElement;
  handleCloseClick: (e) => void;
  hideModal: () => void;
  titleTranslateKey: string;
  isLarge: boolean;
}

export const SlideModal = ({
  show,
  children,
  handleCloseClick,
  hideModal,
  titleTranslateKey,
  isLarge = false,
}: ModalProps) => {
  const { t: translate } = useTranslation();

  return (
    <Backdrop
      sx={{ color: theme.main.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      onClick={handleCloseClick}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer isLarge={isLarge}>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>{translate(titleTranslateKey)}</Title>
            <Icon hover={true} src={'close'} onClick={hideModal} />
          </ModalHeader>
          {children}
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};
export default SlideModal;

const ModalContainer = styled.div<{ isLarge: boolean }>`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0 -36px 67px rgba(0, 0, 0, 0.07), 0 -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0 -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 12px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;

    @media (max-height: ${(props) => (props.isLarge ? '700px' : '530px')}) {
      top: 30px;
      overflow-y: scroll;
    }
  }
`;
