import Autocomplete from '@mui/material/Autocomplete';
import { Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface AutocompleteData {
  id: string;
  label: string;
}

const VerifyIncome = () => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();

  const [value, setValue] = useState<string>('');
  const [selectedBankValue, setSelectedBankValue] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [autocompleteData] = useState<AutocompleteData[]>([
    { id: '1', label: 'Chase' },
    { id: '2', label: 'Chase 2' },
  ]);

  useEffect(() => {
    if (searchTerm?.length > 0) {
      setTimeout(() => {
        // Api Call
      }, 250);
    } else {
      setSelectedBankValue('');
    }
  }, [searchTerm]);

  const setBankValues = (selectedBank) => {
    if (selectedBank) {
      setSelectedBankValue(selectedBank);
      setSearchTerm(selectedBank.label);
      setValue(selectedBank.label);
    }
  };

  const navigateToCantFindBank = () => {
    navigate(`find-bank-account`);
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <IconContainer>
            <Icon src={'cherry-logo'} width={24} height={24} />
            <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 4px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 12px 0px 4px'} width={4} height={4} src={'dot'} />
            <Icon src={'bank'} />
          </IconContainer>
          <Title m={'10px 0px 24px 0px'}>{translate('verifyIncome.title')}</Title>

          <Subtitle
            m={'10px 0px 24px 0px'}
            dangerouslySetInnerHTML={{
              __html: translate('verifyIncome.subTitle'),
            }}
          />

          <Autocomplete
            options={autocompleteData}
            // @ts-ignore value
            value={value}
            onChange={(event, newValue) => {
              setBankValues(newValue);
            }}
            id="disable-clearable"
            disableClearable={true}
            renderInput={(params) => {
              delete params.inputProps.className;
              return (
                <TextField
                  {...params}
                  id="bank-name"
                  variant="filled"
                  data-testid="bank"
                  label={translate('verifyIncome.form.label')}
                  name="bank"
                  value={value}
                  onChange={(e) => {
                    setSearchTerm(e?.target?.value);
                    setValue(e?.target?.value);
                  }}
                />
              );
            }}
          />
          {selectedBankValue ? (
            <SupportedContainer>
              <SupportedTitle>{translate('verifyIncome.bank.title')}</SupportedTitle>
              <SupportedSubtitle>{translate('verifyIncome.bank.subTitle')}</SupportedSubtitle>
            </SupportedContainer>
          ) : null}
        </InnerContainer>
        <InfoContainer>
          <Icon width={20} height={20} src={'info_circle_outline'} />
          <InfoText>{translate('verifyIncome.info')}</InfoText>
        </InfoContainer>
      </ContentContainer>

      <ButtonContainer>
        {!selectedBankValue && (
          <ButtonText onClick={navigateToCantFindBank}>{translate('buttons.cantFindYourBank')}</ButtonText>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default VerifyIncome;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SupportedContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const SupportedTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.main.grayColor};
`;

const SupportedSubtitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.main.grayColor};
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const ButtonText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.main.green};
  justify-content: center;
  display: flex;
  cursor: pointer;
  margin: 24px 0px 12px;
  text-decoration: underline;
`;
