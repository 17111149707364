import { useMutation } from '@apollo/client';

import { CONFIRM_LOAN } from 'lib/graphql/mutations';

export const useConfirm = () => {
  const [confirmLoan] = useMutation(CONFIRM_LOAN);

  const postConfirmLoan = async (loanId: string) => {
    try {
      const { data } = await confirmLoan({
        variables: {
          input: {
            loanId,
          },
        },
      });

      if (data.confirmLoan) {
        return data.confirmLoan;
      }
    } catch (e) {
      console.error(e);
    }
  };

  return { postConfirmLoan };
};
