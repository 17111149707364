import { useApolloClient } from '@apollo/client';

import { GET_BORROWER_KYC_OUTCOME_ADDRESS } from 'lib/graphql/queries';

export const useBorrowerKycAddress = () => {
  const client = useApolloClient();

  const getBorrowerKycAddress = async (borrowerId: string) => {
    const {
      data: { getBorrowerKYCOutcomeAddress },
    } = await client.query({
      query: GET_BORROWER_KYC_OUTCOME_ADDRESS,
      variables: {
        input: {
          borrowerId,
        },
      },
    });

    return getBorrowerKYCOutcomeAddress;
  };

  return { getBorrowerKycAddress };
};
