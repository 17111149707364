import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const DateInput = React.forwardRef<HTMLElement, CustomProps | any>(function TextMaskCustom(props, ref) {
  const onAcceptHandler = (value: string) => {
    onChange({ target: { name: props?.name, value } });
  };

  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={Date}
      max={new Date()}
      maxLength={10}
      minLength={10}
      pattern={'m/`d/`YYYY'}
      inputRef={ref}
      autofix={false}
      onAccept={onAcceptHandler}
      overwrite={true}
    />
  );
});
