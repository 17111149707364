import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { API_URL } from 'config';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';

import { PLAID_ERRORS, useIncomeVerificationLatest } from './services';
import { IframeEventTypes, LoadingEventActions, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';
import { useDidMountEffect } from 'lib/hooks/useDidMountEffect';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

const PlaidAuth = () => {
  const { navigate, navigateBack } = useNavigation();
  const { loanId } = useParams();
  const { trackSegmentEvent } = useSegment();
  const { pushEvent } = useIframeEvents();
  const { t: translate } = useTranslation();
  const loadSegmentController = useRef(true);
  const { sendLoadSegmentEvent } = useSegmentContext();
  const { setAppLoading, defaultLanguage, setSessionApiData } = useStore();

  const { getIncomeVerificationLatest, getIncomeVerificationLatestErrorStatus } = useIncomeVerificationLatest();

  useEffect(() => {
    setAppLoading(true);
    pushEvent(IframeEventTypes.PAGE, PageEventActions.PLAID_BANK_CONNECT_IFRAME);
    window?.addEventListener('message', receiveMessage, false);
    return () => {
      setAppLoading(false);
      window?.removeEventListener('message', receiveMessage);
    };
  }, []);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.incomeVerificationPlaidLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const updateErrorCountInSession = (errorStatus) => {
    const { sessionApiData } = useStore.getState() || {};
    const { plaidErrors } = sessionApiData || {};
    const updatedErrorList = [...(plaidErrors || []), errorStatus];

    setSessionApiData({
      plaidErrors: updatedErrorList,
    });
  };

  const handlePlaidError = (errorCode, applicationId, borrowerId) => {
    if (errorCode) {
      updateErrorCountInSession(errorCode);

      getIncomeVerificationLatestErrorStatus(borrowerId, applicationId);
    } else {
      getIncomeVerificationLatest(borrowerId);
    }
  };

  const receiveMessage = async ({ origin, data }) => {
    if (origin === API_URL) {
      const { application, borrower } = useStore.getState() || {};
      const iFrameEvent = JSON.parse(data);

      switch (iFrameEvent.event) {
        case 'expired':
          await getIncomeVerificationLatest(borrower?.id);
          break;
        case 'complete':
          pushEvent(IframeEventTypes.LOADING, LoadingEventActions.STARTED);
          setAppLoading(true);
          getIncomeVerificationLatest(borrower?.id);
          break;
        case 'institution-complete':
          setAppLoading(true);
          if (iFrameEvent?.data?.isInstitutionFound) {
            getIncomeVerificationLatest(borrower?.id);
          } else {
            navigate(`upload-paystub/${application?.id}`);
          }
          break;
        case 'loading':
          setAppLoading(true);
          break;
        case 'loaded':
          setAppLoading(false);
          break;
        case 'plaid-event':
          break;
        case 'plaid-error':
          const errorCode = PLAID_ERRORS[iFrameEvent?.data?.error?.error_code];

          if (errorCode === PLAID_ERRORS.NO_ACCOUNTS) {
            break;
          } else {
            setAppLoading(true);
            handlePlaidError(errorCode, application?.id, borrower?.id);
          }
          break;
        case 'close':
          setAppLoading(true);
          navigateBack();
          break;
      }
    }
  };

  return (
    <Container>
      <iframe
        id="add-plaid-auth"
        data-testid="the-frame"
        title={translate('plaidAuth.title')}
        width="100%"
        height="100%"
        frameBorder="0"
        src={`${API_URL}/v1/asset-reports/plaid-iframe/${loanId}?language=${defaultLanguage}`}
        data-hj-allow-iframe=""
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    height: calc(100vh - 90px);
  }

  iframe {
    height: 100%;
    width: 100%;
  }
`;

export default PlaidAuth;
