import React from 'react';
import styled from 'styled-components';

import useStore from 'lib/hooks/useStore';
import { Button, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { theme } from 'config/theme';

const AlleConnectWithPlaidRetry = ({ goToPlaid, navigateToUploadPaystub, loading }) => {
  const { organization } = useStore();

  return (
    <Container>
      <InnerContainer>
        <Title color={theme.main.black10} fontSize="24px" fontWeight={400} lineHeight="32px" useElegantFontType={true}>
          We’re sorry we couldn’t connect to your bank
        </Title>
        <Subtitle m="16px 0" textAlign="center" color={theme.main.black20} lineHeight="24px" fontSize="16px">
          In the next few steps, you will verify your income by connecting your bank account via Plaid, our digital
          finance partner. Connecting your bank account is the fastest way to get approved.
        </Subtitle>
      </InnerContainer>

      <Button
        m="32px 0 0"
        black={true}
        uppercase={true}
        thinText={true}
        fontWeight={400}
        fontSize={11}
        disabled={loading}
        loading={loading}
        onClick={goToPlaid}
      >
        Connect Bank Account
      </Button>
      <ButtonText onClick={navigateToUploadPaystub}>Connect Payroll/Paystubs Instead</ButtonText>
      <InnerContainer>
        <InfoContainer>
          <Icon width={16} height={20} src={'lock-secure'} />
          <InfoText>
            {`We secure your information with the same 256-bit encryption that banks use. Neither Cherry nor ${organization?.name} stores your login.`}
          </InfoText>
        </InfoContainer>
      </InnerContainer>
    </Container>
  );
};

export default AlleConnectWithPlaidRetry;

const ButtonText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.main.green};
  margin: 24px 0;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: 'Graphik', sans-serif;
`;

const StepText = styled.div`
  color: ${(props) => props.theme.main.silverGray};
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 4px;
`;
