import { useMutation } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { IDENTITY_VERIFICATION } from 'lib/graphql/mutations';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';

import { Button, Container, HaveQuestionsModal, Icon, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import AlleIdReview from './AlleIdReview';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

const IdReview = () => {
  const alert = useAlert();
  const { captureException } = useSentry();
  const { navigate } = useNavigation();
  const { applicationFlow, setReviewOutcome, organization, isAlle } = useStore();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();

  const [identityVerificationRequest, { loading }] = useMutation(IDENTITY_VERIFICATION);

  const [alleAgree, setAlleAgree] = useState(false);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.idReviewLandingLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const identityVerificationHandler = async () => {
    const { borrower } = useStore.getState() || {};
    sendActionSegmentEvent(WPQSegmentNames.idVerificationSubmitMyIdClicked, {
      organizationSlug: organization?.slug,
    });

    const { data } = await identityVerificationRequest({
      variables: { input: { borrowerId: borrower?.id } },
    });

    if (data?.identityVerifications?.id) {
      setReviewOutcome(data?.identityVerifications);
      navigate(`id-review/self`);
    } else {
      alert.info(translate('idReview.info.message'));
      captureException('Identity Verification Provider Error', { borrower, applicationFlow });
    }
  };

  const handleAllePermission = () => {
    setAlleAgree(true);
  };

  if (isAlle && !alleAgree) {
    return <AlleIdReview handleClick={handleAllePermission} />;
  }

  const buttonText = translate('idReview.button.text');
  const extraStepsText = translate('idReview.info.step');
  const infoText = translate('idReview.info.text');

  return (
    <>
      {!isAlle ? (
        <Container showBackButton={false}>
          <ContentContainer>
            <Icon src={'fingerprint'} />
            <Title m={'10px 0px 24px 0px'} data-testid="id-review-title">
              {extraStepsText}
            </Title>
            <UserInfoContainer>
              <UserInfoItem>
                <UserInfoText>{infoText}</UserInfoText>
              </UserInfoItem>
            </UserInfoContainer>
          </ContentContainer>
          <ButtonContainer>
            <Button
              disabled={loading}
              loading={loading}
              onClick={identityVerificationHandler}
              icon={loading ? 'lock-secure-gray' : 'lock-active'}
            >
              {buttonText}
            </Button>
          </ButtonContainer>
        </Container>
      ) : (
        <>
          {' '}
          <ContentContainer>
            <ContentContainer>
              <Title
                m={'10px 0px 24px 0px'}
                data-testid="id-review-title"
                useElegantFontType={isAlle}
                fontSize={'24px'}
                textAlign={'center'}
                fontWeight={400}
              >
                {extraStepsText}
              </Title>
              <UserInfoContainer>
                <UserInfoItem>
                  <AlleUserInfoText>{infoText}</AlleUserInfoText>
                </UserInfoItem>
              </UserInfoContainer>
            </ContentContainer>

            <Button
              disabled={loading}
              loading={loading}
              icon={''}
              black={true}
              fontFamily={'Graphik'}
              fontWeight={'400'}
              fontSize={'11px'}
              uppercase={true}
              onClick={identityVerificationHandler}
            >
              {buttonText}
            </Button>

            <HaveQuestionsModal />
          </ContentContainer>
        </>
      )}
    </>
  );
};

export default IdReview;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserInfoItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const UserInfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.main.grayColor};
`;

const AlleUserInfoText = styled.span`
  color: ${(props) => props.theme.main.black20};
  font-size: 16px;
  font-family: Graphik, sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
  text-align: center;
`;
