import dayjs from 'dayjs';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { theme } from 'config/theme';
import { FormatAmount } from 'lib/utils';
import { DATE_FORMAT } from 'lib/constants';
import { IPlan } from 'pages/Plans/types';

interface PlanReviewCardProps {
  data: IPlan;
}

export const PlanReviewCard = ({ data }: PlanReviewCardProps) => {
  const { t: translate } = useTranslation();

  return (
    <Container data-testid="plan-card">
      <Shadow>
        <Header>
          {translate('plans.planCard.reviewCard.header', {
            term: data?.product?.term,
          })}
        </Header>
        <ContentContainer>
          <ContentItemContainer>
            <ContentTitle>{translate('plans.planCard.reviewCard.now')}</ContentTitle>
            <ContentText>{FormatAmount(data?.downPaymentAmount)}</ContentText>
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentBorder />
          </ContentItemContainer>
          <ContentItemContainer marginBottom="0px">
            <ContentTitle>{translate('plans.planCard.content1')}</ContentTitle>

            <ContentText>
              {translate('plans.planCard.installmentAmount', {
                amount: FormatAmount(data?.installmentAmount),
              })}
            </ContentText>
            <ContentSubText>
              <span
                dangerouslySetInnerHTML={{
                  __html: translate('plans.planCard.subText1', {
                    term: data?.product?.term,
                    date: dayjs(data?.paymentDates?.[0]).format(DATE_FORMAT),
                  }),
                }}
              />
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: translate('plans.planCard.footer2', {
                    financeCharge: FormatAmount(Number(data?.financeCharge)),
                  }),
                }}
              />
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: translate('plans.planCard.subText2', {
                    apr: data?.promo ? data?.product?.promoApr : data?.product?.apr,
                  }),
                }}
              />
            </ContentSubText>
          </ContentItemContainer>
        </ContentContainer>
      </Shadow>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  min-width: 200px;
  padding-bottom: 24px;
`;

const Shadow = styled.div`
  box-shadow: 6px 24px 18px -16px rgb(121 131 136 / 75%);
  border-radius: 0 0 8px 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  text-transform: uppercase;
  background-color: ${theme.main.midnightBlue};
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  padding: 8px 24px 7px 24px;
  color: ${theme.main.white};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const ContentContainer = styled.div`
  padding: 16px 24px;
  text-align: 'left';
  border: 1px solid ${theme.main.midnightBlue};
  border-radius: 0 0 8px 8px;
`;

const ContentItemContainer = styled.div<{ marginBottom?: string }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props?.marginBottom || '12px'};
`;

const ContentTitle = styled.span<{ useElegantTheme?: boolean; isActive?: boolean }>`
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
  text-align: left;
  color: ${theme.main.green};
  text-transform: uppercase;
`;

const ContentText = styled.span<{ useElegantTheme?: boolean }>`
  font-size: 24px;
  line-height: 33px;
  color: ${(props) => props.theme.main.textColor};
  font-family: ${(props) => props.useElegantTheme && 'Petersburg'};
  font-weight: ${(props) => (props.useElegantTheme ? 400 : 700)};
`;

const ContentSubText = styled.span<{ useElegantTheme?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: ${(props) => props.theme.main.textColor};
  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
  font-style: ${(props) => (props.useElegantTheme ? 'none' : 'italic')};
`;

const ContentBorder = styled.div`
  border: 1px dashed ${theme.main.grey20};
`;
