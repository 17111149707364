import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Button, HaveQuestionsModal, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';

import { theme } from 'config/theme';
import { IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';

interface Props {
  isOptions: boolean;
  goToPlaid: () => void;
  loading: boolean;
  uploadPaystub: () => void;
  featureFlags: {
    mxEnabled: boolean;
  };
  organization: {
    name: string;
  };
}

const AlleSingleConnectBankAccount = ({
  isOptions,
  goToPlaid,
  loading,
  uploadPaystub,
  featureFlags,
  organization,
}: Props) => {
  const { pushEvent } = useIframeEvents();

  useEffect(() => {
    pushEvent(IframeEventTypes.PAGE, PageEventActions.CONNECT_BANK_ACCOUNT);
  }, []);

  const renderButton = () => {
    if (isOptions) {
      return <ButtonText onClick={uploadPaystub}>Verify with Paystubs/Payroll</ButtonText>;
    }

    return null;
  };

  return (
    <Container>
      <Title m={'10px 0px 8px 0px'} textAlign="center" fontFamily="Petersburg">
        Verify your income
      </Title>
      <Subtitle
        fontSize="16px"
        m={'0px 0px 24px 0px'}
        textAlign="center"
        fontFamily="Graphik"
        color={theme.main.black20}
      >
        Your income information is required to complete your application.
      </Subtitle>
      <MessageTitle>What happens next?</MessageTitle>
      <Subtitle fontSize="16px" m={'0px 0px 24px 0px'} textAlign="center" color={theme.main.black20}>
        You will verify your income via {featureFlags?.mxEnabled ? 'MX' : 'Plaid'}, our digital finance partner.
      </Subtitle>
      <Subtitle fontSize="16px" m={'0px 0px 24px 0px'} textAlign="center" color={theme.main.black20}>
        Income information allows Cherry to look beyond credit scores to offer flexible payment plans to patients.
      </Subtitle>
      <Button
        m="32px 0 0"
        black={true}
        uppercase={true}
        thinText={true}
        fontWeight={400}
        fontSize={11}
        disabled={loading}
        loading={loading}
        onClick={goToPlaid}
      >
        <ConnectText>Connect Bank Account</ConnectText>
      </Button>
      {renderButton()}
      <InfoContainer>
        <Icon width={16} height={20} src={'info_circle_outline'} />
        <InfoText>
          {` Cherry never receives your credentials and ${organization?.name} never receives the details of your income `}
        </InfoText>
      </InfoContainer>
      <HaveQuestionsModal />
    </Container>
  );
};

export default AlleSingleConnectBankAccount;

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: Graphik;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  text-align: left;
`;

const ButtonText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.main.alleBlack};
  margin: 24px 0;
  cursor: pointer;
`;

export const MessageTitle = styled.span`
  font-family: 'Graphik';
  color: ${(props) => props.theme.main.alleBlack};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  font-style: normal;
  margin: 24px 0 8px 0;
`;

const ConnectText = styled.div`
  margin-left: 10px;
`;
