import React from 'react';
import { useTranslation } from 'react-i18next';

import { Redirect } from 'lib/utils';
import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { TRACK_VERSION, WEBPREQUAL, getSurveyVersion, isSurveyEnabledIndustry } from 'lib/constants';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { useNavigation } from 'lib/hooks/useNavigation';

const SurveyOffer = () => {
  const { navigate } = useNavigation();
  const { organization, application } = useStore();
  const { trackSegmentEvent } = useSegment();
  const { t: translate } = useTranslation();
  const version = getSurveyVersion(organization?.industry);

  const trackSurveyEvent = (action: string, version?: string) => {
    trackSegmentEvent(`${action} from user`, {
      application: WEBPREQUAL,
      version: version ?? TRACK_VERSION,
      page: 'AFTER REVIEW PLAN',
    });
  };

  const redirectAndNavigate = (url: string) => {
    Redirect(url);
    setTimeout(() => {
      navigate('success');
    }, 2000);
  };

  const confirmOffer = () => {
    if (isSurveyEnabledIndustry(organization)) {
      trackSurveyEvent('Survey accepted', version);
      redirectAndNavigate(
        `https://withcherry.typeform.com/to/MMyn9gtH#application_id=${application?.id}&screen_source=approval&version=${version}`,
      );
    }
  };

  const rejectOffer = () => {
    trackSurveyEvent('Survey rejected', version);
    navigate('success');
  };

  const surveyPrice = '$25';

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'gift-referral-alt'} />
        <Title m={'10px 0px 24px 0px'}>
          {translate('surveyOffer.title', {
            price: surveyPrice,
          })}
        </Title>
        <Subtitle m={'10px 0px 24px 0px'}>{translate('surveyOffer.subTitle')}</Subtitle>
        <Subtitle m={'10px 0px 24px 0px'}>
          {translate('surveyOffer.info', {
            price: surveyPrice,
          })}
        </Subtitle>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={confirmOffer}>{translate('buttons.completeSurvey')}</Button>
        <Button onClick={rejectOffer} secondary={true}>
          {translate('buttons.noThanksNotInterest')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default SurveyOffer;
