import React from 'react';
import styled from 'styled-components';

interface Props extends React.HTMLAttributes<HTMLImageElement> {
  width?: number;
  height?: number;
  src: string;
  onClick?: () => void;
  m?: string;
  p?: string;
  hover?: boolean;
}

export const Icon = React.memo(
  ({ width = 30, height = 30, src, onClick, m = '', p = '', hover = false, ...rest }: Props) => {
    let imageSrc;

    try {
      imageSrc = require(`assets/images/${src}.svg`);
    } catch (err) {}

    return (
      <StyledIcon src={imageSrc} width={width} height={height} onClick={onClick} m={m} p={p} hover={hover} {...rest} />
    );
  },
);

const StyledIcon = styled.img<{ m: string; p: string; width: number; height: number; hover: boolean }>`
  cursor: ${(props) => (props.hover ? 'pointer' : 'default')};
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  margin: ${(props) => props.m};
  padding: ${(props) => props.p};
`;
