import { gql } from '@apollo/client/core';

export const FETCH_BANNER = gql`
  query fetchBanner {
    fetchBanner {
      showForPatient
      patientText
    }
  }
`;

export const GET_ORGANIZATION_REGULATIONS = gql`
  query GetRegulationsConfig($input: GetRegulationsConfigInput) {
    getRegulationsConfig(input: $input) {
      servicingEntity
      riscState
      originationType
      originatingEntity
      organizationStateCode
      borrowerAgreementES
      borrowerAgreementEN
    }
  }
`;
