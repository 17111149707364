import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import React from 'react';
import styled from 'styled-components';

import { theme as configTheme, theme } from 'config/theme';
import { Button, Icon, IconGap, ModalHeader, Title } from 'lib/components';
import useStore from 'lib/hooks/useStore';
import { Trans, useTranslation } from 'react-i18next';
import { FormatPrice } from 'lib/utils';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';

interface WhatDoesMeanModal {
  show: boolean;
  hideModal: () => void;
}

const WhatDoesMeanModal = ({ show, hideModal }: WhatDoesMeanModal) => {
  const { t: translate } = useTranslation();
  const { application, organization } = useStore.getState() || {};
  const { sendActionSegmentEvent } = useSegmentContext();

  const closeModalOnClickAway = (e) => {
    if (e?.target?.ariaHidden) {
      hideModal();
      sendActionSegmentEvent(WPQSegmentNames.whatDoestMeanGotItClicked);
    }
  };

  const gotITClicked = () => {
    sendActionSegmentEvent(WPQSegmentNames.whatDoestMeanGotItClicked);
    hideModal();
  };

  return (
    <Backdrop
      sx={{ color: configTheme.main.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      onClick={closeModalOnClickAway}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer isLarge={false}>
          <ModalHeader>
            <IconGap />
            <Title m={'24px 0px'}>{translate('whatdoesmean.title')}</Title>
            <Icon hover={true} src={'close'} onClick={hideModal} />
          </ModalHeader>
          <ItemContainer>
            <ItemHeader>
              <Icon hover={true} src={'money-dollar'} onClick={hideModal} width={20} height={20} />
              <span>{translate('whatdoesmean.approval.title')}</span>
            </ItemHeader>
            <p>
              <Trans
                i18nKey={'whatdoesmean.approval.description'}
                components={{
                  bold: <strong />,
                }}
                values={{ amount: `$${FormatPrice(application?.balanceAvailable)}`, practice: organization?.name }}
              ></Trans>
            </p>
          </ItemContainer>
          <ItemContainer>
            <ItemHeader>
              <Icon hover={true} src={'payment-active'} onClick={hideModal} width={20} height={20} />
              <span>{translate('whatdoesmean.checkout.title')}</span>
            </ItemHeader>
            <p>
              <Trans i18nKey="whatdoesmean.checkout.description" values={{ name: organization?.name }}></Trans>
            </p>
          </ItemContainer>
          <Button onClick={gotITClicked}>{translate('buttons.gotIt')}</Button>
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

export default WhatDoesMeanModal;

const ModalContainer = styled.div<{ isLarge: boolean }>`
  width: 90%;
  max-height: 80vh;
  position: absolute;
  overflow: auto;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0 -36px 67px rgba(0, 0, 0, 0.07), 0 -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0 -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0 -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0 -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 40px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;

    @media (max-height: ${(props) => (props.isLarge ? '700px' : '530px')}) {
      top: 30px;
      overflow-y: scroll;
    }
  }
`;

const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  span {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 18px 16px 18px;
  border-radius: 24px;
  margin-bottom: 24px;
  opacity: 0px;
  background-color: ${theme.main.greenLight};
  color: ${theme.main.midnightBlue};
  box-shadow: 0 1.23656px 2.30138px 0 rgba(0, 0, 0, 0.02), 0 3.12736px 5.82036px 0 rgba(0, 0, 0, 0.03),
    0 6.37951px 11.87298px 0 rgba(0, 0, 0, 0.04), 0 13.14059px 24.4561px 0 rgba(0, 0, 0, 0.05),
    0 36px 67px 0 rgba(0, 0, 0, 0.07);
`;
