import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAlert } from 'react-alert';
import { useMutation } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import { Controller, useForm } from 'react-hook-form';

import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { US_STATES } from 'lib/constants';
import { useSentry } from 'lib/hooks/useSentry';
import { usePlans } from 'pages/Plans/services';
import { useNavigation } from 'lib/hooks/useNavigation';
import { ButtonContainer } from 'lib/components/Common';
import { setAddressValues } from 'lib/utils/FormHelpers';
import { Box, Button, Icon, TextField, Title, Wrapper } from 'lib/components';
import useAddressAutocomplete from 'lib/hooks/useAddressAutocomplete';
import { UPDATE_PAYMENT_METHOD_ADDRESS } from 'lib/graphql/mutations';
import { ISelectedAddress, USStates } from 'pages/PersonalAddressVerify/types';
import { RowContainer } from 'pages/PersonalAddressVerify/PersonalAddressVerify';
import { numericPattern, poBoxRegex, prohibitedStreetRegex, trapSpacesForRequiredFields } from 'lib/utils';

export const BillingAddressModal = ({ selectedPaymentMethod, show, hideModal, title, center = false }) => {
  const alert = useAlert();
  const { isAlle } = useStore();
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { t: translate } = useTranslation();
  const { getPaymentMethods } = usePlans();

  const { setSearchTerm, autocompleteData, loading: autoCompleteLoading } = useAddressAutocomplete();
  const [loading, setLoading] = useState(false);

  const [updatePaymentMethodAddress] = useMutation(UPDATE_PAYMENT_METHOD_ADDRESS);

  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (selectedPaymentMethod) {
      getInitialRequest?.();
    }
  }, []);

  const getInitialRequest = async () => {
    setAddressValues(selectedPaymentMethod, setValue);
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const handleAddressChange = (selectedAddress: ISelectedAddress) => {
    setAddressValues(selectedAddress, setValue);
  };

  const onSubmit = async (formData) => {
    try {
      setLoading(true);
      const { borrower } = useStore.getState();

      if (poBoxRegex.test(formData?.street)) {
        alert.info(translate('personalAddressVerify.error.pobox'));
      } else if (prohibitedStreetRegex.test(formData?.street)) {
        alert.info(translate('personalAddressVerify.error.street'));
      } else if (borrower?.id) {
        const { data } = await updatePaymentMethodAddress({
          variables: {
            input: {
              borrowerId: borrower?.id,
              paymentMethodId: selectedPaymentMethod?.id,
              city: formData?.city,
              zip: formData?.zip,
              state: formData?.state,
              addressLine1: formData?.street,
              addressLine2: formData?.addressLine2,
            },
          },
        });

        if (data?.updatePaymentMethodAddress?.id) {
          alert.success(translate('billingAddress.updateSuccess'));
          await getPaymentMethods();
          hideModal();
        } else {
          setLoading(false);
          alert.info(translate('billingAddress.error.default'));
        }
      } else {
        setLoading(false);
        captureException('Error', { message: 'Borrower ID not found', page: 'Edit Billing Address Modal' });

        alert.info(translate('billingAddress.error.default'));
        navigate(``);
      }
    } catch (err) {
      captureException(err, { message: 'Edit Billing Address Catch Error', session: formData });
      alert.info(translate('billingAddress.error.default'));
      setLoading(false);
    }
  };

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show}>
      <Wrapper show={show} withSlideAnimation={!center}>
        <ModalContainer>
          <ModalHeader>
            <IconGap />
            <Title
              m={'24px 0px'}
              color={isAlle && theme.main.black10}
              fontSize={isAlle && '24px'}
              fontWeight={isAlle && 400}
              lineHeight={isAlle && '32px'}
              useElegantFontType={isAlle}
            >
              {translate(title)}
            </Title>
            {!isAlle ? <Icon hover={true} src={'close'} onClick={hideModal} /> : null}
          </ModalHeader>

          <Box>
            <StyledTitle fontSize={'16px'}>{translate('billingAddress.title')}</StyledTitle>
            <Controller
              name="street"
              control={control}
              defaultValue=""
              rules={{ required: true, validate: trapSpacesForRequiredFields }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <Autocomplete
                  options={autocompleteData}
                  value={value}
                  onChange={(event, newValue: ISelectedAddress) => {
                    handleAddressChange(newValue);
                  }}
                  id="disable-clearable"
                  disableClearable={true}
                  renderInput={(params) => {
                    delete params.inputProps.className;
                    return (
                      <TextField
                        {...params}
                        id="street-address"
                        variant="filled"
                        data-testid="street"
                        label={translate('personalAddressVerify.form.label.street')}
                        name="street"
                        onKeyDown={checkKeyDown}
                        value={value}
                        onChange={(e) => {
                          setSearchTerm(e?.target?.value);
                          onChange(e?.target?.value);
                        }}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error?.message : null}
                      />
                    );
                  }}
                />
              )}
            />
            <Controller
              name="addressLine2"
              control={control}
              defaultValue=""
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="apt-addressLine2"
                  variant="filled"
                  data-testid="addressLine2"
                  data-neuro-label="address"
                  label={translate('personalAddressVerify.form.label.unit')}
                  name="addressLine2"
                  autoComplete="new-password"
                  value={value}
                  onKeyDown={checkKeyDown}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />
            <RowContainer>
              <Controller
                name="city"
                control={control}
                defaultValue=""
                rules={{ required: true, validate: trapSpacesForRequiredFields }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    id="city"
                    variant="filled"
                    data-testid="city"
                    data-neuro-label="city"
                    data-name="city"
                    label={translate('personalAddressVerify.form.label.city')}
                    value={value}
                    onKeyDown={checkKeyDown}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error ? error?.message : null}
                  />
                )}
              />
              <Controller
                name="state"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    id="state"
                    variant="filled"
                    data-testid="state"
                    data-name="state"
                    label={translate('personalAddressVerify.form.label.state')}
                    select={true}
                    value={value}
                    onKeyDown={checkKeyDown}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error ? error?.message : null}
                  >
                    {US_STATES.map((option: USStates) => (
                      <MenuItem data-testid="stateItem" key={option?.value} value={option?.value}>
                        {option?.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </RowContainer>
            <Controller
              name="zip"
              control={control}
              defaultValue=""
              rules={{ required: true, maxLength: 5, minLength: 5 }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="zip-code"
                  variant="filled"
                  data-testid="zip"
                  data-neuro-label="zip"
                  data-name="zip"
                  type="tel"
                  inputProps={{
                    maxLength: 5,
                  }}
                  label={translate('personalAddressVerify.form.label.zip')}
                  value={value}
                  onKeyPress={(event) => {
                    if (!numericPattern.test(event?.key)) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={checkKeyDown}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />

            <FormButtonContainer>
              <Button
                disabled={!isValid || loading || autoCompleteLoading}
                loading={loading || autoCompleteLoading}
                onClick={handleSubmit(onSubmit)}
              >
                {translate('buttons.saveBillingAddress')}
              </Button>
              <Button onClick={hideModal} secondary={true}>
                {translate('buttons.cancel')}
              </Button>
            </FormButtonContainer>
          </Box>
        </ModalContainer>
      </Wrapper>
    </Backdrop>
  );
};

const ModalContainer = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background-color: ${(props) => props.theme.main.white};
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  color: #0e202f;
  padding: 0 24px 30px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const FormButtonContainer = styled(ButtonContainer)`
  margin: 24px 0 0 0;
  padding: 0;
`;

const StyledTitle = styled(Title)`
  width: 100%;
  display: block;
  margin-bottom: 8px;
  padding-top: 24px;
  border-top: 1px solid #cfd2d5;
`;
