import { useState } from 'react';
import axios from 'axios';
import { useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import {
  START_OTP_FLOW,
  START_SNA_OTP_FLOW,
  SUBMIT_OTP,
  UPDATE_BORROWER,
  VERIFY_SNA_OTP_FLOW,
} from 'lib/graphql/mutations';
import useStore from './useStore';
import { useSegment } from './useSegment';
import { useNavigation } from './useNavigation';
import { useInitializeAuth } from 'pages/AuthChecker/services/useInitializeAuth';
import { useSentry } from './useSentry';
import { AnalyticEventNames, useAnalytics } from './useAnalytics';
import Rox from 'rox-browser';
import { useFlags } from './FeatureManagement/FlagsContext';
import { useServices } from 'pages/AuthChecker/services';
import { LoanStatuses } from 'pages/AuthChecker/type';
import { loanStatusService } from 'pages/AuthChecker/utilityFunctions';
import { useSSEContext } from './SSE/useSSEContext';
import { useSegmentContext } from './Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';

type UseOtpProps = {
  phone: string;
};

type StartSNAFlowResponse = {
  success: boolean;
  status: string;
  message: string;
  error: string;
  snaUrl: string;
};

type OtpResponse = {
  success: boolean;
  status: string;
  message: string;
  error: string;
  authorization: Authorization;
  borrower: Borrower;
};

type Authorization = {
  token: string;
  refreshToken: string;
};

type Borrower = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dob: string;
  ssn: string;
  language: string;
};

export const useOtp = () => {
  const [startSNAOtpFlow] = useMutation(START_SNA_OTP_FLOW);
  const [startOtpFlow] = useMutation(START_OTP_FLOW);
  const [verifySNAOtpFlow] = useMutation(VERIFY_SNA_OTP_FLOW);
  const { t: translate } = useTranslation();
  const alert = useAlert();
  const { navigate } = useNavigation();
  const { id } = useParams();
  const { initializeAuth } = useInitializeAuth();
  const { captureMessage, setTag, captureException } = useSentry();
  const { trackIdentify, trackBorrowerCreatedEvent, trackAlias } = useSegment();
  const { flags } = useFlags();
  const { trackEvent, applicationStatusUpdated } = useAnalytics();
  const { startCheckApplicationStatus } = useSSEContext();
  const { sendLoadSegmentEvent } = useSegmentContext();
  const { createApplication, getLoan, getApplication, getBorrower } = useServices();
  const [submitOtp] = useMutation(SUBMIT_OTP);
  const [updateBorrower] = useMutation(UPDATE_BORROWER);

  const {
    borrower,
    organization,
    setSessionData,
    sessionData,
    setBorrower,
    setAuthData,
    defaultLanguage,
    flowType,
    slug,
    setSessionApiData,
    loan,
    setAppLoading,
    sessionApiData,
  } = useStore.getState() || {};
  const [snaLoading, setSnaLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [snaVerified, setSnaVerified] = useState(false);
  let isAlreadyFetching = false;

  const startSNAFlow = async (phone: string): Promise<StartSNAFlowResponse> => {
    sendLoadSegmentEvent(WPQSegmentNames.twilioSNAOutcome, {
      type: 'SNA',
      isMobile,
      connectionType: identifyConnectionType(),
      phone,
    });

    const snaResponse = await startSNAOtpFlow({
      variables: {
        input: {
          phone: phone,
        },
      },
      context: {
        headers: {
          extra: 'borrowerInit',
        },
      },
    });
    const startSNAResult: StartSNAFlowResponse = snaResponse?.data?.startSNAOtpFlow;
    return startSNAResult;
  };

  const verifySNAFlow = async (phone: string): Promise<OtpResponse> => {
    const verifyResponse = await verifySNAOtpFlow({
      variables: {
        input: {
          phone: phone,
        },
      },
      context: {
        headers: {
          extra: 'borrowerInit',
        },
      },
    });
    const verifySNAResult: OtpResponse = verifyResponse?.data?.verifySNAOtpFlow;
    return verifySNAResult;
  };

  const startRegularOtp = async (phone: string) => {
    sendLoadSegmentEvent(WPQSegmentNames.twilioSNAOutcome, {
      type: 'StandartOTP',
      isMobile,
      connectionType: identifyConnectionType(),
      phone,
    });
    const { data } = await startOtpFlow({
      variables: {
        input: {
          phone: phone,
        },
      },
      context: {
        headers: {
          extra: 'borrowerInit',
        },
      },
    });
    if (data?.startOtpFlow?.success) {
      alert.success(translate('phone.verificationCodeSent'));
      trackIdentify({ phone: phone });

      if (borrower?.id) {
        trackBorrowerCreatedEvent(organization, borrower);
      }

      setSessionData({ phone: phone });
      navigate(`verify`);
    } else {
      if (data?.startOtpFlow?.message) {
        const message =
          data?.startOtpFlow?.message === 'borrower.phone_voip.unprocessable'
            ? translate('phone.voipNumbers')
            : data?.startOtpFlow?.message;
        alert.info(message);
        setSnaLoading(false);
      } else {
        if (!isAlreadyFetching) {
          await initializeAuth(id);
          isAlreadyFetching = true;
          startRegularOtp(phone);
        } else {
          captureMessage('Personal Phone Number 2. Attempt');
          window.location.href = `/${id}`;
        }
      }
    }
  };

  const verifyRegularOtp = async (otp: string, phone: string) => {
    setOtpLoading(true);
    const { data } = await submitOtp({
      variables: { input: { code: otp, phone: phone } },
      context: {
        headers: {
          extra: 'borrowerInit',
        },
      },
    });
    const verifyRegularOtp: OtpResponse = data?.submitOtp;
    setBorrowerandToken(verifyRegularOtp);
  };

  const setBorrowerandToken = async (otpResponse: OtpResponse) => {
    if (otpResponse.success) {
      const { authorization, borrower } = otpResponse;
      Rox.setCustomNumberProperty('borrower_id', Number(borrower?.id));
      Rox.setCustomStringProperty('rox.distinct_id', borrower?.id);
      Rox.setCustomNumberProperty('organization_id', Number(organization?.id));
      Rox.setCustomStringProperty('organization_id_str', organization?.id?.toString());
      setTag('phone', sessionData?.phone);
      setTag('organization', organization?.id);
      setTag('borrower', borrower?.id);
      setBorrower(borrower);
      setAuthData({
        slug: organization?.slug,
        loanToken: authorization?.token,
        token: '',
        refreshToken: authorization?.refreshToken,
      });
      if (borrower?.id && defaultLanguage) {
        const response = await updateBorrower({
          variables: {
            input: {
              id: borrower?.id,
              language: defaultLanguage?.toUpperCase(),
              source: 'GREET',
            },
          },
        });
        setBorrower(response?.data?.updateBorrower?.data);
      }
      trackAlias(borrower?.id);
      trackIdentify({ ...sessionData, ...borrower });
      if (flowType === 'CheckoutWithEmailFlow' || flowType === 'CheckoutOnDeviceFlow') {
        await runCheckoutWithEmailFlow(borrower);
        return;
      }
      const newApprovalScreen = flags.SCREEN.newApproval.isEnabled() || false;
      const newApprovalCard = flags.SCREEN.newApprovalCard.isEnabled() || false;
      setSessionApiData({
        featureFlags: {
          ...sessionApiData?.featureFlags,
          newApprovalScreen,
          newApprovalCard,
        },
      });
      await createApplication();
    } else {
      setOtpLoading(false);
      trackEvent(AnalyticEventNames.CV_WRONG_CODE);
      alert.info(translate('otp.codeDoesNotMatch'));
    }
  };

  const runCheckoutWithEmailFlow = async (borrower) => {
    const loanId = loan?.id ?? useStore.getState()?.loan?.id;

    const loanData = await getLoan(loanId);
    await getBorrower(borrower?.id);
    const applicationResponse = await getApplication(loanData?.applicationId);

    if (loanData?.status === LoanStatuses.AWAITING) {
      navigate(`/${slug}`);
      setAppLoading(false);
    } else if (loanData?.status === LoanStatuses.CANCELLED) {
      navigate(`/${slug}/expired`);
    } else {
      if (loanData?.selfCheckout) {
        applicationStatusUpdated(applicationResponse);
        loanStatusService(loanData, slug);
        setAppLoading(false);
      } else {
        startCheckApplicationStatus({});
        setAppLoading(false);
      }
    }
    navigate('choose-plan');
  };

  const startOTPFlow = async ({ phone }: UseOtpProps) => {
    try {
      setSnaLoading(true);
      const isSNAOTPEnabled = flags.FEATURES.sna.isEnabled();
      if (isSNAOTPEnabled && isMobile) {
        const startSNAResult = await startSNAFlow(phone);
        if (startSNAResult?.snaUrl) {
          await axios.post(startSNAResult?.snaUrl);
          const verifySNAResult = await verifySNAFlow(phone);
          if (verifySNAResult.authorization?.token) {
            setSnaVerified(true);
            sendLoadSegmentEvent(WPQSegmentNames.twilioSNAVerify, {
              isMobile,
              connectionType: identifyConnectionType(),
              phone,
            });
            setBorrowerandToken(verifySNAResult);
          } else {
            startRegularOtp(phone);
          }
        } else {
          if (startSNAResult?.message === 'borrower.phone_voip.unprocessable') {
            alert.info(translate('phone.voipNumbers'));
            setSnaLoading(false);
          } else {
            startRegularOtp(phone);
          }
        }
      } else {
        startRegularOtp(phone);
      }
    } catch (error) {
      captureException('Use OTP Hook Error', {
        level: 'error',
        extra: {
          errorMessage: error,
          page: 'Phone Number',
          phone: phone,
        },
      });
    }
  };

  const identifyConnectionType = () => {
    switch (navigator?.connection?.effectiveType) {
      case '2g':
      case '3g':
      case '4g':
      case '5g':
      case 'cellular':
        return 'cellular';
      default:
        return navigator?.connection?.effectiveType;
    }
  };

  return { startOTPFlow, verifyRegularOtp, snaLoading, otpLoading, setOtpLoading, snaVerified };
};
