import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { PickersDay } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker, StaticDatePickerProps } from '@mui/x-date-pickers/StaticDatePicker';

interface CalendarProps
  extends Omit<
    StaticDatePickerProps<Date, Date>,
    'renderDay' | 'componentProps' | 'showToolbar' | 'renderInput' | 'onChange'
  > {
  hideHeader?: boolean;
  preselectedDates?: Date[];
  isPreselectedDatesLocked?: boolean;
  onChangeDate: (selectedDate: Date) => void;
}

export const Calendar = ({
  hideHeader,
  preselectedDates,
  isPreselectedDatesLocked,
  onChangeDate,
  ...rest
}: CalendarProps) => {
  const findDate = (dates: Date[], date: Date) => {
    const targetDate = new Date(date);
    targetDate.setHours(0, 0, 0, 0);

    return dates.find((item) => {
      item.setHours(0, 0, 0, 0);
      return item.getTime() === targetDate.getTime();
    });
  };

  const renderPickerDay = (date, _, pickersDayProps) => {
    if (!preselectedDates) {
      return <PickersDay {...pickersDayProps} />;
    }

    const dayClasses: string[] = [];
    const isPreselectedDate = findDate(preselectedDates, date);

    if (isPreselectedDate) {
      dayClasses.push('preselected-date');

      if (isPreselectedDatesLocked) {
        dayClasses.push('days-locked');
      }
    }

    return <PickersDay {...pickersDayProps} className={dayClasses} />;
  };

  const handleDateChange = (newValue) => {
    if (!isPreselectedDatesLocked) {
      onChangeDate(newValue);
    }
  };

  return (
    <CalendarWrapper hideHeader={hideHeader} hideSelectedDay={!!(preselectedDates?.length ?? -1 > 0)}>
      <CalendarHeader></CalendarHeader>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          renderInput={(params) => <TextField {...params} />}
          showToolbar={false}
          componentsProps={{ actionBar: { actions: [] } }}
          renderDay={renderPickerDay}
          onChange={handleDateChange}
          {...rest}
        />
      </LocalizationProvider>
    </CalendarWrapper>
  );
};

const CalendarWrapper = styled.div<{ hideHeader?: boolean; hideSelectedDay?: boolean }>`
  border-radius: 8px;
  background: ${(props) => props.theme.main.white};
  width: fit-content;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.02)) drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.03))
    drop-shadow(0px 6px 11px rgba(0, 0, 0, 0.04)) drop-shadow(0px 13px 24px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 36px 67px rgba(0, 0, 0, 0.07));

  .MuiPickerStaticWrapper-root {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
  }

  .MuiPickersCalendarHeader-root,
  .MuiDayPicker-header {
    display: ${({ hideHeader }) => (hideHeader ? 'none' : 'flex')};
  }

  .MuiDayPicker-header {
    span {
      width: 39px;
    }
  }

  .MuiPickersDay-root {
    opacity: 1;
    border-radius: 0;
    width: 41.5px;
    height: 42.5px;
    border: 0.5px solid #dfdfdf;
  }

  .MuiButtonBase-root {
    width: 43px;
    height: 44px;
  }

  .Mui-selected {
    background-color: ${(props) => (props.hideSelectedDay ? 'transparent' : props.theme.main.green)} !important;
    color: ${(props) => (props.hideSelectedDay ? 'rgba(0, 0, 0, 0.87)' : props.theme.main.white)} !important;
  }

  .MuiDayPicker-weekContainer {
    .MuiPickersDay-root:first-child {
      border-left: 1px solid #dfdfdf;
    }

    .MuiPickersDay-root:last-child {
      border-right: 1px solid #dfdfdf;
    }
  }

  .MuiDayPicker-weekContainer:first-of-type {
    .MuiPickersDay-root {
      border-top: 1px solid #dfdfdf;
    }
  }

  .MuiDayPicker-weekContainer:last-of-type {
    .MuiPickersDay-root {
      border-bottom: 1px solid #dfdfdf;
    }
  }

  .MuiPickersDay-root,
  .MuiDayPicker-weekContainer,
  .MuiPickersDay-dayWithMargin {
    margin: 0;
    padding: 0;
  }

  .MuiPickersDay-today {
    border: 0.5px solid #dfdfdf !important;
  }

  .preselected-date {
    border: 4px solid ${(props) => props.theme.main.midnightBlue20} !important;
  }

  .days-locked {
    border-color: ${(props) => props.theme.main.midnightBlue60} !important;
  }
`;

const CalendarHeader = styled.div`
  background-color: ${(props) => props.theme.main.midnightBlue40};
  height: 21px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  margin-bottom: 15px;

  &::after {
    position: absolute;
    content: '';
    width: 34px;
    height: 22px;
    background-color: ${(props) => props.theme.main.midnightBlue40};
    top: -15px;
    left: 25%;
    transform: translateX(-50%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &::before {
    position: absolute;
    content: '';
    width: 34px;
    height: 22px;
    background-color: ${(props) => props.theme.main.midnightBlue40};
    top: -15px;
    right: 25%;
    transform: translateX(50%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;
