import * as Sentry from '@sentry/react';

export const useNeuroId = () => {
  const initPage = (pageName: string) => {
    try {
      // @ts-ignore nid
      window?.nid('start', pageName);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const submitApplication = (key: string) => {
    try {
      // @ts-ignore nid
      window?.nid('applicationSubmit', key);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const setUserId = (userId: string) => {
    try {
      // @ts-ignore nid
      window?.nid('setUserId', userId);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const setFunnel = (name: string) => {
    try {
      // @ts-ignore nid
      window?.nid('setVariable', 'funnel', name);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return {
    initPage,
    submitApplication,
    setUserId,
    setFunnel,
  };
};
