import * as Sentry from '@sentry/react';
import { INCOME_PROVIDER } from 'pages/ConnectBankAccount/ConnectBankAccount';
import { WEBPREQUAL } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import { getDimensions } from 'lib/utils/GetDimensions';
import { AnalyticsBrowser } from '@segment/analytics-next';

export const analytics = new AnalyticsBrowser();

interface IdentifyData {
  firstName?: string;
  lastName?: string;
  dob?: string;
  phone?: string;
  id?: string | number;
  street?: string;
  zip?: string;
  state?: string;
  city?: string;
  email?: string;
  address?: {
    address1?: string;
    zip?: string;
    state?: string;
    city?: {
      state?: {
        code?: string;
      };
    };
  };
}

export const useSegment = () => {
  const { defaultLanguage } = useStore();
  const trackAlleIframeLoaded = (crossSiteTracking: boolean) => {
    try {
      // @ts-ignore analytics
      analytics?.track('Alle Iframe Loaded', {
        application: WEBPREQUAL,
        crossSiteTracking: crossSiteTracking,
        ...getDimensions(),
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackBorrowerCreatedEvent = (organization, borrower) => {
    try {
      // @ts-ignore analytics
      analytics?.track('Borrower Created', {
        organizationSlug: organization?.slug,
        organizationName: organization?.name,
        application: WEBPREQUAL,
        value: 10,
        language: defaultLanguage,
        ...getDimensions(),
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackOptionSelected = (organization, application, loan, plan, amount) => {
    try {
      // @ts-ignore analytics
      analytics?.track('Option Selected', {
        organizationSlug: organization?.slug,
        organizationName: organization?.name,
        value: 70,
        applicationId: application?.id,
        purchaseAmount: loan?.purchaseAmount || amount,
        application: WEBPREQUAL,
        term: plan?.product?.term,
        paymentSize: plan?.installmentAmount,
        apr: plan?.product?.apr,
        language: defaultLanguage,
        ...getDimensions(),
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackIncomeVerification = (
    organization,
    application,
    incomeProvider: INCOME_PROVIDER,
    isMultiScreen = false,
  ) => {
    try {
      // @ts-ignore analytics
      analytics?.track('Income Verification Began', {
        organizationSlug: organization?.slug,
        organizationName: organization?.name,
        applicationId: application?.id,
        application: WEBPREQUAL,
        incomeProvider,
        value: isMultiScreen ? 36 : 35,
        language: defaultLanguage,
        ...getDimensions(),
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj)
        ?.filter(([_, v]) => v != null)
        ?.map(([k, v]) => [k, v === Object(v) ? removeEmptyFields(v) : v]),
    );
  };

  const trackIdentify = (data?: IdentifyData) => {
    try {
      if (data) {
        const userId = data?.id ? `b-${data?.id}` : undefined;
        const traits = removeEmptyFields({
          firstName: data?.firstName,
          lastName: data?.lastName,
          birthday: data?.dob,
          phone: data?.phone,
          email: data?.email,
          address: {
            street: data?.street || data?.address?.address1 || '',
            postalCode: data?.zip || data?.address?.zip || '',
            state: data?.state || data?.address?.city?.state?.code || '',
            city: data?.city || '',
          },
        });
        // @ts-ignore analytics
        analytics?.identify(userId, traits);
      } else {
        // @ts-ignore analytics
        analytics?.identify();
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackAlias = (id: string) => {
    try {
      // @ts-ignore analytics
      analytics.alias({
        userId: id,
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackPage = (pageName: string, data) => {
    try {
      // @ts-ignore analytics
      analytics?.page(pageName, { ...data, language: defaultLanguage, ...getDimensions() });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackGroup = (groupId: string, traits) => {
    try {
      // @ts-ignore analytics
      analytics?.group(groupId, traits);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const trackSegmentEvent = (eventName: string, data) => {
    try {
      // @ts-ignore analytics
      analytics?.track(eventName, {
        ...data,
        language: defaultLanguage,
        ...getDimensions(),
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return {
    trackAlleIframeLoaded,
    trackBorrowerCreatedEvent,
    trackOptionSelected,
    trackIncomeVerification,
    trackPage,
    trackSegmentEvent,
    trackIdentify,
    trackAlias,
    trackGroup,
  };
};
