import React from 'react';
import styled from 'styled-components';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';

import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import AlleSingleConnectBankAccount from './AlleSingleConnectBankAccount';
import { useTranslation } from 'react-i18next';

interface Props {
  isOptions: boolean;
  goToPlaid: () => void;
  loading: boolean;
  goToOriginalApproval: () => void;
  uploadPaystub: () => void;
  featureFlags: {
    mxEnabled: boolean;
  };
  organization: {
    name: string;
  };
}

const SingleConnectBankAccount = ({
  isOptions,
  goToPlaid,
  loading,
  uploadPaystub,
  featureFlags,
  organization,
  goToOriginalApproval,
}: Props) => {
  const { t: translate } = useTranslation();
  const { isAlle } = useStore();
  const { application } = useStore.getState() || {};

  const renderButton = () => {
    if (isOptions) {
      return (
        <ButtonText onClick={uploadPaystub}>{translate('singleConnectBankAccount.button.verifyOther')}</ButtonText>
      );
    }

    return null;
  };

  if (isAlle) {
    return (
      <AlleSingleConnectBankAccount
        isOptions={isOptions}
        goToPlaid={goToPlaid}
        loading={loading}
        uploadPaystub={uploadPaystub}
        featureFlags={featureFlags}
        organization={organization}
      />
    );
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <IconContainer>
            <Icon src={'cherry-logo'} width={24} height={24} />
            <Icon m={'0px 4px 0px 12px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 4px'} width={4} height={4} src={'dot'} />
            <Icon m={'0px 12px 0px 4px'} width={4} height={4} src={'dot'} />
            <Icon src={'bank'} />
          </IconContainer>
          <Title m={'10px 0px 8px 0px'}>{translate('singleConnectBankAccount.title')}</Title>
          <Subtitle m={'0px 0px 24px 0px'}>{translate('singleConnectBankAccount.subTitle')}</Subtitle>
          <MessageTitle>{translate('singleConnectBankAccount.next')}</MessageTitle>
          <Subtitle m={'0px 0px 24px 0px'}>
            {translate('singleConnectBankAccount.viaMessage', {
              type: featureFlags?.mxEnabled ? 'MX' : 'Plaid',
            })}
          </Subtitle>
          <Subtitle m={'0px 0px 24px 0px'}>{translate('singleConnectBankAccount.flexiblePayment')}</Subtitle>
        </InnerContainer>
      </ContentContainer>
      <InfoContainer>
        <Icon width={16} height={20} src={'info_circle_outline'} />
        <InfoText>
          {translate('singleConnectBankAccount.info', {
            name: organization?.name,
          })}
        </InfoText>
      </InfoContainer>
      <ButtonContainer>
        <Button disabled={loading} loading={loading} onClick={goToPlaid}>
          <Icon width={20} height={20} src={'lock-secure-white'} />
          <ConnectText>
            {translate('singleConnectBankAccount.button.connectBankAccount', {
              name: organization?.name,
            })}
          </ConnectText>
        </Button>
        {application?.mickeyMouse && (
          <Button disabled={loading} secondary={true} onClick={goToOriginalApproval}>
            <ConnectText>{translate('buttons.goToOriginalApproval')}</ConnectText>
          </Button>
        )}
        {renderButton()}
      </ButtonContainer>
    </Container>
  );
};

export default SingleConnectBankAccount;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

const ButtonText = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: ${(props) => props.theme.main.green};
  margin: 24px 0;
  cursor: pointer;
`;

export const MessageTitle = styled.span`
  font-family: ${theme.fonts.primary}, serif;
  color: ${(props) => props.theme.main.grayColor};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  font-style: normal;
  margin: 24px 0 8px 0;
`;

const ConnectText = styled.div`
  margin-left: 10px;
`;
