import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { getSurveyLink, getSurveyVersion, isSurveyEnabledIndustry, surveyPages } from 'lib/constants';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import useStore from 'lib/hooks/useStore';
import { Redirect } from 'lib/utils';
import styled from 'styled-components';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';

type Props = {
  title: string;
  page: 'DENIAL' | 'FUND SUCCESS' | 'APPROVAL';
};

export default function SurveyNavigatorBox({ title, page }: Props) {
  const { t: translate } = useTranslation();
  const { flags } = useFlags();
  const { organization } = useStore();
  const { sendActionSegmentEvent } = useSegmentContext();

  const isSurveyEnabled = flags?.APPROVALSURVEY?.approvalScreen?.isEnabled() || false;
  const showSurveyNavigatorBox = isSurveyEnabled && isSurveyEnabledIndustry(organization);
  const version = getSurveyVersion(organization?.industry);

  const goToSurveyForm = () => {
    const { application } = useStore.getState() || {};
    sendActionSegmentEvent(WPQSegmentNames.denialSurveyClicked);

    Redirect(
      `https://withcherry.typeform.com/to/${getSurveyLink(organization?.industry)}#application_id=${
        application?.id
      }&screen_source=${surveyPages[page]}&version=${version}`,
    );
  };

  return (
    showSurveyNavigatorBox && (
      <SurveyNavigatorContainer spacing={1} alignItems="center">
        <Title fontSize={16} fontWeight="700" variant="h3">
          {title}
        </Title>
        <CompleteSurveyButton disableRipple onClick={goToSurveyForm}>
          {translate('buttons.completeSurvey')}
        </CompleteSurveyButton>
      </SurveyNavigatorContainer>
    )
  );
}

const SurveyNavigatorContainer = styled(Stack)`
  margin-bottom: 36px;
  background-color: ${(props) => props.theme.main.blueLight5};
  padding: 12px 16px 16px;
  border-radius: 8px;
`;

const Title = styled(Typography)`
  color: ${(props) => props.theme.main.midnightBlue};
`;

const CompleteSurveyButton = styled(Button)`
  width: 100%;
  background-color: ${(props) => props.theme.main.white} !important;
  color: ${(props) => props.theme.main.greenMain} !important;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.07), 0px 1px 1px 0px rgba(0, 0, 0, 0.04);
  text-transform: capitalize;
`;
