import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';

import { DATE_FORMAT } from 'lib/constants';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { FormatAmount } from 'lib/utils';

import { Button, Container, Icon, RadioButtonGroup, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { usePlans } from '../Plans/services';
import { useTranslation } from 'react-i18next';

enum RadioButtonEnums {
  YES = 'YES',
  NO = 'NO',
}

const Autopay = () => {
  const { patchLoan } = usePlans();
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { t: translate } = useTranslation();
  const { selectedPlan, loan, installmentMethod, setSelectedAutopay, selectedAutopay } = useStore();

  const [radioValue, setRadioValue] = useState<RadioButtonEnums | ''>(selectedAutopay || RadioButtonEnums.YES);
  const [loading, setLoading] = useState(false);

  const handleContinue = async () => {
    try {
      setLoading(true);
      await patchLoan({
        loanId: loan?.id,
        installmentPaymentMethodId: installmentMethod?.id,
        autoPay: radioValue === RadioButtonEnums.YES ? true : false,
      });
      setLoading(false);
      setSelectedAutopay(radioValue);

      navigate('confirm');
    } catch (err) {
      captureException(err, { message: 'Autopay - Patch Loan Error' });
      navigate(`loan-cancelled`);
    }
  };

  const onChangeHandler = (e) => {
    setRadioValue(e?.target?.value);
  };

  return (
    <Container showBackButton={true} backUrl={'method-list'}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <Icon src={'refund'} />
          <Title m={'10px 0px 8px 0px'}>{translate('autopay.title')}</Title>

          <Subtitle
            m={'0px 0px 24px 0px'}
            dangerouslySetInnerHTML={{
              __html: translate('autopay.subTitle', {
                amount: FormatAmount(selectedPlan?.installmentAmount),
              }),
            }}
          />
          <RadioButtonGroup
            onChange={onChangeHandler}
            radioButtonOptions={[
              {
                textValue: RadioButtonEnums.YES,
                radioLabel: translate('autopay.radio.text1'),
              },
              {
                textValue: RadioButtonEnums.NO,
                radioLabel: translate('autopay.radio.text2'),
              },
            ]}
            value={radioValue}
          />
        </InnerContainer>
        {radioValue === RadioButtonEnums.NO && (
          <InfoContainer>
            <Icon width={20} height={20} src={'warning_outline'} />
            <InfoText
              dangerouslySetInnerHTML={{
                __html: translate('autopay.message.no', {
                  date: dayjs(selectedPlan?.paymentDates?.[0]).format(DATE_FORMAT),
                }),
              }}
            />
          </InfoContainer>
        )}
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={radioValue === '' || loading} loading={loading} onClick={handleContinue}>
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`;

export default Autopay;
