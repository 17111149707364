import React, { FC, useState } from 'react';
import { FaqItem, GetFaqListParams } from './types';
import { useTranslation } from 'react-i18next';
import { ContentContainer } from 'lib/components/Common';
import { Accordion, Title } from 'lib/components';

const getFaqKycFailureList = ({ translate }: GetFaqListParams): FaqItem[] => {
  return [
    {
      title: translate('FAQ.doesThisDecisionHurtMyCredit.question'),
      content: translate('FAQ.doesThisDecisionHurtMyCredit.answer'),
    },
    {
      title: translate('FAQ.howWasThisDecisionMade.question'),
      content: translate('FAQ.howWasThisDecisionMade.answer'),
    },
    {
      title: translate('FAQ.howIsMyCreditScoreCalculated.question'),
      content: translate('FAQ.howIsMyCreditScoreCalculated.answer'),
    },
    {
      title: translate('FAQ.canIReapplyWithCherryAtADifferentPractice.question'),
      content: translate('FAQ.canIReapplyWithCherryAtADifferentPractice.answer'),
    },
    {
      title: translate('FAQ.canIAppealTheDenial.question'),
      content: translate('FAQ.canIAppealTheDenial.answer'),
    },
  ];
};

const FaqKycFailureList: FC = () => {
  const { t: translate } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>('panel0');

  const faqKycFailureList = getFaqKycFailureList({ translate });

  const handleChange = (panel: string) => {
    setExpanded(panel);
  };

  return (
    <ContentContainer noPadding noMargin>
      <Title>{translate('FAQ.title')}</Title>
      <Accordion data={faqKycFailureList} onChange={handleChange} expanded={expanded} />
    </ContentContainer>
  );
};

export default FaqKycFailureList;
