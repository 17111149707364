import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Button, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { FaqListModal } from 'lib/components/FaqListModal';
import { theme } from 'config/theme';
import faqConnectWithPlaidAlle from 'pages/Faq/components/faqConnectWithPlaidAlle.json';
import { IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';

interface Props {
  isOptions: boolean;
  loading: boolean;
  displayFaqModal: boolean;
  uploadPaystub: () => void;
  goToPlaid: () => void;
  setSecondPageStep: () => void;
  setFirstPageStep: () => void;
  setDisplayFaqModal: (value: boolean) => void;
  pageStep: number;
  featureFlags: {
    mxEnabled: boolean;
  };
  organization: {
    name: string;
  };
}

const AlleMultiScreenConnectBankAccount = ({
  isOptions,
  uploadPaystub,
  featureFlags,
  organization,
  goToPlaid,
  loading,
  pageStep,
  setFirstPageStep,
  setSecondPageStep,
  displayFaqModal,
  setDisplayFaqModal,
}: Props) => {
  const { pushEvent } = useIframeEvents();

  useEffect(() => {
    pushEvent(IframeEventTypes.PAGE, PageEventActions.CONNECT_BANK_ACCOUNT);
  }, []);

  const renderButton = () => {
    if (isOptions) {
      return <ButtonText onClick={uploadPaystub}>Verify with Paystubs/Payroll</ButtonText>;
    }

    return null;
  };

  const renderPageContent = () => {
    let content;
    if (pageStep === 1) {
      content = (
        <>
          <Title
            color={theme.main.black10}
            fontSize="24px"
            fontWeight={400}
            lineHeight="32px"
            useElegantFontType={true}
            textAlign="center"
          >
            Verify your income
          </Title>
          <Subtitle fontSize="16px" m="16px 0" textAlign="center" color={theme.main.black20} lineHeight="24px">
            We need additional information to verify your income in order to complete your application.
          </Subtitle>
          <Subtitle fontSize="16px" m="16px 0" textAlign="center" color={theme.main.black20} lineHeight="24px">
            Without this information, we will not be able to provide you with the decision.
          </Subtitle>
          <Button
            m="32px 0 0"
            black={true}
            uppercase={true}
            thinText={true}
            fontWeight={400}
            fontSize={11}
            disabled={loading}
            loading={loading}
            onClick={setSecondPageStep}
          >
            <ConnectText>Continue</ConnectText>
          </Button>
          {renderButton()}
          <ButtonText onClick={() => setDisplayFaqModal(true)}>Have a Question?</ButtonText>
          <InfoContainer>
            <Icon width={16} height={20} src={'info_circle_outline'} />
            <InfoText>{`${organization?.name} never receives the details of your income.`}</InfoText>
          </InfoContainer>
          <FaqListModal
            hideModal={() => setDisplayFaqModal(false)}
            show={displayFaqModal}
            faqList={faqConnectWithPlaidAlle}
            title="Have a Question?"
            titleMargin={'auto'}
          />
        </>
      );
    } else {
      content = (
        <>
          <Title m={'10px 0px 8px 0px'}>Why do you need my income?</Title>
          <Subtitle fontSize="16px" m={'0px 0px 24px 0px'}>
            Cherry looks beyond credit scores to offer flexible payment plans to patients.
          </Subtitle>
          <Subtitle fontSize="16px" m={'0px 0px 24px 0px'}>
            Verifying your income by connecting your bank using our digital finance partner,{' '}
            {featureFlags?.mxEnabled ? 'MX' : 'Plaid'}, is the fastest way for us to provide you with the decision.
          </Subtitle>
          <Button disabled={loading} loading={loading} onClick={goToPlaid}>
            <Icon width={20} height={20} src={'lock-secure-white'} />
            <ConnectText>Connect Bank Account</ConnectText>
          </Button>
          {renderButton()}
          <ButtonText onClick={() => setDisplayFaqModal(true)}>Have a Question?</ButtonText>
          <InfoContainer>
            <Icon width={16} height={20} src={'lock-secure'} />
            <InfoText>
              {`We secure your information with the same 256-bit encryption that banks use. Neither Cherry nor ${organization?.name} stores your login.`}
            </InfoText>
          </InfoContainer>
          <FaqListModal
            hideModal={() => setDisplayFaqModal(false)}
            show={displayFaqModal}
            faqList={faqConnectWithPlaidAlle}
            title="Have a Question?"
            titleMargin={'auto'}
          />
        </>
      );
    }

    return content;
  };

  return <Container onClick={setFirstPageStep}>{renderPageContent()}</Container>;
};

export default AlleMultiScreenConnectBankAccount;

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: Graphik;
`;

const StepText = styled.div`
  color: ${(props) => props.theme.main.silverGray};
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 4px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;

  margin: 24px;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  text-align: left;
`;

const ButtonText = styled.div`
  color: ${(props) => props.theme.main.alleBlack};
  margin: 24px 0;
  cursor: pointer;
  text-align: center;
  font-family: 'Graphik';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
`;

const ConnectText = styled.div`
  margin-left: 10px;
`;
