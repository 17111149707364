import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useAlert } from 'react-alert';
import OtpInput from 'react18-input-otp';

import { START_OTP_FLOW } from 'lib/graphql/mutations';

import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { FormatPhoneNumber } from 'lib/utils';
import { useSentry } from 'lib/hooks/useSentry';
import { useSegment } from 'lib/hooks/useSegment';
import { WEBPREQUAL } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';

import { Button, Container, DidntGetCodeModal, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { APP_ENV } from 'config';
import { useOtp } from 'lib/hooks/useOtp';

const inputStyle = {
  width: '54px',
  height: '90px',
  fontSize: '20px',
  borderRadius: '4px',
  border: `1px solid ${theme.main.grayColor}`,
};

const containerStyle = {
  width: '100%',
  maxWidth: '400px',
  justifyContent: 'space-between',
};

const focusStyle = {
  outline: 0,
};

enum OtpReceiveChoiceEnum {
  SMS = 'SMS',
  VOICE = 'VOICE',
}

const Otp = () => {
  const alert = useAlert();
  const { captureException } = useSentry();
  const { trackEvent } = useAnalytics();
  const { trackSegmentEvent } = useSegment();

  const { isDemo, setPageIndex, sessionData, organization } = useStore();
  const { t: translate } = useTranslation();

  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const [startOtpFlow, { loading: resendLogin }] = useMutation(START_OTP_FLOW);
  const loadSegmentController = useRef(true);
  const { verifyRegularOtp, otpLoading, setOtpLoading } = useOtp();

  const otpDefaultValue = isDemo || APP_ENV === 'sandbox' ? '1111' : '';

  const [otp, setOtp] = useState<string>(otpDefaultValue);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.otpLoad, {
        patientPhone: sessionData?.phone,
      });
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(3);
  }, [setPageIndex]);

  useEffect(() => {
    if (otp?.length === 4) {
      sendActionSegmentEvent(WPQSegmentNames.otpInputFilled, {
        otp: otp,
        patientPhone: sessionData?.phone,
      });
      onSubmit?.();
    }
  }, [otp]);

  const closeModal = () => setShow(false);
  const openModal = () => {
    sendActionSegmentEvent(WPQSegmentNames.otpDidnotGetCodeClicked);
    setShow(true);
  };

  const onSubmit = async () => {
    try {
      if (!(otpLoading || resendLogin)) {
        trackEvent(AnalyticEventNames.CV_VERIFY);
        trackSegmentEvent(AnalyticEventNames.CV_VERIFY, {
          application: WEBPREQUAL,
          patientPhone: sessionData?.phone,
        });
        sendActionSegmentEvent(WPQSegmentNames.otpContinueClicked);
        verifyRegularOtp(otp, sessionData?.phone);
      }
    } catch (err) {
      captureException(err, { message: 'Otp page submit error', session: sessionData });
      setOtpLoading(false);
    }
  };

  const handleChange = (value: string): void => {
    setOtp(value);
  };

  const otpActions = () => {
    const { borrower } = useStore.getState() || {};
    trackEvent(AnalyticEventNames.CV_DIDNT_GET_CODE);
    trackSegmentEvent('OTP Not Received', {
      organizationSlug: organization?.slug,
      organizationName: organization?.name,
      application: WEBPREQUAL,
      borrowerId: borrower?.id,
    });
  };

  const resendOtp = useCallback(async () => {
    sendActionSegmentEvent(WPQSegmentNames.otpResendCodeClicked);
    otpActions();

    const { data } = await startOtpFlow({
      variables: {
        input: {
          phone: sessionData?.phone,
          otpChannel: OtpReceiveChoiceEnum.SMS,
        },
      },
      context: {
        headers: {
          extra: 'borrowerInit',
        },
      },
    });
    if (data?.startOtpFlow?.success) {
      alert.success(translate('otp.verificationCodeSent'));
    }
  }, [sessionData?.phone]);

  const callMeWithCode = useCallback(async () => {
    otpActions();
    sendActionSegmentEvent(WPQSegmentNames.otpCallWithClicked);

    const { data } = await startOtpFlow({
      variables: {
        input: {
          phone: sessionData?.phone,
          otpChannel: OtpReceiveChoiceEnum.VOICE,
        },
      },
      context: {
        headers: {
          extra: 'borrowerInit',
        },
      },
    });
    if (data?.startOtpFlow?.success) {
      alert.success(translate('otp.callingYouWithCode'));
    }
  }, [sessionData?.phone]);

  return (
    <Container showBackButton={true}>
      <ContentContainer>
        <Icon src={'message_writing'} />
        <Title data-testid="otp-title" m={'10px 0px 8px 0px'}>
          {translate('otp.weJustTextYou')}
        </Title>
        <Subtitle
          m={'0px 0px 40px 0px'}
          dangerouslySetInnerHTML={{
            __html: translate('otp.enterVerificationCode', {
              phone: FormatPhoneNumber(sessionData?.phone),
            }),
          }}
        />
        <OtpBox>
          <StyledOtpInput
            numInputs={4}
            isInputNum={true}
            inputStyle={inputStyle}
            containerStyle={containerStyle}
            focusStyle={focusStyle}
            shouldAutoFocus={true}
            value={otp}
            data-testid="otp"
            onChange={handleChange}
            placeholder={'#####'}
          />
        </OtpBox>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={otp.length !== 4 || otpLoading || resendLogin}
          loading={otpLoading || resendLogin}
          onClick={onSubmit}
        >
          {translate('buttons.continue')}
        </Button>
        <Button secondary={true} onClick={openModal}>
          {translate('buttons.didntGetACode')}
        </Button>
      </ButtonContainer>
      <DidntGetCodeModal show={show} hideModal={closeModal} reSendCode={resendOtp} callMeWithCode={callMeWithCode} />
    </Container>
  );
};

const OtpBox = styled.form`
  display: flex;
  justify-content: center;
`;

const StyledOtpInput = styled(OtpInput)`
  input {
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #dadada;
    }

    :-ms-input-placeholder {
      color: #dadada;
    }
  }
`;

export default React.memo(Otp);
