import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';

import { Button, Icon, Title } from 'lib/components';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { theme } from 'config/theme';
import { useTranslation } from 'react-i18next';

interface AddPaymentMethodModalProps {
  show: boolean;
  hideModal: () => void;
}

const AddPaymentMethodModal = ({ show, hideModal }: AddPaymentMethodModalProps) => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const { isAlle } = useStore();

  const navigateToCreditCard = () => {
    navigate(`add-card?isComingFromRecurring=true`);
  };

  const navigateToRemoteCheck = () => {
    navigate(`add-remote-check`);
  };

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={hideModal}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer>
          <ModalHeader>
            <IconGap />
            <PageTitle
              m={'24px 0px'}
              color={isAlle && theme.main.black10}
              fontSize={isAlle && '16px'}
              fontWeight={isAlle && 500}
              lineHeight={isAlle && '24px'}
              useElegantTheme={isAlle}
            >
              {isAlle ? <>Add Recurring Payment Method</> : <>{translate('addPaymentMethodModal.title')}</>}
            </PageTitle>
            <Icon src={'close'} onClick={hideModal} height={isAlle && 24} />
          </ModalHeader>
          <IconGap />
          {isAlle ? (
            <>
              <OutlinedButton onClick={navigateToCreditCard}>
                {translate('addPaymentMethodModal.cardType')}
              </OutlinedButton>
              <OutlinedButton onClick={navigateToRemoteCheck}>
                {translate('addPaymentMethodModal.check')}
              </OutlinedButton>
            </>
          ) : (
            <>
              <Button secondary={true} onClick={navigateToCreditCard}>
                <Icon src={'credit-card'} width={16} height={16} m={'0px 10px'} />
                {translate('addPaymentMethodModal.cardType')}
              </Button>
              <Button secondary={true} onClick={navigateToRemoteCheck}>
                <Icon src={'remote-check'} width={16} height={16} m={'0px 10px'} />
                {translate('addPaymentMethodModal.check')}
              </Button>
            </>
          )}
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

export default AddPaymentMethodModal;

export const ModalContainer = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 48px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: 220px;
    border-radius: 6px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const OutlinedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.main.white};
  border: 1px solid ${(props) => props.theme.main.grayColor};
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
`;

const PageTitle = styled(Title)<{ useElegantTheme?: boolean }>`
  font-family: ${(props) => props.useElegantTheme && 'Graphik'};
`;
