import { memo } from 'react';
import styled from 'styled-components';

import { PHONE } from 'lib/constants';
import { CallCherry } from 'lib/utils';
import { useTranslation } from 'react-i18next';

export const Footer = memo(() => {
  const { t: translate } = useTranslation();
  return (
    <Container>
      <FooterText>
        © {new Date().getFullYear()} {translate('footer.cherryInc')}
      </FooterText>
      <FooterTextHover onClick={CallCherry}>
        {' '}
        {translate('buttons.haveAQuestion')} {PHONE}
      </FooterTextHover>
    </Container>
  );
});

const Container = styled.div`
  flex-grow: 0;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 20px 0;
`;

const FooterText = styled.div`
  display: block;
  margin-bottom: 4px;
`;

const FooterTextHover = styled(FooterText)`
  cursor: pointer;
`;
