import { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { API_URL } from 'config';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { WEBPREQUAL } from 'lib/constants';
import { useSegment } from 'lib/hooks/useSegment';

import { IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';
import { useCreditLineIncrease } from 'lib/hooks/useCreditLineIncrease';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useDidMountEffect } from 'lib/hooks/useDidMountEffect';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useTranslation } from 'react-i18next';

const PaystubFileUpload = () => {
  const { navigate } = useNavigation();
  const { idLoan } = useParams();
  const { trackSegmentEvent } = useSegment();
  const { setAppLoading, defaultLanguage } = useStore();
  const { pushEvent } = useIframeEvents();
  const { pollLatestCreditLineIncrease } = useCreditLineIncrease();
  const { sendLoadSegmentEvent } = useSegmentContext();
  const { t: translate } = useTranslation();

  const { startCheckApplicationStatus } = useSSEContext();

  useEffect(() => {
    setAppLoading(true);
    pushEvent(IframeEventTypes.PAGE, PageEventActions.PLAID_PAYSTUB_IFRAME);
    window?.addEventListener('message', receiveMessage, false);
    return () => {
      setAppLoading(false);
      window?.removeEventListener('message', receiveMessage);
    };
  }, []);

  const receiveMessage = async ({ origin, data }) => {
    if (origin === API_URL) {
      const { application } = useStore.getState() || {};
      const iFrameEvent = JSON.parse(data);

      sendLoadSegmentEvent(WPQSegmentNames.incomeVerificationPaystubLoad, { events: iFrameEvent });

      switch (iFrameEvent.event) {
        case 'expired':
          setAppLoading(true);
          startCheckApplicationStatus({ applicationId: iFrameEvent?.data?.idLoan, isExisting: true });

          break;
        case 'close':
          setAppLoading(false);
          navigate(`upload-paystub/${idLoan}`);
          break;
        case 'complete':
          if (application?.mickeyMouse) {
            await pollLatestCreditLineIncrease();
          } else {
            !iFrameEvent?.data?.error && setAppLoading(true);
            startCheckApplicationStatus({ applicationId: application?.id, isExisting: true });
          }
          break;
        case 'loading':
          setAppLoading(true);
          break;
        case 'loaded':
          setAppLoading(false);
          break;
        case 'plaid-event':
        case 'plaid-error':
          // this.loggerSrv.log(iFrameEvent);
          break;
      }
    }
  };

  return (
    <Container>
      <iframe
        id="add-plaid-auth"
        data-testid="the-frame"
        title={translate('paystubFileUpload.title')}
        width="100%"
        height="100%"
        frameBorder="0"
        src={`${API_URL}/v1/paystubs/plaid-iframe/${idLoan}?language=${defaultLanguage}`}
        data-hj-allow-iframe=""
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    height: calc(100vh - 90px);
  }

  iframe {
    height: 100%;
    width: 100%;
  }
`;

export default PaystubFileUpload;
