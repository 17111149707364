import { useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';
import styled from 'styled-components';

import { ADD_PAYMENT_METHOD } from 'lib/graphql/mutations';

import { RCC } from 'lib/constants';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { usePlans } from 'pages/Plans/services';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import AlleRemoteCheckReview from 'pages/RemoteCheckReview/AlleRemoteCheckReview';
import { useTranslation } from 'react-i18next';

interface State {
  routingNumber: string;
  accountNumber: string;
}

const RemoteCheckReview = () => {
  const alert = useAlert();
  const { captureException } = useSentry();
  const { getPaymentMethods } = usePlans();
  const { navigate, navigateBack } = useNavigation();
  const { t: translate } = useTranslation();

  const { user, sessionData, setInstallmentMethod, isAlle } = useStore();
  const [addPayment, { loading }] = useMutation(ADD_PAYMENT_METHOD);

  const renderBorrowerInfo = () => {
    const { borrower } = useStore.getState() || {};
    if (isAlle) {
      return `${borrower?.firstName} ${borrower?.lastName}`;
    }

    return (
      <SummaryTextColor>
        {borrower?.firstName} {borrower?.lastName}
      </SummaryTextColor>
    );
  };

  const addPaymentMethodHandler = async (params: State) => {
    const { borrower } = useStore.getState() || {};

    try {
      const { routingNumber, accountNumber } = sessionData;
      const { data } = await addPayment({
        variables: {
          input: {
            borrowerId: borrower?.id,
            firstName: user?.firstName,
            lastName: user?.lastName,
            type: RCC,
            rccAccount: {
              routingNumber,
              accountNumber,
            },
          },
        },
      });
      if (data?.addPaymentMethod?.id) {
        await getPaymentMethods();
        setInstallmentMethod(data?.addPaymentMethod);

        navigate(`method-list`);
      } else {
        alert.info(translate('remoteCheckReview.error.default'));
        navigateBack();
      }
    } catch (err) {
      alert.info(translate('remoteCheckReview.error.default'));

      captureException('Add Payment Error', {
        page: 'RemoteCheckReview',
        message: 'Remote Check Review Add Payment Error',
        session: sessionData,
        borrowerId: borrower?.id || 'No Borrower',
        user: user?.id || 'No User',
      });

      navigateBack();
    }
  };

  const handleChooseAnotherMethod = () => {
    navigate(`method-list`);
  };

  if (isAlle) {
    return (
      <AlleRemoteCheckReview
        renderBorrowerInfo={renderBorrowerInfo}
        sessionData={sessionData}
        loading={loading}
        addPaymentMethodHandler={addPaymentMethodHandler}
        handleChooseAnotherMethod={handleChooseAnotherMethod}
      />
    );
  }

  return (
    <Container showBackButton={true}>
      <ContentContainer>
        <Icon src={'circle_check_outline_black'} />
        <Title m={'10px 0 24px 0'}>{translate('remoteCheckReview.title')}</Title>
        <SummaryContainer>
          <Icon width={32} height={32} src={'remotecheck'} />
          <SummaryTextContainer>
            <SummaryText>
              {translate('remoteCheckReview.name')} - {renderBorrowerInfo()}
            </SummaryText>
            <SummaryText>
              {translate('remoteCheckReview.account')} -{' '}
              <SummaryTextColor>{sessionData?.accountNumber}</SummaryTextColor>
            </SummaryText>
            <SummaryText>
              {translate('remoteCheckReview.routing')} -{' '}
              <SummaryTextColor>{sessionData?.routingNumber}</SummaryTextColor>
            </SummaryText>
          </SummaryTextContainer>
        </SummaryContainer>
        <Subtitle m={'24px 0 0 0'} dangerouslySetInnerHTML={{ __html: translate('remoteCheckReview.info') }}></Subtitle>
        <CherryAddressContainer>
          <CherryAddressLine>Cherry Technologies Inc.</CherryAddressLine>
          <CherryAddressLine>DEPT 2258</CherryAddressLine>
          <CherryAddressLine>PO BOX 122258</CherryAddressLine>
          <CherryAddressLine>DALLAS, TX 75312-2258</CherryAddressLine>
        </CherryAddressContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          disabled={loading}
          loading={loading}
          onClick={addPaymentMethodHandler}
          icon={loading ? 'lock' : 'lock-active'}
        >
          {translate('buttons.addPaymentMethod')}
        </Button>
        <Button secondary={true} onClick={handleChooseAnotherMethod}>
          {translate('buttons.chooseAnotherMethod')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default RemoteCheckReview;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.theme.main.darkGrayColor};
  padding: 18px 16px;
  border-radius: 8px;
`;

const SummaryTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const CherryAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
`;

const CherryAddressLine = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
`;

const SummaryText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 8px;
`;

const SummaryTextColor = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: ${(props) => props.theme.main.grayColor};
`;
