import { redirectToCustomerPortal } from 'lib/utils';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { MessageTitle } from '../FrozenCredit/FrozenCredit';
import useStore from 'lib/hooks/useStore';
import AlleApproval from '../Approval/AlleApproval';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

const Declined = () => {
  const { t: translate } = useTranslation();
  const { isAlle } = useStore();
  const { sendActionSegmentEvent } = useSegmentContext();

  if (isAlle) {
    return <AlleApproval />;
  }

  const goToCustomerPortal = () => {
    sendActionSegmentEvent(WPQSegmentNames.kycDenialPatientPortalClicked);
    redirectToCustomerPortal();
  };
  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'decline-fail'} />
        <Title m={'10px 0px 8px 0px'}>{translate('declined.title')}</Title>
        <Subtitle m={'24px 0px 24px 0px'}>{translate('declined.subTitle')}</Subtitle>

        <MessageTitle>{translate('declined.info.title')}</MessageTitle>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('declined.info.subTitle')}</Subtitle>
        <Subtitle m={'0 0px 24px 0px'}>{translate('declined.info.patientPortal')}</Subtitle>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={goToCustomerPortal}>{translate('buttons.patientPortal')}</Button>
      </ButtonContainer>
    </Container>
  );
};

export default Declined;
