import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';

import { APP_ENV } from 'config';
import { WEBPREQUAL } from 'lib/constants';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { trapSpacesForRequiredFields, validateDOB } from 'lib/utils/Validators';

import { Box, Button, Container, DateInput, Icon, LegalModal, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import AllePersonalInfo from 'pages/PersonalInfo/AllePersonalInfo';
import { ButtonClickedEventActions, IframeEventTypes, useIframeEvents } from 'lib/hooks/useIframeEvents';
import styled from 'styled-components';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';

const PersonalInfo = () => {
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { initPage, setUserId } = useNeuroId();
  const { trackIdentify, trackSegmentEvent } = useSegment();
  const { setSessionData, setPageIndex, sessionData, organization, application, borrower, isAlle, defaultLanguage } =
    useStore();
  const { pushEvent } = useIframeEvents();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { t: translate } = useTranslation();

  let info = isAlle ? borrower : sessionData;
  info = {
    firstName: sessionData?.firstName || borrower?.firstName || null,
    lastName: sessionData?.lastName || borrower?.lastName || null,
    dob: sessionData?.dob || borrower?.dob || null,
  };

  if (APP_ENV === 'dev') {
    info = {
      dob: sessionData?.dob || '10/10/1944',
      firstName: sessionData?.firstName || 'Alberta Bobbeth',
      lastName: sessionData?.lastName || 'Charleson',
    };
  }

  useEffect(() => {
    setPageIndex(5);
  }, [setPageIndex]);

  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...info,
      dob: info?.dob ? dayjs(info?.dob).format('MM/DD/YYYY') : null,
    },
  });

  const dobValue = watch('dob');

  useEffect(() => {
    if (loadSegmentController.current) {
      if (isAlle) {
        pushEvent(IframeEventTypes.BUTTON_CLICKED, ButtonClickedEventActions.PATIENT_INFO_CONTINUE_BUTTON_CLICKED);
      }
      const { borrower } = useStore.getState() || {};
      sendLoadSegmentEvent(WPQSegmentNames.personalInfoLoad);
      initPage('info');
      setUserId(String(borrower?.id));
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const onSubmit = async (data) => {
    const { borrower } = useStore.getState() || {};

    try {
      setSessionData(data);
      trackIdentify({ ...borrower, ...sessionData, ...data });
      trackSegmentEvent('Name Submitted', {
        organizationSlug: organization?.slug,
        organizationName: organization?.name,
        application: WEBPREQUAL,
        applicationId: application?.id,
        borrowerId: borrower?.id || '',
        organizationId: organization?.id,
        value: 20,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
      });

      navigate(`address-info`);
    } catch (err) {
      captureException(err, { session: sessionData });
    }
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  const handleClick = () => {
    sendActionSegmentEvent(WPQSegmentNames.personalInfoContinueClicked);
    handleSubmit(onSubmit)();
  };

  const [isOpen, setIsOpen] = useState(false);
  const modalToggle = () => {
    sendActionSegmentEvent(WPQSegmentNames.whatIsLegalNameClicked);
    setIsOpen((prevState) => !prevState);
  };

  if (isAlle) {
    return (
      <AllePersonalInfo control={control} checkKeyDown={checkKeyDown} handleClick={handleClick} isValid={isValid} />
    );
  }

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'user_circle'} />
        <Title m={'10px 0px 8px 0px'}>{translate('personalInfo.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('personalInfo.subTitle')}</Subtitle>
        <Box>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{ required: true, validate: trapSpacesForRequiredFields }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error, isTouched } }) => (
              <TextField
                id="first-name"
                name="firstName"
                variant="filled"
                data-testid="firstName"
                data-neuro-label="firstName"
                type="text"
                label={translate('personalInfo.form.firstName')}
                value={value}
                onFocus={() => {
                  sendActionSegmentEvent(WPQSegmentNames.firstNameClicked);
                }}
                onKeyDown={checkKeyDown}
                onChange={onChange}
                onBlur={(e) => {
                  sendActionSegmentEvent(WPQSegmentNames.firstNameFilled, { metaData: e.target.defaultValue });
                  onBlur();
                }}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{ required: true, validate: trapSpacesForRequiredFields }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="last-name"
                name="lastName"
                variant="filled"
                data-testid="lastName"
                data-neuro-label="lastName"
                type="text"
                label={translate('personalInfo.form.lastName')}
                value={value}
                onKeyDown={checkKeyDown}
                onChange={onChange}
                onFocus={() => {
                  sendActionSegmentEvent(WPQSegmentNames.lastNameClicked);
                }}
                onBlur={(e) => {
                  sendActionSegmentEvent(WPQSegmentNames.lastNameFilled, { metaData: e.target.defaultValue });
                  onBlur();
                }}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            )}
          />

          <LegalContainer>
            <Icon width={20} height={20} src={'info_circle_green'} />
            <Subtitle m="0 0 0 4px" color="#3E4D59" underlined cursor="pointer" onClick={modalToggle}>
              {translate('personalInfo.info.title')}
            </Subtitle>
          </LegalContainer>

          <Controller
            name="dob"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              maxLength: 10,
              minLength: 10,
              validate: (value) => validateDOB(value, defaultLanguage),
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <>
                  <TextField
                    id="dob"
                    variant="filled"
                    name="dob"
                    data-testid="dob"
                    data-neuro-label="dob"
                    type="tel"
                    label={translate('personalInfo.form.birthDay')}
                    onKeyDown={checkKeyDown}
                    InputProps={{
                      inputComponent: DateInput,
                      disableUnderline: true,
                    }}
                    data-value={value}
                    value={value === '01/01/1900' ? null : value} // default value in db for dob - not available patients
                    onChange={onChange}
                    onFocus={() => {
                      sendActionSegmentEvent(WPQSegmentNames.dateOfBirthClicked);
                    }}
                    onBlur={(e) => {
                      sendActionSegmentEvent(WPQSegmentNames.dateOfBirthFilled, { metaData: e.target.defaultValue });
                      onBlur();
                    }}
                    error={!!error}
                    helperText={error ? error?.message : null}
                  />
                </>
              );
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={!isValid || dobValue === '01/01/1900'} onClick={handleClick}>
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>

      <LegalModal show={isOpen} hideModal={modalToggle} />
    </Container>
  );
};

export const LegalContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 8px;
`;

export default PersonalInfo;
