import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import { InputAdornment } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';

import { theme } from 'config/theme';
import { useSegment } from 'lib/hooks/useSegment';
import { CustomFormControlLabel } from 'pages/Survey/Survey';
import { CA_HOUSE_RENT_QUESTIONS, TRACK_VERSION, WEBPREQUAL } from 'lib/constants';

import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { Box, Button, Container, Icon, NumberFormatInput, Subtitle, TextField, Title } from 'lib/components';
import InfoModal from 'pages/CherryAccess/AdditionalInfo/modals/InfoModal';
import { useTranslation } from 'react-i18next';
import useStore from 'lib/hooks/useStore';
import { isMissingInfoNextButtonDisabled } from 'lib/utils/IsMissingInfoNextButtonDisabled';

export const HouseRent = ({ saveHouseRent, saveHouseSituation }) => {
  const { t: translate } = useTranslation();
  const { sessionApiData } = useStore();
  const { cherryAccess, missingInfo } = sessionApiData || {};
  const initialHousingPaymentInfo = missingInfo?.find((data) => data?.key === 'HOUSING_PAYMENT');
  const initialHousingSituationInfo = missingInfo?.find((data) => data?.key === 'HOUSING_SITUATION');

  const [showModal, setShowModal] = useState(false);
  const [houseRent, setHouseRent] = useState<number | null>(initialHousingPaymentInfo?.value);
  const [radioValue, setRadioValue] = useState<string | null>(initialHousingSituationInfo?.value);

  const [isHousingPaymentMissing, setIsHousingPaymentMissing] = useState<boolean | null>(null);
  const [isHousingSituationMissing, setIsHousingSituationMissing] = useState<boolean | null>(null);

  useEffect(() => {
    if (!initialHousingPaymentInfo?.value || !initialHousingSituationInfo?.value) {
      const housePayment = cherryAccess?.statedInfos?.find((info) => info?.type === 'HOUSING_PAYMENT');
      setHouseRent(housePayment?.value);

      const houseSituation = cherryAccess?.statedInfos?.find((info) => info?.type === 'HOUSING_SITUATION');
      setRadioValue(houseSituation);

      const situation = missingInfo?.find((data) => data?.key === 'HOUSING_SITUATION');
      const payment = missingInfo?.find((data) => data?.key === 'HOUSING_PAYMENT');

      const _isHousingSituationMissing = (situation && situation?.isCompleted === false) || false;
      const _isHousingPaymentMissing = (payment && payment?.isCompleted === false) || false;

      setIsHousingSituationMissing(!!_isHousingSituationMissing);
      setIsHousingPaymentMissing(!!_isHousingPaymentMissing);
    }
  }, []);

  const { trackSegmentEvent } = useSegment();

  useEffect(() => {
    trackSegmentEvent('cherry_access_housing_cost_viewed', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
    });
  }, []);

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (houseRent !== null && radioValue) {
        setShowModal(true);
      }
    }
  };

  const onAmountApproved = () => {
    if (isHousingPaymentMissing && isHousingSituationMissing) {
      saveHouseRent(String(houseRent));
      saveHouseSituation(radioValue);
    } else if (isHousingSituationMissing) {
      saveHouseSituation(radioValue);
    } else if (isHousingPaymentMissing) {
      saveHouseRent(String(houseRent));
    } else {
      saveHouseRent(String(houseRent));
      saveHouseSituation(radioValue);
    }
  };

  const hideModal = () => setShowModal(false);

  const next = () => {
    if (radioValue && houseRent !== null && (houseRent < 100 || houseRent > 10000)) {
      setShowModal(true);
    } else {
      onAmountApproved();
    }
  };

  const onChange = (e) => {
    const value = e?.target?.value;
    const regex = /^[0-9\b]+$/;
    if (value && regex.test(value)) {
      setHouseRent(Number(value));
    } else {
      setHouseRent(null);
    }
  };

  const handleOptionChange = (e) => {
    setRadioValue(e?.target?.value);
  };

  const isNextButtonDisabled = isMissingInfoNextButtonDisabled(houseRent) || !radioValue;

  return (
    <Container showBackButton={true} backUrl={'approval-boost/additional-info/income'}>
      <ContentContainer>
        <Icon src={'home'} />
        <Title m={'10px 0px 8px 0px'}>{translate('cherryAccess.houseRent.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('cherryAccess.houseRent.subTitle')}</Subtitle>
        <Box>
          <TextField
            id="housing-cost"
            data-testid="housing-cost"
            data-neuro-label="housing-cost"
            variant="filled"
            label={translate('cherryAccess.houseRent.form.housingCostLabel')}
            type="tel"
            value={houseRent}
            onChange={onChange}
            onKeyDown={checkKeyDown}
            InputProps={{
              inputComponent: NumberFormatInput,
              pattern: '[^0-9.]',
              startAdornment: (
                <InputAdornment
                  sx={{
                    padding: '1px 0 0 0',
                    '& .MuiTypography-root': { color: '#000' },
                  }}
                  position="start"
                >
                  $
                </InputAdornment>
              ),
            }}
            disabled={isHousingSituationMissing && !isHousingPaymentMissing}
          />
        </Box>
        <Subtitle m={'24px 0px 8px 0px'}>{translate('cherryAccess.houseRent.form.title')}</Subtitle>
        <Box>
          <RadioGroup>
            {CA_HOUSE_RENT_QUESTIONS?.map((option, index) => {
              return (
                <CustomFormControlLabel
                  className={option?.value === radioValue ? 'checked' : ''}
                  data-testid={`question1-${index}-item`}
                  key={`house-question-${index}-item`}
                  value={option?.value ? option?.value : radioValue!}
                  checked={option?.value === radioValue}
                  control={
                    <Radio
                      onChange={handleOptionChange}
                      sx={{
                        color: `${theme.main.grayColor}`,
                        '&.Mui-checked': {
                          color: `${theme.main.green}`,
                        },
                      }}
                    />
                  }
                  label={option?.name}
                />
              );
            })}
          </RadioGroup>
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={next} disabled={isNextButtonDisabled}>
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
      {showModal && (
        <InfoModal
          show={showModal}
          hideModal={hideModal}
          onApproved={onAmountApproved}
          type={'HOUSING_RENTAL'}
          amount={houseRent}
        />
      )}
    </Container>
  );
};
