import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { TRACK_VERSION, WEBPREQUAL } from 'lib/constants';
import { CREATE_DATA_GATHERING_SURVEY } from 'lib/graphql/mutations';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { useTranslation } from 'react-i18next';

interface State {
  treatmentType: string;
}

const DataGathering = () => {
  const { trackPage } = useSegment();
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();
  const { captureException } = useSentry();
  const [createDataGatheringSurvey, { loading }] = useMutation(CREATE_DATA_GATHERING_SURVEY);

  useEffect(() => {
    trackPage('dataGathering', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
    });
  }, []);

  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
  } = useForm<State>({
    mode: 'onChange',
    defaultValues: {},
  });

  const onSubmit = async (formData: State) => {
    const { borrower, loan } = useStore.getState() || {};
    try {
      const surveyInput = {
        borrowerId: borrower?.id,
        loanId: loan?.id,
        answer: formData.treatmentType,
      };

      const { data } = await createDataGatheringSurvey({
        variables: { input: surveyInput },
      });

      const isSuccess = data?.createDataGatheringSurvey?.message === 'OK';

      if (isSuccess) {
        navigate('success');
      } else {
        captureException('Data Gathering Else Error', {
          error: 'Data Gathering Else Error',
          page: 'Data Gathering',
          message: 'Data Gathering Else Error',
          loanId: loan?.id || 'No Loan',
          borrowerId: borrower?.id || 'No Borrower',
        });
        navigate('success');
      }
    } catch (err) {
      captureException('Data Gathering Error', {
        error: err,
        page: 'Data Gathering',
        message: 'Data Gathering Error',
        loanId: loan?.id || 'No Loan',
        borrowerId: borrower?.id || 'No Borrower',
      });

      navigate('success');
    }
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <InnerContainer>
          <Icon src={'receipt-alt'} />
          <Title m={'10px 0px 8px 0px'}>{translate('dataGathering.title')}</Title>
          <Subtitle m={'0px 0px 24px 0px'}>{translate('dataGathering.subTitle')}</Subtitle>
          <Box>
            <Controller
              name="treatmentType"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                <TextField
                  id="treatmentType"
                  variant="filled"
                  data-testid="treatmentType"
                  data-neuro-label="treatmentType"
                  type="text"
                  label={translate('dataGathering.form.label')}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              )}
            />
          </Box>
        </InnerContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={!isValid || loading} loading={loading} onClick={handleSubmit(onSubmit)}>
          {translate('buttons.confirm')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default DataGathering;

const InnerContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
