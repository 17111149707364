import { useApolloClient, useMutation } from '@apollo/client';
import { PATCH_LOANS } from 'lib/graphql/mutations';

import { GET_PAYMENT_METHOD_FEES, GET_PAYMENT_METHODS } from 'lib/graphql/queries';
import { PaymentMethodSearch } from 'lib/graphql/searches';
import useStore from 'lib/hooks/useStore';
import * as Sentry from '@sentry/react';

export const usePlans = () => {
  const client = useApolloClient();
  const { setPaymentMethods, setPaymentMethodFees } = useStore();
  const [patchLoanRequest] = useMutation(PATCH_LOANS);

  const getPaymentMethods = async () => {
    const { borrower } = useStore.getState() || {};

    const { data } = await client.query({
      query: GET_PAYMENT_METHODS,
      variables: {
        input: {
          borrowerId: borrower?.id,
          search: PaymentMethodSearch,
        },
      },
    });

    if (data?.getPaymentMethods?.contents) {
      setPaymentMethods(data?.getPaymentMethods?.contents);
      return data?.getPaymentMethods;
    } else {
      Sentry.captureException('Get Payment Methods Error', {
        level: 'error',
        extra: {
          errorMessage: 'Get Payment Methods Error',
          page: 'src/pages/PlanReview/services/usePlans.tsx',
          id: borrower?.id || 'No Borrower',
          search: PaymentMethodSearch,
        },
      });
      return [];
    }
  };

  const getDraftedPaymentMethods = async () => {
    const { borrower } = useStore.getState() || {};

    const { data } = await client.query({
      query: GET_PAYMENT_METHODS,
      variables: {
        input: {
          borrowerId: borrower?.id,
        },
      },
    });

    if (data?.getPaymentMethods?.contents) {
      return data?.getPaymentMethods;
    }
    return [];
  };

  const getPaymentMethodFees = async () => {
    const { data } = await client.query({
      query: GET_PAYMENT_METHOD_FEES,
    });

    if (data?.getPaymentFees) {
      setPaymentMethodFees(data?.getPaymentFees);
      return data?.getPaymentFees;
    }
    return [];
  };

  const patchLoan = async (params) => {
    const { loanId, productId } = params || {};

    try {
      const { data } = await patchLoanRequest({ variables: { input: { ...params } } });

      return data?.patchLoan;
    } catch (err) {
      Sentry.captureException('Patch Loan Error', {
        level: 'error',
        extra: {
          errorMessage: 'Patch Loan Error',
          page: 'use Plans',
          path: 'src/pages/PlanReview/services/usePlans.tsx',
          id: loanId || 'No Id',
          productId: productId || 'No Product Id',
        },
      });
    }
  };

  return { getPaymentMethods, getDraftedPaymentMethods, patchLoan, getPaymentMethodFees };
};
