import { styled } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import { theme as CherryTheme } from 'config/theme';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const TextField = styled((props: any) => (
  <MuiTextField
    inputRef={props?.ref}
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    InputProps={{ disableUnderline: true, endAdornment: props?.endAdornment } as Partial<any>}
    {...props}
  />
))(({ theme }) => ({
  '& label': {
    color: '#dadada',
    fontSize: '18px',
  },
  '& label.Mui-focused': {
    color: CherryTheme.main.grayColor,
  },
  '& label.Mui-error': {
    color: '#d32f2f',
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
  '& .MuiFormLabel-filled': {
    color: CherryTheme.main.grayColor,
  },
  '& .MuiFilledInput-root': {
    height: 56,
    border: `1px solid ${CherryTheme.main.grayColor}`,
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: `${CherryTheme.main.white}}`,

    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: 'transparent',
      boxShadow: `0.25 0 0 0 2px`,
      borderColor: `${CherryTheme.main.grayColor}`,
    },
    '&.Mui-error': {
      borderColor: '#d32f2f',
    },
    input: {
      fontSize: '18px',
    },
  },
  '& .MuiFilledInput-input': {
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 30px #fff inset',
      WebkitTextFillColor: `${CherryTheme.main.grayColor}`,
    },
  },
  '& .MuiInputLabel-root': {
    fontSize: '18px',
    fontFamily: `Open Sans, sans-serif`,
  },
  '& .MuiInputLabel-input': {
    '&:focus': {
      outline: 1,
    },
  },
  '& input:invalid + fieldset': {
    borderColor: '#d32f2f',
    borderWidth: 2,
  },
}));
