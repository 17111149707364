import styled from 'styled-components';

import { Button, HaveQuestionsModal, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { theme } from 'config/theme';

const AlleRemoteCheckReview = ({
  renderBorrowerInfo,
  sessionData,
  loading,
  addPaymentMethodHandler,
  handleChooseAnotherMethod,
}) => {
  return (
    <ContentContainer>
      <PageTitle m={'10px 0 24px 0'} color={theme.main.black10} fontSize={'18px'} fontWeight={500} lineHeight={'32px'}>
        Check information verified
      </PageTitle>
      <SummaryContainer>
        <SummaryTextContainer>
          <SummaryText>Name - {renderBorrowerInfo()}</SummaryText>
          <SummaryText>Account - {sessionData?.accountNumber}</SummaryText>
          <SummaryText>Routing - {sessionData?.routingNumber}</SummaryText>
        </SummaryTextContainer>
      </SummaryContainer>
      <Paragraph m={'24px 0 0 0'}>
        Remember: it is your responsibility to send your checks to Cherry to make sure you are not late on your
        payments. Please send checks to:
        <CherryAddressContainer>
          <CherryAddressLine>Cherry Technologies Inc.</CherryAddressLine>
          <CherryAddressLine>DEPT 2258</CherryAddressLine>
          <CherryAddressLine>PO BOX 122258</CherryAddressLine>
          <CherryAddressLine>DALLAS, TX 75312-2258</CherryAddressLine>
        </CherryAddressContainer>
      </Paragraph>

      <Button
        disabled={loading}
        loading={loading}
        onClick={addPaymentMethodHandler}
        black={true}
        uppercase={true}
        thinText={true}
        fontWeight={400}
        fontSize={11}
        m="24px 0 0"
      >
        Add Payment Method
      </Button>
      <OutlinedButton onClick={handleChooseAnotherMethod}>Choose Another Method</OutlinedButton>
      <HaveQuestionsModal />
    </ContentContainer>
  );
};

const PageTitle = styled(Title)`
  font-family: 'Graphik', sans-serif;
`;

const OutlinedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.main.white};
  border: 1px solid ${(props) => props.theme.main.grayColor};
  box-sizing: border-box;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  font-family: 'Graphik', sans-serif !important;
  letter-spacing: 1px;
  margin-bottom: 24px;
`;

const Paragraph = styled(Subtitle)`
  font-family: 'Graphik', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${(props) => props.theme.main.alleBlack};
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 16px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.main.white};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.24);
`;

const SummaryTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const CherryAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;

const CherryAddressLine = styled.span``;

const SummaryText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.main.black20};
  opacity: 0.5;
  margin-bottom: 8px;
`;

export default AlleRemoteCheckReview;
