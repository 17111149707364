import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAlert } from 'react-alert';

import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { POLL_FINISHED, TRACK_VERSION, WEBPREQUAL } from 'lib/constants';
import { useSegment } from 'lib/hooks/useSegment';
import { emailPattern } from 'lib/utils';
import useStore from 'lib/hooks/useStore';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useServices } from 'pages/AuthChecker/services';
import { ApplicationFlowStatuses } from 'pages/AuthChecker/type';
import { UPDATE_BORROWER } from 'lib/graphql/mutations';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useTranslation } from 'react-i18next';

const EmailInfo = () => {
  const [isFormValid, setIsFormValid] = useState(false);
  const alert = useAlert();
  const { trackPage, trackSegmentEvent } = useSegment();
  const { createApplicationFlow } = useServices();
  const [updateBorrower] = useMutation(UPDATE_BORROWER);
  const [loading, setLoading] = useState(false);
  const { navigate } = useNavigation();
  const { startCheckApplicationStatus } = useSSEContext();
  const { t: translate } = useTranslation();
  const { setSessionData, applicationFlow, borrower } = useStore();
  const {
    control,
    formState: { isValid } = {},
    clearErrors,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const emailValue = watch('email');

  useEffect(() => {
    const { application, borrower } = useStore.getState() || {};
    trackPage('defaultHighLineEmailAddress', {
      application: WEBPREQUAL,
      applicationId: application?.id,
      borrowerId: borrower?.id,
    });
  }, []);

  useEffect(() => {
    trackSegmentEvent('default_high_line_email_viewed', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
    });
  }, []);

  const checkKeyDown = (e) => {
    if (e?.code === 'Enter' || Number(e.keyCode) === 13) {
      e?.preventDefault();
      if (isValid) {
        next();
      }
    }
  };

  const next = async () => {
    setLoading(true);
    if (applicationFlow?.status === ApplicationFlowStatuses.MISSING_EMAIL) {
      if (borrower?.id) {
        const { data } = await updateBorrower({
          variables: {
            input: {
              id: borrower?.id,
              email: emailValue,
              source: 'GREET',
            },
          },
        });
        if (data?.updateBorrower?.success) {
          const res = await createApplicationFlow();
          if (res?.success) {
            startCheckApplicationStatus({});
          }
        } else {
          if (['kyc.status.invalid', 'borrower.kyc_status.invalid'].includes(data?.updateBorrower?.message)) {
            startCheckApplicationStatus({ subscribeFlow: true, isExisting: true });
          }
          alert.info(translate('emailInfo.error.info'));
        }
      }
    } else {
      setSessionData({ email: emailValue });
      navigate('info');
    }
  };

  const onFormValueChange = (e, callback) => {
    clearErrors('email');
    const searchTerm = '@';
    const value = e?.target?.value;

    let isActive = false;
    if (value) {
      if (value.indexOf(searchTerm) >= 0 && emailPattern.test(value)) {
        isActive = true;
      }
    }
    setIsFormValid(isActive);
    callback(e);
  };

  return (
    <Container>
      <ContentContainer>
        <Icon src={'mail'} />
        <Title m={'10px 0px 8px 0px'}>{translate('emailInfo.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('emailInfo.subTitle')}</Subtitle>
        <Box>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: emailPattern,
                message: translate('emailInfo.error.rule'),
              },
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="email"
                  variant="filled"
                  data-testid="email"
                  data-neuro-label="email"
                  label={translate('emailInfo.form.label')}
                  type="text"
                  value={value}
                  onChange={(e) => onFormValueChange(e, onChange)}
                  onKeyDown={checkKeyDown}
                  onBlur={onBlur}
                  error={!!error}
                  helperText={error ? error?.message : null}
                />
              );
            }}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button
          onClick={next}
          disabled={!isFormValid || (!emailValue && emailValue !== '') || loading}
          loading={loading}
        >
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default EmailInfo;
