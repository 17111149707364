import { MAIL, PHONE_LINK, WEBPREQUAL } from 'lib/constants';
import { useBrowserHistory } from 'lib/hooks/useBrowserHistory';
import { useNavigation } from 'lib/hooks/useNavigation';
import StorageService from 'lib/services/Storage';
import { Redirect } from 'lib/utils';
import { useSegmentContext } from './Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';

export const useHelpModal = () => {
  const { navigate } = useNavigation();
  const { pageName, merchantSlug } = useBrowserHistory();
  const { sendActionSegmentEvent } = useSegmentContext();

  const onEmailPressed = (e) => {
    sendActionSegmentEvent(WPQSegmentNames.helpModalEmailClicked, {
      pageName: pageName || '',
      merchantSlug: merchantSlug || '',
    });
    Redirect(MAIL, '_self');
    e.preventDefault();
  };

  const onCallPressed = (e) => {
    sendActionSegmentEvent(WPQSegmentNames.helpModalCallClicked, {
      pageName: pageName || '',
      merchantSlug: merchantSlug || '',
    });

    Redirect(PHONE_LINK, '_self');
    e.preventDefault();
  };

  const logoutPressed = () => {
    StorageService.clearUserData();
    navigate('');
  };

  return { onEmailPressed, onCallPressed, logoutPressed };
};
