import { useEffect } from 'react';

import { WEBPREQUAL } from 'lib/constants';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import styled from 'styled-components';
import { MessageTitle } from '../FrozenCredit/FrozenCredit';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useTranslation } from 'react-i18next';

const PlaidPayrollComplete = () => {
  const { trackPage } = useSegment();
  const { t: translate } = useTranslation();
  const { application } = useStore.getState() || {};
  const { startCheckApplicationStatus } = useSSEContext();

  useEffect(() => {
    trackPage('paystubsSubmitted', {
      application: WEBPREQUAL,
      applicationId: application?.id,
    });
  }, []);

  const goToOriginalApproval = async () => {
    startCheckApplicationStatus({ subscribeFlow: true, applicationId: application?.id });
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <InnerContainer>
          <Icon src={'approved'} />
          <Title m={'10px 0px 0px 0px'}>{translate('plaidPayrollComplete.title')}</Title>
          <Subtitle m={'10px 0px 24px 0px'}>{translate('plaidPayrollComplete.subTitle')}</Subtitle>

          <MessageTitle>{translate('plaidPayrollComplete.info.title1')}</MessageTitle>
          <Subtitle m={'0px 0px 24px 0px'}>{translate('plaidPayrollComplete.info.message1')}</Subtitle>

          <MessageTitle>{translate('plaidPayrollComplete.info.title2')}</MessageTitle>
          <Subtitle m={'0px 0px 24px 0px'}>{translate('plaidPayrollComplete.info.message2')}</Subtitle>
        </InnerContainer>
        {application?.mickeyMouse ? (
          <Button secondary={true} onClick={goToOriginalApproval}>
            {translate('buttons.goToOriginalApproval')}
          </Button>
        ) : null}
      </ContentContainer>
    </Container>
  );
};

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default PlaidPayrollComplete;
