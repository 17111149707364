import React from 'react';
import styled from 'styled-components';

import { Button, HaveQuestionsModal, Icon, RadioButtonGroup, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';

enum RadioButtonEnums {
  PAYROLL = 'payroll',
  PAYSTUBS = 'paystubs',
}

const AlleUploadPaystub = ({
  uploadPayrollHandler,
  uploadPaystubHandler,
  addBankAccountHandler,
  onChangeHandler,
  titleText,
  isSelectedEqualToPayStub,
  isSelectedEqualToPayroll,
  loading,
  radioValue,
}) => {
  return (
    <Container>
      <InnerContainer>
        <Title m={'10px 0px 8px 0px'} textAlign="center" fontFamily="Petersburg">
          {titleText()}
        </Title>
        <Subtitle fontSize="16px" m={'0px 0px 30px 0px'} textAlign="center" fontFamily="Petersburg">
          Instead, you can connect your payroll provider or submit paystubs for an additional review.
        </Subtitle>

        <Subtitle fontSize="16px" m={'0px 0px 10px 0px'} textAlign="center" fontFamily="Petersburg">
          What would you like to do?
        </Subtitle>
        <RadioButtonGroup
          onChange={onChangeHandler}
          value={radioValue}
          radioButtonOptions={[
            {
              textValue: RadioButtonEnums?.PAYROLL,
              radioLabel: 'Connect to My Payroll',
              subText: '2-4 business hours to review',
              isPreferred: true,
            },
            {
              textValue: RadioButtonEnums?.PAYSTUBS,
              radioLabel: 'Submit My Last 2 Paystubs',
              subText: 'up to 24 hours to review',
            },
          ]}
          size={'large'}
          type="secondary"
        />
      </InnerContainer>
      {isSelectedEqualToPayStub && (
        <InnerContainer>
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_outline'} />
            <InfoText>
              Submitting paystubs includes an additional review, which can take up to <strong>24 hours.</strong> If
              paystubs are incomplete, this may add additional delays. We recommend trying to connect your payroll
              provider before submitting paystubs.
            </InfoText>
          </InfoContainer>
        </InnerContainer>
      )}

      {isSelectedEqualToPayroll && (
        <InnerContainer>
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_outline'} />
            <InfoText>
              Connecting payroll includes an additional review, which is typically completed within{' '}
              <strong>2-4 business</strong> hours.
            </InfoText>
          </InfoContainer>
        </InnerContainer>
      )}
      {isSelectedEqualToPayStub && (
        <Button
          disabled={loading}
          loading={loading}
          onClick={uploadPaystubHandler}
          m="32px 0 0"
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
        >
          Submit Paystubs
        </Button>
      )}

      {isSelectedEqualToPayroll && (
        <Button
          disabled={loading}
          loading={loading}
          onClick={uploadPayrollHandler}
          m="32px 0 0"
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
        >
          Connect Payroll
        </Button>
      )}

      <ButtonText onClick={addBankAccountHandler}>Connect My Bank Account Instead</ButtonText>
      <HaveQuestionsModal />
    </Container>
  );
};

export default AlleUploadPaystub;

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: 'Graphik';
`;

const ButtonText = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  text-align: center;
  text-decoration-line: underline;
  color: #090909;
  margin: 24px 0;
  cursor: pointer;
  font-family: Graphik;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

const InfoText = styled.span<{ textAlign?: string }>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  font-family: Graphik;
  text-align: left;
`;
