import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styled from 'styled-components';

import { Icon } from 'lib/components';
import { theme } from 'config/theme';
import { useTranslation } from 'react-i18next';

interface RadioButtonItem {
  textValue: string;
  radioLabel: string;
  subText?: string;
  isPreferred?: boolean;
}

interface Props {
  value: string;
  label?: string;
  onChange?: (event: { target: { value: string } }) => void;
  size?: string;
  radioButtonOptions: RadioButtonItem[];
  type?: string;
}

export const RadioButtonGroup = ({
  value,
  onChange,
  label,
  size = 'auto',
  radioButtonOptions,
  type = 'primary',
}: Props) => {
  const { t: translate } = useTranslation();

  const radioSxObject = {
    '&.Mui-checked': {
      color: type === 'primary' ? theme.main.green : '#9A6B5E',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 30,
    },
  };

  const radioContainerClicked = (text: string) => {
    onChange && onChange({ target: { value: text } });
  };

  const renderRadioButtons = (radioButtonItem: RadioButtonItem) => {
    const { textValue, radioLabel, subText, isPreferred } = radioButtonItem;

    const handleClick = () => {
      radioContainerClicked(textValue);
    };

    return (
      <RadioContainer
        type={type}
        data-value={textValue}
        selected={value === textValue}
        data-testid="radio-true"
        onClick={handleClick}
        size={size}
        key={textValue}
      >
        <Radio
          name="radio-button-true"
          inputProps={{ 'aria-label': 'A' }}
          value={textValue}
          sx={radioSxObject}
          checkedIcon={
            <Icon width={16} height={16} src={type === 'primary' ? 'checkedRadio' : 'alleCheckedRadio'} hover={true} />
          }
          icon={<Icon width={16} height={16} src={type === 'primary' ? 'radio' : 'alleRadio'} hover={true} />}
        />
        <RadioLabel size={size} selected={value === textValue} type={type}>
          {radioLabel}
          {subText && (
            <>
              <br />
              <SubTextContainer type={type}>{subText}</SubTextContainer>
            </>
          )}
          {type === 'secondary' && isPreferred && (
            <PreferredContainer type={type}>{translate('radioButtonGroup.preferred')}</PreferredContainer>
          )}
        </RadioLabel>
        {type === 'primary' && isPreferred && (
          <PreferredContainer type={type}>{translate('radioButtonGroup.preferred')}</PreferredContainer>
        )}
      </RadioContainer>
    );
  };

  return (
    <CustomRadioGroup value={value} onChange={onChange} label={label}>
      {label && <Label>{label}</Label>}
      <InnerContainer size={size}>
        {radioButtonOptions?.map((radioButtonItem: RadioButtonItem) => renderRadioButtons(radioButtonItem))}
      </InnerContainer>
    </CustomRadioGroup>
  );
};

const CustomRadioGroup = styled(RadioGroup)<{ label?: string }>`
  width: 100% !important;
  margin-bottom: 8px !important;
  margin-top: ${(props) => (props.label ? '8px' : '0')};
`;

const InnerContainer = styled.div<{ size?: string }>`
  display: flex;
  flex-direction: ${(props) => (props?.size === 'large' ? 'column' : 'row')};
  gap: ${(props) => (props?.size === 'large' ? '0' : '8px')};
  width: ${(props) => (props?.size === 'large' ? '100%' : 'auto')};
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.span`
  display: flex;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.main.grayColor};
`;

const RadioLabel = styled.span<{ selected?: boolean; size?: string; type: string }>`
  font-weight: ${(props) => (props.selected ? '700' : '400')};
  color: ${(props) => (props.selected ? theme.main.midnightBlue : theme.main.grayColor)};
  font-size: ${(props) => (props?.type === 'primary' ? 14 : 16)}px;
  line-height: 19px;
  margin-left: 7px;
  width: ${(props) => (props?.size === 'large' ? '100%' : 'auto')};
  ${(props) => (props?.type === 'secondary' ? 'display:block;text-align:left;font-family:Graphik' : '')}
`;

const RadioContainer = styled.div<{ selected?: boolean; size?: string; padding?: string; type }>`
  height: 56px;
  display: flex;
  flex: 1;
  align-items: center;
  padding: ${(props) => (props?.size === 'large' ? '10px 7px' : '5px 7px')};
  border: ${(props) =>
    props.selected
      ? `2px solid ${props?.type === 'primary' ? theme.main.green : '#9A6B5E'}`
      : `1px solid ${theme.main.grayColor}`};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-radius: 4px !important;
  color: ${(props) => props?.theme?.main?.borderColor};
  cursor: pointer;
  width: ${(props) => (props?.size === 'large' ? '100%' : 'auto')};
  margin-bottom: ${(props) => (props.type === 'primary' ? 8 : 16)}px;
  background-color: ${(props) => (props?.type === 'primary' ? 'transparent' : '#f9f3f1')};
`;

const SubTextContainer = styled.span<{ type: string }>`
  font-weight: normal;
  ${(props) =>
    props.type === 'secondary' ? 'margin: 8px 0; display: block; font-size: 14px; font-family: Graphik' : ''}
`;

const PreferredContainer = styled.div<{ type: string }>`
  font-size: 10px;
  line-height: 10.89px;
  color: ${(props) => (props.type === 'primary' ? theme.main.green : theme.main.mutedRedBrown)};
  background: ${(props) => (props?.type === 'primary' ? '#e6f9f2' : 'transparent')};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 4px 6px 5px;
  gap: 4px;
  border-radius: ${(props) => (props?.type === 'primary' ? 16 : 4)}px;
  ${(props) => (props.type === 'secondary' ? 'border: 1px solid #9A6B5E;display:table' : '')}
`;
