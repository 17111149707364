import { useApolloClient, useMutation } from '@apollo/client';

import {
  ALIGN_DUE_DATES,
  PATCH_MERCHANT_PREFERENCE,
  POST_APPLICATION,
  POST_APPLICATION_FLOW,
} from 'lib/graphql/mutations';
import {
  FETCH_AUTH_INFO,
  GET_APPLICATION,
  GET_BORROWER_DETAIL,
  GET_FUNDED_CONTRACTS,
  GET_LOAN,
  GET_PAYMENT_METHODS,
} from 'lib/graphql/queries';
import useStore, { LanguageEnum } from 'lib/hooks/useStore';

import { APPLICATION_ALREADY_EXIST, BORROWER_INIT, PARTNER_API, PARTNER_LINK, WPQ } from 'lib/constants';
import { StatedInfo } from '../type';
import { useSentry } from 'lib/hooks/useSentry';

import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import * as Sentry from '@sentry/react';
import { PaymentMethodSearch } from 'lib/graphql/searches';

export const useServices = () => {
  const client = useApolloClient();
  const { captureException, setTag } = useSentry();

  const {
    setLoan,
    setUser,
    setFeatures,
    setOrganization,
    setLocations,
    setApplication,
    setBorrower,
    organization,
    setPaymentMethods,
    defaultLanguage,
    setDefaultLanguage,
  } = useStore();
  const { startCheckApplicationStatus } = useSSEContext();

  const [postApplication] = useMutation(POST_APPLICATION);
  const [postApplicationFlow] = useMutation(POST_APPLICATION_FLOW);
  const [patchLocationPreference] = useMutation(PATCH_MERCHANT_PREFERENCE);

  const getApplication = async (applicationId: string) => {
    const { data } = await client.query({
      query: GET_APPLICATION,
      variables: {
        input: {
          applicationId,
        },
      },
    });
    if (data?.getApplication?.id) {
      setApplication(data?.getApplication);
    }
    return data?.getApplication;
  };

  const getLoan = async (loanId: string) => {
    const { data } = await client.query({
      query: GET_LOAN,
      variables: {
        input: {
          loanId,
        },
      },
    });

    if (data?.getLoan?.id) {
      setLoan(data?.getLoan);
    } else {
      Sentry.captureException('Get Loan Error', {
        level: 'error',
        extra: {
          errorMessage: 'Get Loan Error',
          page: 'user Services',
          id: loanId || 'No Id',
        },
      });
    }
    return data?.getLoan;
  };

  const getBorrower = async (borrowerId: string) => {
    const { data } = await client.query({
      query: GET_BORROWER_DETAIL,
      variables: {
        input: {
          id: borrowerId,
        },
      },
    });

    setBorrower(data?.getBorrowerDetail?.data);
    const borrowerLang = LanguageEnum[data?.getBorrowerDetail?.data?.language];
    if (defaultLanguage !== borrowerLang) {
      setDefaultLanguage(borrowerLang);
    }
    return data?.getBorrowerDetail?.data;
  };

  const getBorrowerPaymentMethods = async () => {
    const { borrower } = useStore.getState() || {};

    const { data } = await client.query({
      query: GET_PAYMENT_METHODS,
      variables: {
        input: {
          borrowerId: borrower?.id,
          search: PaymentMethodSearch,
        },
      },
    });

    if (data?.getPaymentMethods?.contents) {
      setPaymentMethods(data?.getPaymentMethods?.contents);
      return data?.getPaymentMethods;
    } else {
      Sentry.captureException('Get Payment Methods Error', {
        level: 'error',
        extra: {
          errorMessage: 'Get Payment Methods Error',
          page: 'src/pages/Plans/services/usePlans.tsx',
          id: borrower?.id || 'No Borrower',
          search: PaymentMethodSearch,
        },
      });
      return [];
    }
  };

  const patchLocationPreferenceHandler = async (data) => {
    try {
      const { selectedLocation } = useStore.getState() || {};
      if (selectedLocation?.id) {
        await patchLocationPreference({
          variables: { input: { applicationId: data?.postApplication?.id, merchantId: selectedLocation.id } },
        });
      }
    } catch (err) {
      return false;
    }
  };

  const createApplication = async () => {
    try {
      const { utmSource, utmMedium, borrower, type, partner, sessionData, isAlle } = useStore.getState() || {};

      const organizationId = organization?.id || useStore.getState()?.organization?.id;

      const channel = type === PARTNER_LINK ? PARTNER_API : WPQ;

      const params = {
        borrowerId: borrower?.id,
        organizationId,
        channel,
        partner,
        referralSource: utmSource,
        referralMedium: utmMedium,
        treatmentAmount: sessionData?.treatmentAmount,
      };

      if (!partner) {
        delete params.partner;
      }

      if (!utmSource) {
        delete params.referralSource;
      }

      if (!utmMedium) {
        delete params.referralMedium;
      }

      if (!sessionData?.treatmentAmount) {
        delete params.treatmentAmount;
      }

      const { data } = await postApplication({
        variables: {
          input: params,
        },
      });

      if (data?.postApplication?.id) {
        patchLocationPreferenceHandler(data);
        setApplication(data?.postApplication);
        if (isAlle) return;
        startCheckApplicationStatus({ applicationId: data?.postApplication?.id, isExisting: true });
      }
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err?.error?.message === APPLICATION_ALREADY_EXIST) {
        const { application } = useStore.getState() || {};
        startCheckApplicationStatus({ applicationId: application?.id });
      } else {
        captureException(err, {
          message: 'useServices hook createApplication error',
          error: err,
        });
      }
    }
  };

  const getAuthInfo = async () => {
    const { data } = await client.query({
      query: FETCH_AUTH_INFO,
      context: {
        headers: {
          extra: BORROWER_INIT,
        },
      },
    });

    setUser(data?.getUserAuthInfo?.user);
    setLocations(data?.getUserAuthInfo?.locations);
    setFeatures(data?.getUserAuthInfo?.features);
    setOrganization(data?.getUserAuthInfo?.organization);

    setTag('user', data?.getUserAuthInfo?.user?.id);

    return data?.getUserAuthInfo;
  };

  const createApplicationFlow = async (statedInfos?: StatedInfo[], treatmentType?: string) => {
    const { application } = useStore.getState() || {};
    const applicationId = application?.id;

    const { data } = await postApplicationFlow({
      variables: {
        input: {
          applicationId,
          ...(statedInfos && statedInfos?.length > 0 && { statedInfos }),
          ...(treatmentType && { treatmentType }),
        },
      },
    });

    return data?.postApplicationFlow;
  };

  const getFundedContracts = async () => {
    try {
      const { borrower } = useStore.getState() || {};

      const {
        data: { fetchLoanListForApplication },
      } = await client.query({
        query: GET_FUNDED_CONTRACTS,
        variables: {
          input: {
            idBorrower: borrower?.id,
          },
        },
      });

      return fetchLoanListForApplication;
    } catch (error) {
      return null;
    }
  };

  const alignDueDates = async (alignmentDate: string) => {
    try {
      const { borrower } = useStore.getState() || {};

      const {
        data: { alignDueDates },
      } = await client.mutate({
        mutation: ALIGN_DUE_DATES,
        variables: {
          input: {
            borrowerId: borrower?.id,
            alignmentDate,
          },
        },
      });

      return alignDueDates;
    } catch (error) {
      return null;
    }
  };

  return {
    getAuthInfo,
    getApplication,
    getLoan,
    getBorrower,
    createApplication,
    createApplicationFlow,
    getFundedContracts,
    alignDueDates,
    getBorrowerPaymentMethods,
  };
};
