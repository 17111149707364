import * as Sentry from '@sentry/react';
import { APP_ENV } from 'config';
import { Application } from 'pages/AuthChecker/type';

export enum AnalyticEventNames {
  WELCOME_APPLY = 'WELCOME_APPLY',
  WELCOME_CONTINUE = 'WELCOME_CONTINUE',
  CHECKLIST_CONTINUE = 'CHECKLIST_CONTINUE',
  PI_SEND_SEC_CODE = 'PI_SEND_SEC_CODE',
  PI_BORROWER_NOT_FOUND = 'PI_BORROWER_NOT_FOUND',
  CV_SECURITY_INP_BLUR = 'CV_SECURITY_INP_BLUR',
  CV_DIDNT_GET_CODE = 'CV_DIDNT_GET_CODE',
  CV_WRONG_CODE = 'CV_WRONG_CODE',
  CV_VERIFY = 'CV_VERIFY',
  BI_ESIGN = 'BI_ESIGN',
  BI_SEE_IF_QUALIFY = 'BI_SEE_IF_QUALIFY',
  SSN_CONTINUE = 'SSN_CONTINUE',
  SSN_CANT_VERIFY = 'SSN_CANT_VERIFY',
  CWP_LINK_ACCOUNT = 'CWP_LINK_ACCOUNT',
  CWP_CHECK = 'CWP_CHECK',
  RES_AMOUNT_BLUR = 'RES_AMOUNT_BLUR',
  RES_AMOUNT_ERROR = 'RES_AMOUNT_ERROR',
  RES_VIEW_OPTIONS = 'RES_VIEW_OPTIONS',
  PLAN_PICK = 'PLAN_PICK',
  PLAN_REVIEW = 'PLAN_REVIEW',
  PLAN_EDIT_AMOUNT = 'PLAN_EDIT_AMOUNT',
  PLAN_REVIEW_CONFIRM = 'PLAN_REVIEW_CONFIRM',
  PR_CHOOSE_ANOTHER = 'PR_CHOOSE_ANOTHER',
  PM_DEBIT = 'PM_DEBIT',
  PM_PLAID_CHECKING = 'PM_PLAID_CHECKING',
  PM_CONTINUE = 'PM_CONTINUE',
  CONF_EXPAND = 'CONF_EXPAND',
  CONF_MODAL_CLOSE = 'CONF_MODAL_CLOSE',
  CONF_CONTINUE = 'CONF_CONTINUE',
  GO_TO_PORTAL = 'GO_TO_PORTAL',
  SSN_INP_BLUR = 'SSN_INP_BLUR',
  PI_PHONE_BLUR = 'PI_PHONE_BLUR',
  INCOME_THREE_MONTHS = 'INCOME_THREE_MONTHS',
  INCOME_DEPOSIT_INCOME = 'INCOME_DEPOSIT_INCOME',
  INCOME_HUNDRED_DOLLAR_MONTH = 'INCOME_HUNDRED_DOLLAR_MONTH',
  LOCATION_SELECT_ONCHANGE = 'LOCATION_SELECT_ONCHANGE',
  PREVIEW_MY_PAYMENTS = 'PREVIEW_MY_PAYMENTS',
  USE_MY_APPROVAL = 'USE_MY_APPROVAL',
  PHONE_NOT_MATCHING_WITH_LOAN = 'PHONE_NOT_MATCHING_WITH_LOAN',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
}

export const useAnalytics = () => {
  const trackEvent = (eventName: AnalyticEventNames, eventLabel?: string, eventValue?: unknown) => {
    try {
      // @ts-ignore gtag
      window?.gtag('event', eventName, {
        event_category: APP_ENV || '',
        event_label: eventLabel || null,
        event_action: '',
        event_value: eventValue || null,
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const applicationStatusUpdated = (application: Application) => {
    try {
      if (application && application?.status) {
        switch (application?.status) {
          case 'APPROVED':
            // @ts-ignore fbq
            window?.fbq('track', 'Purchase', {
              value: application?.maxEligible,
              currency: 'USD',
            });
            break;

          case 'DENIED':
            // @ts-ignore fbq
            window?.fbq('track', 'Purchase', {
              value: 0.0,
              currency: 'USD',
            });
            break;
          default:
            break;
        }
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  return { trackEvent, applicationStatusUpdated };
};
