import { APP_ENV } from 'config';
import {
  CUSTOMER_PORTAL,
  CUSTOMER_PORTAL_CRB,
  CUSTOMER_PORTAL_DEV,
  CUSTOMER_PORTAL_STG,
  CUSTOMER_PORTAL_UAT,
  PATIENT_PORTAL,
} from 'lib/constants';

export const Redirect = (link: string, target = '_blank') => {
  window?.open(link, target);
};

export const redirectToCustomerPortal = (isAlle = false) => {
  const url = `/portal/account`;
  const target = isAlle ? '_self' : '_blank';
  switch (APP_ENV) {
    case 'dev':
      Redirect(`${CUSTOMER_PORTAL_DEV}${url}`, target);
      break;
    case 'staging':
      Redirect(`${CUSTOMER_PORTAL_STG}${url}`, target);
      break;
    case 'uat':
      Redirect(`${CUSTOMER_PORTAL_UAT}${url}`, target);
      break;
    case 'crb':
      Redirect(`${CUSTOMER_PORTAL_CRB}${url}`, target);
      break;
    default:
      Redirect(`${CUSTOMER_PORTAL}${url}`, target);
      break;
  }
};

export const redirectToPaymentPlans = () => {
  const pathName = 'redirectUrl=portal/purchases';
  Redirect(`${PATIENT_PORTAL}/?${pathName}`, '_self');
};
