import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { theme } from 'config/theme';

import { Button, HaveQuestionsModal, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { IframeEventActions, IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';

dayjs.extend(utc);
dayjs.extend(timezone);

const AlleApproval = () => {
  const [loading, setLoading] = useState(false);
  const { pushEvent } = useIframeEvents();

  useEffect(() => {
    pushEvent(IframeEventTypes.PAGE, PageEventActions.COMPLETE);
  }, []);

  const closeIframe = () => {
    setLoading(true);
    pushEvent(IframeEventTypes.IFRAME, IframeEventActions.CLOSE);
  };

  return (
    <Container>
      <Title
        color={theme.main.black10}
        fontSize="24px"
        fontWeight={400}
        lineHeight="32px"
        useElegantFontType={true}
        textAlign="center"
      >
        Application complete
      </Title>
      <Subtitle m="16px 0" textAlign="center" color={theme.main.black20} lineHeight="24px" fontSize="16px">
        Your application is complete. Please return to the Allē Wallet to see your result.
      </Subtitle>
      <ButtonContainer>
        <Button
          onClick={closeIframe}
          m="32px 0 0"
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
          loading={loading}
        >
          Return To ALLĒ Wallet
        </Button>
      </ButtonContainer>
      <HaveQuestionsModal />
    </Container>
  );
};

export default AlleApproval;

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: 'Graphik';
`;

const ButtonContainer = styled.div`
  margin-bottom: 32px;
`;
