import useStore from 'lib/hooks/useStore';

import { CallCherry, Redirect } from 'lib/utils';

import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';

import { MessageTitle } from '../FrozenCredit/FrozenCredit';
import AlleCancelled from './AlleCancelled';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { WEB_SITE } from 'lib/constants';

const Cancelled = () => {
  const { t: translate } = useTranslation();
  const { organization, isAlle } = useStore();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  const { sessionApiData } = useStore.getState();
  const { featureFlags } = sessionApiData || {};
  const isNewDenialUi = featureFlags?.isNewDenialUi || false;

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.purchaseCanceledLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  if (isAlle) {
    return <AlleCancelled />;
  }

  const callToCherry = () => {
    sendActionSegmentEvent(WPQSegmentNames.purchaseCancelCallClicked);
    CallCherry();
  };

  const VisitCherryPage = () => {
    sendActionSegmentEvent(WPQSegmentNames.purchaseCancelWebsiteClicked);
    Redirect(WEB_SITE);
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <Icon src={'warning_outline'} />
        <Title m={'10px 0px 8px 0px'}>{translate('cancelled.title')}</Title>
        <Subtitle m={'0px 0px 8px 0px'}>{translate('cancelled.subTitle')}</Subtitle>

        <MessageTitle>{translate('cancelled.message.title')}</MessageTitle>
        <Subtitle m={'0px 0px 24px 0px'}>
          {translate('cancelled.message.subTitle', {
            name: organization?.name,
          })}
        </Subtitle>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={callToCherry} secondary={true} icon={'phone'}>
          {translate(isNewDenialUi ? 'buttons.newCall' : 'buttons.call')}
        </Button>
        <Button onClick={VisitCherryPage} secondary={true} icon={'visit'}>
          {translate('buttons.website')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default Cancelled;
