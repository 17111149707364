import { useCallback, useEffect, useState } from 'react';

export const useViewport = () => {
  const [width, setWidth] = useState(window?.innerWidth);
  const [height, setHeight] = useState(window?.innerWidth);
  const handleWindowSizeChange = (e) => {
    setWidth(window?.innerWidth);
    setHeight(window?.innerHeight);
  };

  const handleWindowSizeChangeCallback = useCallback((e) => handleWindowSizeChange(e), []);

  useEffect(() => {
    window?.addEventListener('resize', handleWindowSizeChangeCallback);
    return () => {
      window?.removeEventListener('resize', handleWindowSizeChangeCallback);
    };
  }, []);

  return { isMobile: width <= 480, screenWidth: width, screenHeight: height };
};
