import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';

import { Button, Container, DemoMode, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useInitializeAuth } from 'pages/AuthChecker/services/useInitializeAuth';
import { FormatAmount } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import { APP_ENV } from 'config';

const PosLanding = () => {
  const { navigate } = useNavigation();
  const { initializeAuth } = useInitializeAuth();
  const { t: translate } = useTranslation();
  const { organization, setPageIndex, isDemo, sessionData } = useStore();

  useEffect(() => {
    setPageIndex(0);
  }, [setPageIndex]);

  const RenderTitle = () => {
    return (
      <Helmet>
        <title>
          {translate('posLanding.helmetTitle', {
            name: organization?.name,
          })}
        </title>
      </Helmet>
    );
  };

  const listItem = [
    {
      id: 'bank-issued-card',
      icon: 'credit_card_black',
      text: translate('posLanding.listText1'),
    },
    {
      id: 'valid-age',
      icon: 'cherry-logo',
      text: translate('posLanding.listText2'),
    },
  ];

  const navigateToPosQualify = () => {
    navigate(`pos-qualify`);
  };

  return (
    <>
      {(isDemo || APP_ENV === 'sandbox') && <DemoMode />}
      <Container showBackButton={false}>
        <ContentContainer justify="space-between">
          <InnerContainer>
            <RenderTitle />
            <Icon src={'cherry-logo'} />
            <Title m={'10px 0px 0px 0px'} data-testid="title">
              {translate('posLanding.title')}
            </Title>
            <Subtitle m={'10px 0px 24px 0px'}>
              {translate('posLanding.subTitle', {
                treatmentAmount: FormatAmount(sessionData?.treatmentAmount),
              })}
            </Subtitle>
            {listItem?.map((item) => {
              const { id, icon, text } = item || {};
              return (
                <Flex key={id} margin="0 0 24px 0">
                  <Icon src={icon} width={32} height={32} />
                  <Span dangerouslySetInnerHTML={{ __html: text }} />
                </Flex>
              );
            })}
          </InnerContainer>
        </ContentContainer>
        <ButtonContainer>
          <Button onClick={navigateToPosQualify}>{translate('buttons.continue')}</Button>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default PosLanding;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Flex = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  margin: ${(props) => props?.margin || 0};
`;

const Span = styled.span`
  color: ${(props) => props.theme.main.primary};
  margin-left: ${(props) => props.theme.constants.s};
`;
