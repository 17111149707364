import { useMutation } from '@apollo/client';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { WEBPREQUAL } from 'lib/constants';
import { BORROWER_NOTIFY_ID_REVIEW } from 'lib/graphql/mutations';
import { useCognito } from 'lib/hooks/useCognito';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useSegment } from 'lib/hooks/useSegment';
import useStore from 'lib/hooks/useStore';
import { useSentry } from 'lib/hooks/useSentry';

import { Container, Loading } from 'lib/components';
import AlleCognito from 'pages/IdReview/AlleCognito';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';

interface OpenEvent {
  topic: 'ui';
  action: 'opened';
}

interface CloseEvent {
  topic: 'ui';
  action: 'closed';
}

const IdReviewConnect = () => {
  const { createFlow } = useCognito();
  const { navigate } = useNavigation();
  const { startCheckApplicationStatus } = useSSEContext();
  const { captureException } = useSentry();
  const { trackPage, trackSegmentEvent } = useSegment();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  const [notifyIdReviewRequest] = useMutation(BORROWER_NOTIFY_ID_REVIEW);
  const { isAlle, applicationFlow, reviewOutcome, setSessionApiData } = useStore();

  let flow;
  let isFlowCompleted = false;

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.idReviewCognitoLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    const borrowerId = applicationFlow?.borrowerId ?? useStore.getState()?.applicationFlow?.borrowerId;
    const identityVerificationId = reviewOutcome?.id ?? useStore.getState()?.reviewOutcome?.id;
    const templateId = reviewOutcome?.templateId ?? useStore.getState()?.reviewOutcome?.templateId;

    if (!isAlle) {
      if (borrowerId && identityVerificationId && templateId) {
        flow = createFlow({
          borrowerId: borrowerId,
          identityVerificationId: identityVerificationId,
          templateId: templateId,
        });

        flow?.on('session', async (event) => {
          trackSegmentEvent('cognito_auth', {
            application: WEBPREQUAL,
            events: event,
          });

          switch (event.action) {
            case 'passed':
            case 'failed':
              sendActionSegmentEvent(WPQSegmentNames.idVerificationCognitoCompleted);
              isFlowCompleted = true;
              setSessionApiData({
                isCognitoCompleted: true,
              });

              const {
                data: { notifyIdReview },
              } = await notifyIdReviewRequest({
                variables: { input: { borrowerId: applicationFlow?.borrowerId } },
              });

              if (notifyIdReview?.success) {
                flow?.close();
                await startCheckApplicationStatus({});
              }
              break;
          }
        });
        flow?.on('ui', (event: OpenEvent | CloseEvent) => {
          if (event?.action === 'closed' && !isFlowCompleted) {
            navigate('id-review');
          }
        });
        flow?.open();
      } else {
        captureException('Create Flow Error', {
          ...(applicationFlow && { borrowerId: borrowerId }),
          ...(reviewOutcome && { reviewOutcome: reviewOutcome }),
        });
      }
    }
  }, []);

  if (isAlle) {
    return <AlleCognito />;
  }

  return (
    <Container showBackButton={false}>
      <CenterLoading>
        <Loading size={40} />
      </CenterLoading>
    </Container>
  );
};

export default IdReviewConnect;

const CenterLoading = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.main.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000000000;
`;
