import { useEffect, useRef, useState } from 'react';
import { API_URL } from 'config';
import { useAlert } from 'react-alert';

import { useSentry } from 'lib/hooks/useSentry';

const usePartnerApiEventSource = () => {
  const STREAM_URL = `${API_URL}/v1/events/applications/`;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const eventSourceRef: any = useRef();
  const { captureException } = useSentry();

  const alert = useAlert();

  const [eventSourceResponse, setEventSourceResponse] = useState(null);

  const listenPartnerApiEvents = (applicationId: string | number) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    const eventSource = new EventSource(`${STREAM_URL}${applicationId}/partner`);

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    eventSource.addEventListener(`PatientApplicationStatusChangedEvent`, (e: any) => {
      const data = JSON.parse(e?.data);
      setEventSourceResponse(data);
      handleApplicationFlowNavigation(data);
    });

    // listen to error event
    eventSource.onerror = (e) => {
      captureException(e, {
        page: 'PARTNER_SSE',
        message: 'An error occurred while attempting to connect.',
      });
      console.error('=> An error occurred while attempting to connect.', e);
    };

    eventSourceRef.current = eventSource;
  };

  const stopEventSource = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
  };

  useEffect(() => {
    return () => {
      stopEventSource();
    };
  }, []);

  const handleApplicationFlowNavigation = async (response) => {
    // @ts-ignore iframe event
    if (window?.ReactNativeWebView) {
      // @ts-ignore iframe event
      window?.ReactNativeWebView?.postMessage(JSON.stringify(response));
    } else {
      window.parent.postMessage(JSON.stringify(response), '*');
    }
  };

  return { eventSourceResponse, listenPartnerApiEvents };
};

export default usePartnerApiEventSource;
