import { gql } from '@apollo/client/core';

export const GET_BORROWER_DETAIL = gql`
  query getBorrowerDetail($input: GetBorrowerDetailInput) {
    getBorrowerDetail(input: $input) {
      success
      code
      message
      data {
        email
        dob
        createdAt
        language
        address {
          city
          id
          createdAt
          updatedAt
          status
          street
          zip
          state {
            code
            name
          }
        }
        phone
        status
        firstName
        lastName
        id
      }
    }
  }
`;

export const GET_BORROWER_INCOME_VERIFICATION = gql`
  query borrowerIncomeVerificationsWithSearch($input: BorrowerIncomeVerificationsSearchInput) {
    borrowerIncomeVerificationsWithSearch(input: $input) {
      total
      contents {
        assetReport {
          borrowerId
          id
          status
        }
        borrowerId
        createdAt
        errorMessage
        id
        parentId
        provider
        providerId
        providerMessage
        relatedApplications {
          applicationId
          createdAt
        }
        source
        status
        type
      }
    }
  }
`;

export const GET_BORROWER_INCOME_VERIFICATION_LATEST = gql`
  query borrowerIncomeVerificationsLatest($input: BorrowerIncomeVerificationsLatestInput) {
    borrowerIncomeVerificationsLatest(input: $input) {
      assetReport {
        status
        provider
        providerId
        id
      }
      borrowerId
      createdAt
      errorMessage
      id
      parentId
      providerId
      provider
      providerMessage
      relatedApplications {
        applicationId
        createdAt
      }
      source
      status
      type
    }
  }
`;

export const GET_BORROWER_KYC_OUTCOME_ADDRESS = gql`
  query getBorrowerKYCOutcomeAddress($input: GetBorrowerKYCOutcomeAddressInput) {
    getBorrowerKYCOutcomeAddress(input: $input) {
      address
      city
      state
      zip
      borrowerKYCOutcomeId
    }
  }
`;
