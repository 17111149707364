import { InputAdornment } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Icon,
  NumberFormatInput,
  RadioButtonGroup,
  Subtitle,
  TextField,
  Title,
} from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { IncomeTypeEnum } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import InfoModal from '../modals/InfoModal';
import styled from 'styled-components';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { SmallText } from 'pages/Terms/Terms';
import { isMissingInfoNextButtonDisabled } from 'lib/utils/IsMissingInfoNextButtonDisabled';
import { DefaultHighlineActiveSteps, DefaultHighlineComponentProps } from '../type';

export const StatedIncome = ({ loading, setMissingInfo }: DefaultHighlineComponentProps) => {
  const { t: translate } = useTranslation();
  const { application } = useStore.getState() || {};
  const [showModal, setShowModal] = useState(false);
  const [statedIncome, setStatedIncome] = useState<number | null>(null);
  const [radioValue, setRadioValue] = useState<IncomeTypeEnum | null>(null);
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.defaultHighLineStatedIncomeLoad);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (statedIncome !== null) {
        setShowModal(true);
      }
    }
  };

  const onAmountApproved = () => {
    if (statedIncome !== null) {
      sendActionSegmentEvent(WPQSegmentNames.defaultHighLineStatedIncomeContinue, {
        borrowerIncomeCategory: 'Household',
        source: 'Entry Screen',
        statedIncome: statedIncome,
      });

      setMissingInfo(
        String(statedIncome * (radioValue === IncomeTypeEnum.MONTHLY ? 12 : 1)),
        DefaultHighlineActiveSteps.STATED_INCOME,
      );
    }
    setShowModal(false);
  };

  const hideModal = () => setShowModal(false);

  const next = () => {
    if (radioValue && statedIncome !== null) {
      setShowModal(true);
    } else {
      setMissingInfo(String(statedIncome), DefaultHighlineActiveSteps.STATED_INCOME);
    }
  };

  const onChange = (e) => {
    const value = e?.target?.value;
    const regex = /^[0-9\b]+$/;
    if (value && regex.test(value)) {
      setStatedIncome(Number(value));
    } else {
      setStatedIncome(null);
    }
  };

  const onChangeHandler = (e) => {
    setRadioValue(e?.target?.value);
    sendActionSegmentEvent(`WPQ.STANDARD_WPQ.STATED_INCOME_${e?.target?.value.toUpperCase()}_CLICKED`);
  };

  const isNextButtonDisabled = isMissingInfoNextButtonDisabled(statedIncome, loading);

  return (
    <Container>
      <ContentContainer>
        <Icon src={'union_cli'} />
        <>
          <Title m={'10px 0px 8px 0px'} data-testid="stated-income-title">
            {translate('defaultHighLine.view.statedIncome.title1')}
          </Title>
          <Subtitle
            m={'0px 0px 24px 0px'}
            dangerouslySetInnerHTML={{
              __html: translate('defaultHighLine.view.statedIncome.subTitle1'),
            }}
          />
        </>

        <StyledBox>
          <RadioButtonGroup
            onChange={onChangeHandler}
            radioButtonOptions={[
              {
                textValue: IncomeTypeEnum.MONTHLY,
                radioLabel: translate('defaultHighLine.view.statedIncome.monthly'),
              },
              {
                textValue: IncomeTypeEnum.ANNUAL,
                radioLabel: translate('defaultHighLine.view.statedIncome.annual'),
              },
            ]}
            value={radioValue || ''}
          />
        </StyledBox>
        {!!radioValue ? (
          <Box>
            <TextField
              id="annual-income"
              data-testid="annual-income"
              data-neuro-label="annual-income"
              variant="filled"
              label={translate('defaultHighLine.view.statedIncome.form.label1', {
                radioValue: translate(`defaultHighLine.info.${radioValue?.toLowerCase()}`),
              })}
              type="tel"
              value={statedIncome}
              max={application?.creditLineMaxEligible}
              min={application?.balanceAvailable}
              onChange={onChange}
              onFocus={() => {
                sendActionSegmentEvent(WPQSegmentNames.defaultHighLineStatedIncomeClicked);
              }}
              onBlur={(e) => {
                sendActionSegmentEvent(WPQSegmentNames.defaultHighLineStatedIncomeFilled, {
                  metaData: e.target.defaultValue,
                });
              }}
              onKeyDown={checkKeyDown}
              InputProps={{
                inputComponent: NumberFormatInput,
                pattern: '[^0-9.]',
                startAdornment: (
                  <InputAdornment
                    sx={{
                      padding: '1px 0 0 0',
                      '& .MuiTypography-root': { color: '#000' },
                    }}
                    position="start"
                  >
                    $
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        ) : null}
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={next} disabled={isNextButtonDisabled} loading={loading}>
          {translate('buttons.continue')}
        </Button>
        <SmallText>{translate('defaultHighLine.view.statedIncome.footerText')}</SmallText>
      </ButtonContainer>
      {showModal && (
        <InfoModal
          show={showModal}
          hideModal={hideModal}
          onApproved={onAmountApproved}
          type={DefaultHighlineActiveSteps.STATED_INCOME}
          amount={statedIncome}
          selectedRadioType={radioValue}
        />
      )}
    </Container>
  );
};

const StyledBox = styled(Box)`
  gap: 8px;
`;
