export enum IframeEventTypes {
  IFRAME = 'iframe',
  LOADING = 'loading',
  PAGE = 'page',
  BUTTON_CLICKED = 'button-clicked',
}

export enum IframeEventActions {
  LOADED = 'loaded',
  CLOSE = 'close',
}

export enum LoadingEventActions {
  STARTED = 'started',
  FINISHED = 'finished',
}

export enum PageEventActions {
  GET_STARTED = 'get-started',
  PATIENT_INFO = 'patient-info',
  PATIENT_ADDRESS_INFO = 'patient-address-info',
  SSN_COLLECT = 'patient-ssn-info',
  CONNECT_BANK_ACCOUNT = 'connect-bank-account',
  PLAID_BANK_CONNECT_IFRAME = 'plaid-bank-connect-iframe',
  COMPLETE = 'complete',
  UPLOAD_PAYSTUBS_PAYROLL = 'upload-paystub-payroll',
  PLAID_PAYROLL_IFRAME = 'plaid-payroll-iframe',
  PLAID_PAYSTUB_IFRAME = 'plaid-paystub-iframe',
  EMAIL_COLLECTION = 'email-collection',
  SSN_CONFLICT = 'patient-ssn-conflict',
  ID_REVIEW = 'id-review',
  ID_REVIEW_PERMISSION = 'id-review-permission',
  HAVE_QUESTIONS = 'have-questions',
  APPROVAL_PAGE = 'approval-page',
}

export enum ButtonClickedEventActions {
  GET_STARTED_CONTINUE_BUTTON_CLICKED = 'get-started-continue-button-clicked',
  PATIENT_INFO_CONTINUE_BUTTON_CLICKED = 'patient-info-continue-button-clicked',
  PATIENT_ADDRESS_CONTINUE_BUTTON_CLICKED = 'patient-address-continue-button-clicked',
  CHECK_ELIGIBILITY_BUTTON_CLICKED = 'check-eligibility-button-clicked',
  CONNECT_BANK_ACCOUNT_BUTTON_CLICKED = 'connect-bank-account-button-clicked',
  SUBMIT_PAYSTUB_BUTTON_CLICKED = 'submit-paystub-button-clicked',
  CONNECT_PAYROLL_BUTTON_CLICKED = 'connect-payroll-button-clicked',
  CONNECT_BANK_ACCOUNT_INSTEAD_BUTTON_CLICKED = 'connect-bank-account-instead-clicked',
  CONNECT_PAYROLL_PAYSTUB_INSTEAD_BUTTON_CLICKED = 'connect-payroll-paystub-instead-clicked',
  PAYMENT_ESTIMATOR_CONTINUE_BUTTON_CLICKED = 'payment-estimator-continue-button-clicked',
}

export const useIframeEvents = () => {
  const pushEvent = (
    type: IframeEventTypes,
    action: IframeEventActions | LoadingEventActions | PageEventActions | ButtonClickedEventActions,
  ) => {
    const event = {
      topic: 'UI',
      type,
      action,
    };
    // @ts-ignore iframe event
    if (window?.ReactNativeWebView) {
      // @ts-ignore iframe event
      window?.ReactNativeWebView?.postMessage(JSON.stringify(event));
    } else {
      window.parent.postMessage(JSON.stringify(event), '*');
    }
  };

  return { pushEvent };
};
