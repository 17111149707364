import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';

import { Button, Icon, Subtitle, Title } from 'lib/components';
import { FormatAmount } from 'lib/utils';
import useStore from 'lib/hooks/useStore';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  hideModal: () => void;
}

export const PartialApprovalModal = ({ show, hideModal }: Props) => {
  const { t: translate } = useTranslation();
  const { organization, organizationGroup, application } = useStore();

  const closeModalOnClickAway = (e) => {
    if (e?.target?.ariaHidden) {
      hideModal();
    }
  };

  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={closeModalOnClickAway}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <Container>
          <Header>
            <Title m={'24px 0px 24px'}>{translate('partialApprovalModal.title')}</Title>
            <Icon src={'close'} onClick={hideModal} hover={true} />
          </Header>
          <Body>
            <Subtitle m="0 0 24px 0">
              {translate('partialApprovalModal.subTitle', {
                balanceAvailable: FormatAmount(application?.balanceAvailable),
                name: organization?.name || organizationGroup?.name,
              })}
            </Subtitle>
            <Button onClick={hideModal} secondary>
              {translate('buttons.okay')}
            </Button>
          </Body>
        </Container>
      </Slide>
    </Backdrop>
  );
};

const Container = styled.div`
  width: 100%;
  position: absolute;
  bottom: -15px;
  background: #ffffff;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 24px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 600px;
    height: fit-content;
    border-radius: 6px;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px 4px;
`;

const Body = styled.div`
  padding: 24px;
  padding-top: 0;
`;
