import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Redirect } from 'lib/utils';
import { PRACTICE_FINDER } from 'lib/constants';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { SmallText } from 'pages/Terms/Terms';
import { getExternalLinkLangParam } from 'lib/utils/ExternalLinkLangParam';
import useStore from 'lib/hooks/useStore';

const InactiveOrganization = () => {
  const { t: translate } = useTranslation();
  const { defaultLanguage } = useStore();

  const handleNavigatePracticeFinder = () => {
    Redirect(PRACTICE_FINDER, '_self');
  };

  return (
    <Container showBackButton={false} hideTitle={true}>
      <ContentContainer>
        <Icon src={'warning_message'} />
        <Title m={'10px 0 0 0'}>{translate('inactiveOrganization.title')}</Title>
        <Subtitle m={'8px 0px 24px 0px'}>{translate('inactiveOrganization.subTitle')}</Subtitle>

        <Button onClick={handleNavigatePracticeFinder}>{translate('buttons.findPracticeNearYou')}</Button>
      </ContentContainer>
      <StyledButtonContainer>
        <SmallText>
          {translate('inactiveOrganization.content1')}{' '}
          <a
            target="_blank"
            href={`https://withcherry.com/lending-partners/${getExternalLinkLangParam(defaultLanguage)}`}
          >
            <strong>{translate('inactiveOrganization.content2')}</strong>
          </a>
          .
        </SmallText>
      </StyledButtonContainer>
    </Container>
  );
};

export default InactiveOrganization;

const StyledButtonContainer = styled(ButtonContainer)`
  padding: 0 24px 12px 24px;

  strong {
    color: ${(props) => props.theme.main.green};
    text-decoration: underline;
  }
`;
