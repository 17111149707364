import { gql } from '@apollo/client/core';

export const GET_EXCHANGE_TOKEN = gql`
  mutation getExchangeToken($input: ExchangeTokenInput) {
    getExchangeToken(input: $input) {
      success
      message
      code
      status
      type
      token
      data {
        applicationId
        borrowerId
        idLoan
        slug
        incomeVerificationId
        partner
        phone
        firstName
        lastName
        pointOfSaleId
        treatmentAmount
        merchantId
        consentId
      }
    }
  }
`;

export const SET_EXCHANGE_TOKEN = gql`
  mutation setExchangeToken($input: SetExchangeTokenInput) {
    setExchangeToken(input: $input) {
      exchangeTokenId
    }
  }
`;
