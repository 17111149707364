import { createContext, ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { API_URL } from 'config';
import { useApplicationFlowService, useEligibilityService } from 'pages/AuthChecker/services';
import { useFlags } from '../FeatureManagement/FlagsContext';
import useStore from 'lib/hooks/useStore';
import useApplicationFlowNavigation from '../useApplicationFlowNavigation';
import { useSentry } from 'lib/hooks/useSentry';
import { FORBIDDEN_SSE_URL } from 'lib/constants';

interface ContextState {
  startCheckApplicationStatus: ({ applicationId, isExisting, subscribeFlow }: UsePolling) => Promise<void>;
}

interface UsePolling {
  applicationId?: string | number;
  isExisting?: boolean;
  subscribeFlow?: boolean;
  skipSameStatusControl?: boolean;
}

export const SSEContext = createContext<ContextState>({} as ContextState);
const STREAM_URL = `${API_URL}/v1/events/applications/`;

export const SSEContextProvider = ({ children }: { children: ReactNode }) => {
  const { flags } = useFlags();
  const location = useLocation();
  const { captureException } = useSentry();
  const { setApplicationFlow } = useStore();
  const { getApplicationStatus } = useApplicationFlowService();
  const { getUpdatedStatus, getApplication } = useEligibilityService();
  const { handleApplicationFlowNavigation } = useApplicationFlowNavigation();
  const lastStatus = useRef('');
  const [eventSourceInstance, setEventSourceInstance] = useState<EventSource | null>();
  const startApplicationStatusEvents = (
    applicationId: string | number,
    isExisting: boolean,
    skipSameStatusControl: boolean,
  ) => {
    const eventSource = new EventSource(`${STREAM_URL}${applicationId}/flow`);

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    eventSource.addEventListener(`ApplicationFlowFinishedEvent`, (e: any) => {
      const data = JSON.parse(e?.data);
      if (lastStatus.current !== data?.status || skipSameStatusControl) {
        setApplicationFlow(data);
        handleApplicationFlowNavigation(data);
        lastStatus.current = data?.status;
      }
    });

    eventSource.onopen = (e) => {
      setTimeout(() => {
        if (!lastStatus.current) {
          startLegacyPolling(applicationId, isExisting);
          eventSource.close();
          setEventSourceInstance(null);
        }
      }, 3000);
    };

    // listen to error event
    eventSource.onerror = (e) => {
      captureException(e, {
        page: 'SSE',
        message: 'An error occurred while attempting to connect.',
      });
    };

    return eventSource;
  };

  const startCheckApplicationStatus = async ({
    applicationId,
    isExisting = false,
    subscribeFlow = false,
    skipSameStatusControl = false,
  }: UsePolling) => {
    const { application } = useStore.getState() || {};
    const applicationID = applicationId || application?.id;
    const isSSEPollingEnabled = flags?.POLLING?.serverSideEvent?.isEnabled() || false;
    if (isSSEPollingEnabled && applicationID) {
      if (isExisting) {
        await getApplication(applicationID);
      }
      if (!eventSourceInstance || subscribeFlow) {
        const eventSource = startApplicationStatusEvents(applicationID, isExisting, skipSameStatusControl);
        setEventSourceInstance(eventSource);
        if (!eventSource) {
          startLegacyPolling(applicationID, isExisting);
        }
      }
    } else {
      startLegacyPolling(applicationID, isExisting);
    }
  };

  const startLegacyPolling = async (applicationId, isExistingApplication) => {
    if (isExistingApplication) {
      await getUpdatedStatus(applicationId);
    } else {
      await getApplicationStatus(applicationId);
    }
  };

  useEffect(() => {
    if (FORBIDDEN_SSE_URL.includes(location?.pathname?.split('/')[2])) {
      eventSourceInstance?.close();
      setEventSourceInstance(null);
    }
  }, [location.pathname]);

  return <SSEContext.Provider value={{ startCheckApplicationStatus }}>{children}</SSEContext.Provider>;
};
