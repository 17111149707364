import React, { useState } from 'react';
import styled from 'styled-components';
import { HelpMeModal, Icon } from 'lib/components';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useHelpModal } from 'lib/hooks/useHelpModal';

export const AlleHeader = ({ showBackButton = true }) => {
  const { navigateBack } = useNavigation();
  const { onEmailPressed, onCallPressed, logoutPressed } = useHelpModal();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const toggleModalOpenState = () => {
    setModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const goBack = () => {
    navigateBack();
  };

  return (
    <>
      <HeaderContainer>
        <HeaderElement>
          {showBackButton && (
            <button onClick={goBack}>
              <Icon src="alle_back" width={24} />
            </button>
          )}
        </HeaderElement>
        <HeaderElement>
          <Icon src="alle_logo" width={42} />
          <Icon src="vertical_seperation_line" />
          <Icon src="cherry_black_logo" width={88} />
        </HeaderElement>
        <HeaderElement />
      </HeaderContainer>
    </>
  );
};

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
`;

const HeaderElement = styled.div`
  &:nth-child(1) {
    flex: 0.5;
    text-align: left;
  }

  &:nth-child(2) {
    flex: 1;
    text-align: center;
  }

  &:nth-child(3) {
    flex: 0.5;
    text-align: right;
  }

  button {
    border: none;
    background-color: ${(props) => props.theme.main.white};
    cursor: pointer;
  }
`;
