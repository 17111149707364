import { Backdrop, Slide, Stack, Typography } from '@mui/material';
import { theme as configTheme } from 'config/theme';
import dayjs from 'dayjs';
import { Button, Icon, Title } from 'lib/components';
import { EXPIRED_DATE_FORMAT } from 'lib/constants';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { IApplication, Nullable } from 'lib/types';
import { FormatAmount, Redirect } from 'lib/utils';
import React, { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface RequestLargerApprovalModalProps {
  show: boolean;
  hideModal: () => void;
  application: Nullable<IApplication>;
}

const RequestLargerApprovalModal: FC<RequestLargerApprovalModalProps> = ({ application, show, hideModal }) => {
  const { t: translate } = useTranslation();
  const { sendLoadSegmentEvent } = useSegmentContext();

  useEffect(() => {
    sendLoadSegmentEvent(WPQSegmentNames.approvalRequestAnIncreaseFakeModalPageLoad);
  }, []);

  const closeModalOnClickAway = (e) => {
    if (e?.target?.ariaHidden) {
      hideModal();
    }
  };

  const navigateFaq = () => {
    Redirect(`https://withcherry.com/patients#faqs`);
  };

  return (
    <Backdrop
      sx={{ color: configTheme.main.white, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      onClick={closeModalOnClickAway}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <ModalContainer isLarge={false}>
          <ModalHeaderWrapper>
            <TitleWrapper>
              <Icon src="decline-fail" width={32} height={32} />
              <ModalHeaderTitle>{translate('requestLargerApprovalModal.title')}</ModalHeaderTitle>
            </TitleWrapper>

            <Icon hover={true} src={'close'} onClick={hideModal} />
          </ModalHeaderWrapper>

          <Stack spacing={3}>
            <QuestionContainer>
              <ModalDescription bold>{translate('requestLargerApprovalModal.firstQuestion')}</ModalDescription>
              <ModalDescription>{translate('requestLargerApprovalModal.firstAnswer')}</ModalDescription>
            </QuestionContainer>

            <QuestionContainer>
              <ModalDescription bold>{translate('requestLargerApprovalModal.secondQuestion')}</ModalDescription>
              <ModalDescription>
                <Trans
                  i18nKey={'requestLargerApprovalModal.secondAnswer'}
                  components={{
                    b: <strong />,
                  }}
                  values={{
                    approvalAmount: application?.balanceAvailable && FormatAmount(application?.balanceAvailable),
                    expirationDate: dayjs(application?.expireAt).format(EXPIRED_DATE_FORMAT),
                  }}
                />
              </ModalDescription>
            </QuestionContainer>
          </Stack>
          <ButtonWrapper>
            <Button fontSize={20} mb onClick={hideModal}>
              {translate('requestLargerApprovalModal.returnToApproval')}
            </Button>
            <Button secondary fontSize={20} mb onClick={navigateFaq}>
              {translate('FAQ.title')}
            </Button>
          </ButtonWrapper>
        </ModalContainer>
      </Slide>
    </Backdrop>
  );
};

const ModalContainer = styled.div<{ isLarge: boolean }>`
  width: 90%;
  max-height: 80vh;
  position: absolute;
  overflow: auto;
  bottom: -15px;
  background: ${(props) => props.theme.main.white};
  box-shadow: 0px 1px 0px 0px #dadada, 0px -36px 67px 0px rgba(0, 0, 0, 0.07),
    0px -13.14px 24.456px 0px rgba(0, 0, 0, 0.05), 0px -6.38px 11.873px 0px rgba(0, 0, 0, 0.04),
    0px -3.13px 5.82px 0px rgba(0, 0, 0, 0.03), 0px -1.24px 2.301px 0px rgba(0, 0, 0, 0.02);
  border-radius: 0 0 32px 32px;
  padding: 24px 24px 40px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    height: fit-content;
    border-radius: 6px;

    @media (max-height: ${(props) => (props.isLarge ? '700px' : '530px')}) {
      top: 30px;
      overflow-y: scroll;
    }
  }
`;

const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ModalHeaderTitle = styled(Title)`
  font-size: 20px;
  font-weight: 700;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ModalDescription = styled(Typography)<{ bold?: boolean }>`
  color: ${configTheme.main.midnightBlue};
  font-family: 'Open Sans', serif !important;
  font-weight: ${(props) => (props.bold ? 700 : 400)} !important;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
`;

export default RequestLargerApprovalModal;
