import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import Radio from '@mui/material/Radio';
import { useMutation } from '@apollo/client';
import RadioGroup from '@mui/material/RadioGroup';
import { Controller, useForm } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';

import { CREATE_REFUNDING_STATUS_SURVEY } from 'lib/graphql/mutations';

import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { useSentry } from 'lib/hooks/useSentry';
import { useSegment } from 'lib/hooks/useSegment';
import { useNavigation } from 'lib/hooks/useNavigation';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { Box, Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { Q_1, Q_2, SURVEY_QUESTIONS, TRACK_VERSION, WEBPREQUAL } from 'lib/constants';

import { ISurvey, SurveyFormDataType } from 'pages/PersonalAddressInfo/types';
import { useTranslation } from 'react-i18next';

const Survey = () => {
  const surveyInfo: SurveyFormDataType = {
    question1: '',
    question2: null,
  };

  const { navigate } = useNavigation();
  const { trackPage } = useSegment();
  const { captureException } = useSentry();
  const { t: translate } = useTranslation();
  const [createSurvey, { loading }] = useMutation(CREATE_REFUNDING_STATUS_SURVEY);

  useEffect(() => {
    trackPage('surveyConfirmation', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
    });
  }, []);

  const {
    handleSubmit,
    control,
    formState: { isValid } = {},
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: surveyInfo,
  });

  const answer1 = watch('question1');
  const answer2 = watch('question2');
  useEffect(() => {
    if (answer1 === SURVEY_QUESTIONS.Q1) {
      setValue('question2', null, { shouldValidate: true });
    }
  }, [answer1]);

  const onSubmit = async (formData: SurveyFormDataType) => {
    try {
      const { borrower, loan } = useStore.getState() || {};

      const { data } = await createSurvey({
        variables: {
          input: {
            borrowerId: borrower?.id,
            loanId: loan?.id,
            answer1: formData?.question1,
            answer2: formData?.question2,
          },
        },
      });

      if (data?.createRefundingStatusSurvey?.message === 'OK') {
        navigate('success');
      }
    } catch (err) {
      navigate('success');
      captureException('Survey Error', {
        error: err,
        page: 'Survey',
        message: 'Product Calculation Error',
      });
    }
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <InnerContainer>
          <Icon src={'survey'} />
          <Box>
            <TitleContainer m={'10px 0px 24px'}>{translate('survey.title')}</TitleContainer>
            <Controller
              name="question1"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <RadioGroup {...field}>
                  {Q_1.map((option: ISurvey, index) => (
                    <CustomFormControlLabel
                      className={option?.value === answer1 ? 'checked' : ''}
                      data-testid={`question1-${index}-item`}
                      key={`question1-${index}-item`}
                      value={option?.value}
                      control={
                        <Radio
                          sx={{
                            color: `${theme.main.grayColor}`,
                            '&.Mui-checked': {
                              color: `${theme.main.green}`,
                            },
                          }}
                        />
                      }
                      label={translate(`surveyQuestions.${option?.key}`)}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </Box>
          {!!answer1 && answer1 !== SURVEY_QUESTIONS.Q1 && (
            <Box>
              <SubtitleContainer m={'16px 0 8px'}>{translate('survey.subTitle')}</SubtitleContainer>
              <Controller
                name="question2"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field }) => (
                  <RadioGroupContainer {...field}>
                    {Q_2.map((option: ISurvey, index) => (
                      <CustomFormControlLabel
                        className={option?.value === answer2 ? 'checked' : ''}
                        data-testid={`question2-${index}-item`}
                        key={`question2-${index}-item`}
                        value={option?.value}
                        control={
                          <Radio
                            sx={{
                              color: `${theme.main.grayColor}`,
                              '&.Mui-checked': {
                                color: `${theme.main.green}`,
                              },
                            }}
                          />
                        }
                        label={translate(`surveyQuestionAnswers.${option?.key}`)}
                      />
                    ))}
                  </RadioGroupContainer>
                )}
              />
            </Box>
          )}
        </InnerContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button disabled={!isValid || loading} loading={loading} onClick={handleSubmit(onSubmit)}>
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default memo(Survey);

export const CustomFormControlLabel = styled(FormControlLabel)`
  padding: 16px !important;
  margin: 0 0 10px !important;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.main.grayColor};
  color: ${(props) => props.theme.main.grayColor};
  font-size: 14px;

  > span {
    font-family: 'Open Sans', serif !important;
  }

  &.checked {
    border-color: ${(props) => props.theme.main.green};

    > span {
      font-weight: 700;
    }
  }
`;

const TitleContainer = styled(Title)`
  display: block;
`;

const SubtitleContainer = styled(Subtitle)`
  display: block;
`;

const RadioGroupContainer = styled(RadioGroup)`
  display: flex !important;
  flex-direction: row !important;
  gap: 16px;

  > label {
    width: auto;
    min-width: auto;
    flex: 1;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
