import { Redirect } from 'lib/utils';
import { Button, Container, Icon, Subtitle, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { APP_ENV } from 'config';
import useStore from 'lib/hooks/useStore';
import { useNavigation } from 'lib/hooks/useNavigation';
import { theme } from 'config/theme';
import { useSegment } from 'lib/hooks/useSegment';
import { WEBPREQUAL } from 'lib/constants';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';

const PhoneUpdate = () => {
  const { t: translate } = useTranslation();
  const { trackPage, trackSegmentEvent } = useSegment();
  const [contactSupport, setContactSupport] = useState(false);
  const { navigateBack } = useNavigation();
  const { application, borrower, organization } = useStore.getState() || {};
  const { sendLoadSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent('SSN Conflict/Phone Update Landing');
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    if (contactSupport) {
      callSegmentEvents();
    }
  }, [contactSupport]);

  const phoneUpdateNavigate = () => {
    if (contactSupport) {
      setModalVisibility();
    } else {
      callSegmentEvents();
      const url = `https://${APP_ENV === 'master' ? '' : `${APP_ENV}-`}customer.withcherry.com/phone-update/${
        organization?.slug
      }`;
      Redirect(url);
    }
  };

  const closeModal = () => {
    if (contactSupport) {
      setModalVisibility();
    } else {
      navigateBack();
    }
  };

  const callSegmentEvents = () => {
    trackSegmentEvent(contactSupport ? WPQSegmentNames.phoneUpdateNo : WPQSegmentNames.phoneUpdateYes, {
      application: WEBPREQUAL,
      applicationId: application?.id,
      borrowerId: borrower?.id,
      organizationId: organization?.id,
    });
  };

  const setModalVisibility = () => {
    setContactSupport(!contactSupport);
  };

  return (
    <>
      <Container showBackButton={true} goBackCallback={closeModal}>
        {!contactSupport ? (
          <>
            <ContentContainer>
              <Icon src={'mobile'} />
              <Title m={'8px 0'}>{translate('phoneUpdate.title')}</Title>
              <Subtitle>{translate('phoneUpdate.subtitle')}</Subtitle>
            </ContentContainer>
            <ButtonContainer>
              <Button onClick={phoneUpdateNavigate}>{translate('phoneUpdate.button.yes')}</Button>
              <Button onClick={setModalVisibility} secondary={true}>
                {translate('phoneUpdate.button.no')}
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <>
            <ContentContainer>
              <Icon src={'decline-fail'} />
              <Title m={'10px 0px 8px 0px'}>{translate('phoneUpdate.contactSupport.title')}</Title>
              <Subtitle
                color={theme.main.midnightBlue}
                m={'24px 0px 24px 0px'}
                dangerouslySetInnerHTML={{
                  __html: translate('phoneUpdate.contactSupport.subtitle', {}),
                }}
              ></Subtitle>
            </ContentContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default PhoneUpdate;
