import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { Button, Container, Icon, SurveyTypeForm, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { useNavigatePatientPortal } from 'lib/hooks/useNavigatePatientPortal';
import useStore from 'lib/hooks/useStore';

const YouAllSet = () => {
  const { t: translate } = useTranslation();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const { goToPatientPortal } = useNavigatePatientPortal();
  const loadSegmentController = useRef(true);
  const { setPageIndex } = useStore();

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.allSetLoad);
      setPageIndex(7);

      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const visitPatientPortal = () => {
    sendActionSegmentEvent(WPQSegmentNames.allSetVisitPortalClicked);
    goToPatientPortal();
  };

  return (
    <Container showBackButton={true} backUrl={'what-happens-next'}>
      <ContentContainer justify="flex-start">
        <HeaderContainer>
          <Icon src={'circle_check'} />
          <Title m={'10px 0px 8px 0px'}>{translate('youAllSet.allSet')}</Title>
        </HeaderContainer>

        <Description>{translate('youAllSet.canCloseBrowser')}</Description>
        <SurveyTypeForm
          page="APPROVAL"
          title={translate('approval.survey.title', {
            price: '$25',
          })}
          description={translate('approval.survey.description', {
            price: '$25',
          })}
        />
        <Description>{translate('youAllSet.savedYourApproval')}</Description>
        <Button secondary={true} onClick={visitPatientPortal}>
          {translate('buttons.visitPatientPortal')}
        </Button>
      </ContentContainer>
    </Container>
  );
};

export default YouAllSet;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 16px;
`;
