import { APP_ENV } from 'config';

export const LANGUAGES = ['en', 'es'];

export const US_STATES = [
  { name: 'Alabama', value: 'AL' },
  { name: 'Alaska', value: 'AK' },
  { name: 'Arizona', value: 'AZ' },
  { name: 'Arkansas', value: 'AR' },
  { name: 'California', value: 'CA' },
  { name: 'Colorado', value: 'CO' },
  { name: 'Connecticut', value: 'CT' },
  { name: 'District of Columbia', value: 'DC' },
  { name: 'Delaware', value: 'DE' },
  { name: 'Florida', value: 'FL' },
  { name: 'Georgia', value: 'GA' },
  { name: 'Hawaii', value: 'HI' },
  { name: 'Idaho', value: 'ID' },
  { name: 'Illinois', value: 'IL' },
  { name: 'Indiana', value: 'IN' },
  { name: 'Iowa', value: 'IA' },
  { name: 'Kansas', value: 'KS' },
  { name: 'Kentucky', value: 'KY' },
  { name: 'Louisiana', value: 'LA' },
  { name: 'Maine', value: 'ME' },
  { name: 'Maryland', value: 'MD' },
  { name: 'Massachusetts', value: 'MA' },
  { name: 'Michigan', value: 'MI' },
  { name: 'Minnesota', value: 'MN' },
  { name: 'Mississippi', value: 'MS' },
  { name: 'Missouri', value: 'MO' },
  { name: 'Montana', value: 'MT' },
  { name: 'Nebraska', value: 'NE' },
  { name: 'Nevada', value: 'NV' },
  { name: 'New Hampshire', value: 'NH' },
  { name: 'New Jersey', value: 'NJ' },
  { name: 'New Mexico', value: 'NM' },
  { name: 'New York', value: 'NY' },
  { name: 'North Carolina', value: 'NC' },
  { name: 'North Dakota', value: 'ND' },
  { name: 'Ohio', value: 'OH' },
  { name: 'Oklahoma', value: 'OK' },
  { name: 'Oregon', value: 'OR' },
  { name: 'Pennsylvania', value: 'PA' },
  { name: 'Rhode Island', value: 'RI' },
  { name: 'South Carolina', value: 'SC' },
  { name: 'South Dakota', value: 'SD' },
  { name: 'Tennessee', value: 'TN' },
  { name: 'Texas', value: 'TX' },
  { name: 'Utah', value: 'UT' },
  { name: 'Vermont', value: 'VT' },
  { name: 'Virginia', value: 'VA' },
  { name: 'Washington', value: 'WA' },
  { name: 'West Virginia', value: 'WV' },
  { name: 'Wisconsin', value: 'WI' },
  { name: 'Wyoming', value: 'WY' },
];

export const DEBOUNCE_DELAY = 500;
export const DEFAULT_SELECTED_PLAN_INDEX = 0;

export const MINIMUM_AMOUNT = 200;
export const ALERT_TIMEOUT = 4000;
export const PAGE_COUNT = 7;
export const AUTHORIZED = 'Authorized';

export const SCOPE = 'PREQUAL';
export const WEB_SITE = 'https://withcherry.com';
export const CUSTOMER_PORTAL = 'https://patient.withcherry.com';
export const PRACTICE_FINDER = 'https://withcherry.com/practice-finder';
export const CUSTOMER_PORTAL_DEV = 'https://dev-customer.withcherry.com';
export const CUSTOMER_PORTAL_UAT = 'https://uat-customer.withcherry.com';
export const CUSTOMER_PORTAL_CRB = 'https://crb-customer.withcherry.com';
export const CUSTOMER_PORTAL_STG = 'https://staging-customer.withcherry.com';

export const SHOP_WITHCHERRY =
  APP_ENV === 'master' ? 'https://shop.withcherry.com' : `https://${APP_ENV}-shop.withcherry.com`;

export const PATIENT_PORTAL =
  APP_ENV === 'master' ? 'https://patient.withcherry.com' : `https://${APP_ENV}-patient.withcherry.com`;

export const PHONE = '(888) 839-7171';
export const PHONE_LINK = 'tel:8888397171';
export const MAIL = 'mailto:support@withcherry.com';

export const OK = 'ok';
export const SUCCESS = 'success';
export const BORROWER_INIT = 'borrowerInit';

export const PARTNER_LINK = 'PARTNER_LINK';
export const PARTNER_API = 'PARTNER_API';
export const WPQ = 'WPQ';

export const APPLICATION_ALREADY_EXIST = 'application.already_exist';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const EXPIRED_DATE_FORMAT = 'MMM DD, YYYY';
export const EXPIRED_DATE_FORMAT_EXPERIMENT = 'MMMM D, YYYY';
export const CURRENCY = 'currency';
export const USD = 'USD';
export const SUPPORT_URL = 'support@withcherry.com';

export const RCC = 'RCC';
export const WEBPREQUAL = 'webPrequal';
export const TRACK_VERSION = 'reactV1';
export const ACH_PREFERRED = 'ACH_PREFERRED';
export const CREDIT_CARD_DOWN_PAYMENT = 'CREDIT_CARD_DOWN_PAYMENT';
export const REQUEST_PREFUNDING_STATUS = 'REQUEST_PREFUNDING_STATUS';
export const DENTAL_TREATMENT_DATA_GATHERING = 'DENTAL_TREATMENT_DATA_GATHERING';
export const RETAIL_INSTALLMENT_CONTRACT = 'RETAIL_INSTALLMENT_CONTRACT';
export const ALLE_AUTO_APPLICATION = 'ALLE_AUTO_APPLICATION';
export const DUE_DATE_ALIGNMENT = 'DUE_DATE_ALIGNMENT';

export const ALLE_COGNITO_PARAMS =
  'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600,left=100,top=100';

export enum SURVEY_QUESTIONS {
  Q1 = 'I already have',
  Q2 = 'Within 1 month',
  Q3 = 'Within 6 months',
  Q4 = 'More than 6 months',
}

export const DENTAL_INDUSTRIES = [
  'General Dentistry',
  'Dental',
  'Dental & Orthodontics',
  'Oral Surgery',
  'Orthodontics',
  'Periodontics',
];

export const PLASTICS_INDUSTRIES = ['Plastic Surgery', 'Cosmetic Surgery'];
export const MEDSPA_INDUSTRIES = ['Medspa'];

export const DentalSurveyIndustries = DENTAL_INDUSTRIES;
export const PlasticsSurveyIndustries = PLASTICS_INDUSTRIES;
export const SpaSurveyIndustries = MEDSPA_INDUSTRIES;

export const Q_1 = [
  { name: SURVEY_QUESTIONS.Q1, value: SURVEY_QUESTIONS.Q1, key: 'q1' },
  { name: SURVEY_QUESTIONS.Q2, value: SURVEY_QUESTIONS.Q2, key: 'q2' },
  { name: SURVEY_QUESTIONS.Q3, value: SURVEY_QUESTIONS.Q3, key: 'q3' },
  { name: SURVEY_QUESTIONS.Q4, value: SURVEY_QUESTIONS.Q4, key: 'q4' },
];

export const FORBIDDEN_SSE_URL = ['approval'];

export const Q_2 = [
  { name: 'No', value: 'No', key: 'option_1' },
  { name: 'Yes', value: 'Yes', key: 'option_2' },
];

export const UTM_MEDIUM_DATA = ['textmessage', 'qrcode', 'email'];
export const UTM_SOURCE_DATA = ['merchant_text'];
export const LAST4_DISABLED_BANKS = ['CONTINENTAL'];

export const POLL_FINISHED = 'POLL_FINISHED';

export const CA_HOUSE_RENT_QUESTIONS = [
  { name: 'Rent', value: 'Rent' },
  { name: 'Own', value: 'Own' },
  { name: 'Other', value: 'Other' },
];

export const HOUSE_HOLD_INCOME_TYPE = [
  { name: 'Monthly', value: 'Monthly' },
  { name: 'Annual', value: 'Annual' },
];

export enum IncomeTypeEnum {
  MONTHLY = 'Monthly',
  ANNUAL = 'Annual',
}

export const isOrganizationDental = (organizationIndustry) => {
  return DENTAL_INDUSTRIES.includes(organizationIndustry);
};

export const isOrganizationCompetitivePlastics = (organizationIndustry) => {
  return PLASTICS_INDUSTRIES.includes(organizationIndustry);
};

export const isSurveyEnabledDental = (organizationIndustry) => {
  return DentalSurveyIndustries.includes(organizationIndustry);
};

export const isSurveyEnabledPlastics = (organizationIndustry) => {
  return PlasticsSurveyIndustries.includes(organizationIndustry);
};

export const isSurveyEnabledMedSpa = (organizationIndustry) => {
  return SpaSurveyIndustries.includes(organizationIndustry);
};

export const isSurveyEnabledIndustry = (org) => {
  return (
    isSurveyEnabledDental(org?.industry) ||
    (isSurveyEnabledPlastics(org?.industry) && org?.menuCode.includes('CompetitivePlastics')) ||
    (isSurveyEnabledMedSpa(org?.industry) &&
      (org?.menuCode.includes('CS_Competitive') || org?.menuCode.includes('Inj_Competitive')))
  );
};

export const getSurveyVersion = (organizationIndustry) => {
  if (isSurveyEnabledDental(organizationIndustry) || isSurveyEnabledMedSpa(organizationIndustry)) {
    return 'v2';
  } else if (isSurveyEnabledPlastics(organizationIndustry)) {
    return 'v3';
  }
};

export const surveyPages = {
  'FUND SUCCESS': 'checkout',
  DENIAL: 'denial',
  APPROVAL: 'approval',
};

export const getSurveyLink = (organizationIndustry) => {
  if (isSurveyEnabledDental(organizationIndustry)) {
    return 'MMyn9gtH';
  } else if (isSurveyEnabledMedSpa(organizationIndustry)) {
    return 'RbRog16m';
  } else if (isSurveyEnabledPlastics(organizationIndustry)) {
    return 'kbDkPvtk';
  }
};
export const ClientErrors = {
  'borrower.has_approved_applications.unprocessable':
    'We’re sorry, Spanish language support is not available at this time.',
};

export const MIN_ALLOWED_TREATMENT_COST = 200;

export const STORED_CARD_TYPES = {
  DEBIT: 'DEBIT',
  PREPAID: 'PREPAID',
};

export const CONFIRM_LOAN_STATUSES = {
  INVALID: 'INVALID',
  AWAITING_FUNDING: 'AWAITING_FUNDING',
};

export const CONFIRM_LOAN_RESPONSE_CODES = {
  APPROVED: '00',
  INSUFFICIENT_FUNDS: '51',
  DO_NOT_HONOR: '05',
  RISK_BLOCKED: '101',
  NOT_FINALIZED: null,
};
