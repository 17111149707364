import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { SUCCESS } from 'lib/constants';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import { filterDebitOrAchMethods, FormatAmount } from 'lib/utils';
import { useSentry } from 'lib/hooks/useSentry';

import { useServices } from 'pages/AuthChecker/services';
import { usePlans } from 'pages/Plans/services';

import { CheckoutProgressBar, Container, Icon, Loading, Subtitle, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { PaymentMethodConnect } from './components/PaymentMethodConnect';

import AlleAddCard from 'pages/AddCard/AlleAddCard';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { Trans, useTranslation } from 'react-i18next';
import DeclinedCardModal from './components/DeclinedCardModal';
import { theme } from 'config/theme';

const DownPayment = () => {
  const { navigate } = useNavigation();
  const {
    isDemo,
    setPageIndex,
    loan,
    setDownPaymentMethod,
    setInstallmentMethod,
    selectedPlan,
    paymentMethodFees,
    paymentMethods,
    isAlle,
    paymentFailError,
    setPaymentFailError,
    declinedCardError,
  } = useStore();
  const { getLoan } = useServices();
  const { patchLoan, getPaymentMethods } = usePlans();
  const { captureException } = useSentry();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const { t: translate } = useTranslation();
  const [showDeclinedCardModal, setShowDeclinedCardModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const isComingFromRecurring = !!searchParams?.get('isComingFromRecurring');

  const loadSegmentController = useRef(true);
  const hideShowDeclinedCardModal = () => setShowDeclinedCardModal(false);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.postCheckoutAddCardLoad, {
        application: 'checkout',
        add_card_type: 'down_payment',
      });
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    if (declinedCardError) {
      setShowDeclinedCardModal(true);
    }
  }, []);

  useEffect(() => {
    setPageIndex(3);
  }, [setPageIndex]);

  const navigateToPaymentList = async (IFrameEvent) => {
    const loanId = loan?.id ?? useStore.getState()?.loan?.id;

    try {
      setLoading(true);
      const debitOrAchMethods = filterDebitOrAchMethods(paymentMethods);
      const loanData = await getLoan(loanId);

      if (loanData?.status === 'CANCELLED') {
        navigate(`expired`);
        setLoading(false);
        return;
      }

      let routeName = '';

      if (isComingFromRecurring) {
        routeName = 'method-list';
        if (!(debitOrAchMethods?.length > 0 && IFrameEvent?.paymentMethod?.storedCard?.type !== 'DEBIT')) {
          setInstallmentMethod(IFrameEvent?.paymentMethod);
        }
      } else {
        routeName = 'down-payment';
        await patchLoan({
          loanId: loanId,
          downPaymentPaymentMethodId: IFrameEvent?.paymentMethod?.id,
        });
        setDownPaymentMethod(IFrameEvent?.paymentMethod);

        if (paymentFailError) {
          setInstallmentMethod(IFrameEvent?.paymentMethod);
          setPaymentFailError(false);
        }
      }

      await getPaymentMethods();

      navigate(routeName);
      setLoading(false);
    } catch (err) {
      captureException('Add Card Navigate Error', {
        error: err,
        page: 'Add Card',
        message: 'Navigate to payment list error',
        loanId: loanId || 'No Loan',
      });

      navigate(`loan-cancelled`);
      setLoading(false);
    }
  };

  const onConnectCardHandler = async (payload: { status: string }) => {
    if (payload && payload?.status === SUCCESS) {
      sendActionSegmentEvent(WPQSegmentNames.addCardClicked);
      await navigateToPaymentList(payload);
    }
  };

  const paymentFee = paymentMethodFees?.CREDIT?.percent;

  if (loading) {
    return (
      <CenterLoading>
        <Loading size={40} />
      </CenterLoading>
    );
  }

  if (isAlle) {
    return <AlleAddCard onConnectCardHandler={onConnectCardHandler} isComingFromRecurring={isComingFromRecurring} />;
  }

  const addCardTitle = translate('addCard.recurringTitle.text', {
    titleText: `<strong>${translate('addCard.recurringTitle.content')}</strong>`,
  });

  return (
    <Container showBackButton={true}>
      <ContentContainer justify="space-between">
        <InnerContainer>
          <BarContainer>
            <CheckoutProgressBar />
            {!isComingFromRecurring ? (
              <>
                <Subtitle m={'10px 0px 12px 0px'} color={theme.main.midnightBlue}>
                  <Trans
                    i18nKey="addCard.subTitle.yourDownPaymentOf"
                    values={{ amount: FormatAmount(selectedPlan?.downPaymentAmount) }}
                    components={{
                      bold: <strong />,
                    }}
                  ></Trans>
                </Subtitle>
              </>
            ) : (
              <Title m={'10px 0px 12px 0px'}>
                {translate('addCard.title.text')} <GreenText>{translate('addCard.title.content')}</GreenText>
              </Title>
            )}
          </BarContainer>
          <PaymentMethodConnect onConnect={onConnectCardHandler} isDemo={isDemo || false} />
        </InnerContainer>
        {paymentFee ? (
          <InfoContainer>
            <Icon width={20} height={20} src={'info_circle_outline'} />
            <InfoText
              dangerouslySetInnerHTML={{
                __html: translate('addCard.info.text', {
                  paymentFee: paymentFee,
                }),
              }}
            />
          </InfoContainer>
        ) : null}
        {showDeclinedCardModal ? (
          <DeclinedCardModal show={showDeclinedCardModal} hideModal={hideShowDeclinedCardModal} />
        ) : null}
      </ContentContainer>
    </Container>
  );
};

const CenterLoading = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
  color: ${(props) => props.theme.main.midnightBlue};
`;

const GreenText = styled.span`
  color: ${(props) => props.theme.main.green};
`;

const BarContainer = styled.div`
  position: sticky;
  top: 4px;
  padding-top: 8px;
  background: #ffffff;
`;

export default DownPayment;
