import { useEffect, useState } from 'react';
import SmartySDK from 'smartystreets-javascript-sdk';
import { IAddress, IAutoComplete } from 'pages/PersonalAddressInfo/types';
import { APP_SMARTY_KEY } from 'config';

const useAddressAutocomplete = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [autocompleteData, setAutocompleteData] = useState<IAutoComplete[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    core,
    usAutocompletePro: { Lookup },
  } = SmartySDK || {};

  const smartyClientKey = APP_SMARTY_KEY!;
  const credentials = new core.SharedCredentials(smartyClientKey);
  const clientBuilder = new core.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud']);
  const client = clientBuilder.buildUsAutocompleteProClient();

  useEffect(() => {
    if (searchTerm?.length > 0) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const lookup = new Lookup(searchTerm);
          lookup.maxResults = 10;
          lookup.preferRatio = 33;
          lookup.source = 'all';

          const results = await client.send(lookup);
          logSuggestions(results);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [searchTerm]);

  const logSuggestions = async (response) => {
    const _autoCompleteData: IAutoComplete[] = [];
    response?.result?.forEach((address: IAddress, index: number) => {
      const { city, state, zipcode, streetLine } = address || {};
      const addressLine = `${streetLine}, ${city}, ${state}, ${zipcode}`;
      const obj = {
        id: index,
        label: addressLine,
        street: streetLine,
        city,
        state,
        zip: zipcode,
      };
      _autoCompleteData.push(obj);
    });

    setAutocompleteData(_autoCompleteData);
  };

  return {
    searchTerm,
    setSearchTerm,
    autocompleteData,
    loading,
  };
};

export default useAddressAutocomplete;
