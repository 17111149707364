import { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { emailPattern } from 'lib/utils/Validators';

import { Box, Button, Subtitle, Title, UnderlinedTextField as TextField, HaveQuestionsModal } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import styled from 'styled-components';
import { IframeEventTypes, PageEventActions, useIframeEvents } from 'lib/hooks/useIframeEvents';

const AlleEmailCollection = ({ control, isValid, checkKeyDown, handleClick, loading }) => {
  const { pushEvent } = useIframeEvents();

  useEffect(() => {
    pushEvent(IframeEventTypes.PAGE, PageEventActions.EMAIL_COLLECTION);
  }, []);

  return (
    <Container>
      <Title m={'10px 0px 8px 0px'} textAlign="center" fontFamily="Petersburg">
        We'll need your email address
      </Title>
      <Subtitle m={'0px 0px 24px 0px'} textAlign="center" fontFamily="Graphik" fontSize="16px">
        We need your email to keep you updated on your application status.
      </Subtitle>
      <Box>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: true,
            pattern: {
              value: emailPattern,
              message: 'Please enter a valid e-mail address (ex:name@mail.com)',
            },
          }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            return (
              <TextField
                id="email-input"
                variant="filled"
                data-testid="email"
                data-neuro-label="email"
                label="Email"
                type="email"
                onKeyDown={checkKeyDown}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            );
          }}
        />
      </Box>
      <ButtonContainer>
        <Button
          disabled={!isValid || loading}
          loading={loading}
          onClick={handleClick}
          m="32px 0 0"
          black={true}
          uppercase={true}
          thinText={true}
          fontWeight={400}
          fontSize={11}
        >
          Continue
        </Button>
      </ButtonContainer>
      <HaveQuestionsModal />
    </Container>
  );
};

export default AlleEmailCollection;

const Container = styled(ContentContainer)`
  text-align: center;
  font-family: 'Graphik';
`;

const ButtonContainer = styled.div`
  margin-bottom: 32px;
`;
