import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { API_URL } from 'config';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';

import { useIncomeVerificationLatest } from './services';
import { useTranslation } from 'react-i18next';

const ConnectWithOauth = () => {
  const { navigate } = useNavigation();
  const { oauthStateId } = useParams();
  const { t: translate } = useTranslation();
  const { getIncomeVerificationLatest } = useIncomeVerificationLatest();
  const { setAppLoading } = useStore();

  useEffect(() => {
    setAppLoading(true);
    window?.addEventListener('message', receiveMessage, false);
    return () => {
      setAppLoading(false);
      window?.removeEventListener('message', receiveMessage);
    };
  }, []);

  const getUrl = () => {
    const { incomeVerification, defaultLanguage, sessionApiData } = useStore.getState() || {};
    const { featureFlags } = sessionApiData || {};

    const iframeURL = featureFlags?.mxEnabled
      ? `${API_URL}/v1/asset-reports/mx-iframe/${incomeVerification?.id}?language=${defaultLanguage}`
      : `${API_URL}/v1/asset-reports/plaid-iframe/${incomeVerification?.id}/oauth?oauth_state_id=${oauthStateId}&language=${defaultLanguage}`;

    return iframeURL;
  };

  const receiveMessage = async ({ origin, data }) => {
    const { application, borrower } = useStore.getState() || {};

    if (origin === API_URL) {
      const iFrameEvent = JSON.parse(data);

      switch (iFrameEvent.event) {
        case 'expired':
          await getIncomeVerificationLatest(borrower?.id);
          break;
        case 'complete':
          setAppLoading(true);
          getIncomeVerificationLatest(borrower?.id);
          break;
        case 'institution-complete':
          setAppLoading(true);
          if (iFrameEvent?.data?.isInstitutionFound) {
            getIncomeVerificationLatest(borrower?.id);
          } else {
            navigate(`upload-paystub/${application?.id}`);
          }
          break;
        case 'loading':
          setAppLoading(true);
          break;
        case 'loaded':
          setAppLoading(false);
          break;
        case 'plaid-event':
          break;
        case 'plaid-error':
          setAppLoading(true);
          getIncomeVerificationLatest(borrower?.id);
          break;
      }
    }
  };

  return (
    <Container>
      <iframe
        id="add-plaid-auth"
        data-testid="the-frame"
        title={translate('connectWithOauth.title')}
        width="100%"
        height="100%"
        frameBorder="0"
        src={getUrl()}
        data-hj-allow-iframe=""
      />
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    height: calc(100vh - 90px);
  }

  iframe {
    height: 100%;
    width: 100%;
  }
`;

export default ConnectWithOauth;
