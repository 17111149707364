import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { useNavigation } from 'lib/hooks/useNavigation';
import { TRACK_VERSION, WEBPREQUAL } from 'lib/constants';

import { Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useTranslation } from 'react-i18next';

const TreatmentType = () => {
  const { navigate } = useNavigation();
  const { trackPage, trackSegmentEvent } = useSegment();
  const { t: translate } = useTranslation();
  const { setSessionData } = useStore();
  const { application, borrower } = useStore.getState() || {};

  const [treatmentType, setTreatmentType] = useState<string>('');
  const [validationError, setValidationError] = useState<boolean>();

  useEffect(() => {
    trackSegmentEvent('treatment_type_viewed', {
      application: WEBPREQUAL,
      version: TRACK_VERSION,
    });
  }, []);

  useEffect(() => {
    trackPage('treatmentType', {
      application: WEBPREQUAL,
      applicationId: application?.id,
      borrowerId: borrower?.id,
    });
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    setTreatmentType(value);
  };

  const submit = () => {
    setSessionData({ treatmentType });
    navigate('request-amount');
  };

  const checkKeyDown = (e) => {
    if (e.code === 'Enter' || Number(e.keyCode) === 13) {
      e.preventDefault();
      if (treatmentType) {
        submit();
      }
    }
  };

  const navigateTreatmentCost = () => {
    navigate('request-amount');
  };

  return (
    <Container>
      <ContentContainer>
        <Icon src={'receipt-alt'} />
        <Title data-testid="treatment-type-title" m={'10px 0px 8px 0px'}>
          {translate('treatmentType.title')}
        </Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('treatmentType.subTitle')}</Subtitle>
        <TextField
          id="treatment-type"
          variant="filled"
          data-testid="treatment-type"
          data-neuro-label="treatment-type"
          label={translate('treatmentType.form.label')}
          value={treatmentType}
          onChange={handleChange}
          onKeyDown={checkKeyDown}
        />
      </ContentContainer>
      <ButtonContainer>
        <Button dataTestId="submit-treatment-type" onClick={submit} disabled={!treatmentType || validationError}>
          {translate('buttons.continue')}
        </Button>
        <NotToAnswer onClick={navigateTreatmentCost}>{translate('buttons.noAnswer')}</NotToAnswer>
      </ButtonContainer>
    </Container>
  );
};

export default TreatmentType;

const NotToAnswer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
  color: #b7bcc1;
`;
