import { COGNITO_PUBLISHABLE_KEY } from 'config';

export const useCognito = () => {
  const createFlow = ({ borrowerId, identityVerificationId, templateId }) => {
    // @ts-ignore Flow
    const flow = new Flow({
      publishableKey: COGNITO_PUBLISHABLE_KEY,
      templateId,
      user: {
        customerReference: `${borrowerId}-${identityVerificationId}`,
      },
    });

    return flow;
  };

  return {
    createFlow,
  };
};
