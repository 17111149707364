import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useAlert } from 'react-alert';

import { Button, Calendar, Container, Icon, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { useServices } from 'pages/AuthChecker/services';
import { useSentry } from 'lib/hooks/useSentry';
import { GetOrdinal } from 'lib/utils';
import { useNavigation } from 'lib/hooks/useNavigation';
import { DENTAL_TREATMENT_DATA_GATHERING, REQUEST_PREFUNDING_STATUS, WEBPREQUAL } from 'lib/constants';
import useStore, { LanguageEnum } from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { useTranslation } from 'react-i18next';

type FundedContract = {
  id: string;
  dueAt: string;
};

const DueDateAlignment = () => {
  const alert = useAlert();
  const { trackPage } = useSegment();
  const { navigate } = useNavigation();
  const { captureException } = useSentry();
  const { getFundedContracts, alignDueDates } = useServices();
  const { t: translate } = useTranslation();
  const { features, loan, borrower } = useStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [alignedDueDate, setAlignedDueDate] = useState<Date | null>(null);
  const [fundedContractList, setFundedContractList] = useState<Array<FundedContract>>();
  const [isDueDateAlignmentActive, setIsDueDateAlignmentActive] = useState<boolean>(false);
  const [preselectedDates, setPreselectedDates] = useState<Array<Date>>();

  const title = !isDueDateAlignmentActive ? translate('dueDateAlignment.title1') : translate('dueDateAlignment.title2');

  useEffect(() => {
    sendSegmentEvent('align_payment_welcome_screen');
    getFundedContractList();
  }, []);

  const sendSegmentEvent = (action: string) => {
    trackPage(action, {
      application: WEBPREQUAL,
      loan_id: loan?.id,
      borrower_id: borrower?.id,
    });
  };

  const getFundedContractList = async () => {
    try {
      setLoading(true);
      const fundedContracts = await getFundedContracts();
      const list = fundedContracts?.contents?.map((contract) => ({
        id: contract?.id,
        dueAt: contract?.paymentSchedules?.[0]?.dueAt,
      }));
      setFundedContractList(list);

      if (list?.length) {
        const dates = Array.from({ length: list.length }, () => new Date());
        list?.forEach((contract, index) => {
          dates[index].setDate(Number(dayjs(contract.dueAt).format('DD')));
        });
        setPreselectedDates(dates);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      captureException('Get Loan List Error in Due Date Alignment Page', {
        error: err,
        page: 'DueDateAlignment',
        message: 'Get Loan List Error in Due Date Alignment Page',
        loanId: loan?.id || 'No Loan',
      });

      return null;
    }
  };

  const activateDueDateAlignmentState = () => {
    sendSegmentEvent('align_payment_date_selection_screen');
    setIsDueDateAlignmentActive(true);
    setAlignedDueDate(null);
  };

  const handleCancelClick = () => {
    if (isDueDateAlignmentActive) {
      setIsDueDateAlignmentActive(false);
      resetPreselectedDates();
      setAlignedDueDate(null);
    } else {
      sendSegmentEvent('align_payment_no_btn');
      continueFlow();
    }
  };

  const resetPreselectedDates = () => {
    if (fundedContractList?.length) {
      const dates = Array.from({ length: fundedContractList.length }, () => new Date());
      fundedContractList?.forEach((contract, index) => {
        dates[index].setDate(Number(dayjs(contract.dueAt).format('DD')));
      });
      setPreselectedDates(dates);
    }
  };

  const setNewPaymentDate = async () => {
    if (alignedDueDate) {
      setLoading(true);
      sendSegmentEvent('align_payment_date_select');
      const response = await alignDueDates(dayjs(alignedDueDate).format('YYYY-MM-DD'));
      if (response?.success) {
        const hasDataGathering = features?.includes(DENTAL_TREATMENT_DATA_GATHERING);
        const navigateKey = hasDataGathering ? 'data-gathering' : 'success';
        navigate(navigateKey);
      } else if (response?.error) {
        alert.info(
          response?.error?.message?.includes('409')
            ? translate('dueDateAlignment.info1')
            : translate('dueDateAlignment.info2'),
        );
      }
    }
  };

  const continueFlow = () => {
    const hasSurvey = features?.includes(REQUEST_PREFUNDING_STATUS);
    const hasDataGathering = features?.includes(DENTAL_TREATMENT_DATA_GATHERING);

    const navigateKey = hasDataGathering ? 'data-gathering' : hasSurvey ? 'survey' : 'success';
    navigate(navigateKey);
  };

  const onDateChange = (value) => {
    if (preselectedDates?.length ?? -1 > 0) {
      setPreselectedDates([]);
    }
    setAlignedDueDate(value);
  };

  return (
    <Container showBackButton={false} backUrl="confirm">
      <ContentContainer>
        <Icon src="calendar" />
        <Title m={'10px 0px 8px 0px'}>{title}</Title>
        {!isDueDateAlignmentActive ? <Text>{translate('dueDateAlignment.date.payment')}</Text> : null}
        <CalendarWrapper>
          <Calendar
            loading={loading}
            hideHeader
            value={preselectedDates?.[0] || alignedDueDate || null}
            isPreselectedDatesLocked={!isDueDateAlignmentActive}
            preselectedDates={preselectedDates}
            onChangeDate={onDateChange}
          />
        </CalendarWrapper>
        {!alignedDueDate ? (
          <SelectedDayPreviewContainer>
            <SelectedDayIcon />
            <Text>{translate('dueDateAlignment.date.notAlignedDue')}</Text>
          </SelectedDayPreviewContainer>
        ) : (
          <SelectedDayPreviewContainer>
            <Icon src="info_circle_outline" />
            <Text
              dangerouslySetInnerHTML={{
                __html: translate('dueDateAlignment.date.alignedDue', {
                  date: GetOrdinal(dayjs(alignedDueDate).date()),
                }),
              }}
            ></Text>
          </SelectedDayPreviewContainer>
        )}
      </ContentContainer>
      <ButtonContainer>
        {alignedDueDate && isDueDateAlignmentActive ? (
          <Button disabled={loading} onClick={setNewPaymentDate}>
            {translate('dueDateAlignment.date.newPayment')}
          </Button>
        ) : null}
        {!isDueDateAlignmentActive ? (
          <Button disabled={loading} onClick={activateDueDateAlignmentState}>
            {translate('dueDateAlignment.date.alignPayment')}
          </Button>
        ) : null}
        <Button disabled={loading} secondary={true} onClick={handleCancelClick}>
          {!isDueDateAlignmentActive ? translate('buttons.nope') : translate('buttons.nevermind')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default DueDateAlignment;

export const Text = styled.div`
  color: ${(props) => props.theme.main.grayColor};
  font-size: 14px;

  span {
    font-weight: bold;
  }
`;

export const CalendarWrapper = styled.div`
  margin: 35px auto 0;
`;

export const SelectedDayPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 38px auto 0;
  gap: 16px;

  div:nth-of-type(2) {
    max-width: 188px;
  }
`;

export const SelectedDayIcon = styled.div`
  width: 28px;
  height: 28px;
  border: 5px solid ${(props) => props.theme.main.midnightBlue60};
`;
