import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';
import StorageService from 'lib/services/Storage';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';

export const useIdleTimeout = ({ idleTime = 1 }) => {
  const location = useLocation();
  const { navigate } = useNavigation();
  const idleTimeout = 60000 * idleTime; // Minutes
  const [isIdle, setIdle] = useState(false);

  const handleIdle = () => {
    try {
      const { organization } = useStore.getState() || {};
      if (
        !(
          location.pathname.includes('terms') ||
          location.pathname.includes('phone') ||
          location.pathname === `/${organization?.slug}`
        )
      ) {
        setIdle(true);
        navigate('');
        StorageService.clearUserData();
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: idleTimeout / 2,
    onIdle: handleIdle,
    debounce: 500,
  });

  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
