import { useCallback, useEffect, useRef, useState } from 'react';

import useStore from 'lib/hooks/useStore';
import { useSegment } from 'lib/hooks/useSegment';
import { useNavigation } from 'lib/hooks/useNavigation';
import { IVScreenTypes } from 'lib/hooks/FeatureManagement/Flags';
import { useFlags } from 'lib/hooks/FeatureManagement/FlagsContext';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useEligibilityService } from 'pages/AuthChecker/services';
import { ButtonClickedEventActions, IframeEventTypes, useIframeEvents } from 'lib/hooks/useIframeEvents';

import DefaultConnectBankAccount from './DefaultConnectBankAccount';
import MultiScreenConnectBankAccount from './MultiScreenConnectBankAccount';
import SingleConnectBankAccount from './SingleConnectBankAccount';
import { useSSEContext } from 'lib/hooks/SSE/useSSEContext';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';

export enum INCOME_PROVIDER {
  MX = 'MX',
  PLAID = 'PLAID',
}

const getScreenTypeForSegmentEvent = (screenType: string) => {
  let screenVariation = 'control';

  if (screenType === IVScreenTypes.SINGLE) {
    screenVariation = 'singleScreen';
  } else if (screenType === IVScreenTypes.MULTI) {
    screenVariation = 'multiScreen1';
  }

  return screenVariation;
};

const ConnectBankAccount = () => {
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { trackIncomeVerification } = useSegment();
  const { flags } = useFlags();
  const { startCheckApplicationStatus } = useSSEContext();
  const { pushEvent } = useIframeEvents();
  const { getBorrowerIncomeVerification } = useEligibilityService();

  const { organization, setPageIndex, application, isAlle, setSessionApiData, sessionApiData } = useStore();
  const { featureFlags } = sessionApiData || {};

  const [loading, setLoading] = useState(false);
  const [isOptions, setIsOptions] = useState(false);
  const [screenType, setScreenType] = useState<IVScreenTypes | string>(IVScreenTypes.DEFAULT);
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  useEffect(() => {
    const { application } = useStore.getState() || {};
    if (application?.ivAllow?.paystub) {
      setIsOptions(true);
    }

    setSessionApiData({
      featureFlags: { ...featureFlags, screenType },
    });
  }, []);

  useEffect(() => {
    if (loadSegmentController.current) {
      const screenTypeFlag = flags.IV.screenType.getValue();
      setScreenType(screenTypeFlag);
      sendLoadSegmentEvent(WPQSegmentNames.incomeVerificationLandingLoad, {
        screenVariation: getScreenTypeForSegmentEvent(screenTypeFlag),
      });
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const goToPlaid = useCallback(async () => {
    if (isAlle) {
      pushEvent(IframeEventTypes.BUTTON_CLICKED, ButtonClickedEventActions.CONNECT_BANK_ACCOUNT_BUTTON_CLICKED);
    }
    sendActionSegmentEvent(WPQSegmentNames.ivConnectBankAcountClicked);
    const { application } = useStore.getState();
    const incomeProvider = featureFlags?.mxEnabled ? INCOME_PROVIDER.MX : INCOME_PROVIDER.PLAID;

    try {
      trackEvent(AnalyticEventNames.CWP_LINK_ACCOUNT);
      trackIncomeVerification(organization, application, incomeProvider);
      setLoading(true);

      const response = await getBorrowerIncomeVerification('ASSET_REPORT');

      if (response?.id) {
        setLoading(false);
        const authPath = featureFlags?.mxEnabled ? `mx-auth` : `plaid-auth`;
        navigate(`${authPath}/${response.id}`);
      }
    } catch (error) {
      console.error('Error in goToPlaid:', error);
      setLoading(false);
    }
  }, [isAlle, useStore, featureFlags, organization, setLoading, trackEvent, trackIncomeVerification, navigate]);

  const goToOriginalApproval = async () => {
    startCheckApplicationStatus({ subscribeFlow: true, applicationId: application?.id });
  };

  const uploadPaystub = useCallback(() => {
    if (isAlle) {
      pushEvent(
        IframeEventTypes.BUTTON_CLICKED,
        ButtonClickedEventActions.CONNECT_PAYROLL_PAYSTUB_INSTEAD_BUTTON_CLICKED,
      );
    }

    const { application } = useStore.getState();
    navigate(`upload-paystub/${application?.id}`);
  }, [isAlle, useStore, navigate]);

  useEffect(() => {
    setPageIndex(0);
  }, [setPageIndex]);

  const renderPage = () => {
    const commonProps = {
      isOptions,
      uploadPaystub,
      featureFlags,
      organization,
      goToOriginalApproval,
    };

    const singleScreenContent = <SingleConnectBankAccount goToPlaid={goToPlaid} loading={loading} {...commonProps} />;
    const multiScreenContent = <MultiScreenConnectBankAccount {...commonProps} />;
    const defaultScreenContent = <DefaultConnectBankAccount goToPlaid={goToPlaid} loading={loading} {...commonProps} />;

    switch (screenType) {
      case IVScreenTypes.SINGLE:
        return singleScreenContent;
      case IVScreenTypes.MULTI:
        return multiScreenContent;
      default:
        return defaultScreenContent;
    }
  };

  return renderPage();
};

export default ConnectBankAccount;
