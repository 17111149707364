import { theme } from 'config/theme';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Subtitle, TextField } from 'lib/components';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';

interface SendApprovalEmailProps {
  openThanksModal: (status: boolean) => void;
  page: string;
}

interface State {
  email: string;
}

const SendApprovalEmail = ({ openThanksModal, page }: SendApprovalEmailProps) => {
  const { t: translate } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm<State>({ mode: 'onChange' });
  const { sendActionSegmentEvent } = useSegmentContext();

  const onSubmit = async (formData: State): Promise<void> => {
    const segmentName =
      page === 'approval' ? WPQSegmentNames.approvalCardEmailSubmit : WPQSegmentNames.whatHappenNextEmailSubmit;
    sendActionSegmentEvent(segmentName);
    openThanksModal(true);
  };

  const handleEmailBlur = () => {
    const segmentName =
      page === 'approval' ? WPQSegmentNames.approvalCardEmailFilled : WPQSegmentNames.whatHappenNextEmailFilled;
    sendActionSegmentEvent(segmentName);
    sendActionSegmentEvent(WPQSegmentNames.successEmailFilled);
  };

  return (
    <>
      <SendApprovalEmailContainer>
        <EmailContainer>
          <Subtitle m="0 0 8px 0" color={theme.main.textColor} fontSize="14px">
            {translate('approvalCard.email.title')}
          </Subtitle>

          <Form onSubmit={handleSubmit(onSubmit)} hasError={!!errors?.email}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  sx={{
                    '& .MuiFilledInput-root': {
                      border: '0px !important',
                      borderTopRightRadius: '0 !important',
                      borderBottomRightRadius: '0 !important',
                    },
                    '& .MuiFormControl-root': { flex: 1 },
                  }}
                  id="email"
                  variant="filled"
                  data-testid="email"
                  data-neuro-label="email"
                  type="email"
                  label={translate('planSummary.email.label')}
                  autoComplete="off"
                  value={value}
                  onChange={onChange}
                  onBlur={handleEmailBlur}
                  error={!!error}
                />
              )}
            />
            <ButtonContainer>
              <StyledButton
                disabled={!isValid || !!errors?.email}
                onClick={handleSubmit(onSubmit)}
                data-testid="submit"
              >
                {translate('buttons.submit')}
              </StyledButton>
            </ButtonContainer>
          </Form>

          <ErrorMessage>
            {Boolean(watch('email')) && !isValid ? <span>{translate('planSummary.email.error')}</span> : null}
          </ErrorMessage>
        </EmailContainer>
      </SendApprovalEmailContainer>
    </>
  );
};
export default SendApprovalEmail;

const SendApprovalEmailContainer = styled.div`
  margin-top: 24px;
`;

const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.main.white};
  padding: 16px 24px;
  margin-bottom: 24px;
`;

const Form = styled.form<{ hasError: boolean }>`
  flex: 1;
  display: flex;
  border: 1px solid ${(props) => (props?.hasError ? '#EC3360' : '#879097')};
  border-radius: 4px;

  label {
    font-family: ${theme.fonts.primary}, serif !important;
  }

  > input[type='email'] {
    width: 100%;
    flex: 1;
    font-size: 16px;
    font-family: ${theme.fonts.primary}, serif !important;
  }
`;

const StyledButton = styled(Button)`
  border: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.7px;
  padding: 15px 16px;
  max-height: 58px;
  color: #ffffff;
  background: #00c37d;
  cursor: pointer;
  font-family: ${theme.fonts.primary}, serif !important;

  :disabled {
    color: #b7bcc1;
    background: #e7e9ea;
  }
`;

const ButtonContainer = styled.div<{ center?: boolean }>`
  max-width: 100px;

  > button {
    margin-bottom: 0;
    border-radius: 0 4px 4px 0;
  }
`;

const ErrorMessage = styled.div`
  color: #ec3360;
  margin-top: 4px;
  font-size: 14px;
  font-family: ${theme.fonts.primary}, serif !important;
`;
