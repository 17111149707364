import React, { useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Icon } from '@frontend/cherry-library';

import { APP_ENV } from 'config';
import { WEB_SITE, WEBPREQUAL } from 'lib/constants';
import { AnalyticEventNames, useAnalytics } from 'lib/hooks/useAnalytics';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { useSegment } from 'lib/hooks/useSegment';
import { useSentry } from 'lib/hooks/useSentry';
import useStore from 'lib/hooks/useStore';
import { phonePattern } from 'lib/utils/Validators';
import { Box, Button, Container, PhoneInput, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer, FooterText } from 'lib/components/Common';
import { generateSevenDigitNumber } from 'lib/utils';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useTranslation } from 'react-i18next';
import { getExternalLinkLangParam } from 'lib/utils/ExternalLinkLangParam';
import { useOtp } from 'lib/hooks/useOtp';
import { ReactComponent as CheckMark } from 'assets/images/circle_check_gray.svg';
import { ReactComponent as Mobile } from 'assets/images/mobile.svg';
interface State {
  phone: string;
}

const PhoneNumber = () => {
  const alert = useAlert();
  const { initPage } = useNeuroId();
  const { navigate } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { t: translate } = useTranslation();
  const { captureException, setTag } = useSentry();
  const { trackSegmentEvent } = useSegment();
  const { startOTPFlow, snaLoading, snaVerified } = useOtp();
  const { setPageIndex, sessionData, flowType, defaultLanguage } = useStore();
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);
  let failedAttemptCount = 0;
  const [countDown, setCountDown] = useState(9);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.phoneNumberLoad);
      initPage('phone');
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  useEffect(() => {
    setPageIndex(2);
  }, [setPageIndex]);

  const footerText = translate('phone.footer.text', {
    eSignDisclosuresAndConsent: `<a target="_blank" href=${WEB_SITE}/e-sign-disclosure${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('phone.footer.eSignDisclosuresAndConsent')}</a>`,
    termsOfService: `<a target="_blank" href=${WEB_SITE}/terms${getExternalLinkLangParam(defaultLanguage)}>${translate(
      'phone.footer.termsOfService',
    )}</a>`,
    privacyPolicy: `<a target="_blank" href=${WEB_SITE}/privacy${getExternalLinkLangParam(defaultLanguage)}>${translate(
      'phone.footer.privacyPolicy',
    )}</a>`,
    usaPatriotAct: `<a target="_blank" href=${WEB_SITE}/terms/#patriot-act${getExternalLinkLangParam(
      defaultLanguage,
    )}>${translate('phone.footer.usaPatriotAct')}</a>`,
  });
  const defaultPhone = () => {
    if (APP_ENV === 'dev') {
      return `216${String(generateSevenDigitNumber())}`;
    }
    return sessionData?.phone;
  };

  const refreshPhone = () => {
    setValue('phone', defaultPhone());
  };

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isValid } = {},
  } = useForm<State>({
    mode: 'onChange',
    defaultValues: { phone: defaultPhone() },
  });

  const onSubmit = async (formData: State) => {
    const { application, borrower, utmMedium } = useStore.getState() || {};
    try {
      trackEvent(AnalyticEventNames.PI_SEND_SEC_CODE);
      trackSegmentEvent(AnalyticEventNames.PI_SEND_SEC_CODE, {
        applicationId: application?.id,
        borrowerId: borrower?.id,
        application: WEBPREQUAL,
        patientPhone: formData?.phone,
      });
      if (formData?.phone) {
        if (flowType === 'CheckoutWithEmailFlow' && formData?.phone !== borrower?.phone) {
          if (++failedAttemptCount === 3) {
            trackSegmentEvent(AnalyticEventNames.PHONE_NOT_MATCHING_WITH_LOAN, {
              applicationId: application?.id,
              borrowerId: borrower?.id,
              application: WEBPREQUAL,
              phone: formData?.phone,
            });
            navigate('');
          }
          alert.info(`${translate('phone.notMatch')}`);
          return;
        }
        setTag('phone', formData?.phone);
        startOTPFlow({ phone: formData?.phone });
      } else {
        alert.error(`${translate('phone.validPhone')}}`);
      }
    } catch (err) {
      captureException(err, { phone: formData?.phone });
    }
  };

  const checkKeyDown = (e) => {
    if (e?.code === 'Enter' || Number(e?.keyCode) === 13) {
      e?.preventDefault();
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    }
  };

  useEffect(() => {
    if (!snaLoading || countDown <= 0) return;

    const intervalId = setInterval(() => {
      setCountDown((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [snaLoading, countDown]);

  const countDownTimer = countDown > 0 ? `(00:0${countDown})` : '';

  const handleClick = () => {
    sendActionSegmentEvent(WPQSegmentNames.phoneNumberContinueClicked, {
      patientPhone: getValues('phone'),
    });
    handleSubmit(onSubmit)();
  };

  const buttonTextDecider = () => {
    if (snaLoading) {
      return `${translate('buttons.loading')} ${countDownTimer}`;
    } else {
      return translate('buttons.continue');
    }
  };

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <IconContainer>
          <Icon src={Mobile} width={30} height={30} />
        </IconContainer>
        <Title data-testid="phone-number-title" m={'10px 0px 8px 0px'}>
          {translate('phone.enterYourMobileNumber')}
        </Title>
        <Subtitle m={'0px 0px 24px 0px'}>{translate('phone.needPhoneNumber')}</Subtitle>
        <Box>
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            rules={{ required: true, pattern: phonePattern }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="phone"
                variant="filled"
                data-testid="phone"
                data-neuro-label="phone"
                type="tel"
                label={translate('phone.personalMobileNumber')}
                disabled={flowType === 'PointOfSalesFlow'}
                onKeyDown={checkKeyDown}
                InputProps={{
                  inputComponent: PhoneInput,
                  disableUnderline: true,
                }}
                value={value}
                onChange={onChange}
                onFocus={() => {
                  sendActionSegmentEvent(WPQSegmentNames.phoneNumberInputClicked);
                }}
                onBlur={(e) => {
                  sendActionSegmentEvent(WPQSegmentNames.phoneNumberInputFilled, { metaData: e.target.defaultValue });
                  onBlur();
                }}
                error={!!error}
                helperText={error ? error?.message : null}
              />
            )}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        {APP_ENV === 'dev' ? (
          <Button dataTestId="refresh-phone-button" secondary={true} onClick={refreshPhone}>
            {translate('buttons.refreshPhone')}
          </Button>
        ) : null}

        <Button
          dataTestId="continue-button"
          disabled={!isValid || snaLoading}
          loading={countDown === 0}
          onClick={handleClick}
        >
          <ContinueButtonContainer>
            {snaVerified && <Icon src={CheckMark} />}
            {snaVerified ? `${translate('buttons.verifiedPhone')} ` : buttonTextDecider()}
          </ContinueButtonContainer>
        </Button>
        <FooterText dangerouslySetInnerHTML={{ __html: footerText }} />
      </ButtonContainer>
    </Container>
  );
};

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const ContinueButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

export default PhoneNumber;
