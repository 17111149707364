import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Confetti from 'react-confetti';

import { confettiColors } from 'config/theme';
import { Container, Icon, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { useTranslation } from 'react-i18next';

const PosCongrats = () => {
  const { t: translate } = useTranslation();
  const [confettiActive, setConfettiActive] = useState(true);

  useEffect(() => {
    setConfettiActive(true);

    setTimeout(() => {
      setConfettiActive(false);
    }, 5000);
  }, []);

  return (
    <Container showBackButton={false}>
      <ContentContainer>
        <InnerContainer>
          <Icon src={'party'} />
          <Title m={'10px 0px 0px 0px'}>{translate('posCongrats.title')}</Title>
        </InnerContainer>
        {confettiActive && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            numberOfPieces={300}
            colors={confettiColors}
            recycle={false}
          />
        )}
      </ContentContainer>
    </Container>
  );
};

export default PosCongrats;

const InnerContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
