import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { showChatBotYellowAI } from 'lib/utils';
import { theme } from 'config/theme';
import { Icon } from '../Icon';

export const ChatBotYellowAIButton = () => {
  const { t: translate } = useTranslation();
  const { sendActionSegmentEvent } = useSegmentContext();

  const openYellowAIChatbot = () => {
    sendActionSegmentEvent(WPQSegmentNames.chatbotYellowAIClicked);
    showChatBotYellowAI();
  };

  return (
    <HelpMeButton onClick={openYellowAIChatbot}>
      <Icon width={16} height={16} m={'0px 10px'} src={'message-white'} />
      <Text>{translate('header.modal.aiChat')}</Text>
    </HelpMeButton>
  );
};

const HelpMeButton = styled.div<{ useElegantFontType?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.main.greenMain};
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
`;

const Text = styled.span`
  color: ${theme.main.white};
  text-align: center;
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;
