import { useSentry } from './useSentry';

import client from 'lib/graphql/client';
import { IVR_TOKEN_EXCHANGE } from 'lib/graphql/mutations/IVRTokenExchange';
import { initChatWidgetYellowAI, updateChatWidgetYellowAI } from 'lib/utils';

export const useYellowAIChatbot = () => {
  const { captureException } = useSentry();

  async function fetchIVRToken() {
    let token: string | undefined;
    try {
      const response = await client.mutate({
        mutation: IVR_TOKEN_EXCHANGE,
      });

      token = response?.data?.ivrTokenExchange?.ivrToken ?? null;
    } catch (error) {
      captureException(error, { errorContext: 'fetchIVRToken' });
    }

    return token;
  }

  async function updateChatbotYellowAI(borrower) {
    try {
      const { id, phone, firstName } = borrower;

      if (firstName) {
        const borrowerIVRToken = await fetchIVRToken();
        if (borrowerIVRToken) {
          const borrowerDetails = {
            id,
            phone,
            name: firstName,
            borrowerIVRToken,
          };
          updateChatWidgetYellowAI(borrowerDetails);
        } else {
          captureException('updateChatbotYellowAI - IVRToken is null. Could not update chatbot', { borrower });
        }
      } else {
        captureException('updateChatbotYellowAI - firstName is null. Could not update chatbot', { borrower });
      }
    } catch (error) {
      captureException(error, { errorContext: 'updateChatbotYellowAI' });
    }
  }

  function initChatbotYellowAI() {
    initChatWidgetYellowAI();
  }

  return { initChatbotYellowAI, updateChatbotYellowAI };
};
