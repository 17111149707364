import React from 'react';
import styled from 'styled-components';
import Slide from '@mui/material/Slide';
import Backdrop from '@mui/material/Backdrop';

import useStore from 'lib/hooks/useStore';
import { Icon, Subtitle, Title } from 'lib/components';
import { useNavigation } from 'lib/hooks/useNavigation';
import { useTranslation } from 'react-i18next';

interface BackPressModalProps {
  show: boolean;
  hideModal: () => void;
  logoutPressed: () => void;
}

export const BackPressModal = React.memo(({ show, hideModal, logoutPressed }: BackPressModalProps) => {
  const { flowType } = useStore();
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();

  const restartApplication = () => {
    if (flowType === 'CheckoutFlow') {
      navigate(`choose-plan`);
    } else {
      logoutPressed();
    }
  };

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={show} onClick={hideModal}>
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <BackPressContainer>
          <InnerContainer>
            <BackPressHeader>
              <IconGap />
              <Title m={'24px 0px'}>{translate('backPressModal.title')}</Title>
              <Icon src={'close'} onClick={hideModal} />
            </BackPressHeader>
            <Subtitle m={'8px 0px 8px 0px'} textAlign="center">
              {translate('backPressModal.subTitle')}
            </Subtitle>
            <Subtitle m={'10px 0px 24px 0px'} textAlign="center">
              {translate('backPressModal.info')}
            </Subtitle>
            <BackPressButton onClick={restartApplication}>
              <Icon src={'logout'} m={'0px 10px'} />
              {translate('buttons.restartApplication')}
            </BackPressButton>
            <BackPressButton onClick={hideModal}>{translate('buttons.cancel')}</BackPressButton>
          </InnerContainer>
        </BackPressContainer>
      </Slide>
    </Backdrop>
  );
});

const BackPressContainer = styled.div`
  width: 90%;
  position: absolute;
  bottom: -15px;
  background-color: ${(props) => props.theme.main.white};
  z-index: 1000;
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 24px 30px;
  height: 380px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: 400px;
    border-radius: 6px;
  }
`;

const BackPressHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackPressButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 4px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  border: 1px solid ${(props) => props.theme.main.grayColor};
  background: ${(props) => props.theme.main.white};
  color: ${(props) => props.theme.main.midnightBlue};
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
