import { useEffect, useMemo, useRef } from 'react';
import { useMutation } from '@apollo/client';
import MenuItem from '@mui/material/MenuItem';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { PATCH_MERCHANT_PREFERENCE } from 'lib/graphql/mutations';
import { useNavigation } from 'lib/hooks/useNavigation';
import useStore from 'lib/hooks/useStore';

import { Box, Button, Container, Icon, Subtitle, TextField, Title } from 'lib/components';
import { ButtonContainer, ContentContainer } from 'lib/components/Common';
import { WPQSegmentNames } from 'lib/constants/segmentConstants';
import { useSegmentContext } from 'lib/hooks/Segment/useSegmentContext';
import { useTranslation } from 'react-i18next';

interface LocProps {
  id: string;
}

const LocationSelection = () => {
  const { navigate } = useNavigation();
  const { t: translate } = useTranslation();

  const { organization, locations, selectedLocation, setSelectedLocation, selectedApplication } = useStore();
  const [patchLocationPreference, { loading }] = useMutation(PATCH_MERCHANT_PREFERENCE);
  const { sendLoadSegmentEvent, sendActionSegmentEvent } = useSegmentContext();
  const loadSegmentController = useRef(true);

  const sortedLocations = useMemo(() => {
    const locationData = locations?.slice();
    return locationData?.sort((a, b) => a?.name?.localeCompare(b?.name));
  }, [locations]);
  const defaultLocation = {};

  const { handleSubmit, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: { location: selectedLocation || defaultLocation },
  });

  useEffect(() => {
    const findLocation = locations?.find((loc) => loc?.id === selectedApplication?.merchantId);
    if (findLocation) {
      setValue('location', findLocation);
    }
  }, [selectedApplication]);

  useEffect(() => {
    if (loadSegmentController.current) {
      sendLoadSegmentEvent(WPQSegmentNames.changeApprovalLocationLoaded);
      loadSegmentController.current = false;
    }
  }, [loadSegmentController]);

  const patchLocationPreferenceHandler = async (data) => {
    try {
      if (data?.location?.id && selectedApplication?.id) {
        await patchLocationPreference({
          variables: { input: { applicationId: selectedApplication?.id, merchantId: data?.location?.id } },
        });
      }
    } catch (err) {
      return false;
    }
  };

  const onSubmit = async (data) => {
    await patchLocationPreferenceHandler(data);
    const findLocation = locations?.find((loc: LocProps) => loc?.id === data?.location?.id);
    sendActionSegmentEvent(WPQSegmentNames.locationChangeContinueClicked);
    if (findLocation) {
      setSelectedLocation(findLocation);
    }

    navigate('approval');
  };

  return (
    <Container showBackButton={true}>
      <ContentContainer>
        <Icon src={'location-pin'} />
        <Title m={'10px 0px 8px 0px'}>{translate('locationSelection.title')}</Title>
        <Subtitle m={'0px 0px 24px 0px'}>
          {translate('locationSelection.subTitle', {
            name: organization?.name,
          })}
        </Subtitle>
        <Box>
          <Controller
            name="location"
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                id="location"
                variant="filled"
                data-testid="location"
                data-name="location"
                label={translate('locationSelection.form.label')}
                select={true}
                value={value}
                SelectProps={{
                  renderValue: (option) => option?.name,
                }}
                onChange={onChange}
                onBlur={(e) => {
                  sendActionSegmentEvent(WPQSegmentNames.locationChangeInputClicked);
                  sendActionSegmentEvent(WPQSegmentNames.locationChangeInputFilled, {
                    metaData: e.target.defaultValue,
                  });
                  onBlur();
                }}
                error={!!error}
                helperText={error ? error?.message : null}
              >
                {sortedLocations?.map((option) => (
                  <MenuItem data-testid="stateItem" key={option?.id} value={option}>
                    <MenuItemValue>
                      <Label primary={true}>{option?.name}</Label>
                      <Label>
                        {option?.address?.street} - {option?.address?.city}
                      </Label>
                    </MenuItemValue>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Box>
      </ContentContainer>
      <ButtonContainer>
        <Button loading={loading} disabled={loading} onClick={handleSubmit(onSubmit)}>
          {translate('buttons.continue')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const MenuItemValue = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const Label = styled.span<{ primary?: boolean }>`
  font-weight: 400;
  font-size: ${(props) => (props.primary ? '18px;' : '12px')};
  line-height: 19px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.main.textColor};
`;

export default LocationSelection;
