import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import styled from 'styled-components';
import { useNavigation } from 'lib/hooks/useNavigation';

import { Button, Icon, Title } from 'lib/components';
import { CalculationData, FormatAmount } from 'lib/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  hideModal: () => void;
  chosenPlan: CalculationData;
}

export const PaymentPlanModal = ({ show, hideModal, chosenPlan }: Props) => {
  const { t: translate } = useTranslation();
  const { navigate } = useNavigation();

  const closeModalOnClickAway = (e) => {
    if (e?.target?.ariaHidden) {
      hideModal();
    }
  };

  const handleSelectThisPlan = () => {
    navigate(`what-happens-now`);
  };

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={show}
      onClick={closeModalOnClickAway}
    >
      <Slide direction="up" in={show} mountOnEnter={true} unmountOnExit={true}>
        <PPModalContainer>
          <PPModalHeader>
            <IconGap />
            <Title m={'24px 0px 24px'}>
              {translate('paymentPlanModal.title', {
                term: chosenPlan?.product?.term,
              })}
            </Title>
            <Icon src={'close'} onClick={hideModal} hover={true} />
          </PPModalHeader>
          <ListContainer>
            <MainContainer>
              <SoloContainer>
                <Container>
                  <Header>
                    {translate('paymentPlanModal.title', {
                      term: chosenPlan?.product?.term,
                    })}
                  </Header>
                  <ContentContainer>
                    <ContentItemContainer>
                      <ContentTitle>{translate('paymentPlanModal.content.title')}</ContentTitle>
                      <ContentText>{FormatAmount(chosenPlan?.downPaymentAmount)}</ContentText>
                    </ContentItemContainer>
                    <ContentItemContainer>
                      <ContentBorder />
                    </ContentItemContainer>
                    <ContentItemContainer>
                      <ContentTitle>{translate('paymentPlanModal.content.subTitle')}</ContentTitle>
                      <ContentText>
                        {translate('paymentPlanModal.content.installmentAmount', {
                          installmentAmount: FormatAmount(chosenPlan?.installmentAmount),
                        })}
                      </ContentText>
                      <ContentSubText>
                        <b>
                          {translate('paymentPlanModal.content.subTitle', {
                            productTerm: chosenPlan?.product?.term,
                          })}
                        </b>
                        <br />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: translate('paymentPlanModal.content.totalFinanceCharge', {
                              financeCharge: FormatAmount(Number(chosenPlan?.financeCharge)),
                            }),
                          }}
                        />
                        <br />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: translate('paymentPlanModal.content.promoApr', {
                              promo: chosenPlan?.promo ? chosenPlan?.product?.promoApr : chosenPlan?.product?.apr,
                            }),
                          }}
                        />
                      </ContentSubText>
                    </ContentItemContainer>
                  </ContentContainer>
                </Container>
              </SoloContainer>

              <Button onClick={handleSelectThisPlan}>{translate('buttons.selectThisPlan')}</Button>
            </MainContainer>
          </ListContainer>
        </PPModalContainer>
      </Slide>
    </Backdrop>
  );
};

const PPModalContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: -15px;
  background-color: ${(props) => props.theme.main.white};
  box-shadow: 0px -36px 67px rgba(0, 0, 0, 0.07), 0px -13.14px 24.4561px rgba(0, 0, 0, 0.0482987),
    0px -6.38px 11.873px rgba(0, 0, 0, 0.0389404), 0px -3.13px 5.82036px rgba(0, 0, 0, 0.0310596),
    0px -1.24px 2.30138px rgba(0, 0, 0, 0.0217013);
  border-radius: 0 0 32px 32px;
  padding: 0 0 24px;

  @media (min-width: ${(props) => props.theme.size.mobileXl}) {
    top: 170px;
    width: auto;
    height: fit-content;
    border-radius: 6px;
    padding: 0;
  }
`;

const PPModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px 4px;
`;

const IconGap = styled.div`
  width: 24px;
  height: 24px;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SoloContainer = styled.div`
  margin: auto auto 24px;
`;

const Container = styled.div`
  position: relative;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.main['green']};
  border-radius: 8px;
  max-width: 100%;
  min-width: 327px;

  @media (max-width: ${(props) => props.theme.size.mobileXl}) {
    min-width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.main.white};
  background-color: ${(props) => props.theme.main['green']};
  padding: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const ContentContainer = styled.div`
  padding: 18px 18px 0;
`;

const MainContainer = styled.div`
  padding: 0 24px 24px;
`;

const ContentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
`;

const ContentTitle = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  color: ${(props) => props.theme.main.green};
`;

const ContentText = styled.span`
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: ${(props) => props.theme.main.textColor};
  margin-top: 4px;
`;

const ContentSubText = styled.span`
  font-style: italic;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.005em;
  color: ${(props) => props.theme.main.textColor};
`;

const ContentBorder = styled.div`
  border: 1px dashed ${(props) => props.theme.main.grayColor};
`;
