import { useEffect } from 'react';
import Router from 'Route';
import { useTranslation } from 'react-i18next';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { positions, Provider as AlertProvider } from 'react-alert';

import { theme } from 'config/theme';
import useStore from 'lib/hooks/useStore';
import { ALERT_TIMEOUT } from 'lib/constants';
import ApolloClient from 'lib/graphql/client';
import { useNeuroId } from 'lib/hooks/useNeuroId';
import { SSEContextProvider } from 'lib/hooks/SSE/SSEContext';
import { SegmentContextProvider } from 'lib/hooks/Segment/segmentContext';

import { AlertTemplate } from 'lib/components';

const alertOptions = {
  timeout: ALERT_TIMEOUT,
  position: positions.TOP_CENTER,
  containerStyle: {
    zIndex: theme.constants.zIndex + 1,
    marginBottom: '50px',
  },
};

const App = () => {
  const { setFunnel } = useNeuroId();

  const { defaultLanguage } = useStore();
  const { i18n } = useTranslation();

  useEffect(() => {
    setFunnel(window?.location?.pathname?.replace('/', ''));
  }, []);

  useEffect(() => {
    i18n?.changeLanguage(defaultLanguage);
  }, [defaultLanguage, i18n]);

  return (
    <ApolloProvider client={ApolloClient}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <BrowserRouter>
          <SSEContextProvider>
            <SegmentContextProvider>
              <Router />
            </SegmentContextProvider>
          </SSEContextProvider>
        </BrowserRouter>
      </AlertProvider>
    </ApolloProvider>
  );
};

export default App;
