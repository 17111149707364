import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import styled from 'styled-components';

import { theme } from 'config/theme';

interface Props {
  label: string | React.ReactElement;
  checked: boolean;
  onChange: (value: boolean) => void;
  activeColor?: string;
  removeBorder?: boolean;
  labelColor?: string;
}

const colors = {
  black: theme.main.black,
};

const getColor = (color: string) => {
  if (colors.hasOwnProperty(color)) {
    return colors[color];
  }

  return color;
};

const CheckboxItem = ({
  onChange,
  checked,
  label,
  activeColor = theme.main.green,
  removeBorder = false,
  labelColor,
}: Props) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e?.target?.checked);
  };

  return (
    <StyledFormControlLabel
      checked={checked}
      label={<StyledLabel labelColor={labelColor}>{label}</StyledLabel>}
      activeColor={activeColor}
      removeBorder={removeBorder}
      control={
        <Checkbox
          data-testid="checkbox"
          onChange={onChangeHandler}
          checked={checked}
          sx={{
            '&.Mui-checked': {
              color: activeColor ? getColor(activeColor) : '#1ec37c',
            },
          }}
        />
      }
    />
  );
};

export default CheckboxItem;

const StyledLabel = styled.span<{ labelColor?: string }>`
  font-family: ${theme.fonts.primary}, serif;
  color: ${theme.main.grayColor};
  color: ${(props) => (props.labelColor ? props.labelColor : props.theme.main.grayColor)};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

const StyledFormControlLabel = styled(FormControlLabel)<{ activeColor: string; removeBorder?: boolean }>`
  border: 1px solid
    ${(props) =>
      props.removeBorder ? 'transparent' : props.checked ? getColor(props?.activeColor) : props.theme.main.grayColor};
  box-shadow: ${(props) => (props.removeBorder ? 'transparent' : props.checked ? 'inset 0 0 0 1px #00c37d' : 'none')};

  margin: 8px 0 0 0 !important;
  border-radius: 4px;

  padding: ${(props) => (props.removeBorder ? '0' : '5px 16px')};
  cursor: pointer;
`;
