import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from 'lib/constants';
import useStore from 'lib/hooks/useStore';
import { Accordion, Title } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';

import faqManualReviewList from './faqManualReview.json';
import styled from 'styled-components';
import { theme } from 'config/theme';
import { useViewport } from 'lib/hooks/useViewport';

const FaqManualReviewList = () => {
  const { defaultLanguage } = useStore();
  const { t: translate } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>('panel0');
  const { isMobile } = useViewport();

  const handleChange = (panel: string) => {
    setExpanded(panel);
  };

  const backgroundColor = () => {
    return isMobile ? theme.main.whiteGray : theme.main.white;
  };

  return (
    <ContentContainerCustom IsMobile={isMobile}>
      <Title m={'10px 0 0 0'}>{translate('faqManualReview.title')}</Title>
      <Accordion
        bgColor={backgroundColor()}
        data={faqManualReviewList?.[defaultLanguage || LANGUAGES[0]]}
        onChange={handleChange}
        expanded={expanded}
      />
    </ContentContainerCustom>
  );
};

export default FaqManualReviewList;

const ContentContainerCustom = styled(ContentContainer)<{ IsMobile: boolean }>`
  background-color: ${(props) => (props?.IsMobile ? theme.main.whiteGray : theme.main.white)};
  padding: 0;
  margin-top: 24px;
`;
