export enum DefaultHighlineActiveSteps {
  HOUSING_PAYMENT = 'HOUSING_PAYMENT',
  STATED_INCOME = 'STATED_INCOME',
  EMAIL = 'EMAIL',
}

export interface DefaultHighlineProps {
  type: DefaultHighlineActiveSteps;
}

export interface DefaultHighlineComponentProps {
  loading: boolean;
  setMissingInfo: (info: string, type: DefaultHighlineProps['type']) => void;
}
