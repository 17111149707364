import { Controller } from 'react-hook-form';

import { AlleHeader, Box, Button, HaveQuestionsModal, Title, UnderlinedTextField } from 'lib/components';
import { ContentContainer } from 'lib/components/Common';
import { numericPattern } from 'lib/utils/Validators';
import styled from 'styled-components';
import { theme } from 'config/theme';

const AlleAddRemoteCheck = ({
  control,
  validRoutingNumber,
  validAccountNumber,
  isValid,
  handleSubmit,
  onSubmit,
  goBackHandler,
}) => {
  return (
    <ContentContainer>
      <AlleHeader />
      <PageTitle
        m={'10px 0px 8px 0px'}
        color={theme.main.black10}
        fontSize={'18px'}
        fontWeight={500}
        lineHeight={'32px'}
      >
        Add check
      </PageTitle>
      <Box>
        <InputLabel>Account number</InputLabel>
        <Controller
          name="accountNumber"
          control={control}
          rules={{ required: true, validate: validAccountNumber }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <UnderlinedTextField
              id="account-number"
              variant="filled"
              data-testid="accountNumber"
              type="tel"
              label="Account number"
              autoComplete="off"
              value={value}
              onKeyPress={(event) => {
                if (!numericPattern.test(event?.key)) {
                  event.preventDefault();
                }
              }}
              onChange={onChange}
              onBlur={onBlur}
              error={!!error}
              helperText={error ? 'Invalid Account Number' : null}
            />
          )}
        />
        <InputLabel>Routing number</InputLabel>
        <Controller
          name="routingNumber"
          control={control}
          defaultValue=""
          rules={{ required: true, validate: validRoutingNumber }}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <UnderlinedTextField
              id="routing-number"
              variant="filled"
              data-testid="routingNumber"
              type="tel"
              label="Routing number"
              autoComplete="off"
              value={value}
              onKeyPress={(event) => {
                if (!numericPattern.test(event?.key)) {
                  event.preventDefault();
                }
              }}
              onChange={onChange}
              onBlur={onBlur}
              error={!!error}
              helperText={error ? 'Invalid Routing Number' : null}
            />
          )}
        />
      </Box>
      <Button
        disabled={!isValid}
        onClick={handleSubmit(onSubmit)}
        black={true}
        uppercase={true}
        thinText={true}
        fontWeight={400}
        fontSize={11}
        m="24px 0 16px"
      >
        Continue
      </Button>
      <OutlinedButton onClick={goBackHandler}>Back</OutlinedButton>
      <HaveQuestionsModal />
    </ContentContainer>
  );
};

const PageTitle = styled(Title)`
  font-family: 'Graphik';
`;

const OutlinedButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.main.white};
  border: 1px solid ${(props) => props.theme.main.grayColor};
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 32px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => props.theme.main.textColor};
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  font-family: 'Graphik', sans-serif !important;
`;

const InputLabel = styled.label`
  color: ${(props) => props.theme.main.black20};
  font-family: 'Graphik', sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 40px;
`;

export default AlleAddRemoteCheck;
